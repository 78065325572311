import React, { useRef, useEffect, useState } from 'react'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { v4 as uuid } from 'uuid'
import QuestControls from 'components/questions/QuestionControls'
import UrlAnswer from '../UrlAnswer'
import PostAnswer from '../PostAnswer'
import AnswerControls from 'components/answers/AnswerControls'
import Video from '../Video'
import PostEmbed from '../PostEmbed'
import FileEmbed from '../FileEmbed'
import Connector from '../../state/Connector'
import AnswerImage from './AnswerImage'
import YouTube from 'react-youtube'
import mediumZoom from 'medium-zoom'
import api from 'api/api'
import LazyYoutube from 'components/shared/LazyYoutube'
import questModel from 'components/quests/questModel'
import { getValue, getText, getImages } from 'lib/value'
import answerModel from 'components/answers/answerModel'
import cn from 'classnames'
import styles from './answer.module.scss'
import { focus } from '../../../state/imperativeApis/focusApi'
import { isEqual, debounce } from 'lodash'
import { LinkPreview } from 'components/shared/LinkPreview'
import KnovLogo from 'public/icon128.png'
import * as cache from 'state/cache'
import { extractUrls } from 'components/shared/LinkPreview'
import { fileHandler } from 'components/answers/uploader'
import useStore from 'state/knovStore'
import Debug from 'components/shared/Debug'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import LockControl from 'components/answers/LockControl'
import VoteControl from 'components/answers/VoteControl'
import ThreadInfo from 'components/answers/ThreadInfo'
import useInsertRight from 'refactor/hooks/useInsertRight'
import useRemoveRight from 'refactor/hooks/useRemoveRight'
import usePanelContext from 'refactor/hooks/usePanelContext'
import { getSwiperRef, isPanelCenter, isPanelRight } from 'state/imperativeApis/swiperApi'
import JobStatus from 'components/answers/Answer/JobStatus'
import ImagePlaceholder from './ImagePlaceholder'
import useAnswerHighlight from 'refactor/hooks/useAnswerHighlight'
import AnswerGate from './AnswerGate'

const stateSelector = (state, props) => ({
    DEBUG: state.DEBUG,
    //answer: props.answer,
    isSelected:
        state.selectedAnswers[props.panelContext.panel?.panelId]?.answerId === props.answer?.id,
    selectedQuote: state.selectedQuote,
    answerJobs: state.answerJobs,
})

const sharedZoom = mediumZoom()

class Answer extends React.PureComponent {
    constructor(props) {
        super(props)
        this.touched = moment(this.props?.answer?.updated_at).format('MM/DD/YYYY')
        this.zoom = sharedZoom
        this.selectionCache = null

        const answer = this.props.answer
        this.state = {
            value: getValue(answer),
            editMode: false,
            command: answer?.command,
            vidStart: answer?.vid_start_time,
            vidLoop: answer?.vid_loop_time,
            showControlButtons: false,
            imageDownload: false,
            acted: false,
            showKnovigator: false,
            linkPreviews: extractUrls(getText(answer?.delta_json))?.nonKnovLinks || [],
            hasSeenGenerating: answer?.is_generating_image,
        }
        this.videoSourceRef = React.createRef()
        this.spinnerRef = React.createRef()
        this.downloadRef = React.createRef()
        this.containerRef = React.createRef()
        this.videoTagRef = React.createRef()
        this.uiId = 'answer-' + Date.now()
        this.ensureDraftAnswer()
    }

    zoomImageSelector = () =>
        `[data-zoomable='${this.uiId}'], [data-paste-image='${this.uiId}'] img`

    ensureDraftAnswer = () => {
        const answer = this.props.answer
        if (answer) {
            let draftAnswer = cache.getCachedAnswer(answer.draft_answer_id)
            //console.log('ensureDraftAnswer', answer.id, draftAnswer?.id)
            if (!draftAnswer) {
                draftAnswer = answerModel.getDraft(answer)
                cache.cacheAnswers([{ ...answer, draft_answer_id: draftAnswer.id }, draftAnswer])
            }
            return draftAnswer
        } else {
            return null
        }
    }

    getDraftAnswer = () => {
        return this.props.answer?.draft_answer_id
            ? cache.getCachedAnswer(this.props.answer?.draft_answer_id)
            : null
    }

    componentDidMount = () => {
        // This is meant to run hover if a new element is added.
        // Probably similar to what I deleted in PostEmbed TODO
        var myNode = this.containerRef && this.containerRef.current
        setTimeout(
            function () {
                if (myNode && myNode.parentElement?.querySelector(':hover') === myNode) {
                    this.onMouseEnter()
                }
            }.bind(this),
            0,
        )

        this.zoom.attach(this.zoomImageSelector(), { scrollOffset: 0 })
    }

    componentDidUpdate = async prevProps => {
        this.ensureDraftAnswer()

        if (
            prevProps.answer?.content != this.props.answer?.content ||
            !isEqual(
                prevProps.answer?.delta_json?.ops || [],
                this.props.answer?.delta_json?.ops || [],
            )
        ) {
            this.setState({
                value: getValue(this.props.answer),
                linkPreviews:
                    extractUrls(getText(this.props.answer?.delta_json))?.nonKnovLinks || [],
            })
        }

        if (!prevProps.answer?.is_generating_image && this.props.answer?.is_generating_image) {
            this.setState({ hasSeenGenerating: true })
        }
    }

    editMode = () => this.state.editMode

    showDownload = async newAnswer => {
        const videoTime = this.videoSourceRef?.current?.currentTime
        const isPlaying =
            this.videoTagRef?.current?.currentTime > 0 && !this.videoTagRef.current.ended

        if (!isPlaying) {
            //console.log('show download not playing')
            this.videoSourceRef?.current?.setAttribute('type', `video/mp4`)
            this.videoSourceRef?.current?.setAttribute(
                'src',
                `${newAnswer?.mp4_recording_url}#t=${videoTime > 0.1 ? videoTime : 0.1}`,
            )
        } else if (this.videoTagRef?.current && this.videoSourceRef?.current) {
            //console.log('show download playing')
            this.videoTagRef.current.onended = () => {
                //console.log('show download onend')
                this.videoSourceRef.current.setAttribute('type', `video/mp4`)
                this.videoSourceRef.current.setAttribute(
                    'src',
                    `${newAnswer?.mp4_recording_url}#t=${videoTime > 0.1 ? videoTime : 0.1}`,
                )
            }
        }

        this.spinnerRef?.current?.setAttribute('style', 'display: none;')
        if (this.downloadRef?.current?.firstChild)
            this.downloadRef.current.firstChild.setAttribute('href', newAnswer?.mp4_recording_url)
        this.downloadRef?.current?.setAttribute('style', 'display: block;')
    }

    setEmbeds = embeds => {
        const draftAnswer = this.getDraftAnswer()
        const embedIds = (embeds || []).map(e => e.id)
        const currentEmbedIds = (draftAnswer?.embeds || []).map(e => e.id)
        if (!isEqual(currentEmbedIds, embedIds)) {
            cache.updateCachedAnswer(draftAnswer?.id, { embeds })
        }
    }

    setLinkPreviews = linkPreviews => this.setState({ linkPreviews: linkPreviews || [] })

    componentWillUnmount = () => {
        clearInterval(window.answerScrollTimer)
        this.zoom.detach(this.zoomImageSelector(), { scrollOffset: 0 })
    }

    getDomContent = () => {
        return this.postAnswer?.content.content.innerText
    }

    isYouTube = () => this.props?.answer?.yt_app
    vidId = () =>
        this.isYouTube() && this.props.answer?.url ? this.props.answer?.url.video_id : null
    vidWidth = () => '100%'
    vidHeight = () => '100%'

    getYtPlayer = () => this.ytPlayer

    toggleEdit = ev => {
        this.setState(
            state => {
                // Hide scroll button when editing.
                this.props.panelContext.setExternalHideScrollButton(!state.editMode)

                return {
                    editMode: !state.editMode,
                    showBottomControls: !state.showBottomControls,
                    showControlsPopup: false,
                    value: getValue(this.props.answer),
                }
            },
            () => {
                if (this.editMode()) {
                    // In uncontrolled mode, focus is handled by onEditorLoad in PostAnswer
                    // We don't need to call focus() directly anymore
                }
            },
        )
    }

    toggleArchiveConfirm = ev => {
        this.setState(state => {
            return {
                archiveConfirm: !state.archiveConfirm,
                showBottomControls: !state.showBottomControls,
                showControlsPopup: false,
            }
        })
    }

    toggleDeleteConfirm = ev => {
        this.setState(state => {
            return {
                deleteConfirm: !state.deleteConfirm,
                showBottomControls: !state.showBottomControls,
                showControlsPopup: false,
            }
        })
    }

    getEditor = () => this.postAnswer?.current?.getEditor()
    getDeltaJson = () => this.getEditor()?.getContents()
    getContent = () => getText(this.getDeltaJson() || {}).trim()

    copyContent = () => getText(this.props.answer?.delta_json)

    getPublicLink = async () => {
        const quest = await api.getCommentQuest(this.props?.answer?.id)

        return quest?.quest_url
    }

    hasFiles = () => !!this.state.files?.length
    hasVideo = () => !!this.state.video
    hasImage = () => !!this.state.imgPreview

    savePost = async () => {
        const content = this.getContent()
        const deltaJson = this.getDeltaJson()

        if (this.validContent()) {
            this.toggleEdit()

            const now = new Date()

            const answer = this.props.answer
            const draftAnswer = this.getDraftAnswer()
            // Merge draft in.
            const newAnswer = {
                ...answer,
                ...draftAnswer,
                id: answer.id,
                content: content,
                delta_json: deltaJson,
                embeds: draftAnswer.embeds || [],
                updated_at: now,
            }

            cache.cacheAnswer(newAnswer)
            // Bring draft into parity with answer to be published.
            cache.cacheAnswer(
                { ...answerModel.getDraft(newAnswer), id: draftAnswer.id },
                { override: true },
            )
            api.updateAnswer(answer?.id, {
                updated_at: now,
                content,
                delta_json: deltaJson,
                embeds: newAnswer.embeds,
                files: newAnswer.files,
                recording: newAnswer.recording,
                answer_image: newAnswer.answer_image,
            })
        }
    }

    updatePost = async () => {
        const content = this.getContent()
        const deltaJson = this.getDeltaJson()

        if (this.validContent()) {
            const answer = await api.updateAnswer(this.props.answer?.id, {
                content: content,
                delta_json: deltaJson,
            })
            return answer
        } else {
            return null
        }
    }

    squashEventInEditMode = ev => {
        if (this.editMode()) {
            ev.preventDefault()
            ev.stopPropagation()
        }
    }

    squashEvent = ev => {
        ev.preventDefault()
        ev.stopPropagation()
    }

    validContent = () => {
        const draftAnswer = this.getDraftAnswer()
        const embeds = draftAnswer?.embeds
        const content = this.getContent()
        const deltaJson = this.getDeltaJson()
        const hasFile = draftAnswer?.files?.length > 0
        const hasImage = draftAnswer?.answer_image_url || getImages(deltaJson)

        return (
            content.length ||
            getText(deltaJson) ||
            hasImage ||
            embeds?.length ||
            // TODO how is this calculated? should this be in the draft quest?
            this.props.answer?.url ||
            hasFile
        )
    }

    insertAnswer = async ev => {
        ev.preventDefault()
        ev.stopPropagation()
        this.props.insertAnswer(this.props.answer?.id)
    }

    loadAndPlay = () => {
        this.setState({ [`yt_${this.vidId()}`]: true })
    }

    isUserSignedIn = () => !!gon.currentUser
    isOwner = () => gon.currentUser && gon.currentUser.id === this.props.answer?.user_id

    showInsert = () => {
        if (typeof this.props.showInsert === 'undefined')
            return this.props.showAll && this.isUserSignedIn() && this.isOwner()
        else return this.props.showInsert
    }

    setImageDownload = imageDownload => {
        this.setState({ imageDownload })
    }

    setControlButtons = show => {}

    onMouseEnter = ev => {
        if (this.isSystemUser() || isMobile || this.props.isEmbed) return

        this.setState({
            hover: true,
            showControlButtons: true,
        })
    }
    onMouseLeave = ev => {
        if (this.isSystemUser() || isMobile || this.props.isEmbed) return

        this.setState({
            hover: false,
            showControlButtons: false,
        })
    }

    isSelected = () => {
        if (this.props.isTitle) return false
        const panel = this.props.panelContext.panel
        if (!panel) return false

        return useStore.getState().selectedAnswers[panel.panelId]?.answerId === this.props.answer.id
    }

    hasValidChildPanel = () => {
        const panel = this.props.panelContext.panel
        if (!panel) return false

        const panelToRight = useStore.getState().panels.getPanelToRight(panel.panelId)
        const childQuest = this.props.answer.child_quests?.[0]

        return (
            panelToRight &&
            !panelToRight.empty &&
            childQuest &&
            panelToRight.filter?.questId === childQuest.id
        )
    }

    toggleSelectedAnswer = async ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (!this.canToggle(ev)) return

        const { answer, panelContext, insertRight, removeRight } = this.props
        const panel = panelContext.panel
        const panelId = panel.panelId
        const panelToRight = useStore.getState().panels.getPanelToRight(panelId)
        const selected = this.isSelected()

        // Only make API call if we don't have child_quests data
        let childQuestId
        if (answer.child_quests?.[0]?.id) {
            childQuestId = answer.child_quests[0].id
        } else {
            const commentQuest = await api.getCommentQuest(answer.id)
            childQuestId = commentQuest?.id
        }

        if (selected && this.hasValidChildPanel()) {
            // Already selected and next panel is child quest.

            if (isMobile || isPanelRight(panelId)) {
                const swiper = getSwiperRef()?.current
                setTimeout(function afterSlide() {
                    panelToRight.highlightAnswer(answer.id)
                    clearTimeout(afterSlide)
                }, 500)
                swiper.slideNext()
            } else {
                useStore.getState().panels.getPanelToRight(panelId).shake()
            }
            const panelToRight = useStore.getState().panels.getPanelToRight(panel.panelId)
            this.props.highlightAnswer(answer.id, panelToRight.panelId)
        } else if (childQuestId) {
            // Create panel with id so we can highlight the answer in the new panel.
            const rightPanelId = uuid()
            insertRight({
                panelId: rightPanelId,
                filter: { questId: childQuestId, answerId: answer.id },
            })
            // Add highlight animation for the answer in the new panel
            useStore.getState().set(state => {
                state.selectedAnswers[panelId] = {
                    answerId: answer.id,
                }
            })
        }
    }

    isTitleSelected = () => {
        if (!this.props.isTitle) return false
        const panel = this.props.panelContext.panel
        const panelToLeft = useStore.getState().panels.getPanelToLeft(panel.panelId)
        if (useStore.getState().selectedAnswers[panelToLeft.panelId]) {
            return (
                useStore.getState().selectedAnswers[panelToLeft.panelId].answerId ===
                this.props.answer.id
            )
        } else {
            return false
        }
    }

    deleteEmbed = embedId => {
        if (this.props.deleteEmbed && this.props.quest) {
            useStore.getState().deleteEmbed(this.props.quest, this.props.answer, embedId)
            const newEmbeds = this.props.answer.embeds.filter(e => e.id !== embedId)
            this.setEmbeds({ embeds: newEmbeds })
        }
    }

    clearSelection = () => {
        const selection = window.getSelection()
        const hasTextSelection = selection.toString().length > 0
        // If there is a text selection we want to clear it instead of toggling selected answer.
        if (hasTextSelection) {
            let anchor = selection.anchorNode
            if (anchor.nodeType !== Node.ELEMENT_NODE) anchor = anchor.parentElement
            const anchorInSelection = !!anchor.closest('.with-selection-comp')
            if (!anchorInSelection || this.clickOutsideSelection) {
                selection.removeAllRanges()
            }
            this.clickOutsideSelection = null
            return true
        } else return false
    }

    canToggle = ev => {
        return (
            !this.state.showControlsPopup &&
            !this.state.showBottomControls &&
            !this.isSystemUser() &&
            // Make sure no text is selected, clear it if there is.
            !this.clearSelection() &&
            !this.props.isEmbed &&
            !window.isPanelScrolling &&
            // Catch event from wierd hidden form for image upload.
            !ev?.target?.className.includes('image-upload-control') &&
            this.props.quest
        )
    }

    childCount = () => {
        const answer = this.props.answer
        if (this.props.isTitle) {
            return questModel.showUnarchivedAnswers(this.props.quest)?.length
        } else if (answer?.child_answer_count) {
            return answer?.child_answer_count
        } else {
            let count = 0
            const childQuests = this.props?.answer?.child_quests
            if (childQuests) {
                const childQuest = childQuests.find(quest => quest.side_quest)
                const quest = useStore.getState().quests[childQuest?.quest_id] || childQuest
                if (quest && quest.sorted_answers)
                    count = quest.sorted_answers.filter(ans => !ans.archive).length
            }
            return count
        }
    }

    showGate = () => {
        const isActiveTitle = this.props.isActive && this.props.isTitle
        const hover = this.state.hover && !isActiveTitle
        const isTitleInStream = !this.props.isActive && this.props.isTitle
        const selectedOrChildCount =
            !this.props.isTitle && (this.isSelected() || this.childCount() > 0)
        return !this.isSystemUser() && (hover || isTitleInStream || selectedOrChildCount)
    }

    postAnswer = React.createRef()
    title = React.createRef()

    youtubeStart = duration => {
        if (
            this.state.vidStart &&
            0 <= parseInt(this.state.vidStart, 10) &&
            parseInt(this.state.vidStart, 10) < duration
        ) {
            return parseInt(this.state.vidStart, 10)
        } else {
            return 0
        }
    }

    toggleShowKnovigator = () => {
        if (gon && gon.currentUser && gon.currentUser.id === this.props.answer?.user.id)
            this.setState(state => {
                return { showKnovigator: !state.showKnovigator }
            })
    }

    deleteFile = (attachmentId, answerId) => {
        fetch(`${window.knovApiUrl}/answers/delete_file`, {
            method: 'POST',
            body: JSON.stringify({
                attachment_id: attachmentId,
                answer_id: answerId,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    fileHandler = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        const files = ev.target.files
        fileHandler(files?.[0], this.getDraftAnswer()?.id)
    }

    videoHandler = video => {
        if (video) fileHandler(video, this.getDraftAnswer()?.id)
    }

    clearEdits = () => {
        this.setState({
            files: null,
            video: null,
            videoUrl: null,
            imgPreview: null,
            imgUrl: null,
        })
    }

    onClickTitle = ev => {
        if (this.isSystemUser() || this.state.showControlsPopup || this.editMode()) return
        ev.preventDefault()
        ev.stopPropagation()

        this.clearSelection()
        this?.props?.titleAnswerProps?.onClickTitle(ev)
    }

    toggleControlsPopup = () =>
        this.setState(state => ({ showControlsPopup: !state.showControlsPopup }))
    toggleBottomControls = () =>
        this.setState(state => ({ showBottomControls: !state.showBottomControls }))

    isSystemUser = () => {
        const answer = this.props.answer
        return answer?.is_system || answer?.user?.system || answer?.user_id === gon.SYSTEM_USER
    }

    isSystemQuoteNoti = () => this.isSystemUser() && this.props.answer.action_tags?.quoted

    answerImageRef = React.createRef()

    render() {
        const debug = this.props.DEBUG
        const answer = this.props.answer
        const draftAnswer = this.getDraftAnswer()
        const linkPreviews = this.state.linkPreviews || []
        const quest = this.props.quest
        //console.log('ANSWER RENDERING', answer.id, answer)
        const postClass = answer?.is_post ? 'post' : ''
        const vidLoaded = this.state[`yt_${this.vidId()}`] ? true : false
        const childCount = this.childCount()
        const jobStatus = this?.props?.answerJobs?.[answer?.id]

        let selectedClass = ''
        let gateSelectedClass = ''
        let mobileSelectedStyles
        if (this.isSelected()) {
            gateSelectedClass = 'selected selected-child-quests'
            if (isMobile) mobileSelectedStyles = styles.mobileSelected
            else selectedClass = 'selected selected-child-quests'
        }

        const fileAnswer = this.editMode() ? draftAnswer : answer

        const imageUrl =
            fileAnswer?.answer_image_url &&
            (this.editMode()
                ? fileAnswer?.answer_image_url
                : `${window.knovApiUrl}/api/v1/answers/${answer?.id}/image`)
        const fileUrl = fileAnswer?.file_urls?.[0]

        const systemUser = this.isSystemUser()
        const systemQuoteNoti = this.isSystemQuoteNoti()
        const answerContentContainerStyle = systemQuoteNoti
            ? styles.systemUserContentContainer
            : styles.answerContentContainer
        const isTitle = this.props.isTitle
        const stylesTitleContentContainer = isTitle ? styles.titleContentContainer : null
        const isEmbed = this.props.isEmbed
        const stylesEmbed = isEmbed ? styles.embed : null
        const stylesEmbedStatusContainer = isEmbed ? styles.embedStatusContainer : null
        const stylesTitleYouTubeSpacer = isTitle ? styles.titleYouTubeSpacer : null
        const hasAnnotation = answerModel.hasAnnotation(this.props.answer, this.getEditor())
        const showPostAnswer = !(systemUser && answer.action_tags?.quoted)
        const storyClass =
            this.props.isStory && !this.props.isMobile ? 'desktop-story' : 'mobile-story'
        const storyStyles = this.props.isStory ? styles.story : null
        const isActive = this.props.isActive
        const payloadContainerStyles = isMobile
            ? styles.mobilePayloadContainer
            : styles.payloadContainer

        const showVideo = fileAnswer?.mp4_recording_url || fileAnswer?.recording_url

        const activeTitleStyles = isActive && isTitle ? styles.activeTitleStyles : null

        const isCenter = isPanelCenter(this.props.panelContext.panelId)
        const isCenterActiveTitle = isActive && isTitle && isCenter

        const hoverClass =
            //!this.isSelected() &&
            (
                isCenterActiveTitle
                    ? false
                    : this.state.hover || (this.props.isTitle && this.props.questionHover)
            )
                ? styles.hover
                : null

        const mobileStyles = isMobile && !isTitle && !isEmbed ? styles.mobileAnswerComp : null
        const chatGptStyles =
            answer.message_type === 'chatgpt' || answer.message_type === 'agent'
                ? styles.chatGpt
                : null

        const showSystemQuotedHeader = systemUser && answer.action_tags?.['quoted']
        let embedContainerStyles
        if (isTitle) embedContainerStyles = styles.titleEmbedContainer
        else if (showSystemQuotedHeader) embedContainerStyles = styles.systemQuotedEmbedContainer
        else embedContainerStyles = styles.embedContainer

        const urlAnswerContainerStyles = isEmbed
            ? styles.embedUrlAnswerContainer
            : styles.urlAnswerContainer

        const noSelectClass = isMobile && !this.editMode() && !isTitle ? 'noselect' : ''

        let mobileControlContainerStyles
        let controlContainerStyles
        if (isMobile && this.editMode())
            controlContainerStyles = styles.mobileEditAnswerControlContainer
        else if (isMobile) controlContainerStyles = styles.mobileAnswerControlContainer

        const embeds = this.editMode() ? draftAnswer?.embeds : answer?.embeds

        const emptyPayload = !(
            this.isYouTube() ||
            showVideo ||
            fileAnswer?.answer_image_url ||
            embeds?.length > 0
        )
        const emptyContentStyles =
            answerModel.empty(answer) && !this.editMode() && emptyPayload
                ? styles.emptyContent
                : null

        const showQuestControls = isTitle && (!systemUser || systemQuoteNoti)
        //const showControls = this.editMode() && (isTitle && (!systemUser || systemQuoteNoti)) // We need this separated out in the case of PostEmbed.
        const showControlButtons = true //this.props.isMobile || this.state.showControlButtons

        const titleControlsContainerStyles = isTitle ? styles.titleControlsContainer : null

        const b = this.props.CONTROL_BUTTONS
        let questionControlButtons = b ? [b.filter, b.sort, b.edit, b.copy, b.play, b.collapse] : []
        if (b && !isMobile) questionControlButtons.push(b.search)
        const isBsv = this.props.isBsv

        // Add column prop to UserName for full layout
        const isIndentLayout =
            !this.props.isTitle && gon?.currentUser?.space_options?.layout === 'indent'

        const Comp = (
            <div
                ref={this.containerRef}
                id={`answer-${answer?.id}`}
                className={cn(
                    noSelectClass,
                    styles.answerComp,
                    activeTitleStyles,
                    //isCenterActiveTitle && styles.centerActiveTitle,
                    isCenterActiveTitle && 'center-active-title',
                    !isActive && isTitle && styles.streamAnswer,
                    mobileStyles,
                    mobileSelectedStyles,
                    chatGptStyles,
                    storyStyles,
                    isIndentLayout && styles.indentLayout,
                    isTitle ? 'is-title-answer' : '',
                    `answer comp answer-comp partial ${postClass} ${selectedClass} ${storyClass}`,
                )}
                data-answer-id={answer?.id}
                data-panel-id={this.props.panelContext.panelId}
                data-quest-id={this.props.answer?.quest_id}
                data-edit-mode={this.editMode()}
                onClick={ev => {
                    if (isTitle) this.onClickTitle(ev)
                    else this.squashEventInEditMode(ev)
                }}
            >
                {!isActive && isTitle && <div className={styles.rightBorder} />}
                {/*<div className={`acted-noti ${actedClass}`} />*/}

                {this.showInsert() && (
                    <div className="insert-container">
                        <div className="insert-answer-btn" onClick={this.insertAnswer}>
                            <i className="fa fa-plus" />
                        </div>
                    </div>
                )}

                {this.props.answer?.archive && (
                    <div className={cn(styles.statusContainer, stylesEmbedStatusContainer)}>
                        <i className="fa fa-folder" /> archived
                    </div>
                )}

                <div
                    className={cn(
                        answerContentContainerStyle,
                        stylesTitleContentContainer,
                        stylesEmbed,
                    )}
                >
                    {debug && (
                        <Debug
                            quest={quest}
                            answer={answer}
                            draftAnswer={this.getDraftAnswer()}
                            position={answer.position}
                            ix={this.props.ix}
                            answerUpdatedAt={answer.updated_at?.toString()}
                        />
                    )}

                    {this.state.showControlsPopup && !this.state.showBottomControls && (
                        <div className={styles.answerControlsPopupContainer}>
                            <AnswerControls
                                answer={answer}
                                isBsv={isBsv}
                                isTitle={isTitle}
                                answerHover={true} //this.state.hover}
                                quest={this.props.quest}
                                addAnswer={this.props.addAnswer}
                                removeAnswer={this.props.removeAnswer}
                                editMode={this.editMode()}
                                archiveConfirm={this.state.archiveConfirm}
                                deleteConfirm={this.state.deleteConfirm}
                                toggleEdit={this.toggleEdit}
                                toggleArchiveConfirm={this.toggleArchiveConfirm}
                                toggleDeleteConfirm={this.toggleDeleteConfirm}
                                toggleControlsPopup={this.toggleControlsPopup}
                                toggleBottomControls={this.toggleBottomControls}
                                popup
                                saving={this.state.saving}
                                savePost={this.savePost}
                                imgHandler={this.fileHandler}
                                fileHandler={this.fileHandler}
                                videoHandler={this.videoHandler}
                                mobileVideoHandler={this.fileHandler}
                                updatePost={this.updatePost}
                                showControlButtons={showControlButtons}
                                showBranching={this.props.showBranching}
                                copyContent={this.copyContent}
                                getPublicLink={this.getPublicLink}
                                content={this.getContent()}
                                selectedAnswer={this.props.selectedAnswer}
                                setImageDownload={this.props.setImageDownload}
                                showEmbedButton={this.props.showEmbedButton}
                                panel={this.props.panel}
                                systemQuoteNoti={systemQuoteNoti}
                            />
                        </div>
                    )}

                    {showPostAnswer && (
                        <PostAnswer
                            key={`post-answer-${answer.id}-${this.editMode()}`}
                            isIndentLayout={isIndentLayout}
                            isBsv={isBsv}
                            ref={this.postAnswer}
                            isTitle={isTitle}
                            isTail={this.props.isTail}
                            answer={answer}
                            quest={quest}
                            textIsCollapsed={this.props.textIsCollapsed}
                            setTextIsCollapsed={this.props.setTextIsCollapsed}
                            COLLAPSE_POST_LENGTH={this.props.COLLAPSE_POST_LENGTH}
                            isEmbed={isEmbed}
                            value={answer.delta_json}
                            editMode={this.editMode()}
                            savePost={this.savePost}
                            showVotes={this.props.showVotes}
                            answerHover={this.state.hover}
                            updateAnswer={this.props.updateAnswer}
                            removeAnswer={this.props.removeAnswer}
                            saving={this.state.saving}
                            updatePost={this.updatePost}
                            imgHandler={this.fileHandler}
                            showBranching={this.props.showBranching}
                            selectedAnswer={this.props.selectedAnswer}
                            isAnswerSelected={this.isSelected()}
                            setImageDownload={this.setImageDownload}
                            showEmbedButton={this.props.showEmbedButton}
                            setEmbeds={this.setEmbeds}
                            setLinkPreviews={this.setLinkPreviews}
                            embeds={embeds}
                            panel={this.props.panel}
                            copyContent={this.copyContent}
                            getPublicLink={this.getPublicLink}
                            getContent={this.getContent}
                            blog={this.props.blog}
                            showKnovigator={this.state.showKnovigator || answer.is_system}
                            toggleShowKnovigator={this.toggleShowKnovigator}
                            uiId={this.uiId}
                            systemUser={systemUser}
                            isStory={this.props.isStory}
                            // TopAnswerControls props.
                            toggleEdit={this.toggleEdit}
                            showControlsPopup={this.state.showControlsPopup}
                            toggleControlsPopup={ev => this.setState({ showControlsPopup: true })}
                            archiveConfirm={this.state.archiveConfirm}
                            toggleArchiveConfirm={this.toggleArchiveConfirm}
                            isUserSignedIn={this.isUserSignedIn()}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                        />
                    )}
                </div>

                {answer?.url && (
                    <div
                        className={cn(
                            urlAnswerContainerStyles,
                            this.props.isTitle && styles.titleUrlAnswerContainer,
                        )}
                    >
                        <UrlAnswer
                            ref={el => (this.urlAnswer = el)}
                            isTitle={isTitle}
                            answer={answer}
                            filter={this.props.filter}
                            panel={this.props.panel}
                            hasAnnotation={hasAnnotation}
                            command={this.state.command}
                            vidStart={this.state.vidStart}
                            vidLoop={this.state.vidLoop}
                            quest={this.props.quest}
                            getYtPlayer={this.getYtPlayer}
                            editMode={this.editMode()}
                            vidLoaded={vidLoaded}
                            loadVid={() => this.setState({ [`yt_${this.vidId(0)}`]: true })}
                            showVotes={this.props.showVotes && !hasAnnotation && !this.editMode()}
                            isEmbed={this.props.isEmbed}
                            answerHover={this.state.hover}
                            updateAnswer={this.props.updateAnswer}
                            removeAnswer={this.props.removeAnswer}
                            showEmbedButton={this.props.showEmbedButton}
                            updatePost={this.updatePost}
                            copyContent={this.copyContent}
                            getContent={this.getContent}
                            setImageDownload={this.setImageDownload}
                            showKnovigator={this.state.showKnovigator}
                            toggleShowKnovigator={this.toggleShowKnovigator}
                            uiId={this.uiId}
                            isYoutube={this.isYouTube()}
                            inViewCallback={this.props.inViewCallback}
                        />
                    </div>
                )}

                {fileUrl && (
                    <div
                        key={`files-${this.props.panel}-${this.props.answer?.id}`}
                        className={cn(styles.filesContainer)}
                    >
                        <FileEmbed
                            panel={this.props.panel}
                            file={fileUrl}
                            answerId={answer?.id}
                            canDelete={gon.currentUser?.id === answer?.user_id}
                            showDelete={gon.currentUser?.id === answer?.user_id && this.editMode()}
                            deleteFile={this.deleteFile}
                            hover={this.state.hover}
                        />
                    </div>
                )}

                {!this.isYouTube() && fileAnswer?.answer_image_url && (
                    <AnswerImage
                        ref={this.answerImageRef}
                        uiId={this.uiId}
                        answer={answer}
                        draftAnswer={this.editMode() ? draftAnswer : null}
                        hasSeenGenerating={this.state.hasSeenGenerating}
                        imageSize={this.props.imageSize}
                        isTitle={this.props.isTitle}
                        isActive={isActive}
                        path={fileAnswer?.path}
                        downloading={this.state.imageDownload}
                        isEmbed={isEmbed}
                        showDownload={isMobile && !isEmbed}
                        toggle={() => {
                            const el = this.answerImageRef.current
                            //console.log('zoome el', el)
                            if (el) this.zoom?.toggle({ target: el })
                        }}
                    />
                )}

                {(answer?.is_generating_image || answer?.image_generation_failed) && (
                    <div
                        className={cn(
                            styles.generatingImageContainer,
                            this.props.isTitle && styles.titleGeneratingImageContainer,
                        )}
                    >
                        <ImagePlaceholder failed={answer?.image_generation_failed} />
                    </div>
                )}

                {this.isYouTube() && (
                    <div
                        className={cn(
                            payloadContainerStyles,
                            this.props.isTitle && styles.titlePayloadContainer,
                            styles.youtubeSpacer,
                            stylesTitleYouTubeSpacer,
                        )}
                        onClick={ev => {
                            ev.preventDefault()
                            ev.stopPropagation()
                        }}
                    >
                        <div className="youtube-container">
                            <div className="youtube-video" data-answer-id={`${answer?.id}`}>
                                {!!this.state[`yt_${this.vidId()}`] ? (
                                    <YouTube
                                        enablejsapi={1}
                                        origin="https://knovigator.com"
                                        containerClassName={'react-youtube-container'}
                                        videoId={this.vidId()}
                                        opts={{
                                            origin: 'https://knovigator.com',
                                            enablejsapi: 1,
                                            width: this.vidWidth(),
                                            height: this.vidHeight(),
                                            playerVars: {
                                                start: this.state.vidStart,
                                                origin: 'https://knovigator.com',
                                                enablejsapi: 1,
                                            },
                                        }}
                                        onReady={ev => {
                                            const player = (this.ytPlayer = ev.target)
                                            player.seekTo(
                                                this.youtubeStart(player.getDuration()),
                                                true,
                                            )
                                            player.playVideo()
                                        }}
                                    />
                                ) : (
                                    <LazyYoutube
                                        videoId={this.vidId()}
                                        imageUrl={imageUrl}
                                        onPlay={this.loadAndPlay}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {showVideo && (
                    <div
                        className={cn(
                            payloadContainerStyles,
                            styles.videoContainer,
                            this.props.isTitle && styles.titlePayloadContainer,
                        )}
                        onClick={ev => ev.stopPropagation()}
                    >
                        <Video
                            //editedVideoUrl={editedVideoUrl}
                            //editedVideoType={this.state.video?.type}
                            answer={fileAnswer}
                            refObj={{
                                videoSourceRef: this.videoSourceRef,
                                spinnerRef: this.spinnerRef,
                                downloadRef: this.downloadRef,
                                videoTagRef: this.videoTagRef,
                            }}
                            isTitle={this.props.isTitle}
                            showDownload={this.showDownload}
                            editMode={this.editMode()}
                        />
                    </div>
                )}

                {jobStatus && <JobStatus jobStatus={jobStatus} />}

                {!this.props.isEmbed &&
                    linkPreviews &&
                    linkPreviews
                        .filter(lp => {
                            // don't show link previews if the answer already has a url for this address
                            return this?.props?.answer?.url?.address !== lp
                        })
                        .slice(0, 1)
                        .map((href, i) => (
                            <div key={i} className={cn(styles.embedContainer)}>
                                <LinkPreview
                                    url={href}
                                    urlModel={this.props.answer.url}
                                    // Title.
                                    onHeadEnter={this.props.onHeadEnter}
                                    onHeadLeave={this.props.onHeadLeave}
                                    // Answer.
                                    onMouseEnterAnswer={this.onMouseEnter}
                                    onMouseLeaveAnswer={this.onMouseLeave}
                                />
                            </div>
                        ))}

                {!this.props.isEmbed &&
                    !!embeds?.length &&
                    embeds.map(embed => {
                        return (
                            <div key={embed.id} className={cn(embedContainerStyles)}>
                                {showSystemQuotedHeader && (
                                    <div className={styles.embedHeaderContainer}>
                                        <div className={cn(styles.knovIcon, styles.notiIcon)}>
                                            {true && <i className="fa fa-bell" />}
                                            {false && <img src={KnovLogo} />}
                                        </div>

                                        <div className={styles.embedHeader}>
                                            This message was quoted.
                                        </div>
                                    </div>
                                )}

                                <PostEmbed
                                    label="QUOTE"
                                    isQuote
                                    panel={this.props.panel}
                                    answer={embed}
                                    parentAnswer={this.props.answer}
                                    containerQuest={this.props.quest}
                                    containerAnswer={this.props.answer}
                                    editMode={this.editMode()}
                                    delete={this.deleteEmbed}
                                    // Passed down from Quest if Answer is a parent answer and the head/title of a quest.
                                    isTitle={this.props.isTitle}
                                    // Title.
                                    onHeadEnter={this.props.onHeadEnter}
                                    onHeadLeave={this.props.onHeadLeave}
                                    // Answer.
                                    onMouseEnter={this.onMouseEnter}
                                    onMouseLeave={this.onMouseLeave}
                                />
                            </div>
                        )
                    })}

                {this.state.showBottomControls && ( //showControls && (!isTitle || this.editMode()) && (
                    <div
                        className={cn(
                            styles.answerControlContainer,
                            mobileControlContainerStyles,
                            titleControlsContainerStyles,
                            emptyContentStyles,
                        )}
                    >
                        <AnswerControls
                            answer={answer}
                            isTitle={isTitle}
                            answerHover={true} //this.state.hover}
                            quest={this.props.quest}
                            addAnswer={this.props.addAnswer}
                            removeAnswer={this.props.removeAnswer}
                            editMode={this.editMode()}
                            archiveConfirm={this.state.archiveConfirm}
                            toggleArchiveConfirm={this.toggleArchiveConfirm}
                            deleteConfirm={this.state.deleteConfirm}
                            toggleEdit={this.toggleEdit}
                            toggleDeleteConfirm={this.toggleDeleteConfirm}
                            toggleControlsPopup={this.toggleControlsPopup}
                            toggleBottomControls={this.toggleBottomControls}
                            saving={this.state.saving}
                            savePost={this.savePost}
                            imgHandler={this.fileHandler}
                            fileHandler={this.fileHandler}
                            videoHandler={this.videoHandler}
                            mobileVideoHandler={this.fileHandler}
                            updatePost={this.updatePost}
                            showControlButtons={showControlButtons}
                            showBranching={this.props.showBranching}
                            copyContent={this.copyContent}
                            getPublicLink={this.getPublicLink}
                            content={this.getContent()}
                            selectedAnswer={this.props.selectedAnswer}
                            setImageDownload={this.props.setImageDownload}
                            showEmbedButton={this.props.showEmbedButton}
                            panel={this.props.panel}
                            systemQuoteNoti={systemQuoteNoti}
                        />
                    </div>
                )}

                {!this.state.archiveConfirm &&
                    !this.state.deleteConfirm &&
                    !this.props.isEmbed &&
                    !this.isSystemUser() && (
                        <div
                            className={cn(
                                styles.voteControlsContainer,
                                this.props.isTitle && styles.titleVoteControlsContainer,
                            )}
                        >
                            {isBsv ? (
                                (answer?.bsv_tx?.id || answer?.bsv_tx_id) && (
                                    <LockControl answer={answer} />
                                )
                            ) : (
                                <VoteControl
                                    answer={answer}
                                    isUserSignedIn={this.isUserSignedIn()}
                                />
                            )}
                        </div>
                    )}
            </div>
        )

        const Selectable = this.props.selectable ? (
            <div
                className={`selected-container ${selectedClass}`}
                onClick={this.toggleSelectedAnswer}
                onMouseDown={ev => {
                    //console.log('onmousedown')
                    const hasSelection = window.getSelection().toString().length > 0
                    // <WithSelection> prevents the event so this wont fire and wont be set.
                    // We set this so we know to clear the selection in this.toggleSelectedAnswer()
                    if (hasSelection) this.clickOutsideSelection = true
                }}
            >
                {/*<div className={`selected-frame ${selectedClass}`} />*/}
                <div
                    className={cn(
                        `answer-highlight`,
                        `answer-highlight-${answer?.id}`,
                        !this.isSystemUser() && isTitle && styles.titleAnswerHighlight,
                        //this.isTitleSelected() && styles.titleSelected,
                        this.props.isFirst && styles.firstAnswer,
                        this.props.isLast && styles.lastAnswer,
                        hoverClass,
                    )}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                >
                    {Comp}

                    {this.showGate() && (
                        <div
                            className={cn(
                                styles.answerGateContainer,
                                this.props.isTitle && styles.titleAnswerGateContainer,
                                !this.props.isActive &&
                                    this.props.isTitle &&
                                    styles.streamTitleAnswerGateContainer,
                            )}
                        >
                            <AnswerGate
                                childCount={childCount}
                                hover={this.state.hover}
                                isSelected={this.isSelected()}
                            />
                        </div>
                    )}
                </div>

                {isTitle && !systemUser && (
                    <div
                        className={cn(
                            styles.threadInfoContainer,
                            isActive && isCenter && styles.isActiveThreadInfoContainer,
                        )}
                        onClick={ev => {
                            ev.stopPropagation()
                            ev.preventDefault()
                            this.onClickTitle(ev)
                        }}
                    >
                        <ThreadInfo
                            quest={quest}
                            answers={this.props.tailAnswers}
                            query={this.props.query}
                            isActive={isActive}
                            answerFilter={this.props.answerFilter}
                            setAnswerFilter={this.props.setAnswerFilter}
                            answerFilterOptions={this.props.answerFilterOptions}
                        />
                    </div>
                )}
            </div>
        ) : (
            <>
                {Comp}

                {isActive && showQuestControls && isTitle && (
                    <div className={cn(styles.questionControlsContainer)}>
                        {questionControlButtons.length > 0 && (
                            <QuestControls
                                quest={quest}
                                toggleEdit={this.toggleEdit}
                                editMode={this.editMode()}
                                addAnswer={this.props.addAnswer}
                                controlButtons={questionControlButtons}
                                isMobile={isMobile}
                                content={this.getContent()}
                                filter={this.props.answerFilter}
                                setShowFilterDropdown={this.props.setShowFilterDropdown}
                                showFilterDropdown={this.props.showFilterDropdown}
                                setShowSortColDropdown={this.props.setShowSortColDropdown}
                                showSortColDropdown={this.props.showSortColDropdown}
                                setShowExtendedOptions={this.props.setShowExtendedOptions}
                                showExtendedOptions={this.props.showExtendedOptions}
                                collapseHandler={this.props.collapseHandler}
                                copyContent={this.copyContent}
                                getPublicLink={this.getPublicLink}
                                CB={this.props.CONTROL_BUTTONS}
                                showEmbedButton={this.props.showEmbedButton}
                                panel={this.props.panel}
                            />
                        )}
                    </div>
                )}
            </>
        )

        return Selectable
    }
}

export default Connector(stateSelector, props => ({
    insertRight: useInsertRight().insertRight,
    removeRight: useRemoveRight(),
    panelContext: usePanelContext(),
    highlightAnswer: useAnswerHighlight(),
}))(
    React.forwardRef((props, ref) => (
        <ErrorBoundary label={`Answer ${props.answer?.id}`}>
            <Answer ref={ref} {...props} />
        </ErrorBoundary>
    )),
)

export { AnswerImage }
