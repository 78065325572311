import React, { useRef, useState, useLayoutEffect, useCallback, ReactNode, useEffect } from 'react'
import { PanelContextProvider } from 'refactor/hooks/usePanelContext'
import { isMobile, isAndroid } from 'react-device-detect'
import cn from 'classnames'
import styles from 'components/panels/panel-container.module.scss'
import {
    getCenterIndex,
    isPanelCenter,
    isPanelLeft,
    isPanelRight,
} from 'state/imperativeApis/swiperApi'
import { SMALL, BIG, transformTo, getTransform } from 'lib/transform'
import useStore from 'state/knovStore'
import { PanelState } from 'state/PanelState'
import RemoveButton from './RemoveButton'
import FloatingFilterIcon from './FloatingFilterIcon'
import ScrollButton from './ScrollButton'
import queryClient from 'api/queryClient'
import { serializeFilterKey } from 'refactor/hooks/api/useGetQuests'
import { logEv } from 'lib/log'
import PullToRefresh from './PullToRefresh'
import MobilePullToRefresh from './MobilePullToRefresh'
import { withDepth } from 'components/filters/useStreamFilters'
import { clearNotificationCount } from 'utils/notificationUtils'
import { getCachedQuest } from 'state/cache'

declare global {
    interface Window {
        isPanelScrolling: ReturnType<typeof setTimeout> | null
    }
}

interface PanelContainerProps {
    panel: PanelState
    index: number
    hide?: boolean
    children: ReactNode
}

export default React.memo(function PanelContainer({
    panel,
    index,
    hide,
    children,
}: PanelContainerProps) {
    // Only subscribe to panel changes for this specific panel
    const panelContainerRef = useRef<HTMLDivElement>(null)
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const [isVisible, setIsVisible] = useState(true)
    const [externalHideScrollButton, setExternalHideScrollButton] = useState<boolean>(false)
    const lastScrollTop = useRef(0)
    const scrollingTimeoutRef = useRef<number | null>(null)
    const scrollButtonContainerRef = useRef<HTMLDivElement>(null)
    const hideTimeoutRef = useRef<number | null>(null)
    const floatingFilterRef = useRef<HTMLDivElement>(null)

    // Pull-to-refresh state
    const [isRefreshing, setIsRefreshing] = useState(false)

    const fakeRefresh = useCallback(() => {
        setTimeout(() => {
            setIsRefreshing(false)
            logEv('PULL_TO_REFRESH', { filter: panel.filter, isFake: true })
        }, 500)
    }, [panel.filter])

    // Function to refresh content
    const refreshContent = useCallback(async () => {
        if (!panel.filter && !panel.empty) return

        setIsRefreshing(true)

        // Handle empty panels or new quests differently
        if (panel.empty) {
            // For empty panels, just animate away after a short timeout without making a request
            fakeRefresh()
            return
        }

        // For non-empty panels, continue with normal refresh logic
        const newFilter = withDepth(panel.filter)
        const filterKey = serializeFilterKey(newFilter)

        // clear notification count if we're refreshing the notifications stream
        if (newFilter?.notifications) {
            clearNotificationCount()
        }

        // For quest containers (single quest)
        if (panel.filter?.questId) {
            const quest = getCachedQuest(panel.filter.questId)
            if (quest?.is_draft) {
                fakeRefresh()
                return
            } else {
                await queryClient.invalidateQueries({ queryKey: ['quest', panel.filter.questId] })
            }
        } else if (panel.filter?.history) {
            // For history panels
            await queryClient.invalidateQueries({ queryKey: ['actions'] })
        } else {
            await queryClient.invalidateQueries({ queryKey: ['quests', filterKey], exact: true })
        }

        setIsRefreshing(false)
        logEv('PULL_TO_REFRESH', { filter: panel.filter })
    }, [panel.filter])

    const onPanelScroll = useCallback((ev: React.UIEvent<HTMLDivElement>) => {
        if (scrollingTimeoutRef.current) {
            window.cancelAnimationFrame(scrollingTimeoutRef.current)
        }

        scrollingTimeoutRef.current = window.requestAnimationFrame(() => {
            const scrollContainer = scrollContainerRef.current
            const scrollButtonContainer = scrollButtonContainerRef.current
            const floatingFilter = floatingFilterRef.current
            if (!scrollContainer || !scrollButtonContainer || !floatingFilter) return

            const offset = 100
            const scrollTop = scrollContainer.scrollTop
            const scrollHeight = scrollContainer.scrollHeight
            const clientHeight = scrollContainer.clientHeight
            const atTop = scrollTop <= offset
            const atBottom = scrollTop >= scrollHeight - clientHeight - offset
            const hasQuestFilter = !!panel.filter?.questId

            // Show/hide floating filter based on scroll position using CSS class
            if (scrollTop > 50) {
                floatingFilter.classList.add(styles.visible)
            } else {
                floatingFilter.classList.remove(styles.visible)
            }

            // First remove all scroll classes
            scrollButtonContainer.classList.remove(styles.scrollUp, styles.scrollDown)

            // Only hide scroll button when at the very top
            if (atTop) return

            if (hasQuestFilter) {
                if (atBottom) {
                    // When at bottom, ONLY show up arrow
                    scrollButtonContainer.classList.add(styles.scrollUp)
                } else {
                    // When in middle of content, show arrow based on scroll direction
                    const scrollDirection =
                        scrollTop > lastScrollTop.current ? 'scrollDown' : 'scrollUp'
                    scrollButtonContainer.classList.add(styles[scrollDirection])
                }
            } else {
                scrollButtonContainer.classList.add(styles.scrollUp)
            }
            lastScrollTop.current = scrollTop

            // Hide scroll button after delay
            if (hideTimeoutRef.current) {
                clearTimeout(hideTimeoutRef.current)
            }
            hideTimeoutRef.current = window.setTimeout(() => {
                scrollButtonContainer?.classList.remove(styles.scrollUp, styles.scrollDown)
            }, 3000) // Increased to 3 seconds

            scrollingTimeoutRef.current = null
        })
    }, [])

    const handleScrollButtonClick = useCallback(() => {
        if (scrollContainerRef.current) {
            const isScrollingUp = scrollButtonContainerRef.current?.classList.contains(
                styles.scrollUp,
            )

            if (isScrollingUp) {
                scrollContainerRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            } else if (panel.filter?.questId) {
                panel.scrollToBottom()
            } else {
                scrollContainerRef.current.scrollTo({
                    top: scrollContainerRef.current.scrollHeight,
                    behavior: 'smooth',
                })
            }
        }
    }, [])

    const handleFloatingFilterClick = useCallback(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }, [scrollContainerRef?.current])

    useLayoutEffect(
        function performTransform() {
            if (isMobile || !panelContainerRef.current) return

            const transformContainer =
                panelContainerRef.current?.querySelector<HTMLElement>('.transform-container')
            const scrollContainer =
                panelContainerRef.current?.querySelector<HTMLDivElement>('.scroll-container')
            if (!transformContainer || !scrollContainer) return

            // Batch all reads
            const reads = () => {
                const centerIndex = getCenterIndex()
                const transformObject = getTransform(transformContainer.style.transform)
                const currentScale = transformObject.scale || 1
                return { centerIndex, currentScale }
            }

            // Batch all writes
            const writes = ({ centerIndex, currentScale }) => {
                if (centerIndex === index && currentScale === SMALL) {
                    transformTo(BIG, panel)
                } else if (centerIndex !== index && currentScale === BIG) {
                    transformTo(SMALL, panel)
                }
            }

            // Schedule reads then writes
            const frame = requestAnimationFrame(() => {
                const measurements = reads()
                writes(measurements)
            })

            return () => cancelAnimationFrame(frame)
        },
        [index],
    )

    // We  memoize the panel context so it does not cause re-renders when passed to the provider below.
    const panelContext = React.useMemo(
        () => ({
            panel,
            panelId: panel.panelId,
            filter: panel.filter,
            hide,
            isCenter: () => isPanelCenter(panel.panelId),
            panelContainerRef,
            scrollContainerRef,
            setExternalHideScrollButton,
            setIsRefreshing,
        }),
        [panel.panelId, panel.filter, hide],
    )

    useEffect(() => {
        if (!panelContainerRef.current || isMobile) return

        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    setIsVisible(entry.isIntersecting)
                })
            },
            {
                root: null, // Use viewport
                rootMargin: '0px 100% 0px 100%', // 100% horizontal margin to match panel width, 200px vertical margin for early loading
                threshold: 0.1, // Show when at least 10% is visible
            },
        )

        observer.observe(panelContainerRef.current)

        return () => {
            if (panelContainerRef.current) {
                observer.unobserve(panelContainerRef.current)
            }
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        return () => {
            if (scrollingTimeoutRef.current) {
                window.cancelAnimationFrame(scrollingTimeoutRef.current)
            }
            if (hideTimeoutRef.current) {
                clearTimeout(hideTimeoutRef.current)
            }
        }
    }, [])

    return (
        <PanelContextProvider value={panelContext}>
            <div
                style={{
                    width: '100%',
                    visibility: isVisible ? 'visible' : 'hidden',
                }}
                className={cn(
                    isMobile && 'is-mobile',
                    styles.panelContainer,
                    'panel-container-comp',
                )}
                ref={useCallback(ref => {
                    if (!ref) {
                        return
                    }

                    panelContainerRef.current = ref
                    const panelRef = panel.getPanelRef()
                    if (panelRef !== ref) {
                        panel.setPanelRef(ref)
                        const prevScrollTop = panelRef?.scrollTop
                        if (prevScrollTop) {
                            ref.scrollTop = prevScrollTop
                        }
                    }
                }, [])}
            >
                {isMobile ? (
                    <MobilePullToRefresh
                        scrollContainerRef={scrollContainerRef}
                        onRefresh={refreshContent}
                        isRefreshing={isRefreshing}
                        filter={panel.filter}
                        showSpinner={!panel.empty}
                        disabled={Boolean((useStore.getState() as any).anyMenuOpen)}
                    />
                ) : (
                    <PullToRefresh
                        scrollContainerRef={scrollContainerRef}
                        onRefresh={refreshContent}
                        isRefreshing={isRefreshing}
                        filter={panel.filter}
                        showSpinner={!panel.empty}
                    />
                )}

                {!panel.empty && (
                    <div
                        ref={floatingFilterRef}
                        className={cn(styles.floatingFilterContainer)}
                        onClick={handleFloatingFilterClick}
                    >
                        <FloatingFilterIcon
                            panel={panel}
                            onScrollToTop={handleFloatingFilterClick}
                        />
                    </div>
                )}

                <div
                    ref={scrollContainerRef}
                    className={cn('scroll-container', styles.scrollContainer, 'h100')}
                    onScroll={onPanelScroll}
                    style={{
                        display: isVisible ? 'block' : 'none',
                    }}
                >
                    <div
                        className={cn(
                            styles.transformContainer,
                            `panel-${panel.panelId}`,
                            'transform-container h100',
                            isMobile && 'is-mobile',
                            'will-change-transform',
                        )}
                    >
                        {children}
                    </div>
                </div>

                {!externalHideScrollButton && (
                    <ScrollButton
                        index={index}
                        onClick={handleScrollButtonClick}
                        containerRef={scrollButtonContainerRef}
                    />
                )}

                {!panel.empty && panel.filter && !isMobile && (
                    <RemoveButton panel={panel} index={index} />
                )}
            </div>
        </PanelContextProvider>
    )
})
