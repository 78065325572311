import usePanelContext from 'refactor/hooks/usePanelContext'
import useStore from 'state/knovStore'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import { isMobile } from 'react-device-detect'

export default function useInsertLeft(panelId) {
    const { panelId: nativePanelId } = usePanelContext()
    const anchorPanelId = panelId || nativePanelId

    const insertPanelLeft = useStore(store => store.panels.insertPanelLeft)
    const getIndexById = useStore(store => store.panels.getIndexById)

    return function insertLeft(newPanel: Parameters<typeof insertPanelLeft>[1]) {
        const indexBeforeChange = getIndexById(anchorPanelId)
        const clickedLeftPanel = indexBeforeChange < getCenterIndex()
        insertPanelLeft(anchorPanelId, newPanel, { animate: !isMobile && !clickedLeftPanel })
    }
}
