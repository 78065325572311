import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import styles from './right-sidebar.module.scss'
import { isMobile } from 'react-device-detect'
import WithTooltip from 'components/shared/WithTooltip'
import useStore from 'state/knovStore'

export default function RightSidebar({ children, show, onClose }) {
    const sidebarRef = useRef(null)
    const openEditProfileModal = useStore(state => state.actions.modalActions.openEditProfileModal)

    useEffect(() => {
        function handleClickOutside(event) {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                onClose()
            }
        }

        if (show) {
            document.addEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [show, onClose])

    return (
        <div className={cn(styles.rightSidebar, show && styles.show)}>
            <div className={styles.overlay} />
            <div ref={sidebarRef} className={cn(styles.content, isMobile && styles.mobileContent)}>
                <div
                    className={cn(styles.settingsBtnComp)}
                    onClick={e => {
                        e.stopPropagation()
                        openEditProfileModal()
                    }}
                >
                    <WithTooltip tip="Edit your profile">
                        <i className="fa fa-cog" />
                    </WithTooltip>
                </div>
                {children}
            </div>
        </div>
    )
}
