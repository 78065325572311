import React from 'react'
import cn from 'classnames'
import styles from './parent-answer-gate.module.scss'
import { CornerUpLeft, ArrowUpNarrowWide } from 'lucide-react'

interface ParentAnswerGateProps {
    childCount: number
    hover?: boolean
    selected?: boolean
    onClick?: () => void
}

const ParentAnswerGate: React.FC<ParentAnswerGateProps> = ({
    childCount,
    hover,
    selected,
    onClick,
}) => {
    return (
        <div
            className={cn(
                styles.parentAnswerGate,
                selected && styles.selected,
                hover && !selected && styles.hover,
            )}
            onClick={onClick}
            style={{ cursor: 'pointer' }}
        >
            <span className={styles.count}>
                {childCount === 0 && <CornerUpLeft size={16} />}
                {childCount > 0 && Math.min(childCount, 99)}
            </span>
            {childCount > 99 && <span className={styles.gateOverflow}>+</span>}
        </div>
    )
}

export default ParentAnswerGate
