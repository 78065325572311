import { useQuery } from '@tanstack/react-query'
import api from 'api/api'

type Options = {
    enabled?: boolean
}

export default function useGetUser(userId: string | undefined, options: Options = {}) {
    const { enabled = true } = options

    const result = useQuery({
        queryKey: ['user', userId],

        queryFn: async () => {
            if (!userId) return null
            return await api.getUser(userId)
        },

        enabled: !!userId && enabled,
        gcTime: Infinity,
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        structuralSharing: false,
        networkMode: 'always',
    })

    return {
        ...result,
        user: result.data,
    }
}
