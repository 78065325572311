import React, { useRef, useState, useEffect, useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import cn from 'classnames'
import styles from 'components/panels/pull-to-refresh.module.scss'
import {
    ScrollGestureAnalyzer,
    DELTA_WINDOW_SIZE,
    MIN_DELTA_THRESHOLD,
    DERIVATIVE_THRESHOLD,
    OUTLIER_THRESHOLD,
    SMOOTHING_FACTOR,
} from './ScrollGestureAnalyzer'

// Constants for pull-to-refresh behavior
export const MOBILE_REFRESH_THRESHOLD = 40
export const DESKTOP_REFRESH_THRESHOLD = 50
export const TOP_REACHED_DELAY = 500 // ms to wait after reaching the top before allowing pull-to-refresh
export const MIN_PULL_HOLD_TIME = 100 // Minimum hold time at threshold before refresh is triggered (ms)
export const INITIAL_SENSITIVITY_MULTIPLIER = 2.0 // Higher sensitivity for the first pull movement
export const EVENT_FREQUENCY_THRESHOLD = 120 // Increased from 60 - more tolerant of slow events
export const MOMENTUM_DELTA_THRESHOLD = 0.5 // Reduced from 0.7 - less aggressive momentum detection
export const INTERACTION_COOLDOWN = 200 // ms to wait after last user interaction before allowing refresh
export const GESTURE_TIMEOUT = 100 // ms to determine if a new gesture has started
export const POST_REFRESH_COOLDOWN = 300 // Standard cooldown for all panels
// Constants for new scroll gesture detection
export const NEW_GESTURE_TIME_GAP = 300 // ms gap between events to consider it a new gesture
export const REFRESH_LOCK_DURATION = 800 // Reduced from 2000ms to 800ms - more responsive while still preventing duplicates
export const MIN_EVENTS_IN_GESTURE = 2 // Reduced from 3 to 2 for more responsive triggering
// Animation block durations
export const HARD_ANIMATION_BLOCK = 1000 // Hard block all pull animations for this long after refresh

export interface PullToRefreshProps {
    scrollContainerRef: React.RefObject<HTMLDivElement>
    onRefresh: () => void
    isRefreshing: boolean
    filter?: any // The filter object that determines if refresh is available
    showSpinner?: boolean
}

// Animation helper for consistent animation behavior
export const animatePullBackToStart = (
    startAmount: number,
    setPullProgress: (progress: number) => void,
    setIsPulling: (isPulling: boolean) => void,
    onComplete = () => {},
) => {
    const startTime = Date.now()
    const duration = 150 // Faster retraction for snappier feel

    const animate = () => {
        const elapsed = Date.now() - startTime
        const progress = Math.min(elapsed / duration, 1)
        // Cubic ease out for more premium feel
        const easeOut = 1 - Math.pow(1 - progress, 3)
        const newAmount = startAmount * (1 - easeOut)

        setPullProgress(newAmount)

        if (progress < 1) {
            requestAnimationFrame(animate)
        } else {
            // Animation complete - terminate promptly
            setPullProgress(0)
            setIsPulling(false)
            onComplete()
        }
    }

    // Start animation immediately
    requestAnimationFrame(animate)
}

// Inner component for visual representation of pull state
const PullIndicator = ({ isPulling, pullProgress, isRefreshing, showSpinner = true }) => {
    // Define a constant for when the component is considered "fully pulled"
    const FULL_PULL_HEIGHT = 100
    const height = Math.min(pullProgress, FULL_PULL_HEIGHT)

    return (
        <div
            className={cn(
                styles.pullToRefresh,
                isPulling && styles.pulling,
                isRefreshing && styles.refreshing,
            )}
            style={{
                height: `${height}px`,
            }}
            data-testid="pull-to-refresh-indicator"
        >
            {showSpinner && isRefreshing && (
                <div className={styles.loadingIcon}>
                    <i className="fa fa-circle-o-notch fa-spin fa-lg" />
                </div>
            )}
        </div>
    )
}

interface ScrollGestureAnalysis {
    phase: 'unknown' | 'acceleration' | 'constant' | 'deceleration'
    isAcceleration: boolean
    isMomentum: boolean
    isConstant: boolean
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({
    scrollContainerRef,
    onRefresh,
    isRefreshing,
    filter,
    showSpinner = true,
}) => {
    // Pull-to-refresh state
    const [isPulling, setIsPulling] = useState(false)
    const [pullProgress, setPullProgress] = useState(0)
    const targetPullProgressRef = useRef(0) // Track target pull progress for smooth animations
    const animationFrameRef = useRef<number | null>(null) // Store animation frame ID
    const overscrollAmount = useRef(0)
    const REFRESH_THRESHOLD = isMobile ? MOBILE_REFRESH_THRESHOLD : DESKTOP_REFRESH_THRESHOLD

    // Event tracking
    const lastEventTimeRef = useRef<number>(0)
    const lastDeltaRef = useRef<number>(0)
    const thresholdReachedTimeRef = useRef<number | null>(null)

    // Key flag: tracks whether the current gesture started at scrollTop 0
    const gestureStartedAtTopRef = useRef<boolean>(false)

    // Gesture analyzer for better gesture detection
    const gestureAnalyzerRef = useRef<ScrollGestureAnalyzer>(new ScrollGestureAnalyzer())

    // Track the last phase to detect phase changes
    const lastPhaseRef = useRef<string | null>(null)

    // Flag to track if we're currently in an active gesture
    const isActiveGestureRef = useRef<boolean>(false)

    // Flag to track if a gesture may have started before reaching scrollTop 0
    const maybeStartedGestureBeforeTopRef = useRef<boolean>(false)

    // Timestamp of the last scroll event to detect when scrolling stops
    const lastScrollEventTimeRef = useRef<number>(0)

    // Timestamp of the last refresh to prevent double-refreshes
    const lastRefreshTimeRef = useRef<number>(0)

    // Helper function to check if a filter is valid/active
    const isFilterValid = useCallback(() => {
        if (filter === undefined) return true
        if (!filter) return false
        return true
    }, [filter])

    // Animation helper function for smooth pull progress updates
    const updatePullProgressSmoothly = useCallback(
        (targetValue: number) => {
            // Immediately set the reference so other parts of the code know our target
            targetPullProgressRef.current = targetValue

            // Cancel any existing animation frame for immediate response
            if (animationFrameRef.current !== null) {
                cancelAnimationFrame(animationFrameRef.current)
            }

            // For small values, just set immediately without animation for instant feedback
            const currentValue = pullProgress
            if (Math.abs(targetValue - currentValue) < 2) {
                setPullProgress(targetValue)
                return
            }

            // For larger changes, use a faster animation
            const startTime = performance.now()

            // Much shorter duration for more immediate response
            const duration = 80 // ms - significantly reduced from 180ms

            // Function to animate the progress change - simplified for performance
            const animateProgress = (timestamp: number) => {
                const elapsed = timestamp - startTime
                const progress = Math.min(elapsed / duration, 1)

                // Use a more aggressive easing curve that responds faster initially
                // This is a modified ease-out quartic curve
                const easedProgress = 1 - Math.pow(1 - progress, 2.5)

                // Calculate the new value
                const newProgress = currentValue + (targetValue - currentValue) * easedProgress

                // Update the state
                setPullProgress(newProgress)

                // Continue animation if not complete
                if (progress < 1) {
                    animationFrameRef.current = requestAnimationFrame(animateProgress)
                } else {
                    // Ensure we reach exactly the target value
                    setPullProgress(targetValue)
                    animationFrameRef.current = null
                }
            }

            // Start animation immediately
            animationFrameRef.current = requestAnimationFrame(animateProgress)
        },
        [pullProgress],
    )

    // Clean up animation on unmount
    useEffect(() => {
        return () => {
            if (animationFrameRef.current !== null) {
                cancelAnimationFrame(animationFrameRef.current)
            }
        }
    }, [])

    // Process the analysis from the gesture analyzer and update our state
    const processGestureAnalysis = useCallback(
        (analysis: ScrollGestureAnalysis, isAtTop: boolean, isPullingDown: boolean) => {
            // If we see an unknown or constant phase while not at the top, mark that we may have started
            // a gesture before reaching the top (which we don't want to count)
            // console.log('analysis.phase', analysis.phase)
            if (
                (analysis.phase === 'deceleration' ||
                    analysis.phase === 'unknown' ||
                    analysis.phase === 'constant' ||
                    analysis.phase === null) &&
                !isAtTop
            ) {
                maybeStartedGestureBeforeTopRef.current = true
                // console.log('maybeStartedGestureBeforeTopRef.current', maybeStartedGestureBeforeTopRef.current)
            }

            // Detect if this is a new gesture based on phase transition
            let isAcceleration = false

            // IMPORTANT: When transitioning TO acceleration phase, treat as a new gesture
            // But only if we didn't start the gesture before reaching the top
            if (analysis.isAcceleration) {
                // console.log('isAcceleration')
                // Set our own flag instead of modifying the analyzer's result
                isAcceleration = true
            }

            // Reset active gesture if phase transitions to anything other than acceleration
            // This ensures we can detect new gestures more reliably
            if (!analysis.isAcceleration && lastPhaseRef.current === 'acceleration') {
                // Reset gesture state when transitioning out of acceleration phase
                // to ensure we properly detect subsequent gestures
                // console.log('acceleration ended')
                maybeStartedGestureBeforeTopRef.current = false
                isActiveGestureRef.current = false
                gestureStartedAtTopRef.current = false
            }

            // Update our last phase
            lastPhaseRef.current = analysis.phase

            // Handle momentum detection first (even before gesture transitions)
            if (analysis.isMomentum) {
                // IMPORTANT: When momentum is detected, mark the current gesture as complete
                // This allows a new acceleration phase to be recognized as a new gesture
                // even if scroll events haven't completely stopped
                gestureStartedAtTopRef.current = false
                isActiveGestureRef.current = false

                // But for active pulls, override momentum detection to prevent disruption
                if (isPulling) {
                    analysis.isMomentum = false
                }
            }

            // Handle gesture state transitions
            if (isAtTop && isPullingDown) {
                // Start a new gesture if:
                // 1. We don't already have an active gesture AND
                // 2. We're at the top AND
                // 3. Either this is a new gesture OR it's the first scroll event AND
                // 4. The gesture didn't start before reaching the top
                if (
                    !isActiveGestureRef.current &&
                    isAcceleration &&
                    !maybeStartedGestureBeforeTopRef.current
                ) {
                    // console.log('STARTING NEW GESTURE')
                    // Start new gesture
                    gestureStartedAtTopRef.current = true
                    isActiveGestureRef.current = true
                }
                lastScrollEventTimeRef.current = Date.now()
            } else if (!isAtTop && isActiveGestureRef.current) {
                // If we scrolled away from the top while a gesture was active, end it
                gestureStartedAtTopRef.current = false
                isActiveGestureRef.current = false
            }

            return analysis
        },
        [isPulling],
    )

    // Helper to check if we're in the refresh cooldown period
    const isInRefreshCooldown = useCallback(() => {
        const now = Date.now()
        return now - lastRefreshTimeRef.current < POST_REFRESH_COOLDOWN
    }, [])

    // Trigger refresh with faster animation
    const handleRefresh = useCallback(() => {
        // Log detailed rejection reasons for debugging
        if (isRefreshing) {
            return
        }

        if (!isFilterValid()) {
            return
        }

        if (isInRefreshCooldown()) {
            return
        }

        // Mark refresh time and reset gesture tracking immediately
        lastRefreshTimeRef.current = Date.now()
        isActiveGestureRef.current = false
        gestureStartedAtTopRef.current = false

        try {
            // Show animation to full pull - using faster timing
            const targetFullPull = Math.max(pullProgress, REFRESH_THRESHOLD)
            updatePullProgressSmoothly(targetFullPull)

            // Trigger refresh with much shorter delay for more immediate response
            setTimeout(() => {
                try {
                    onRefresh()
                } catch (error) {
                    // Error during refresh
                }

                // Reset after shorter delay
                setTimeout(() => {
                    overscrollAmount.current = 0
                }, 150) // Reduced from 300ms
            }, 80) // Reduced from 200ms for more immediate response
        } catch (error) {
            // Error setting up refresh animation
            onRefresh()
        }
    }, [
        onRefresh,
        isRefreshing,
        isFilterValid,
        isInRefreshCooldown,
        filter,
        pullProgress,
        updatePullProgressSmoothly,
        REFRESH_THRESHOLD,
    ])

    // Wheel event handler
    const onWheel = useCallback(
        (e: WheelEvent) => {
            // console.log('onWheel')
            const scrollContainer = scrollContainerRef.current
            if (!scrollContainer || isRefreshing || !isFilterValid()) {
                return
            }

            const isAtTop = scrollContainer.scrollTop <= 1 // More forgiving top check (0 or 1)
            // console.log('isAtTop', isAtTop)

            // Don't process events during refresh cooldown
            if (isInRefreshCooldown()) {
                e.preventDefault()
                return
            }

            const now = Date.now()
            const currentDelta = e.deltaY

            // Record that we had a scroll event
            lastScrollEventTimeRef.current = now

            // CORE LOGIC: Check if we're at the top and pulling down
            const isPullingDown = e.deltaY < 0

            // ONLY prevent default when at the top AND pulling down AND active gesture
            // This allows normal scrolling in all other cases
            if (isAtTop && isPullingDown && isActiveGestureRef.current) {
                e.preventDefault()
            }

            // Use the ScrollGestureAnalyzer to determine if this is a new gesture or momentum
            const analysis = gestureAnalyzerRef.current.addDelta(Math.abs(currentDelta))
            // Process the analysis and update our gesture state
            processGestureAnalysis(analysis, isAtTop, isPullingDown)

            if (!isAtTop) {
                return
            }

            if (isAtTop && isPullingDown) {
                // If this is momentum scrolling, ignore it
                if (analysis.isMomentum) {
                    e.preventDefault()
                    return
                }

                // Process pull if gesture started at top
                if (gestureStartedAtTopRef.current && isActiveGestureRef.current) {
                    // Calculate pull amount with enhanced sensitivity for small deltas
                    const deltaYAbs = Math.abs(e.deltaY)

                    // Standardized high sensitivity for all panels
                    let sensitivity = 10.0

                    if (deltaYAbs <= 1) {
                        sensitivity = 18.0 // Much higher for tiny movements
                    } else if (deltaYAbs <= 3) {
                        sensitivity = 15.0 // Very high for small movements
                    }

                    // Calculate the new amount
                    const deltaAmount = deltaYAbs * sensitivity

                    // ANIMATION FIX: Ensure a minimum amount for the first pull movement
                    // This makes the initial pull more visible
                    if (overscrollAmount.current === 0 && !isPulling) {
                        // For first pull, use a capped reasonable amount to ensure visibility
                        // but prevent triggering refresh instantly with large delta values
                        const initialPullAmount = Math.min(deltaAmount, 30)
                        overscrollAmount.current = Math.max(initialPullAmount, 10)
                    } else {
                        // Normal accumulation for subsequent movements
                        overscrollAmount.current = Math.min(
                            overscrollAmount.current + deltaAmount,
                            150,
                        )
                    }

                    // Set pulling state
                    if (!isPulling) {
                        setIsPulling(true)
                    }

                    // Use smooth animation to update pull progress
                    updatePullProgressSmoothly(overscrollAmount.current)

                    // Standardized thresholds for all panels
                    const thresholdPercent = 0.35
                    const isCloseToThreshold =
                        overscrollAmount.current >= REFRESH_THRESHOLD * thresholdPercent
                    const isSustainedPull =
                        isPulling && isCloseToThreshold && now - lastEventTimeRef.current < 300

                    // Use single refresh threshold for all panels
                    const refreshThreshold = REFRESH_THRESHOLD * 0.45

                    if (overscrollAmount.current >= refreshThreshold || isSustainedPull) {
                        // For big scroll movements, animate to a larger pull first
                        const isBigScroll =
                            deltaYAbs > 5 && overscrollAmount.current > refreshThreshold

                        if (isBigScroll) {
                            // For big scrolls, animate to target height with minimal delay
                            const bigPullTarget = Math.min(overscrollAmount.current, 120)
                            updatePullProgressSmoothly(bigPullTarget)

                            // Minimal delay for big scrolls - just enough to see animation start
                            setTimeout(() => {
                                if (isSustainedPull) {
                                    handleRefresh()
                                    thresholdReachedTimeRef.current = null
                                } else {
                                    handleRefresh()
                                    thresholdReachedTimeRef.current = null
                                }
                            }, 50)
                        } else {
                            // Normal refresh for smaller scroll movements
                            if (isSustainedPull) {
                                handleRefresh()
                                thresholdReachedTimeRef.current = null
                            } else {
                                handleRefresh()
                                thresholdReachedTimeRef.current = null
                            }
                        }
                    }

                    e.preventDefault()
                }
            } else if (e.deltaY > 0 && isPulling) {
                // Allow natural release when pulling back up
                const deltaYAbs = Math.abs(e.deltaY)
                const sensitivity = 0.7

                // Reduce overscroll based on upward movement
                overscrollAmount.current = Math.max(
                    0,
                    overscrollAmount.current - deltaYAbs * sensitivity,
                )

                // Update UI with smooth animation
                updatePullProgressSmoothly(overscrollAmount.current)

                // Cancel pull if completely reversed
                if (overscrollAmount.current === 0) {
                    setIsPulling(false)
                    gestureStartedAtTopRef.current = false
                    isActiveGestureRef.current = false
                }

                e.preventDefault()
            } else if (!isAtTop) {
                // Not at the top, cancel gesture tracking
                gestureStartedAtTopRef.current = false
                // If scrolled away from top, end the gesture
                if (isActiveGestureRef.current) {
                    isActiveGestureRef.current = false

                    // Animate pull back when gesture ends without refresh
                    if (isPulling && overscrollAmount.current > 0) {
                        animatePullBackToStart(
                            overscrollAmount.current,
                            updatePullProgressSmoothly,
                            setIsPulling,
                        )
                    }
                }
            }

            // Track events for later analysis
            lastEventTimeRef.current = now
            lastDeltaRef.current = e.deltaY
        },
        [
            isRefreshing,
            isFilterValid,
            isPulling,
            handleRefresh,
            processGestureAnalysis,
            isInRefreshCooldown,
            updatePullProgressSmoothly,
        ],
    )

    // Set up event listeners
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current
        if (!scrollContainer) return

        // Add wheel event listener
        scrollContainer.addEventListener('wheel', onWheel, {
            passive: false, // Need to keep this false to allow preventDefault
            capture: true, // Wheel events still need capture for proper handling
        })

        // Initialize gesture tracking refs
        gestureStartedAtTopRef.current = false
        isActiveGestureRef.current = false
        lastPhaseRef.current = null

        return () => {
            // Clean up event listener
            scrollContainer.removeEventListener('wheel', onWheel, { capture: true })

            // Reset the gesture analyzer
            gestureAnalyzerRef.current.reset()
        }
    }, [onWheel])

    // Reset when isRefreshing changes to false
    useEffect(() => {
        if (!isRefreshing) {
            setPullProgress(0)
            setIsPulling(false)
            overscrollAmount.current = 0
            thresholdReachedTimeRef.current = null
            // Also reset gesture state
            gestureStartedAtTopRef.current = false
            isActiveGestureRef.current = false
            lastPhaseRef.current = null
        }
    }, [isRefreshing])

    // Effect to reset flags when scrolling stops
    useEffect(() => {
        const SCROLL_TIMEOUT = 300 // ms to wait after last scroll event before considering scrolling stopped

        const checkScrollStopped = () => {
            const now = Date.now()
            const timeSinceLastScroll = now - lastScrollEventTimeRef.current

            if (timeSinceLastScroll > SCROLL_TIMEOUT && !isRefreshing && !isPulling) {
                // Reset the flags if we're not actively refreshing or pulling
                maybeStartedGestureBeforeTopRef.current = false

                if (!isActiveGestureRef.current) {
                    lastPhaseRef.current = null
                    gestureStartedAtTopRef.current = false
                }
            }
        }

        // Set up an interval to periodically check if scrolling has stopped
        const intervalId = setInterval(checkScrollStopped, SCROLL_TIMEOUT)

        return () => {
            clearInterval(intervalId)
        }
    }, [isRefreshing, isPulling])

    return isPulling || isRefreshing ? (
        <PullIndicator
            isPulling={isPulling}
            pullProgress={pullProgress}
            isRefreshing={isRefreshing}
            showSpinner={showSpinner}
        />
    ) : null
}

export default PullToRefresh
