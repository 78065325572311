import React, { useState } from 'react'
import api from '../../api/api'
import styles from 'components/users/user-edit-form.module.scss'
import { PurpleButton } from 'components/shared/buttons/Button'
import queryClient from 'api/queryClient'

export default function UserEditForm(props) {
    const { user } = props
    const [name, setName] = useState(user.name || '')
    const [email, setEmail] = useState(user.email || '')
    const [description, setDescription] = useState(user.description || user.bio || '')
    const [isDescriptionFocused, setIsDescriptionFocused] = useState(false)
    //const [password, setPassword] = useState('')
    //const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [success, setSuccess] = useState([])
    const [errors, setErrors] = useState([])

    const handleSubmit = async ev => {
        ev.preventDefault()

        // Get the form element
        const formElement = document.getElementById(`edit_user_${user.id}`)
        const form = new FormData(formElement)

        if (!form.get('user[name]') || form.get('user[name]') === user?.name)
            form.delete('user[name]')
        if (!form.get('user[email]') || form.get('user[email]') === user?.email)
            form.delete('user[email]')
        if (!form.get('user[description]') || form.get('user[description]') === user?.description)
            form.delete('user[description]')

        //if (!form.get('user[password]')) form.delete('user[password]')
        //if (!form.get('user[password_confirmation]')) form.delete('user[password_confirmation]')

        const data = await api.updateUser(user.id, form)
        if (data.user) {
            //setName(data.user?.name)
            //setEmail(data.user?.email)
            //setDescription(data.user?.description)
            //setPassword('')
            //setPasswordConfirmation('')
            setErrors([])
            setSuccess(['User updated.'])
            props.setUser(data.user)

            // Invalidate user cache to refresh data in UserProfile
            queryClient.invalidateQueries({ queryKey: ['user', user.id] })
        } else {
            setErrors(data.errors)
        }
    }

    const meta = document.querySelector('meta[name="csrf-token"]')
    //const csrfToken = meta && meta.getAttribute('content')

    const charCount = description.length
    const isOverLimit = charCount > 160

    return (
        <form className="edit_user" id={`edit_user_${user.id}`} acceptCharset="UTF-8" method="post">
            {errors?.length > 0 && (
                <div className={styles.error}>
                    <div>
                        {errors.map((msg, index) => (
                            <div key={index}>{msg}</div>
                        ))}
                    </div>
                </div>
            )}

            {success?.length > 0 && (
                <div className={styles.success}>
                    <div>
                        {success.map((msg, index) => (
                            <div key={index}>{msg}</div>
                        ))}
                    </div>
                </div>
            )}

            <div className={styles.profileSection}>
                <div className={styles.field}>
                    <label className={styles.label} htmlFor="user_name">
                        Name
                    </label>
                    <input
                        className={styles.input}
                        type="text"
                        value={name}
                        name="name"
                        id="user_name"
                        onChange={e => setName(e.target.value)}
                    />
                </div>
                <div className={styles.field}>
                    <label className={styles.label} htmlFor="user_email">
                        Email
                    </label>
                    <input
                        className={styles.input}
                        type="text"
                        value={email}
                        name="email"
                        id="user_email"
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div className={styles.field}>
                    <div className={styles.labelContainer}>
                        <label className={styles.label} htmlFor="user_description">
                            Bio
                        </label>
                        {isDescriptionFocused && (
                            <span
                                className={`${styles.charCounter} ${
                                    isOverLimit ? styles.overLimit : ''
                                }`}
                            >
                                {charCount}/160
                            </span>
                        )}
                    </div>
                    <textarea
                        className={`${styles.textarea} ${isOverLimit ? styles.textareaError : ''}`}
                        value={description}
                        name="description"
                        id="user_description"
                        rows="4"
                        onFocus={() => setIsDescriptionFocused(true)}
                        onBlur={() => setIsDescriptionFocused(false)}
                        onChange={e => setDescription(e.target.value)}
                        placeholder="Tell others about yourself..."
                    />
                </div>
            </div>

            <div className={styles.buttonContainer}>
                <PurpleButton
                    text={'Update'}
                    onClick={e => {
                        e.preventDefault()
                        handleSubmit(e)
                    }}
                />
            </div>
        </form>
    )
}
