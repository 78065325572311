import React from 'react'
import cn from 'classnames'
import iconStyles from 'components/shared/icons/icons.module.scss'

interface OrdinalsIconProps {
    size?: number
    width?: number
    height?: number
    style?: React.CSSProperties
    contextStyles?: string
}

const OrdinalsIcon: React.FC<OrdinalsIconProps> = ({
    size,
    width,
    height,
    style,
    contextStyles,
}) => {
    // If size is provided, use it as default for both width and height
    const iconWidth = width || size || 20
    const iconHeight = height || size || 20

    return (
        <div
            style={{ width: iconWidth, height: iconHeight, ...style }}
            className={cn(iconStyles.iconComp, iconStyles.fontIconComp, contextStyles)}
        >
            <img
                src="/images/ordinalsLogo.svg"
                alt="Ordinals"
                width={iconWidth}
                height={iconHeight}
            />
        </div>
    )
}

export default OrdinalsIcon
