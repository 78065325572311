import React, { useState, useEffect, useLayoutEffect, useRef, useCallback, useMemo } from 'react'
import Sidebar from '../shared/Sidebar'
import FilterOptions from 'components/filters/FilterOptions'
import Control from 'components/panels/Control'
import { isMobile, isAndroid, isIOS } from 'react-device-detect'
import ReactTooltip from 'react-tooltip'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Virtual } from 'swiper'
import 'swiper/swiper-bundle.css'
import { DndProvider } from 'react-dnd'
import MultiBackend, { TouchTransition, MouseTransition } from 'react-dnd-multi-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import Preview from 'react-dnd-preview'
import Answer from '../answers/Answer'
import PanelContainer from 'components/panels/PanelContainer'
import Panel from 'components/panels/Panel'
import EmptyPanel from 'components/panels/EmptyPanel'
import HistoryPanel from 'components/panels/HistoryPanel'
import useStore, { startingIndex } from 'state/knovStore'
import { getSwiperRef, getCenterIndex, slideTo } from 'state/imperativeApis/swiperApi'
import { getTooltipRef } from 'state/imperativeApis/tooltipApi'
import AutocompletePopup from 'components/AutocompletePopup'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import CableApp from '../../actioncable'
import { uniqBy } from 'lodash'
import * as cache from 'state/cache'
import styles from 'components/PanelController/panel-controller.module.scss'
import { throttle } from 'lodash'
import { pathFromFilter } from 'components/filters/useStreamFilters'
import api from 'api/api'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import cn from 'classnames'
import SidebarButton from 'components/shared/SidebarButton'

import useSlideEffects from 'refactor/hooks/useSlideEffects'
import useAnimatedSlide from 'refactor/hooks/useAnimatedSlide'
import UserControl from 'components/panels/UserControl'
import { transformTo, SMALL, BIG } from 'lib/transform'
import useWalletDetails from 'api/useWalletDetails'
import { BsvWalletType } from 'wallets/wallets'
import RightSidebar from '../shared/RightSidebar'
import WalletHistory from 'components/wallet/WalletHistory'

let initialInnerHeight = window.innerHeight
SwiperCore.use([Virtual])

const HTML5toTouch = {
    backends: [
        {
            backend: HTML5Backend,
            transition: MouseTransition,
        },
        {
            backend: TouchBackend,
            options: {
                enableMouseEvents: true,
                delayTouchStart: 500,
                ignoreContextMenu: true,
            },
            preview: true,
            transition: TouchTransition,
        },
    ],
}

// Move refs outside component to avoid recreation
const prevDeltaX = { current: 0 }
const isSwiping = { current: false }
const isSwipingLeft = { current: false }
const isSwipingRight = { current: false }
export const isManualSlide = { current: false } // Track if slide was triggered by next/prev buttons

export default React.memo(function PanelController() {
    //console.log('RENDER PANEL CONTROLLER')
    const swiperRef = getSwiperRef()
    const tooltipRef = getTooltipRef()

    const showSidebar = useStore(state => state.showSidebar)
    const setShowSidebar = useStore.getState().setShowSidebar

    const showRightSidebar = useStore(state => state.showRightSidebar)
    const setShowRightSidebar = useStore.getState().setShowRightSidebar

    const [showFilterOptions, setShowFilterOptions] = useState(false)
    const [slideUpdates, triggerTouchSlideUpdate] = useState(0)
    const [keyboardOpen, setKeyboardOpen] = useState(false)

    const canSlidePrev = useCallback(() => !swiperRef?.current?.isBeginning, [])
    const canSlideNext = useCallback(() => !swiperRef?.current?.isEnd, [])

    // ensure the wallet details are loaded when app starts
    useWalletDetails(BsvWalletType.SHUALLET)

    const slidePrev = useCallback(() => {
        if (canSlidePrev()) {
            isManualSlide.current = true
            slideTo(getCenterIndex() - 1)
        }
    }, [])

    const slideNext = useCallback(() => {
        if (canSlideNext()) {
            isManualSlide.current = true
            slideTo(getCenterIndex() + 1)
        }
    }, [])

    const handleSidebarClose = useCallback(() => {
        // We check the global state bc the sidebar state is not updated immediately when the sidebar is closed due to throttle.
        if (useStore.getState().showSidebar) {
            setShowSidebar(false)
        }
    }, [])

    // Create stable version of swipe handlers
    const handleSwipeLeft = useCallback(() => {
        if (useStore.getState().showRightSidebar) {
            useStore.getState().setShowRightSidebar(false)
        } else {
            slidePrev()
        }
    }, [slidePrev])

    const handleSwipeRight = useCallback(() => {
        if (useStore.getState().showSidebar) {
            handleSidebarClose()
        } else {
            slideNext()
        }
    }, [handleSidebarClose, slideNext])

    // Use useRef to maintain stable reference to throttled function
    const handleTouchPadSwipeRef = useRef(
        throttle(
            ev => {
                const { deltaX, deltaY } = ev
                const isHorizontalScroll = Math.abs(deltaX) > Math.abs(deltaY)

                if (!isHorizontalScroll) return

                const isIncreasingDelta = Math.abs(deltaX) - Math.abs(prevDeltaX.current) > 1
                const isDecreasingDelta = prevDeltaX.current - Math.abs(deltaX) > 1

                prevDeltaX.current = Math.abs(deltaX)

                if (isDecreasingDelta || Math.abs(deltaX) <= 1) {
                    isSwiping.current = false
                    isSwipingLeft.current = false
                    isSwipingRight.current = false
                } else if (isIncreasingDelta && !isSwiping.current) {
                    isSwiping.current = true

                    // Only prevent default if we're actually going to handle the swipe
                    ev.preventDefault()
                    ev.stopPropagation()

                    if (deltaX < 0 && !isSwipingLeft.current) {
                        isSwipingLeft.current = true
                        handleSwipeLeft()
                    } else if (deltaX > 0 && !isSwipingRight.current) {
                        isSwipingRight.current = true
                        handleSwipeRight()
                    }
                }
            },
            50,
            {
                leading: true,
                trailing: false,
            },
        ),
    )

    useEffect(() => {
        if (isMobile || !swiperRef.current) return

        const onSlideStart = ev => {
            document.removeEventListener('wheel', handleTouchPadSwipeRef.current)
        }
        swiperRef.current.on('slideChangeTransitionStart', onSlideStart)

        const onSlideEnd = ev => {
            document.addEventListener('wheel', handleTouchPadSwipeRef.current, { passive: false })
        }
        swiperRef.current.on('slideChangeTransitionEnd', onSlideEnd)

        document.addEventListener('wheel', handleTouchPadSwipeRef.current, { passive: false })

        return () => {
            document.removeEventListener('wheel', handleTouchPadSwipeRef.current)
            swiperRef.current.off('slideChangeTransitionStart', onSlideStart)
            swiperRef.current.off('slideChangeTransitionEnd', onSlideEnd)
            isSwiping.current = false
            prevDeltaX.current = 0
        }
    }, [])

    useEffect(() => {
        let channel
        const currentUser = gon?.currentUser
        if (currentUser) {
            channel = CableApp.cable.subscriptions.create(
                { channel: 'UserChannel', userId: currentUser.id },
                {
                    received: async data => {
                        if (data.message === 'history updated') {
                            const stateQuestHistory = useStore.getState().questHistory
                            if (!cache.getCachedQuest(data.quest?.id)) {
                                cache.cacheQuest(data.quest)
                            }
                            useStore.getState().set({
                                questHistory: uniqBy([data.quest, ...stateQuestHistory], 'id'),
                            })
                        }
                    },
                },
            )
        }
        return () => {
            if (channel && channel.unsubscribe) {
                channel.unsubscribe()
            }
        }
    }, [])

    useEffect(() => {
        const user = gon.currentUser
        let channel
        if (user) {
            channel = CableApp.cable.subscriptions.create(
                { channel: 'UserChannel', userId: user.id },
                {
                    received: async data => {
                        if (data.message === 'new quest notification added') {
                            // TODO PANEL REFACTOR
                            // show new noti
                        }
                    },
                },
            )
        }

        return () => {
            if (channel && channel.unsubscribe) {
                channel.unsubscribe()
            }
        }
    }, [])

    const isExpoAndroid = window.KNOVIGATOR_IS_MOBILE && /Android/i.test(navigator.userAgent)
    const isOldMobile = isMobile && !window.visualViewport

    useEffect(() => {
        // Handle keyboard for expo android or if no viewport available.
        if (isExpoAndroid || isOldMobile) {
            function keyboardCheck() {
                if (window.innerHeight < initialInnerHeight) {
                    setKeyboardOpen(true)
                } else {
                    setKeyboardOpen(false)
                }
            }

            window.addEventListener('resize', keyboardCheck)

            return () => {
                if (keyboardCheck) window.removeEventListener('resize', keyboardCheck)
            }
        }
    }, [])

    const panelControllerRef = useRef(null)
    const lastActiveIndexRef = useRef(null)

    useEffect(() => {
        if (!isExpoAndroid && isMobile && window.visualViewport) {
            const visualViewport = window.visualViewport
            let initialVisualViewportHeight = visualViewport.height

            const keyboardCheck = throttle(
                () => {
                    const isKeyboardOpen = initialVisualViewportHeight - visualViewport.height > 100
                    setKeyboardOpen(isKeyboardOpen)
                },
                100,
                { leading: false, trailing: true },
            )

            visualViewport.addEventListener('resize', keyboardCheck)

            return () => {
                visualViewport.removeEventListener('resize', keyboardCheck)
            }
        }
    }, [])

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar)
    }

    const blurSidebar = ev => {
        if (showSidebar && document.getElementById('sidebar') !== ev.target) toggleSidebar()
    }

    const panels = useStore(store => store.panels)
    const panelList = panels.state
    const { getTransformStyle } = useAnimatedSlide()
    const { updateCenteredPanelId } = useSlideEffects()

    // Calculate animation styles for each panel
    const centerIndex = isMobile
        ? swiperRef.current?.activeIndex
        : swiperRef.current?.activeIndex + 1
    const panelAnimationStyles = panelList.map((_, index) => getTransformStyle(index, centerIndex))

    //console.log('RENDER PANELCONTROLLER-------------------------------------')
    //console.log('panel state', panels)
    //console.log('active index', swiperRef.current?.activeIndex)
    //console.log('starting index', startingIndex)
    //console.log('center index', centerIndex)

    useLayoutEffect(function handleMobileSwipe() {
        if (isMobile) {
            let touchStartX = null
            let touchStartY = null
            let touchStartTime = null

            const handleTouchStart = event => {
                // Check if the touch started on or inside a model-viewer element
                const path = event.composedPath?.() || event.path || []
                const isModelViewerTouch = path.some(
                    el => el.tagName?.toLowerCase() === 'model-viewer',
                )
                if (isModelViewerTouch) {
                    return // Don't track this touch, let model-viewer handle it
                }

                touchStartX = event.touches[0].clientX
                touchStartY = event.touches[0].clientY
                touchStartTime = Date.now()
            }

            const handleTouchMove = event => {
                // Check if the touch is on or inside a model-viewer element
                const path = event.composedPath?.() || event.path || []
                const isModelViewerTouch = path.some(
                    el => el.tagName?.toLowerCase() === 'model-viewer',
                )
                if (isModelViewerTouch) {
                    return // Let model-viewer handle the touch
                }

                if (!touchStartX || !touchStartY) return

                const touchMoveX = event.touches[0].clientX
                const touchMoveY = event.touches[0].clientY
                const deltaX = touchMoveX - touchStartX
                const deltaY = Math.abs(touchMoveY - touchStartY)
                const timeDelta = Date.now() - touchStartTime

                // Calculate velocity (pixels per millisecond)
                const velocity = Math.abs(deltaX) / timeDelta

                // Adjust thresholds based on velocity
                const SWIPE_THRESHOLD = velocity > 0.5 ? 50 : 100 // More sensitive for fast swipes
                const VERTICAL_LIMIT = 30 // Stricter vertical limit

                if (Math.abs(deltaX) > SWIPE_THRESHOLD && deltaY < VERTICAL_LIMIT) {
                    if (deltaX < 0 && useStore.getState().showSidebar) {
                        handleSidebarClose()
                        touchStartX = null // Prevent multiple triggers
                    }
                    if (deltaX > 0 && useStore.getState().showRightSidebar) {
                        setShowRightSidebar(false)
                        touchStartX = null // Prevent multiple triggers
                    }
                }
            }

            const handleTouchEnd = () => {
                touchStartX = null
                touchStartY = null
                touchStartTime = null
            }

            document.addEventListener('touchstart', handleTouchStart)
            document.addEventListener('touchmove', handleTouchMove, { passive: false })
            document.addEventListener('touchend', handleTouchEnd)

            return () => {
                document.removeEventListener('touchstart', handleTouchStart)
                document.removeEventListener('touchmove', handleTouchMove)
                document.removeEventListener('touchend', handleTouchEnd)
            }
        }
    }, [])

    const handleUserControlClick = useCallback(() => {
        setShowRightSidebar(true)
    }, [])

    // Memoize Swiper initialization and event handlers
    const handleSwiperInit = useCallback(swiper => {
        swiperRef.current = swiper
        window.swiper = swiper

        // Add touch handler to prevent swipes on model-viewer
        swiper.el.addEventListener(
            'touchstart',
            e => {
                const path = e.composedPath?.() || e.path || []
                const isModelViewerTouch = path.some(
                    el => el.tagName?.toLowerCase() === 'model-viewer',
                )
                if (isModelViewerTouch) {
                    swiper.allowTouchMove = false
                    e.stopPropagation()
                }
            },
            { passive: true },
        )

        swiper.el.addEventListener(
            'touchend',
            () => {
                swiper.allowTouchMove = true
            },
            { passive: true },
        )
    }, [])

    const handleSlideChange = useCallback(async () => {
        const swiper = swiperRef.current
        if (!swiper) return

        const ix = isMobile ? swiper.activeIndex : swiper.activeIndex + 1
        let panels = useStore.getState().panels.state
        let panel = panels[ix]
        const filter = panel?.filter
        if (filter) {
            const isDraft = filter.questId && cache.getCachedQuest(filter.questId)?.is_draft
            if (isDraft) return

            const panelId = panel.panelId
            const path = pathFromFilter(filter)
            history.pushState({ centerIndex: ix, panelId, filter }, '', path)
            api.updateUserSpaceOptions({
                last_path: path,
                filter,
            })
            if (filter.questId) {
                api.userViewsQuest(filter.questId)

                queryClient.invalidateQueries({
                    queryKey: ['actions'],
                    exact: true,
                })
            }
        }
    }, [])

    // Memoize panel transformation logic
    const handlePanelTransforms = useCallback((activeIndex, lastActiveIndex, animate = true) => {
        if (isMobile) return

        const panels = useStore.getState().panels.state
        if (lastActiveIndex !== null) {
            const lastCenterIndex = lastActiveIndex + 1
            const newCenterIndex = activeIndex + 1

            // Transform the new center panel to BIG
            if (panels[newCenterIndex]) {
                transformTo(BIG, panels[newCenterIndex], animate ? undefined : 'none')
            }

            // Transform the old center panel to SMALL
            if (lastCenterIndex !== newCenterIndex && panels[lastCenterIndex]) {
                transformTo(SMALL, panels[lastCenterIndex], animate ? undefined : 'none')
            }
        } else {
            // Initial load or edge case.
            if (panels[activeIndex]) {
                transformTo(SMALL, panels[activeIndex], animate ? undefined : 'none')
            }
            if (panels[activeIndex + 1]) {
                transformTo(BIG, panels[activeIndex + 1], animate ? undefined : 'none')
            }
            if (panels[activeIndex + 2]) {
                transformTo(SMALL, panels[activeIndex + 2], animate ? undefined : 'none')
            }
        }
    }, [])

    // Keep onActiveIndexChange as a regular function since it needs fresh state
    const onActiveIndexChange = swiper => {
        const centerIndex = isMobile ? swiper.activeIndex : swiper.activeIndex + 1
        updateCenteredPanelId(centerIndex)
        useStore.getState().set({ activeIndex: swiper.activeIndex, centerIndex })

        // Only run transforms if this was triggered by next/prev buttons
        if (isManualSlide.current) {
            // Call with animate=true to animate the scale.
            handlePanelTransforms(swiper.activeIndex, lastActiveIndexRef.current, true)
            isManualSlide.current = false
        } else {
            // Call with animate=false for instant scale.
            handlePanelTransforms(swiper.activeIndex, lastActiveIndexRef.current, false)
        }

        // Update the lastActiveIndexRef
        lastActiveIndexRef.current = swiper.activeIndex
    }

    // Memoize generatePreview to prevent recreation on every render
    const generatePreview = useMemo(() => {
        return ({ itemType, item, style }) => {
            let width = isMobile ? '100%' : '33%'
            return (
                <div
                    style={Object.assign({}, style, { zIndex: 2000, width, height: '100%' })}
                    className={'noselect row'}
                >
                    <div className="col-md-12">
                        <Answer {...item} />
                    </div>
                </div>
            )
        }
    }, []) // Only depends on isMobile which is constant

    const isMobileAppOrAndroid = window.KNOVIGATOR_IS_MOBILE || isAndroid
    const isMobileIosBrowser = !window.KNOVIGATOR_IS_MOBILE && isIOS

    return (
        <>
            <div className={styles.panelControllerComp} onClick={blurSidebar}>
                <Sidebar showSidebar={showSidebar} />

                {gon.currentUser && (
                    <RightSidebar
                        show={showRightSidebar}
                        onClose={() => setShowRightSidebar(false)}
                    >
                        <FilterOptions
                            user={gon.currentUser}
                            header="User"
                            show={['user']}
                            theme="dark"
                            contextStyles={cn(styles.userSidebar)}
                            setShowFilterOptions={setShowFilterOptions}
                            close={() => setShowRightSidebar(false)}
                            append={true}
                        />

                        <WalletHistory />
                    </RightSidebar>
                )}

                <div className={styles.sidebarControls}>
                    <div
                        className={cn(
                            styles.leftCornerContainer,
                            isMobile && styles.mobileLeftCornerContainer,
                        )}
                    >
                        <div
                            className={cn(
                                styles.sidebarButtonContainer,
                                isMobile && styles.mobileSidebarButtonContainer,
                            )}
                        >
                            <SidebarButton
                                showSidebar={showSidebar}
                                toggleSidebar={toggleSidebar}
                                customClass="sidebar-button"
                            />
                        </div>
                    </div>

                    {!showRightSidebar && (
                        <div
                            className={cn(
                                styles.rightCornerContainer,
                                isMobile && styles.mobileRightCornerContainer,
                            )}
                        >
                            <div
                                className={cn(
                                    styles.userStreamContainer,
                                    isMobile && styles.mobileUserStreamContainer,
                                )}
                            >
                                {gon?.currentUser ? (
                                    <UserControl
                                        user={gon?.currentUser}
                                        showRightSidebar={showRightSidebar}
                                        setShowRightSidebar={setShowRightSidebar}
                                        contextStyles={cn(styles.userIcon)}
                                        isOpen={showSidebar}
                                        onClick={handleUserControlClick}
                                    />
                                ) : (
                                    <div
                                        className={cn(styles.loginIcon)}
                                        onClick={ev => (location.href = '/login')}
                                        data-tip={'Sign up / Log in'}
                                    >
                                        <i className={cn('fa fa-user')} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.swipeControls}>
                    <SlidePrevControl slidePrev={slidePrev} />
                    <SlideNextControl slideNext={slideNext} />
                </div>

                <ErrorBoundary label="DndProvider">
                    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                        <ErrorBoundary label="Swiper">
                            <div
                                ref={panelControllerRef}
                                style={{ overflow: 'hidden', height: '100dvh' }}
                            >
                                <Swiper
                                    slidesPerView={isMobile ? 1 : 3}
                                    initialSlide={isMobile ? startingIndex : startingIndex + 1}
                                    onSwiper={handleSwiperInit}
                                    preventClicksPropagation={true}
                                    preventClicks={false}
                                    touchMoveStopPropagation={true}
                                    simulateTouch={isMobile}
                                    passiveListeners={false}
                                    {...(isMobile &&
                                        slideUpdates === 0 && { style: { overflow: 'initial' } })}
                                    onSlideChange={handleSlideChange}
                                    onActiveIndexChange={onActiveIndexChange}
                                    virtual={{
                                        enabled: true,
                                        addSlidesBefore: 10,
                                        addSlidesAfter: 10,
                                    }}
                                >
                                    {panelList.map((panel, index) => {
                                        const panelAnimationStyle = panelAnimationStyles[index]
                                        //console.log('PANEL ANIMATION STYLE', index, panelAnimationStyle)
                                        return (
                                            <SwiperSlide key={panel.panelId} virtualIndex={index}>
                                                <div
                                                    className={cn(
                                                        'panel-slide-wrapper',
                                                        'will-change-transform',
                                                    )}
                                                    style={panelAnimationStyle}
                                                >
                                                    <PanelContainer
                                                        panel={panel}
                                                        index={index}
                                                        isEmpty={panel.empty}
                                                        // Need this to pass through the context to the explicit visibility styles in child components.
                                                        hide={
                                                            panelAnimationStyle.dispaly === 'none'
                                                        }
                                                    >
                                                        {panel.empty ? (
                                                            <EmptyPanel />
                                                        ) : panel.filter?.history ? (
                                                            <HistoryPanel />
                                                        ) : (
                                                            <Panel keyboardOpen={keyboardOpen} />
                                                        )}
                                                    </PanelContainer>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                        </ErrorBoundary>

                        <Preview generator={generatePreview} />
                    </DndProvider>
                </ErrorBoundary>

                {true && (
                    <ReactTooltip
                        ref={tooltip => (tooltipRef.current = tooltip)}
                        effect="solid"
                        place="bottom"
                        delayShow={1000}
                        arrowColor="rgba(0,0,0,0)"
                        globalEventOff={isMobile ? 'touchstart' : undefined}
                    />
                )}
                <TitleNotificationWidget />
            </div>

            <div
                className={cn(
                    styles.controlContainer,
                    isMobileAppOrAndroid && styles.mobileControlContainer,
                    isMobileIosBrowser && styles.mobileBrowserIosControlContainer,
                )}
            >
                {!keyboardOpen && (
                    <Control
                        showFilterOptions={showFilterOptions}
                        setShowFilterOptions={setShowFilterOptions}
                        showRightSidebar={showRightSidebar}
                        setShowRightSidebar={setShowRightSidebar}
                    />
                )}
            </div>

            {showFilterOptions && (
                <div
                    className={cn(
                        styles.filterOptionsContainer,
                        isMobile && styles.mobileFilterOptionsContainer,
                    )}
                >
                    <FilterOptions
                        show={['default', 'teams']}
                        theme="dark"
                        contextStyles={cn(
                            styles.filterOptions,
                            isMobile && styles.mobileFilterOptions,
                        )}
                        showNoti
                        setShowFilterOptions={setShowFilterOptions}
                        blur
                        append
                        close={() => setShowFilterOptions(!showFilterOptions)}
                    />
                </div>
            )}

            <AutocompletePopup />
        </>
    )
})

function SlidePrevControl({ slidePrev }) {
    const panelsLength = useStore(state => state.panels.state?.length)
    const activeIndex = useStore(state => state.activeIndex)
    const [canSlide, setCanSlide] = useState(false)

    useEffect(() => {
        // Update canSlide whenever panels or activeIndex changes
        const swiper = getSwiperRef().current
        if (swiper) {
            setCanSlide(!swiper.isBeginning)
        }
    }, [panelsLength, activeIndex])

    return (
        !isMobile &&
        canSlide && (
            <div className={styles.swipeLeftContainer} onClick={slidePrev}>
                <ChevronLeft className={styles.swipeLeft} size={16} />
            </div>
        )
    )
}

function SlideNextControl({ slideNext }) {
    const panelsLength = useStore(state => state.panels.state?.length)
    const activeIndex = useStore(state => state.activeIndex)
    const [canSlide, setCanSlide] = useState(false)

    useEffect(() => {
        // Update canSlide whenever panels or activeIndex changes
        const swiper = getSwiperRef().current
        if (swiper) {
            setCanSlide(!swiper.isEnd)
        }
    }, [panelsLength, activeIndex])

    return (
        !isMobile &&
        canSlide && (
            <div className={styles.swipeRightContainer} onClick={slideNext}>
                <ChevronRight className={styles.swipeRight} size={16} />
            </div>
        )
    )
}

function TitleNotificationWidget() {
    const numNoti = useStore(state => state.numNoti)
    useEffect(() => {
        document.title = `Treechat ${numNoti ? `(${numNoti})` : ''}`
    }, [numNoti])
    return <></>
}
