import { useQuery } from '@tanstack/react-query'
import { BsvWalletType, initWallet, IWalletInterface } from 'wallets/wallets'

export const WALLET_DETAILS_QUERY_KEY = 'wallet-details'

interface WalletDetails {
    balance: number
    confirmedBalance?: number
    unconfirmedBalance?: number
    address: string
    ordinalsAddress: string
    wallet: IWalletInterface
    balanceLoaded: boolean
    addressLoaded: boolean
}

export default function useWalletDetails(walletType: BsvWalletType) {
    return useQuery<WalletDetails>({
        queryKey: [WALLET_DETAILS_QUERY_KEY, walletType],
        queryFn: async () => {
            if (!walletType) {
                throw new Error('Wallet type is required')
            }

            const wallet = initWallet(walletType)

            // trigger the worker processes - backend will generate request ids
            await Promise.all([wallet.getBalance(), wallet.getAddress()])

            // return initial state - actual values will be updated via websocket
            return {
                wallet,
                balance: 0,
                address: '',
                ordinalsAddress: '',
                balanceLoaded: false,
                addressLoaded: false,
            }
        },
        staleTime: 30 * 1000, // refresh after 30 seconds
        enabled: !!walletType, // only run if we have a wallet type
        gcTime: Infinity,
        retry: 0,
        refetchOnMount: true, // refetch on mount if data is stale
        refetchOnWindowFocus: true, // refetch on focus if data is stale
        refetchOnReconnect: true, // refetch if connection is re-established
    })
}
