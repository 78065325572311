import { createContext, useContext } from 'react'
import type { PanelState, PanelId } from 'state/PanelState'
import {
    isPanelLeft as _isPanelLeft,
    isPanelCenter as _isPanelCenter,
    isPanelRight as _isPanelRight,
} from 'state/imperativeApis/swiperApi'
import useStore from 'state/knovStore'

const StaticPanelContext = createContext<{
    panelId: PanelId
}>({
    panelId: undefined,
})

export const StaticPanelContextProvider = StaticPanelContext.Provider

export function useStaticPanelContext() {
    const context = useContext(StaticPanelContext)
    if (context === undefined) {
        throw new Error('useStaticPanelContext must be used within a StaticPanelContextProvider')
    }
    return context
}

const PanelContext = createContext<{
    panel: PanelState
    panelId: PanelId
    filter: PanelState['filter']
    hide: boolean
    isCenter?: () => boolean // Add isCenter function type
    panelContainerRef: React.RefObject<HTMLDivElement>
    scrollContainerRef: React.RefObject<HTMLDivElement>
    setExternalHideScrollButton?: React.Dispatch<React.SetStateAction<boolean>>
    setIsRefreshing?: React.Dispatch<React.SetStateAction<boolean>>
}>({
    panel: undefined,
    panelId: undefined,
    filter: undefined,
    hide: undefined,
    isCenter: undefined, // Initialize isCenter as undefined
    panelContainerRef: undefined,
    scrollContainerRef: undefined,
    setExternalHideScrollButton: undefined,
    setIsRefreshing: undefined,
})

export const PanelContextProvider = PanelContext.Provider

export default function usePanelContext() {
    const context = useContext(PanelContext)
    const panelId = context.panelId

    const isPanelLeft = () => _isPanelLeft(panelId)

    const isPanelCenter = () => _isPanelCenter(panelId)

    const isPanelRight = () => _isPanelRight(panelId)

    const panelToLeft = () => {
        const panels = useStore.getState().panels
        const panelIx = panels.getIndexById(panelId)
        return panels.state[panelIx - 1]
    }

    const panelToRight = () => {
        const panels = useStore.getState().panels
        const panelIx = panels.getIndexById(panelId)
        return panels.state[panelIx + 1]
    }

    return {
        ...context,
        isPanelLeft,
        isPanelCenter,
        isPanelRight,
        panelToLeft,
        panelToRight,
    }
}
