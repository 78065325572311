import api from 'api/api'
import { useBsvExchangeRate } from 'api/useBsvExchangeRate'
import ReactECharts from 'echarts-for-react'
import { SATOSHIS } from 'lib/bsv-util'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './metrics-viewer.module.scss'

export const TREECHAT_DEVS = ['arghzero', 'metamitya']

// Local storage key for metrics state
const METRICS_STATE_KEY = 'metricsViewerState'

// Default state values
const getDefaultState = () => ({
    dateRange: {
        startDate: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString().split('T')[0], // 90 days ago
        endDate: new Date().toISOString().split('T')[0], // today
    },
    showDaily: true,
    excludeTreechatDevs: true,
    activeTab: 'upvalues',
})

// Load state from local storage
const loadStateFromStorage = () => {
    try {
        const savedState = localStorage.getItem(METRICS_STATE_KEY)
        return savedState ? JSON.parse(savedState) : null
    } catch (error) {
        console.error('Error loading metrics state from storage:', error)
        return null
    }
}

// Save state to local storage
const saveStateToStorage = state => {
    try {
        localStorage.setItem(METRICS_STATE_KEY, JSON.stringify(state))
    } catch (error) {
        console.error('Error saving metrics state to storage:', error)
    }
}

/**
 * MetricsViewer component for displaying usage metrics
 */
const MetricsViewer = ({ modalParams, closeModal }) => {
    const location = useLocation()

    // Parse initial state from URL if available, fallback to local storage, then defaults
    const params = queryString.parse(location.search)
    const savedState = loadStateFromStorage()

    const [activeTab, setActiveTab] = useState(params.tab || savedState?.activeTab || 'upvalues')
    const [isLoading, setIsLoading] = useState(true)
    const [metricsData, setMetricsData] = useState(null)
    const [error, setError] = useState(null)
    const [dateRange, setDateRange] = useState({
        startDate:
            params.startDate ||
            savedState?.dateRange?.startDate ||
            new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        endDate:
            params.endDate ||
            savedState?.dateRange?.endDate ||
            new Date().toISOString().split('T')[0],
    })
    const [showDaily, setShowDaily] = useState(
        params.showDaily === 'true' ||
            (params.showDaily === undefined && savedState?.showDaily) ||
            true,
    )
    const [excludeTreechatDevs, setExcludeTreechatDevs] = useState(
        params.excludeTreechatDevs === 'true' ||
            (params.excludeTreechatDevs === undefined && savedState?.excludeTreechatDevs) ||
            true,
    )
    const [refreshTrigger, setRefreshTrigger] = useState(0)

    // Get the exchange rate at the component level
    const exchangeRate = useBsvExchangeRate()

    // Safely convert sats to USD
    const satsToDollars = sats => {
        if (!sats || !exchangeRate) return null
        return (sats / SATOSHIS) * exchangeRate
    }

    // Format USD value
    const formatUsdValue = amount => {
        if (!amount) return 'N/A'
        if (amount < 0.001) return '< $0.001'
        return `$${amount.toFixed(3)}`
    }

    // Update URL and local storage when state changes
    useEffect(() => {
        const currentState = {
            dateRange,
            showDaily,
            excludeTreechatDevs,
            activeTab,
        }

        // Save to local storage
        saveStateToStorage(currentState)

        // Update URL without causing navigation
        const queryParams = queryString.stringify({
            tab: activeTab,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            showDaily: showDaily.toString(),
            excludeTreechatDevs: excludeTreechatDevs.toString(),
        })

        const newUrl = `/modal/metrics?${queryParams}`
        window.history.replaceState({}, '', newUrl)
    }, [dateRange, showDaily, excludeTreechatDevs, activeTab])

    // Add class to the modal content for styling
    useEffect(() => {
        const modalContent = document.querySelector('.knov-modal-content')
        if (modalContent) {
            modalContent.classList.add('metrics-modal-content')
        }

        return () => {
            const modalContent = document.querySelector('.knov-modal-content')
            if (modalContent) {
                modalContent.classList.remove('metrics-modal-content')
            }
        }
    }, [])

    // Fetch metrics data when parameters change
    useEffect(() => {
        const fetchMetrics = async () => {
            setIsLoading(true)
            setError(null)
            try {
                let data
                const params = {
                    start_date: dateRange.startDate,
                    end_date: dateRange.endDate,
                    daily: showDaily,
                    excluded_users: excludeTreechatDevs ? TREECHAT_DEVS.join(',') : '',
                }

                switch (activeTab) {
                    case 'upvalues':
                        data = await api.getUpvaluesMetrics(params)
                        break
                    case 'posts':
                        data = await api.getPostsMetrics(params)
                        break
                    case 'ai':
                        data = await api.getAiUsageByTypeMetrics(params)
                        break
                    case 'signups':
                        data = await api.getSignupsMetrics(params)
                        break
                    default:
                        data = await api.getUpvaluesMetrics(params)
                }

                setMetricsData(data)
            } catch (err) {
                console.error(`Error fetching ${activeTab} metrics:`, err)
                setError(`Failed to load ${activeTab} metrics. Please try again later.`)
            } finally {
                setIsLoading(false)
            }
        }

        if (dateRange.startDate && dateRange.endDate) {
            fetchMetrics()
        }
    }, [dateRange, showDaily, excludeTreechatDevs, activeTab, refreshTrigger])

    const handleDateChange = (field, value) => {
        setDateRange(prev => ({
            ...prev,
            [field]: value,
        }))
    }

    const handleRefresh = () => {
        // Increment the refreshTrigger to force a re-fetch
        setRefreshTrigger(prev => prev + 1)
    }

    const handleReset = () => {
        const defaultState = getDefaultState()
        setDateRange(defaultState.dateRange)
        setShowDaily(defaultState.showDaily)
        setExcludeTreechatDevs(defaultState.excludeTreechatDevs)
        setActiveTab(defaultState.activeTab)
    }

    // Handle tab changes
    const handleTabChange = tab => {
        setActiveTab(tab)
    }

    // Handle checkbox changes
    const handleShowDailyChange = () => {
        setShowDaily(prev => !prev)
    }

    const handleExcludeDevsChange = () => {
        setExcludeTreechatDevs(prev => !prev)
    }

    const renderUpvaluesMetrics = () => {
        const upvaluesData = metricsData?.upvalues

        if (!upvaluesData) return <p>No upvalues data available</p>

        // Convert satoshis to USD - do this calculation here instead of in the render
        const totalFeeUsd = satsToDollars(upvaluesData.total_fee)
        const avgFeeUsd = satsToDollars(upvaluesData.average_fee)

        // Pre-process daily data to avoid doing calculations in the render loop
        const dailyData =
            upvaluesData.daily?.map(day => ({
                ...day,
                totalUsd: satsToDollars(day.total),
                averageUsd: satsToDollars(day.average),
            })) || []

        // Calculate trend line data for upvalues metrics
        const totalData = dailyData.map(day => day.total) || []
        const countData = dailyData.map(day => day.count) || []
        const totalTrendData = calculateTrendLine(totalData)

        // Prepare chart options for daily data
        const chartOptions = {
            title: {
                text: 'Daily Upvalues',
                left: 'center',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985',
                    },
                },
                formatter: function (params) {
                    let result = `${params[0].axisValue}<br/>`
                    params.forEach(param => {
                        // Skip trend lines in tooltip if the param name contains "Trend"
                        if (!param.seriesName.includes('Trend')) {
                            const value = param.seriesName.includes('Total (sats)')
                                ? param.value.toLocaleString() + ' sats'
                                : param.value
                            result += `${param.marker} ${param.seriesName}: ${value}<br/>`
                        }
                    })
                    return result
                },
            },
            legend: {
                data: ['Total (sats)', 'Total (sats) Trend', 'Count'],
                top: 25,
                textStyle: {
                    fontSize: 12,
                },
                itemGap: 15,
                itemWidth: 25,
                itemHeight: 14,
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: dailyData.map(day => day.date) || [],
            },
            yAxis: [
                {
                    type: 'value',
                    name: 'Sats',
                    position: 'left',
                },
                {
                    type: 'value',
                    name: 'Count',
                    position: 'right',
                },
            ],
            series: [
                {
                    name: 'Count',
                    type: 'bar',
                    data: countData,
                    yAxisIndex: 1,
                    itemStyle: {
                        color: '#91cc75',
                    },
                    z: 1,
                },
                {
                    name: 'Total (sats)',
                    type: 'line',
                    smooth: true,
                    data: totalData,
                    yAxisIndex: 0,
                    itemStyle: {
                        color: '#5470c6',
                    },
                    z: 2,
                },
                {
                    name: 'Total (sats) Trend',
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    itemStyle: {
                        color: '#5470c6',
                        opacity: 0.7,
                    },
                    lineStyle: {
                        type: 'dashed',
                        color: '#5470c6',
                        width: 2,
                        opacity: 0.7,
                    },
                    z: 3,
                    data: totalTrendData,
                    yAxisIndex: 0,
                },
            ],
        }

        return (
            <>
                <div className={styles.metricsOverview}>
                    <div className={styles.metricCard}>
                        <h3>Total Upvalue Fee</h3>
                        <p className={styles.metricValue}>
                            {upvaluesData.total_fee.toLocaleString()} sats
                        </p>
                        <p className={styles.metricSubvalue}>{formatUsdValue(totalFeeUsd)}</p>
                    </div>
                    <div className={styles.metricCard}>
                        <h3>Upvalue Count</h3>
                        <p className={styles.metricValue}>
                            {upvaluesData.upvalue_count.toLocaleString()}
                        </p>
                    </div>
                    <div className={styles.metricCard}>
                        <h3>Average Fee</h3>
                        <p className={styles.metricValue}>
                            {upvaluesData.average_fee.toLocaleString()} sats
                        </p>
                        <p className={styles.metricSubvalue}>{formatUsdValue(avgFeeUsd)}</p>
                    </div>
                </div>

                {showDaily && dailyData.length > 0 && (
                    <>
                        <div className={styles.chartSection}>
                            <ReactECharts
                                option={chartOptions}
                                style={{ height: '350px', width: '100%' }}
                            />
                        </div>
                        <div className={styles.dailyData}>
                            <h3>Daily Upvalues</h3>
                            <table className={styles.dataTable}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Total (sats)</th>
                                        <th>Total (USD)</th>
                                        <th>Count</th>
                                        <th>Average (sats)</th>
                                        <th>Average (USD)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dailyData.map((day, index) => (
                                        <tr key={index}>
                                            <td>{day.date}</td>
                                            <td>{day.total.toLocaleString()}</td>
                                            <td>{formatUsdValue(day.totalUsd)}</td>
                                            <td>{day.count}</td>
                                            <td>{day.average.toLocaleString()}</td>
                                            <td>{formatUsdValue(day.averageUsd)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </>
        )
    }

    const renderPostsMetrics = () => {
        const postsData = metricsData?.posts

        if (!postsData) return <p>No posts data available</p>

        // Calculate trend line data
        const dailyData = postsData.daily?.map(day => day.count) || []
        const trendData = calculateTrendLine(dailyData)

        // Prepare chart options for daily posts
        const chartOptions = {
            title: {
                text: 'Daily Posts',
                left: 'center',
            },
            tooltip: {
                trigger: 'axis',
            },
            legend: {
                data: ['Posts', 'Trend'],
                top: 25,
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                data: postsData.daily?.map(day => day.date) || [],
                boundaryGap: false,
            },
            yAxis: {
                type: 'value',
                name: 'Posts',
            },
            series: [
                {
                    name: 'Posts',
                    type: 'line',
                    data: dailyData,
                    areaStyle: {},
                    smooth: true,
                },
                {
                    name: 'Trend',
                    type: 'line',
                    data: trendData,
                    smooth: true,
                    symbol: 'none',
                    lineStyle: {
                        type: 'dashed',
                    },
                },
            ],
        }

        return (
            <div className={styles.metricsContent}>
                <div className={`${styles.metricsOverview} ${styles.singleCardOverview}`}>
                    <div className={styles.metricCard}>
                        <h3>Total Posts</h3>
                        <p className={styles.metricValue}>{postsData.total_count}</p>
                    </div>
                </div>

                {showDaily && postsData.daily && postsData.daily.length > 0 && (
                    <div className={styles.chartSection}>
                        <ReactECharts option={chartOptions} style={{ height: '400px' }} />
                        <div className={styles.dailyData}>
                            <h3>Daily Posts Table</h3>
                            <table className={styles.dataTable}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {postsData.daily.map((day, index) => (
                                        <tr key={index}>
                                            <td>{day.date}</td>
                                            <td>{day.count}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    // Helper function to calculate trend line
    const calculateTrendLine = data => {
        const n = data.length
        if (n < 2) return data

        // Calculate the slope and intercept using least squares method
        let sumX = 0
        let sumY = 0
        let sumXY = 0
        let sumXX = 0

        for (let i = 0; i < n; i++) {
            sumX += i
            sumY += data[i]
            sumXY += i * data[i]
            sumXX += i * i
        }

        const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX)
        const intercept = (sumY - slope * sumX) / n

        // Generate trend line points
        return data.map((_, i) => slope * i + intercept)
    }

    const renderAiUsageMetrics = () => {
        const aiData = metricsData?.ai_usage_by_type || metricsData

        if (!aiData || (!aiData.by_provider && !aiData.by_type)) {
            return <p>No AI usage data available</p>
        }

        // Prepare chart options for AI usage by type
        const typeChartOptions = {
            title: {
                text: 'AI Usage by Type',
                left: 'center',
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}: {c} ({d}%)',
            },
            series: [
                {
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2,
                    },
                    label: {
                        show: true,
                        position: 'outside',
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '16',
                            fontWeight: 'bold',
                        },
                    },
                    data:
                        aiData.by_type?.map(item => ({
                            name: item.type,
                            value: item.usage_count,
                        })) || [],
                },
            ],
        }

        // Prepare chart options for AI usage by provider
        const providerChartOptions = {
            title: {
                text: 'AI Usage by Provider',
                left: 'center',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            legend: {
                top: 25,
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                data: [...new Set(aiData.by_provider?.map(item => item.provider))] || [],
            },
            yAxis: {
                type: 'value',
                name: 'Usage Count',
            },
            series: aiData.by_provider
                ? [...new Set(aiData.by_provider.map(item => item.type))].map(type => ({
                      name: type,
                      type: 'bar',
                      stack: 'total',
                      data: aiData.by_provider
                          .filter(item => item.type === type)
                          .map(item => item.usage_count),
                  }))
                : [],
        }

        return (
            <div className={styles.metricsContent}>
                <div className={styles.chartSection}>
                    <ReactECharts option={typeChartOptions} style={{ height: '400px' }} />
                </div>

                <div className={styles.chartSection}>
                    <ReactECharts option={providerChartOptions} style={{ height: '400px' }} />
                </div>

                {aiData.by_type && (
                    <div className={styles.section}>
                        <h3>AI Usage by Type Table</h3>
                        <table className={styles.dataTable}>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Usage Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {aiData.by_type.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.type}</td>
                                        <td>{item.usage_count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {aiData.by_provider && (
                    <div className={styles.section}>
                        <h3>AI Usage by Provider Table</h3>
                        <table className={styles.dataTable}>
                            <thead>
                                <tr>
                                    <th>Provider</th>
                                    <th>Type</th>
                                    <th>Usage Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {aiData.by_provider.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.provider}</td>
                                        <td>{item.type}</td>
                                        <td>{item.usage_count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        )
    }

    const renderSignupsMetrics = () => {
        const signupsData = metricsData?.signups

        if (!signupsData) return <p>No signups data available</p>

        // Calculate trend line data
        const dailyData = signupsData.daily?.map(day => day.count) || []
        const trendData = calculateTrendLine(dailyData)

        // Prepare chart options for daily signups
        const chartOptions = {
            title: {
                text: 'Daily Signups',
                left: 'center',
            },
            tooltip: {
                trigger: 'axis',
            },
            legend: {
                data: ['Signups', 'Trend'],
                top: 25,
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                data: signupsData.daily?.map(day => day.date) || [],
                boundaryGap: false,
            },
            yAxis: {
                type: 'value',
                name: 'Signups',
            },
            series: [
                {
                    name: 'Signups',
                    type: 'bar',
                    data: dailyData,
                    itemStyle: {
                        color: '#91cc75',
                    },
                },
                {
                    name: 'Trend',
                    type: 'line',
                    data: trendData,
                    smooth: true,
                    symbol: 'none',
                    lineStyle: {
                        type: 'dashed',
                        color: '#666',
                    },
                },
            ],
        }

        return (
            <div className={styles.metricsContent}>
                <div className={`${styles.metricsOverview} ${styles.singleCardOverview}`}>
                    <div className={styles.metricCard}>
                        <h3>Total Signups</h3>
                        <p className={styles.metricValue}>{signupsData.total_count}</p>
                    </div>
                </div>

                {showDaily && signupsData.daily && signupsData.daily.length > 0 && (
                    <div className={styles.chartSection}>
                        <ReactECharts option={chartOptions} style={{ height: '400px' }} />
                        <div className={styles.dailyData}>
                            <h3>Daily Signups Table</h3>
                            <table className={styles.dataTable}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {signupsData.daily.map((day, index) => (
                                        <tr key={index}>
                                            <td>{day.date}</td>
                                            <td>{day.count}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const renderTabContent = () => {
        if (isLoading) {
            return (
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}>
                        <i className="fa fa-circle-o-notch fa-spin fa-2x" />
                    </div>
                    <p>Loading metrics data...</p>
                </div>
            )
        }

        if (error) {
            return (
                <div className={styles.errorContainer}>
                    <i className="fa fa-exclamation-triangle" />
                    <p>{error}</p>
                </div>
            )
        }

        switch (activeTab) {
            case 'upvalues':
                return renderUpvaluesMetrics()
            case 'posts':
                return renderPostsMetrics()
            case 'ai':
                return renderAiUsageMetrics()
            case 'signups':
                return renderSignupsMetrics()
            default:
                return <p>Select a tab to view metrics</p>
        }
    }

    // Helper function to get date range presets
    const getDateRangePreset = preset => {
        const endDate = new Date().toISOString().split('T')[0] // Today
        let startDate

        switch (preset) {
            case 'day':
                // Last 24 hours
                startDate = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]
                break
            case 'week':
                // Last 7 days
                startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]
                break
            case 'month':
                // Last 30 days
                startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]
                break
            case 'year':
                // Last 365 days
                startDate = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]
                break
            default:
                startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split('T')[0]
        }

        return { startDate, endDate }
    }

    // Apply a preset date range
    const applyDateRangePreset = preset => {
        const newRange = getDateRangePreset(preset)
        setDateRange(newRange)
    }

    // Update the renderDateControls to put quick select buttons in line with date inputs
    const renderDateControls = () => (
        <div className={styles.controls}>
            <div className={styles.dateControlsRow}>
                <div className={styles.dateRange}>
                    <div className={styles.dateInput}>
                        <label>Start Date:</label>
                        <input
                            type="date"
                            value={dateRange.startDate}
                            onChange={e => handleDateChange('startDate', e.target.value)}
                        />
                    </div>
                    <div className={styles.dateInput}>
                        <label>End Date:</label>
                        <input
                            type="date"
                            value={dateRange.endDate}
                            onChange={e => handleDateChange('endDate', e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles.quickSelect}>
                    <span className={styles.quickSelectLabel}>Quick select last:</span>
                    <div className={styles.quickSelectButtons}>
                        <button
                            className={styles.quickSelectButton}
                            onClick={() => applyDateRangePreset('year')}
                        >
                            Year
                        </button>
                        <button
                            className={styles.quickSelectButton}
                            onClick={() => applyDateRangePreset('month')}
                        >
                            Month
                        </button>
                        <button
                            className={styles.quickSelectButton}
                            onClick={() => applyDateRangePreset('week')}
                        >
                            Week
                        </button>
                        <button
                            className={styles.quickSelectButton}
                            onClick={() => applyDateRangePreset('day')}
                        >
                            Day
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.checkboxControls}>
                <label className={styles.checkboxControl}>
                    <input type="checkbox" checked={showDaily} onChange={handleShowDailyChange} />
                    Show Daily Data
                </label>
                <label className={styles.checkboxControl}>
                    <input
                        type="checkbox"
                        checked={excludeTreechatDevs}
                        onChange={handleExcludeDevsChange}
                    />
                    <span>
                        Exclude Treechat Devs
                        <span className={styles.helperText}>({TREECHAT_DEVS.join(', ')})</span>
                    </span>
                </label>
            </div>
            <div className={styles.buttonGroup}>
                <button className={styles.refreshButton} onClick={handleRefresh}>
                    <i className="fa fa-sync-alt" /> Refresh Data
                </button>
                <button className={styles.resetButton} onClick={handleReset}>
                    <i className="fa fa-undo" /> Reset
                </button>
            </div>
        </div>
    )

    return (
        <div className={styles.container}>
            {renderDateControls()}

            <div className={styles.tabsContainer}>
                <div
                    className={`${styles.tab} ${activeTab === 'upvalues' ? styles.active : ''}`}
                    onClick={() => handleTabChange('upvalues')}
                >
                    Upvalues
                </div>
                <div
                    className={`${styles.tab} ${activeTab === 'signups' ? styles.active : ''}`}
                    onClick={() => handleTabChange('signups')}
                >
                    Signups
                </div>
                <div
                    className={`${styles.tab} ${activeTab === 'posts' ? styles.active : ''}`}
                    onClick={() => handleTabChange('posts')}
                >
                    Posts
                </div>
                <div
                    className={`${styles.tab} ${activeTab === 'ai' ? styles.active : ''}`}
                    onClick={() => handleTabChange('ai')}
                >
                    AI Usage
                </div>
            </div>

            <div className={styles.content}>
                {isLoading ? (
                    <div className={styles.loadingContainer}>
                        <div className={styles.spinner}>Loading...</div>
                    </div>
                ) : error ? (
                    <div className={styles.errorContainer}>
                        <p>{error}</p>
                    </div>
                ) : (
                    <div className={styles.metricsContent}>{renderTabContent()}</div>
                )}
            </div>
        </div>
    )
}

export default MetricsViewer
