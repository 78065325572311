import { v4 as uuid } from 'uuid'
import { getText, getImages } from '../../lib/value'
import { pick } from 'lodash'

// These are the properties which can be editied in the front end.
export const EDIT_WHITELIST = [
    'content',
    'delta_json',
    'position',
    'embeds',
    'files',
    'file_urls',
    'answer_image',
    'answer_image_url',
    'recording',
    'recording_url',
    'team_id',
    'team',
    'private',
    'public',
]

// These are the answer properties that can be inherited from published answer.
// We do this to limit the ways in which a draftAnswer can interact with the app.
export const DRAFT_WHITELIST = [
    ...EDIT_WHITELIST,
    'space_id',
    'mp4_recording',
    'mp4_recording_url',
    'space_id',
    'user_id',
    'user',
    'created_at',
    'updated_at',
]

const model = {
    getDraft: answer => model.newAnswer(pick(answer, DRAFT_WHITELIST)),

    getDeltaText: deltaJson => getText(deltaJson),

    getDeltaJson: editor => editor?.getContents() || {},

    getText: answer => model.getDeltaText(answer?.delta_json),

    empty: answer => model.getDeltaText(answer?.delta_json)?.trim() == '',

    hasText: answer => answer.content?.length || getText(answer.deltaJson),
    hasEmbeds: answer => answer.embeds?.length,
    hasFiles: answer => answer.file_urls?.length,
    hasVideo: answer => answer.recording_url,
    hasImage: answer => answer.answer_image_url || getImages(answer.deltaJson),
    hasUrl: answer => answer.url,

    hasContent: answer => {
        let hasContent = null

        if (answer) {
            hasContent =
                model.hasText(answer) ||
                model.hasEmbeds(answer) ||
                model.hasFiles(answer) ||
                model.hasVideo(answer) ||
                model.hasImage(answer)
        }
        return hasContent
    },

    hasAnnotation: (answer, editor = null) => {
        let deltaJson
        if (editor) {
            deltaJson = model.getDeltaJson(editor)
        } else {
            deltaJson = answer?.delta_json
        }
        const deltaText = model.getDeltaText(deltaJson)
        return answer?.url && !!deltaText?.trim()
    },

    newAnswer: (answer = {}) => {
        const answerId = uuid()
        let localAnswer = {
            id: answerId,
            is_draft: true,
            ...answer,
        }

        const now = new Date()
        if (!localAnswer.created_at) localAnswer.created_at = now
        if (!localAnswer.updated_at) localAnswer.updated_at = now
        if (!localAnswer.position) localAnswer.position = now.getTime()

        if (!localAnswer.user_id) localAnswer.user_id = gon.currentUser?.id
        if (!localAnswer.user) localAnswer.user = gon.currentUser

        if (!localAnswer.path) localAnswer.path = `/answer/${answerId}`
        if (!localAnswer.archive) localAnswer.archive = false

        return localAnswer
    },

    hasLLMRequest: answer => {
        return answer.message_type !== 'agent' && answer.agent_model
    },

    hasLLMReply: answer => {
        return answer.message_type === 'agent' && answer.agent_model
    },
}

export default model
