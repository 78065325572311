import React from 'react'
import styles from 'components/quests/confirm.module.scss'

export default function Confirm({ modalParams, closeModal }) {
    const onConfirm = () => {
        closeModal()
        if (modalParams.onConfirm) modalParams.onConfirm()
    }

    const onCancel = () => {
        if (modalParams.onCancel) modalParams.onCancel()
        else if (closeModal) closeModal()
    }

    const renderMessage = () => {
        // If message is a React element, return it directly
        if (React.isValidElement(modalParams.message)) {
            return modalParams.message
        }
        // Otherwise treat it as a string
        return <div>{modalParams.message}</div>
    }

    return (
        <div className={styles.confirmComp}>
            <div className={styles.confirmMessage}>{renderMessage()}</div>
            <div className={styles.confirmActions}>
                {!modalParams.hideCancel && (
                    <button className={styles.cancelButton} onClick={onCancel}>
                        {modalParams.cancelMessage || 'Cancel'}
                    </button>
                )}

                <button className={styles.confirmButton} onClick={onConfirm}>
                    {modalParams.confirmLabel || 'Got it!'}
                </button>
            </div>
        </div>
    )
}
