import useStore from 'state/knovStore'

export default function useSearchHeaderHighlight() {
    const highlightSearchHeader = panelId => {
        if (!panelId) return

        useStore.getState().set(state => {
            state.searchHeaderAnimationStates = {
                ...state.searchHeaderAnimationStates,
                [panelId]: {
                    state: 'fadeIn',
                    timestamp: Date.now(),
                },
            }
        })

        setTimeout(() => {
            useStore.getState().set(state => {
                if (state.searchHeaderAnimationStates?.[panelId]) {
                    state.searchHeaderAnimationStates[panelId].state = 'fadeOut'
                }
            })
        }, 1000)
    }

    return highlightSearchHeader
}
