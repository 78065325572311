import React, { useState, useRef, useEffect, useMemo } from 'react'
import useStore from 'state/knovStore'
import cn from 'classnames'
import styles from 'components/panels/search-header.module.scss'
import FilterIcon from 'components/filters/FilterIcon'
import FilterOptions from 'components/filters/FilterOptions'
import FilterOptionsControl from 'components/filters/FilterOptionsControl'
import SearchBar from 'components/panels/SearchBar'
import StreamOrder from 'components/panels/StreamOrder'
import StreamOptions from 'components/panels/StreamOptions'
import useStreamFilter from 'components/filters/useStreamFilters'
import WithTooltip from 'components/shared/WithTooltip'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useSlideToPanel from 'refactor/hooks/useSlideToPanel'
import queryClient from 'api/queryClient'
import { serializeFilterKey } from 'refactor/hooks/api/useGetQuests'
import useGetUser from 'refactor/hooks/api/useGetUser'
import { Participants, nonUserParticipants } from 'components/people/People'
import { getCachedQuest } from 'state/cache'
import { getDepth, withDepth } from 'components/filters/useStreamFilters'
import { clearNotificationCount } from 'utils/notificationUtils'

export default function SearchHeader(props) {
    const [hover, setHover] = useState(false)
    const { panel, hide } = usePanelContext()
    const panelId = panel.panelId
    const filter = panel.filter
    const query = filter?.query

    //console.log('search header filter', props.panel, filter)
    const DEBUG = useStore(state => state.DEBUG)
    const animationState = useStore(state => state.searchHeaderAnimationStates?.[panelId]?.state)

    const [showFilterOptions, setShowFilterOptions] = useState()

    const showSearchBar =
        !filter?.notifications &&
        !filter?.history &&
        !filter?.starred &&
        !filter?.people &&
        !filter?.participants

    const isMeme = filter?.meme
    const searchBarPlaceholder = isMeme ? 'Search Meme' : 'Search Stream'
    const filterUserId = filter?.user
    const { user: filterUser } = useGetUser(filterUserId)
    const minStyles =
        filter?.notifications ||
        filter?.history ||
        filter?.starred ||
        filter.people ||
        filter?.participants
            ? styles.min
            : null

    const memeFilterIconStyles = isMeme ? styles.memeFilterIcon : null
    const memeFilterNameStyles = isMeme ? styles.memeFilterName : null
    const memeControlContainerStyles = isMeme ? styles.memeControlContainer : null

    const slideToPanel = useSlideToPanel()

    const refreshStream = async () => {
        props.setIsRefreshing(true)
        // Invalidate and refetch quests when the panel mounts
        const newFilter = withDepth(filter)
        const filterKey = serializeFilterKey(newFilter)

        // clear notification count if we're refreshing the notifications stream
        if (newFilter?.notifications) {
            await clearNotificationCount()
        }

        await queryClient.invalidateQueries({ queryKey: ['quests', filterKey], exact: true })
        props.setIsRefreshing(false)
    }

    const onClickStream = ev => {
        ev.stopPropagation()
        ev.preventDefault()

        refreshStream()
    }

    const lastAction = useStore.getState().panels?.lastAction

    // We get the participantQuest from the prior panel via lastAction so we don't have to wait for the quest.
    let participantQuest = props.quest
    if (
        !participantQuest &&
        panel?.panelId === lastAction?.insert?.panelId &&
        lastAction?.questId
    ) {
        participantQuest = getCachedQuest(lastAction.questId)
    }
    const participants = useMemo(() => nonUserParticipants(participantQuest), [participantQuest])
    const showStream = !filter?.participants
    const showStreamOptions = !(
        filter.people ||
        filter?.participants ||
        filter?.meme ||
        filter?.notifications ||
        filter?.history ||
        filter?.starred
    )

    const animationClass =
        (animationState === 'fadeIn' && styles.selectedAnimationFadeIn) ||
        (animationState === 'fadeOut' && styles.selectedAnimationFadeOut) ||
        ''

    return (
        <div
            className={cn(
                styles.searchHeaderComp,
                `search-header-comp ${props.panel}`,
                minStyles,
                animationClass,
            )}
            style={{ visibility: hide || props.hideDuringScroll ? 'hidden' : 'visible' }}
            onClick={ev => slideToPanel()}
        >
            {DEBUG && (
                <div className={styles.debugContainer}>
                    <span className={styles.debugText}>
                        Filter:{' '}
                        <span
                            style={{ fontFamily: 'monospace', wordWrap: 'break-word' }}
                            onClick={() => {
                                const text = JSON.stringify(filter)
                                navigator.clipboard.writeText(text)
                                // briefly bold the text
                                const spanElement = document.getElementById('filterText')
                                spanElement.style.fontWeight = 'bold'
                                setTimeout(() => {
                                    spanElement.style.fontWeight = 'normal'
                                }, 500)
                            }}
                            id="filterText"
                        >
                            {JSON.stringify(filter)}
                        </span>
                    </span>
                </div>
            )}
            {showStream && (
                <div className={cn(styles.streamControlContainer, memeControlContainerStyles)}>
                    <div className={styles.filterContainer}>
                        <div
                            className={styles.filterIconContainer}
                            onClick={onClickStream}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <FilterIcon
                                disable={true}
                                filter={filter}
                                panel={props.panel}
                                user={filterUser}
                                showNumNoti
                                contextStyles={cn(styles.searchHeaderIcon, memeFilterIconStyles)}
                                initialStyles={styles.initialStyles}
                                publicIcon={styles.publicIcon}
                                treechatIcon={styles.treechatIcon}
                                hodlockerIcon={styles.hodlockerIcon}
                                twetchIcon={styles.twetchIcon}
                                notiIcon={styles.notiIcon}
                                fontIcon={styles.fontIcon}
                                privateIcon={styles.privateIcon}
                                linksIcon={styles.linksIcon}
                                historyIcon={styles.historyIcon}
                                peopleIcon={styles.peopleIcon}
                            />
                        </div>

                        <div
                            className={styles.filterOptionsControlContainer}
                            //onClick={ev => ev.stopPropagation()}
                        >
                            <FilterOptionsControl
                                filter={filter}
                                panel={props.panel}
                                user={gon.currentUser}
                                filterUser={filterUser}
                                showFilterOptions={showFilterOptions}
                                setShowFilterOptions={setShowFilterOptions}
                                nameStyles={cn(styles.name, memeFilterNameStyles)}
                                showName
                                disable
                            />
                        </div>
                    </div>
                </div>
            )}

            {showFilterOptions && (
                <div className={styles.filterOptionsContainer}>
                    <FilterOptions
                        origin="search-header"
                        filter={filter}
                        panelId={props.panelId}
                        panel={props.panel}
                        query={props.query}
                        close={() => setShowFilterOptions(!showFilterOptions)}
                        show={['default', 'teams']}
                        theme="dark"
                        setShowFilterOptions={setShowFilterOptions}
                        showNoti
                        blur
                    />
                </div>
            )}

            {filter?.meme && (
                <div className={styles.memeHeaderContainer}>
                    <div className={cn(styles.memeIcon, styles.leftMemeIcon)}>
                        <span
                            className={cn(
                                styles.bracketIcon,
                                styles.outerBracket,
                                styles.outerLeftBracket,
                            )}
                        >
                            [
                        </span>
                        <span
                            className={cn(
                                styles.bracketIcon,
                                styles.innerBracket,
                                styles.innerLeftBracket,
                            )}
                        >
                            [
                        </span>
                    </div>

                    <div className={styles.memeHeader}>{filter?.meme}</div>

                    <div className={cn(styles.memeIcon, styles.rightMemeIcon)}>
                        <span
                            className={cn(
                                styles.bracketIcon,
                                styles.innerBracket,
                                styles.innerRightBracket,
                            )}
                        >
                            ]
                        </span>
                        <span
                            className={cn(
                                styles.bracketIcon,
                                styles.outerBracket,
                                styles.outerRightBracket,
                            )}
                        >
                            ]
                        </span>
                    </div>
                </div>
            )}

            {showSearchBar && (
                <>
                    <div className={styles.searchBarContainer} onClick={ev => ev.stopPropagation()}>
                        <div className={styles.searchAndOptionsRow}>
                            <SearchBar
                                key={Object(filter).keys?.join('_')}
                                filter={filter}
                                query={query}
                                panel={props.panel}
                                placeholder={searchBarPlaceholder}
                                onHandlersReady={handlers => {
                                    // Store search handlers for the outside button
                                    window.searchHandlers = handlers
                                }}
                            />

                            <div
                                className={cn(
                                    styles.externalSearchButton,
                                    window.searchHandlers?.hasQuery ? styles.active : '',
                                )}
                                onClick={() => window.searchHandlers?.search('relev')}
                            >
                                <i className="fa fa-search" />
                            </div>

                            {showStreamOptions && (
                                <div className={styles.streamOptionsContainer}>
                                    <StreamOptions panelId={panelId} filter={filter} />
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        className={cn(styles.streamOrderContainer)}
                        onClick={ev => ev.stopPropagation()}
                    >
                        <StreamOrder
                            filter={filter}
                            panelId={panelId}
                            panel={props.panel}
                            query={query}
                        />
                    </div>
                </>
            )}

            {filter?.participants && participants && (
                <div className={styles.participantsContainer}>
                    {participants && (
                        <Participants
                            participants={participants}
                            quest={participantQuest}
                            contextStyles={styles.participants}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
