import { useEffect } from 'react'
import CableApp from 'actioncable'
import useStore from 'state/knovStore'
import { BsvWalletType } from 'wallets/wallets'
import queryClient from 'api/queryClient'
import { WALLET_DETAILS_QUERY_KEY } from 'api/useWalletDetails'

const msgTypes = {
    USER_BSV_BALANCE_UPDATED: 'update user bsv balance',
    USER_BSV_ADDRESS_UPDATED: 'update user bsv address',
    USER_BSV_TRANSACTION_SENT: 'sent bsv transaction',
    USER_BSV_TRANSACTION_FAIL: 'failed bsv transaction',
    USER_BSV_UPVALUE: 'upvalue',
}

export default function useUserChannel() {
    const set = useStore(state => state.set)
    const activeSpaceId = useStore(state => state.activeSpaceId)
    const currentUserBsvWalletType = useStore(state => state.currentUserBsvWalletType)

    useEffect(() => {
        if (gon.currentUser && currentUserBsvWalletType === BsvWalletType.SHUALLET) {
            const cable = CableApp.cable
            const channel = cable.subscriptions.create(
                { channel: 'UserChannel', userId: gon.currentUser.id },
                {
                    received: data => {
                        if (data.message === 'new space' && data.space_id !== activeSpaceId) {
                            location.reload()
                        }

                        if (data.message === msgTypes.USER_BSV_BALANCE_UPDATED) {
                            // console.log(`>>> Changing balance to: ${data.balance}`)
                            queryClient.setQueryData(
                                [WALLET_DETAILS_QUERY_KEY, currentUserBsvWalletType],
                                (oldData: any) => ({
                                    ...oldData,
                                    balance: data.balance,
                                    confirmedBalance: data.confirmed,
                                    unconfirmedBalance: data.unconfirmed,
                                    balanceLoaded: true,
                                }),
                            )
                        }

                        if (data.message === msgTypes.USER_BSV_ADDRESS_UPDATED) {
                            if (data.address !== '') {
                                try {
                                    const addressInfo = JSON.parse(data.address)
                                    //console.log('>>> received address info: ', addressInfo)
                                    queryClient.setQueryData(
                                        [WALLET_DETAILS_QUERY_KEY, currentUserBsvWalletType],
                                        (oldData: any) => ({
                                            ...oldData,
                                            address: addressInfo.address,
                                            ordinalsAddress: addressInfo.ordinalsAddress,
                                            addressLoaded: true,
                                        }),
                                    )
                                } catch (error) {
                                    console.error('Failed to parse address info:', error)
                                }
                            } else {
                                console.warn(">>> Attempted to change address to ''!")
                            }
                        }

                        if (data.message === msgTypes.USER_BSV_UPVALUE) {
                            queryClient.setQueryData(
                                ['bsv-upvalue-history', gon.currentUser?.id],
                                (oldData: any[] = []) => {
                                    // Ensure we don't add duplicate entries
                                    if (oldData.some(item => item.id === data.upvalue.id)) {
                                        return oldData
                                    }
                                    return [data.upvalue, ...oldData]
                                },
                            )
                        }
                    },
                },
            )

            return () => {
                cable.subscriptions.remove(channel)
            }
        }
    }, [currentUserBsvWalletType])
}

// core function to create a transaction subscription
// this handles the common logic for both hook and non-hook versions
function createTxChannelSubscription(
    successCallback: (txId: string) => void,
    errorCallback: (error: string) => void,
) {
    if (!gon.currentUser) return null

    const cable = CableApp.cable
    const channel = cable.subscriptions.create(
        { channel: 'UserChannel', userId: gon.currentUser.id },
        {
            received: data => {
                if (data.message === msgTypes.USER_BSV_TRANSACTION_SENT) {
                    successCallback(data.transactionId)
                }

                if (data.message === msgTypes.USER_BSV_TRANSACTION_FAIL) {
                    errorCallback(data.error)
                }
            },
        },
    )

    // return a function to unsubscribe
    return () => {
        cable.subscriptions.remove(channel)
    }
}

// react hook version for functional components
function useTxChannel({
    successCallback,
    errorCallback,
}: {
    successCallback: (txId: string) => void
    errorCallback: (error: string) => void
}) {
    useEffect(() => {
        // Create the subscription and store the cleanup function
        const unsubscribe = createTxChannelSubscription(successCallback, errorCallback)

        // Return cleanup function for useEffect
        return () => {
            if (unsubscribe) unsubscribe()
        }
    }, [])
}

// non-hook version for class components (looking at you NewAnswer)
function createTxSubscription(
    successCallback: (txId: string) => void,
    errorCallback: (error: string) => void,
) {
    return createTxChannelSubscription(successCallback, errorCallback)
}

export { useTxChannel, createTxSubscription }
