import useStore from 'state/knovStore'
import { slideTo } from 'state/imperativeApis/swiperApi'
import usePanelContext from 'refactor/hooks/usePanelContext'
import { isManualSlide } from 'components/PanelController/PanelController'

export default function useSlideToPanel() {
    const { panelId } = usePanelContext()
    const getIndexById = useStore(store => store.panels.getIndexById)
    return ({ index, speed }: { index?: number; speed?: number } = {}) => {
        if (index === undefined) {
            index = getIndexById(panelId)
        }
        console.log('useSlideToPanel', index, speed === 0 ? 'instant' : 'slide')
        isManualSlide.current = true
        slideTo(index, speed ?? 300)
    }
}
