import React, { useRef, useEffect, useState, useCallback } from 'react'
import CommonEditor from 'components/shared/CommonEditor'
import styles from 'components/panels/search-bar.module.scss'
import cn from 'classnames'
import { getText } from 'lib/value'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useStreamFilters, { withDepth } from 'components/filters/useStreamFilters'
import { assignMiddleSearchBarRef, assignRightSearchBarRef } from 'state/imperativeApis/editorApi'
import queryClient from 'api/queryClient'
import { serializeFilterKey } from 'refactor/hooks/api/useGetQuests'

function SearchBar(props) {
    const placeholder = props.placeholder || 'Search stream...'
    const { panelId, filter } = usePanelContext()
    const query = filter?.query

    const { setQuery } = useStreamFilters(panelId)

    const [editorQuery, setEditorQuery] = useState()
    const onChange = useCallback(value => {
        // Extract text directly from value to ensure numbers are preserved
        const text = getText(value)?.trim() || ''
        setEditorQuery(text)
    }, [])

    const richEditor = useRef()
    useEffect(() => {
        if (props.panel === 'middle') assignMiddleSearchBarRef(richEditor.current)
        else assignRightSearchBarRef(richEditor.current)
    }, [])

    const searchHandler = async order => {
        const editorValue = richEditor.current?.getEditor()?.getContents()
        const query = getText(editorValue)?.trim()

        // Invalidate cache before setting query
        const newFilter = withDepth({ ...filter, query, order })
        const filterKey = serializeFilterKey(newFilter)
        await queryClient.invalidateQueries({ queryKey: ['quests', filterKey], exact: true })

        setEditorQuery(query)
        setQuery(query, order)
        logEv('SEARCH', { filter })
    }

    const clearSearch = () => {
        setEditorQuery('')
        setQuery('')
    }

    // Expose search handler and clear function for parent components
    if (props.onHandlersReady) {
        props.onHandlersReady({
            search: searchHandler,
            clear: clearSearch,
            hasQuery: !!editorQuery,
        })
    }

    return (
        <div className={cn(styles.searchBarComp, 'search-bar-comp')}>
            {false && (
                <div className={styles.searchIcon}>
                    <i className="fa fa-search" />
                </div>
            )}

            <div className={cn(styles.commonEditorContainer, 'new-quest-overflow-container')}>
                <CommonEditor
                    // Make sure to init value  when switching streams/memes.
                    key={JSON.stringify(filter)}
                    ref={richEditor}
                    defaultValue={
                        editorQuery
                            ? {
                                  ops: [{ insert: String(editorQuery) }],
                              }
                            : undefined
                    }
                    placeholder={placeholder}
                    postHandler={() => searchHandler('relev')}
                    onTextChange={onChange}
                    //users={this.props.users}
                    //tags={this.props.tags}
                    //ignoreQuestMentions={true}
                    type="search"
                />
            </div>

            <div className={styles.searchButtonsContainer}>
                {editorQuery && (
                    <div className={styles.cancelSearchButton} onClick={clearSearch}>
                        <i className="fa fa-remove" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default SearchBar
