import { Quill } from 'react-quill-new'

const Delta = Quill.import('delta')
const Clipboard = Quill.import('modules/clipboard') as any

const URL_REGEX = /https?:\/\/[^\s]+/g

export class KnovClipboard extends Clipboard {
    quill: any // Add quill property to fix type errors

    // adapted from https://stackoverflow.com/a/51255601
    async onPaste(range, e) {
        if (e?.text?.match(URL_REGEX)) {
            const range = this.quill.getSelection()
            const delta = this.quill.getContents()

            // remove the trailing \n quill likes to add if present
            let lastOp = delta.ops[delta.ops.length - 1]
            if (lastOp.insert && typeof lastOp.insert === 'string')
                lastOp.insert = lastOp.insert.replace(/\n$/, '')

            const index = range.index
            // NOTE: this mirrors the logic in auto_links.js
            delta.ops.push({ insert: e.text, attributes: { link: e.text } })
            this.quill.setContents(delta, Quill.sources.USER)
            this.quill.setSelection(index + e.text.length, 0) // move cursor to the end of the link
            this.quill.focus()
        } else if (e?.clipboardData?.files?.[0]?.type == 'image/png') {
            e.preventDefault()
            const range = this.quill.getSelection()
            const file = e.clipboardData.files[0]
            const blob = new Blob([file], { type: file.type })
            const url = await blobToDataUrl(blob)
            // console.log('URL is now: ', url)
            const index = range.index
            setTimeout(() => {
                const delta = new Delta().retain(index).insert({ image: url })
                this.quill.updateContents(delta, Quill.sources.USER)
                this.quill.focus()
            })
        } else {
            return super.onPaste.call(this, range, e)
        }
    }
}

/** converts a data url into a browser Blob object
 * from: https://github.com/quilljs/quill/issues/1089#issuecomment-614313509
 **/
export function dataUrlToBlob(dataUrl) {
    // Split the base64 string in data and contentType
    const blocks = dataUrl.split(';')
    // Get the content type of the image
    const contentType = blocks[0].split(':')[1]
    // get the real base64 content of the file
    const b64data = blocks[1].split(',')[1]

    let byteCharacters = atob(b64data)
    let byteArrays = []

    const sliceSize = 512

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize)

        let byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        let byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }

    let blob = new Blob(byteArrays, { type: contentType })
    return blob
}

function blobToDataUrl(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
}

/**
 * Normalizes Quill Delta JSON styles to match application's native styling
 * Removes any styles not explicitly allowed
 * Preserves only basic formatting and structural elements
 */
export const normalizeDeltaStyles = delta => {
    if (!delta || !delta.ops) return delta

    return {
        ops: delta.ops.map(op => {
            // If no attributes, return as is
            if (!op.attributes) return op

            // Create new attributes object without color
            const { color, ...otherAttributes } = op.attributes

            // If we have any remaining attributes, return with them, otherwise without attributes
            return Object.keys(otherAttributes).length > 0
                ? { ...op, attributes: otherAttributes }
                : { insert: op.insert }
        }),
    }
}

/**
 * Helper to normalize existing delta JSONs
 * Handles proper formatting of mentions and ensures consistent spacing
 */
export const normalizeExistingDelta = value => {
    if (!value) return { ops: [{ insert: '' }] }

    if (typeof value === 'string') {
        // If string is empty, return empty delta
        if (!value.trim()) {
            return { ops: [{ insert: '' }] }
        }

        try {
            const parsedValue = JSON.parse(value)

            // If this is a Delta with ops, process it for proper mention handling
            if (parsedValue && parsedValue.ops) {
                const newOps = []

                // Process ops to ensure proper mention boundaries
                for (let i = 0; i < parsedValue.ops.length; i++) {
                    const op = parsedValue.ops[i]
                    newOps.push(op)

                    // If this is a mention embed, ensure proper spacing after it
                    if (op.insert && typeof op.insert === 'object' && op.insert.mention) {
                        // Check if the next op exists and is text
                        const nextOp = parsedValue.ops[i + 1]

                        // If there's no next op or next op doesn't start with a space, insert one
                        if (!nextOp) {
                            // No next op, add a space with explicit null formatting
                            newOps.push({ insert: ' ', attributes: null })
                        } else if (typeof nextOp.insert === 'string') {
                            if (!nextOp.insert.startsWith(' ')) {
                                // Next op exists but doesn't start with space, add a space
                                newOps.push({ insert: ' ', attributes: null })
                            } else {
                                // Next op starts with space, make sure it has no formatting
                                // We'll handle this in the next iteration by checking the first char
                                const firstChar = nextOp.insert.charAt(0)
                                if (firstChar === ' ' && nextOp.attributes) {
                                    // Split the next op into a space with no attributes and the rest
                                    newOps.push({ insert: ' ', attributes: null })

                                    // Adjust the nextOp to remove the first character (the space)
                                    parsedValue.ops[i + 1] = {
                                        ...nextOp,
                                        insert: nextOp.insert.substring(1),
                                    }

                                    // If the remaining text is empty, remove the op entirely
                                    if (parsedValue.ops[i + 1].insert === '') {
                                        parsedValue.ops.splice(i + 1, 1)
                                    }
                                }
                            }
                        }
                    }
                }

                // Replace the original ops with our processed version
                parsedValue.ops = newOps
            }

            return normalizeDeltaStyles(parsedValue)
        } catch (e) {
            console.warn('Failed to parse string as JSON delta:', e)
            // If the string is not valid JSON, return a simple delta with the string as content
            return {
                ops: [{ insert: value }],
            }
        }
    }

    // Handle the case where value might be an object but not in the correct format
    if (typeof value === 'object' && (!value.ops || !Array.isArray(value.ops))) {
        console.warn('Invalid delta object format:', value)
        return {
            ops: [{ insert: JSON.stringify(value) }],
        }
    }

    return normalizeDeltaStyles(value)
}

/**
 * A custom `getText()` method that returns a textual representation of the
 * contents of the given quill editor, replacing any embeds with
 * `replacementChr` (as opposed to quill's native .getText() which completely
 * elides all embeds) Useful for preventing off by 1 error hell that happens
 * when doing text processing on quill contents when using `quill.getText()`
 *
 * lightly adapted from https://stackoverflow.com/a/55965372
 * */
export function getStableText(quill, replacementChr = ' ') {
    //console.log('getText called quill replacement:', quill)
    if (!quill || !quill.getContents || typeof quill.getContents !== 'function') {
        console.warn('getText called with invalid quill instance')
        return ''
    }

    try {
        const contents = quill.getContents()
        if (!contents || !contents.ops || !Array.isArray(contents.ops)) {
            return ''
        }

        return contents.ops.reduce((text, op) => {
            if (typeof op.insert === 'string') {
                // If the op is a string insertion, just concat
                // Convert explicitly to string to ensure numbers are preserved
                return text + String(op.insert)
            } else {
                // Otherwise it's a block. Represent this as a newline,
                // which will preserve the length of 1, and also prevent
                // searches matching across the block
                return text + replacementChr
            }
        }, '')
    } catch (error) {
        console.error('Error in getText:', error)
        return ''
    }
}
