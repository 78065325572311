import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import styles from './babylon-viewer.module.scss'
import { isMobile } from 'react-device-detect'

declare global {
    interface Window {
        logBabylon?: boolean
    }
}

type FileType = {
    name: string
    url: string
    type: string
    size: number
}

interface BabylonViewerProps {
    file: FileType
}

// Add type definitions for Babylon.js
type BabylonType = {
    Engine: any
    WebGPUEngine: any
    Scene: any
    ArcRotateCamera: any
    Vector3: any
    AssetsManager: any
    HemisphericLight: any
    Color3: any
    DirectionalLight: any
    DefaultRenderingPipeline: any
    ShadowGenerator: any
    HDRCubeTexture: any
    SceneLoader: any
    Mesh: any
    TransformNode: any
    StandardMaterial: any
}

const loadBabylonModules = async () => {
    const [BABYLON, gltfModule, standardMaterial, pbrMaterial, gltf, obj, stl] = await Promise.all([
        import('@babylonjs/core'),
        import('@babylonjs/loaders/glTF/glTFFileLoader'),
        import('@babylonjs/core/Materials/standardMaterial'),
        import('@babylonjs/core/Materials/PBR/pbrMaterial'),
        import('@babylonjs/loaders/glTF'),
        import('@babylonjs/loaders/OBJ'),
        import('@babylonjs/loaders/STL'),
    ])
    return { BABYLON, gltfModule, obj, stl }
}

interface LoadingScreenElement extends HTMLDivElement {
    updateProgress?: (progress: number) => void
}

// Memoize the model loading configuration based on file type
const modelLoadConfig = (file: FileType) => {
    const fileExtension = file.name.toLowerCase().split('.').pop()
    return {
        isGLB: fileExtension === 'glb',
        mimeType:
            fileExtension === 'obj'
                ? 'model/obj'
                : fileExtension === 'stl'
                ? 'model/stl'
                : 'application/octet-stream',
        fileExtension,
    }
}

// Keep the existing loadModel function as is, but with updated types
const loadModel = async (gltfModule: any, file: FileType) => {
    try {
        const arrayBuffer = await loadGLBFile(file)
        if (!arrayBuffer) return

        const { isGLB } = modelLoadConfig(file)

        if (isGLB) {
            const dataUrl = await handleGLBLoad(gltfModule, arrayBuffer)
            URL.revokeObjectURL(file.url)
            return dataUrl
        } else {
            // TODO return dataUrl for other models
        }
    } catch (error) {
        console.error('Error loading 3D model:', error)
        URL.revokeObjectURL(file.url)
        return null
    }
}

const setupEngine = async (BABYLON: any, canvas: HTMLCanvasElement) => {
    let useWebGPU = false

    // Check for WebGPU support and device
    try {
        if (navigator.gpu) {
            const adapter = await navigator.gpu.requestAdapter()
            if (adapter) {
                const device = await adapter.requestDevice()
                if (device) {
                    useWebGPU = true
                }
            }
        }
    } catch (e) {
        console.warn('WebGPU not available, falling back to WebGL:', e)
    }

    console.log('Using WebGPU:', useWebGPU)
    const antialias = useWebGPU ? true : false

    let engine: any

    try {
        if (useWebGPU) {
            engine = new BABYLON.WebGPUEngine(canvas, {
                enableAllFeatures: true,
                setMaximumLimits: true,
                antialias,
                useHighPrecisionMatrix: true,
                disableLoadingScreen: true,
                adaptToDeviceRatio: true,
                powerPreference: 'high-performance',
            })
            await engine.initAsync()
        } else {
            engine = new BABYLON.Engine(canvas, antialias, {
                useHighPrecisionMatrix: true,
                premultipliedAlpha: false,
                preserveDrawingBuffer: true,
                antialias,
                forceSRGBBufferSupportState: false,
                disableLoadingScreen: true,
                adaptToDeviceRatio: true,
                powerPreference: 'high-performance',
            })
        }
    } catch (e) {
        console.warn('Failed to initialize WebGPU engine, falling back to WebGL:', e)
        engine = new BABYLON.Engine(canvas, antialias, {
            useHighPrecisionMatrix: true,
            premultipliedAlpha: false,
            preserveDrawingBuffer: true,
            antialias,
            forceSRGBBufferSupportState: false,
            disableLoadingScreen: true,
            adaptToDeviceRatio: true,
            powerPreference: 'high-performance',
        })
    }
    return engine
}

const setupScene = async (BABYLON: any, canvas: HTMLCanvasElement) => {
    if (!canvas) {
        throw new Error('Canvas is not available')
    }

    const engine = await setupEngine(BABYLON, canvas)

    // Ensure engine is properly initialized before creating scene
    if (!engine || engine.isDisposed) {
        throw new Error('Failed to initialize engine')
    }

    // Wait a frame to ensure engine is ready
    await new Promise(resolve => setTimeout(resolve, 0))

    const scene = new BABYLON.Scene(engine)
    if (!scene) {
        throw new Error('Failed to create scene')
    }

    scene.getEngine().hideLoadingUI()
    BABYLON.SceneLoader.ShowLoadingScreen = false

    scene.skipPointerMovePicking = true
    scene.useGeometryIdsMap = true
    scene.useClonedMeshMap = true

    scene.clearColor = new BABYLON.Color4(0, 0, 0, 0)
    scene.autoClearDepthAndStencil = false
    scene.blockMaterialDirtyMechanism = true
    scene.performancePriority = BABYLON.ScenePerformancePriority.Aggressive
    scene.fogEnabled = false
    scene.particlesEnabled = false
    scene.collisionsEnabled = false
    scene.probesEnabled = false
    scene.lightsEnabled = true

    scene.pointerMovePredicate = () => false
    scene.cameraToUseForPointers = null

    return { engine, scene }
}

const setupCamera = (
    BABYLON: any,
    scene: any,
    canvas: HTMLCanvasElement,
    options?: { worldExtends?: any },
) => {
    // Create default camera
    const camera = new BABYLON.ArcRotateCamera(
        'camera',
        -Math.PI / 2, // alpha (rotation around Y axis) - start at -90 degrees
        Math.PI / 2, // beta (rotation around X axis)
        1, // radius - will be adjusted based on world size
        BABYLON.Vector3.Zero(),
        scene,
    )

    // Basic camera setup
    camera.attachControl(canvas, true)

    // Set rotation limits to prevent flipping
    camera.lowerBetaLimit = 0.1
    camera.upperBetaLimit = Math.PI - 0.1

    // Increase rotation sensitivity
    if (isMobile) {
        camera.angularSensibilityX = 150 // Much more sensitive for mobile
        camera.angularSensibilityY = 150 // Much more sensitive for mobile
    } else {
        camera.angularSensibilityX = 200 // Lower value = more sensitive rotation
        camera.angularSensibilityY = 200 // Lower value = more sensitive rotation
    }

    // Set rotation and zoom sensitivities
    camera.wheelDeltaPercentage = 0.01
    camera.pinchDeltaPercentage = 0.01

    // Disable panning and inertia
    camera.panningSensibility = 0
    camera.inertia = 0

    // Setup framing behavior
    camera.useFramingBehavior = true
    const framingBehavior = new BABYLON.FramingBehavior()
    framingBehavior.mode = BABYLON.FramingBehavior.FitFrustumSidesMode
    camera.addBehavior(framingBehavior)
    camera.useAutoRotationBehavior = false

    // Configure framing behavior
    framingBehavior.framingTime = 0
    framingBehavior.elevationReturnTime = -1
    framingBehavior.zoomStopsAnimation = true
    framingBehavior.autoCorrectCameraLimitsAndSensibility = true

    // If we have world extends (i.e., a loaded model), frame it
    if (options?.worldExtends) {
        const worldSize = options.worldExtends.max.subtract(options.worldExtends.min)
        const worldCenter = options.worldExtends.min.add(worldSize.scale(0.5))

        // Calculate radius based on world size (matching Sandbox)
        const radius = worldSize.length() * 1.5

        // Position camera
        camera.setTarget(worldCenter)
        camera.radius = radius

        // Set camera limits based on world size
        camera.minZ = radius * 0.01
        camera.maxZ = radius * 1000

        camera.wheelPrecision = 200 / camera.radius
        camera.pinchPrecision = 200 / camera.radius

        camera.lowerRadiusLimit = 0.5 * camera.radius
        camera.upperRadiusLimit = 5 * camera.radius

        camera.pinchDeltaPercentage = 0.01
        if (isMobile) {
            camera.wheelDeltaPercentage = 0.01
        } else {
            camera.wheelDeltaPercentage = 0.1 // More sensitive zoom on desktop
            camera.wheelPrecision = 100 / camera.radius // More sensitive wheel zoom
        }

        // For glTF assets (which use +Z forward convention while camera faces +Z)
        camera.alpha += Math.PI
    }

    return camera
}

const setupEnvironment = async (BABYLON: any, scene: any) => {
    const assetsManager = new BABYLON.AssetsManager(scene)
    assetsManager.useDefaultLoadingScreen = false
    const envTextureTask = assetsManager.addCubeTextureTask(
        'envTexture',
        'https://playground.babylonjs.com/textures/environment.env',
    )

    envTextureTask.onSuccess = task => {
        if (scene) {
            scene.environmentTexture = task.texture
            scene.createDefaultSkybox(task.texture, true, 2000, 0.3, true)
            scene.environmentIntensity = 1.0
        }
    }

    return new Promise<void>(resolve => {
        assetsManager.onFinish = () => resolve()
        assetsManager.load()
    })
}

const setupLighting = (BABYLON: any, scene: any) => {
    const hemiLight = new BABYLON.HemisphericLight('hemiLight', new BABYLON.Vector3(0, 1, 0), scene)
    hemiLight.intensity = 0.7
    hemiLight.groundColor = new BABYLON.Color3(0.2, 0.2, 0.2)

    const dirLight = new BABYLON.DirectionalLight('dirLight', new BABYLON.Vector3(-1, -2, 1), scene)
    dirLight.position = new BABYLON.Vector3(20, 40, -20)
    dirLight.intensity = 0.7

    return { hemiLight, dirLight }
}

const setupPostProcessing = (BABYLON: any, scene: any, camera: any) => {
    scene.environmentIntensity = 1.0

    const pipeline = new BABYLON.DefaultRenderingPipeline('defaultPipeline', true, scene, [camera])

    if (pipeline.imageProcessing) {
        pipeline.imageProcessing.exposure = 1.2
        pipeline.imageProcessing.contrast = 1.1
        pipeline.imageProcessing.toneMappingEnabled = true

        pipeline.samples = 1
        pipeline.bloomEnabled = false
        pipeline.chromaticAberrationEnabled = false
        pipeline.grainEnabled = false
        pipeline.sharpenEnabled = false
        pipeline.depthOfFieldEnabled = false
        pipeline.fxaaEnabled = false
        pipeline.imageProcessingEnabled = true

        pipeline.imageProcessing.applyByPostProcess = false
        pipeline.imageProcessing.vignetteEnabled = false
        pipeline.imageProcessing.colorCurvesEnabled = false
    }

    return pipeline
}

const setupShadowsAndHDR = (BABYLON: any, scene: any, dirLight: any) => {
    const shadowGenerator = new BABYLON.ShadowGenerator(1024, dirLight)
    shadowGenerator.useBlurExponentialShadowMap = true
    shadowGenerator.blurScale = 2

    const hdrTexture = new BABYLON.HDRCubeTexture(
        'https://playground.babylonjs.com/textures/environment.env',
        scene,
        512,
        false,
        true,
        false,
        true,
    )
    scene.environmentTexture = hdrTexture

    return { shadowGenerator, hdrTexture }
}

export default function BabylonViewer({ file }: BabylonViewerProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const engineRef = useRef<any | null>(null)
    const sceneRef = useRef<any | null>(null)
    const observerRef = useRef<IntersectionObserver | null>(null)
    const loadingScreenRef = useRef<LoadingScreenElement | null>(null)
    const renderLoopActiveRef = useRef<boolean>(false)

    // Memoize touch event handlers since they're complex calculations that don't need to change
    const handleTouchStart = useCallback((e: TouchEvent) => {
        let touchPoints = Array.from(e.touches)
        if (touchPoints.length === 2) {
            const previousDistance = Math.hypot(
                touchPoints[0].clientX - touchPoints[1].clientX,
                touchPoints[0].clientY - touchPoints[1].clientY,
            )

            if (sceneRef.current?.activeCamera) {
                const pinchStartRadius = sceneRef.current.activeCamera.radius
                // Store these values in refs or state if needed across handlers
                sceneRef.current.pinchData = { previousDistance, pinchStartRadius }
            }
        }
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const handleTouchMove = useCallback((e: TouchEvent) => {
        if (e.touches.length === 2 && sceneRef.current?.pinchData) {
            const currentTouches = Array.from(e.touches)
            const currentDistance = Math.hypot(
                currentTouches[0].clientX - currentTouches[1].clientX,
                currentTouches[0].clientY - currentTouches[1].clientY,
            )

            if (sceneRef.current?.activeCamera) {
                const camera = sceneRef.current.activeCamera
                const zoomDelta =
                    (currentDistance - sceneRef.current.pinchData.previousDistance) * 0.01
                const newRadius = camera.radius - zoomDelta

                camera.radius = Math.min(
                    Math.max(newRadius, camera.lowerRadiusLimit),
                    camera.upperRadiusLimit,
                )

                sceneRef.current.pinchData.previousDistance = currentDistance
            }
        }
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const handleTouchEnd = useCallback((e: TouchEvent) => {
        if (e.touches.length < 2 && sceneRef.current) {
            sceneRef.current.pinchData = null
        }
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const preventScroll = useCallback((e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    // Memoize the resize handler
    const handleResize = useCallback(() => {
        if (engineRef.current && !engineRef.current.isDisposed) {
            engineRef.current.resize()
        }
    }, [])

    // Memoize the loading screen setup since it's a complex DOM operation
    const setupLoadingScreenMemo = useMemo(() => {
        return (canvasRef: React.RefObject<HTMLCanvasElement>) => {
            const loadingScreen = document.createElement('div') as LoadingScreenElement
            loadingScreen.style.position = 'absolute'
            loadingScreen.style.top = '50%'
            loadingScreen.style.left = '50%'
            loadingScreen.style.transform = 'translate(-50%, -50%)'
            loadingScreen.style.color = '#ccc'
            loadingScreen.style.fontSize = '24px'
            loadingScreen.style.textAlign = 'center'
            loadingScreen.style.width = '100px'
            loadingScreen.style.height = '100px'
            loadingScreen.style.display = 'flex'
            loadingScreen.style.flexDirection = 'column'
            loadingScreen.style.alignItems = 'center'
            loadingScreen.style.justifyContent = 'center'

            const spinner = document.createElement('i')
            spinner.className = 'fa fa-circle-o-notch fa-spin fa-lg'
            spinner.style.position = 'relative'
            loadingScreen.appendChild(spinner)

            const progressText = document.createElement('div')
            progressText.style.position = 'absolute'
            progressText.style.left = '50%'
            progressText.style.bottom = '-30px'
            progressText.style.transform = 'translateX(-50%)'
            progressText.style.fontSize = '14px'
            progressText.style.color = '#fff'
            loadingScreen.appendChild(progressText)

            loadingScreen.updateProgress = (progress: number) => {
                if (progress >= 0) {
                    progressText.textContent = `${Math.round(progress)}%`
                } else {
                    progressText.textContent = ''
                }
            }

            canvasRef.current?.parentNode?.appendChild(loadingScreen)
            return loadingScreen
        }
    }, [])

    // Memoize engine and scene validity check
    const isEngineSceneValid = useCallback(() => {
        return (
            engineRef.current &&
            sceneRef.current &&
            !renderLoopActiveRef.current &&
            sceneRef.current.activeCamera &&
            !sceneRef.current.isDisposed &&
            !engineRef.current.isDisposed
        )
    }, [])

    // Memoize observer options
    const observerOptions = useMemo(
        () => ({
            root: null,
            threshold: 0.1,
        }),
        [],
    )

    const startRenderLoop = useCallback(() => {
        if (isEngineSceneValid()) {
            renderLoopActiveRef.current = true
            engineRef.current.runRenderLoop(() => {
                if (sceneRef.current && !sceneRef.current.isDisposed) {
                    sceneRef.current.render()
                }
            })
        }
    }, [isEngineSceneValid])

    const stopRenderLoop = useCallback(() => {
        if (engineRef.current && !engineRef.current.isDisposed) {
            engineRef.current.stopRenderLoop()
            renderLoopActiveRef.current = false
        }
    }, [])

    // Memoize intersection observer callback
    const handleIntersection = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            const isVisible = entries[0].isIntersecting
            if (!isVisible) {
                stopRenderLoop()
            } else {
                startRenderLoop()
            }
        },
        [startRenderLoop, stopRenderLoop],
    )

    // Memoize visibility change handler
    const handleVisibilityChange = useCallback(() => {
        if (document.hidden) {
            stopRenderLoop()
        } else {
            startRenderLoop()
        }
    }, [startRenderLoop, stopRenderLoop])

    // Setup visibility and intersection observers
    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange)

        if (canvasRef.current) {
            observerRef.current = new IntersectionObserver(handleIntersection, observerOptions)
            observerRef.current.observe(canvasRef.current)
        }

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
            if (observerRef.current) {
                observerRef.current.disconnect()
            }
            stopRenderLoop()
        }
    }, [handleVisibilityChange, handleIntersection, stopRenderLoop, observerOptions])

    const initScene = async () => {
        try {
            if (!canvasRef.current) {
                throw new Error('Canvas reference is not available')
            }

            const { BABYLON, gltfModule, obj, stl } = await loadBabylonModules()

            // Initialize core components with proper error handling
            let sceneData
            try {
                sceneData = await setupScene(BABYLON as BabylonType, canvasRef.current)
            } catch (error) {
                console.error('Error in setupScene:', error)
                throw error
            }

            const { engine, scene } = sceneData
            engineRef.current = engine
            sceneRef.current = scene

            // Load model only after scene is properly initialized
            const dataUrl = await loadModel(gltfModule, file)
            if (!dataUrl) {
                throw new Error('Failed to load model data')
            }

            BABYLON.SceneLoader.ShowLoadingScreen = false
            BABYLON.SceneLoader.loggingLevel = BABYLON.SceneLoader.NO_LOGGING

            const result = await BABYLON.SceneLoader.ImportMeshAsync(
                null,
                '',
                dataUrl,
                scene,
                evt => {
                    if (evt.lengthComputable) {
                        const progress = (evt.loaded / evt.total) * 100
                        loadingScreenRef.current?.updateProgress?.(progress)
                    }
                },
            )

            // Get world extends for camera framing
            const worldExtends = scene.getWorldExtends((mesh: any) => {
                return mesh.isVisible && mesh.isEnabled()
            })

            // Setup camera with model framing
            const camera = setupCamera(BABYLON, scene, canvasRef.current, { worldExtends })
            scene.activeCamera = camera

            // Setup lighting and get directional light
            const { dirLight } = setupLighting(BABYLON, scene)

            // Setup post-processing pipeline
            const renderPipeline = setupPostProcessing(BABYLON, scene, camera)

            // Setup environment and shadows
            await setupEnvironment(BABYLON, scene)
            const { shadowGenerator } = setupShadowsAndHDR(BABYLON, scene, dirLight)

            // Apply shadows to all meshes
            scene.meshes.forEach(mesh => {
                if (mesh.receiveShadows) {
                    shadowGenerator.addShadowCaster(mesh)
                }
            })

            // Setup environment from GLTF data if available
            const gltfData = (result as any).gltf
            if (gltfData) {
                const defaultScene = gltfData.scene || 0
                const sceneData = gltfData.scenes?.[defaultScene]
                setupEnvironmentFromGLTF(scene, sceneData, renderPipeline)
            }

            // Stop any automatic animations
            scene.animationGroups.forEach(animationGroup => {
                animationGroup.stop()
            })

            // Safely check visibility and start render loop if needed
            const isVisible =
                !document.hidden &&
                canvasRef.current &&
                canvasRef.current.getBoundingClientRect().top < window.innerHeight

            if (isVisible) {
                startRenderLoop()
            }

            // Configure GLTFFileLoader settings
            gltfModule.GLTFFileLoader.IncrementalLoading = false
            gltfModule.GLTFFileLoader.HomogeneousCoordinates = false

            return { engine, scene }
        } catch (error) {
            console.error('Error in initScene:', error)
            throw error
        }
    }

    useEffect(() => {
        let isComponentMounted = true

        const init = async () => {
            loadingScreenRef.current = setupLoadingScreenMemo(canvasRef)

            // Add event listeners using memoized handlers
            canvasRef.current?.addEventListener('touchstart', handleTouchStart, { passive: false })
            canvasRef.current?.addEventListener('touchmove', handleTouchMove, { passive: false })
            canvasRef.current?.addEventListener('touchend', handleTouchEnd)
            canvasRef.current?.addEventListener('wheel', preventScroll, { passive: false })

            try {
                const { engine, scene } = await initScene()
                if (!isComponentMounted) {
                    // Component unmounted during async operation, clean up and return
                    engine.dispose()
                    return
                }

                engineRef.current = engine
                sceneRef.current = scene

                const resizeListener = () => {
                    if (engine && !engine.isDisposed) {
                        engine.resize()
                    }
                }
                window.addEventListener('resize', resizeListener)

                loadingScreenRef.current?.remove()
            } catch (error) {
                console.error('Error initializing scene:', error)
                if (isComponentMounted && loadingScreenRef.current) {
                    showError(loadingScreenRef.current)
                }
            }
        }

        init()

        return () => {
            isComponentMounted = false
            stopRenderLoop()
            window.removeEventListener('resize', handleResize)

            // Clean up event listeners
            canvasRef.current?.removeEventListener('touchstart', handleTouchStart)
            canvasRef.current?.removeEventListener('touchmove', handleTouchMove)
            canvasRef.current?.removeEventListener('touchend', handleTouchEnd)
            canvasRef.current?.removeEventListener('wheel', preventScroll)

            // Clean up loading screen if it's still in the DOM
            if (loadingScreenRef.current?.parentNode) {
                loadingScreenRef.current.remove()
            }

            // Clean up Babylon.js resources
            if (sceneRef.current && !sceneRef.current.isDisposed) {
                sceneRef.current.dispose()
            }
            if (engineRef.current && !engineRef.current.isDisposed) {
                engineRef.current.dispose()
            }
        }
    }, [
        file.url,
        file.name,
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd,
        preventScroll,
        handleResize,
        setupLoadingScreenMemo,
        modelLoadConfig,
        stopRenderLoop,
    ])

    return (
        <div className={styles.container}>
            <canvas ref={canvasRef} className={styles.canvas} />
        </div>
    )
}

const showError = (loadingScreen: LoadingScreenElement) => {
    const errorDiv = document.createElement('div')
    errorDiv.style.color = '#fff'
    errorDiv.style.marginTop = '10px'
    errorDiv.style.padding = '8px 12px'
    errorDiv.style.background = 'rgba(255, 0, 0, 0.7)'
    errorDiv.style.borderRadius = '4px'
    errorDiv.textContent = 'Error loading 3D model'
    loadingScreen.appendChild(errorDiv)
}

// Add these new utility functions after the existing setupShadowsAndHDR function
const setupGLBLoader = (gltfModule: any) => {
    const loader = new gltfModule.GLTFFileLoader()
    loader.compileMaterials = true
    loader.compileShadowGenerators = true
    loader.useClipPlane = true
    loader.transparencyAsCoverage = true
    loader.showLoadingScreen = false
    return loader
}

const setupEnvironmentFromGLTF = (scene: any, sceneData: any, pipeline: any) => {
    const envSettings =
        sceneData?.extensions?.['KHR_lights_punctual'] ||
        sceneData?.extensions?.['KHR_lights_environment']

    if (envSettings) {
        if (window.logBabylon) console.log('Environment settings found:', envSettings)
        if (scene && envSettings.intensity !== undefined) {
            scene.environmentIntensity = envSettings.intensity
        }
    }

    const materials = sceneData?.materials || []
    const hasMetallicRoughness = materials.some(
        (mat: any) =>
            mat.pbrMetallicRoughness || mat.extensions?.['KHR_materials_pbrSpecularGlossiness'],
    )

    if (hasMetallicRoughness && pipeline.imageProcessing) {
        pipeline.imageProcessing.exposure = 1.0
        pipeline.imageProcessing.contrast = 1.1
        pipeline.imageProcessing.toneMappingEnabled = true
    }
}

const loadGLBFile = async (file: { url: string }): Promise<ArrayBuffer | null> => {
    try {
        const response = await fetch(file.url)
        const arrayBuffer = await response.arrayBuffer()
        return arrayBuffer
    } catch (error) {
        console.error('Error loading file:', error)
        return null
    }
}

const createDataUrl = (arrayBuffer: ArrayBuffer, mimeType: string): string => {
    const uint8Array = new Uint8Array(arrayBuffer)
    const base64 = btoa(Array.from(uint8Array, byte => String.fromCharCode(byte)).join(''))
    return `data:${mimeType};base64,${base64}`
}

const handleGLBLoad = async (gltfModule: any, arrayBuffer: ArrayBuffer) => {
    try {
        const loader = setupGLBLoader(gltfModule)

        // Keep only safe GLTFLoader optimizations
        loader.compileMaterials = true
        loader.compileShadowGenerators = true
        loader.useClipPlane = true

        const dataUrl = createDataUrl(arrayBuffer, 'model/gltf-binary')
        return dataUrl
    } catch (error) {
        console.error('Error loading GLB model:', error)
        return null
    }
}
