import usePanelContext from 'refactor/hooks/usePanelContext'
import useStore from 'state/knovStore'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import { isMobile } from 'react-device-detect'
import useRemoveRight from 'refactor/hooks/useRemoveRight'
import { getSwiperRef } from 'state/imperativeApis/swiperApi'

export default function useInsertRight(panelId?: string) {
    const { panelId: nativePanelId } = usePanelContext()
    const anchorPanelId = panelId || nativePanelId
    const removeRight = useRemoveRight()

    const insertPanelRight = useStore.getState().panels.insertPanelRight
    const getIndexById = useStore.getState().panels.getIndexById
    //const prefetchQuest = usePrefetchQuest()

    const insertRight = (
        newPanel: Parameters<typeof insertPanelRight>[1],
        actionParams: { questId?: string } = {},
    ) => {
        const indexBeforeChange = getIndexById(anchorPanelId)
        const clickedRightPanel = indexBeforeChange > getCenterIndex()

        if (!isMobile && !clickedRightPanel) {
            insertPanelRight(anchorPanelId, newPanel, {
                animate: true,
                actionParams,
            })
        } else {
            insertPanelRight(anchorPanelId, newPanel, {
                animate: false,
                actionParams,
            })
        }
    }

    return {
        insertRight,

        toggleRightQuest: (questId: string, answerId?: string) => {
            if (isQuestInPanelToRight(anchorPanelId, questId)) {
                isMobile ? getSwiperRef()?.current.slideNext() : removeRight()
            } else {
                insertRight({ filter: { questId, ...(answerId ? { answerId } : {}) } })
            }
        },
    }
}

export function isQuestInPanelToRight(panelId: string, questId: string) {
    const panelToRight = useStore.getState().panels.getPanelToRight(panelId)
    return panelToRight && panelToRight.filter?.questId === questId
}
