import useStore from 'state/knovStore'

export default function useAnswerHighlight() {
    return (answerId, panelId) => {
        if (!answerId || !panelId) return

        const panel = useStore.getState().panels.getPanel(panelId)
        if (panel) {
            panel.highlightAnswer(answerId)
        }
    }
}
