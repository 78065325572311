import React, { useState } from 'react'
import styles from './version-info.module.scss'

/**
 * Component to display version information and update option
 */
export default function VersionInfo({ isUpdateAvailable, onClose }) {
    const [isRefreshing, setIsRefreshing] = useState(false)

    // Format the date from timestamp
    const formatDate = timestamp => {
        if (!timestamp) return 'Unknown'
        return new Date(timestamp).toLocaleString()
    }

    // Handle refresh to update
    const handleRefresh = () => {
        setIsRefreshing(true)
        // Add a small delay to show the refreshing state
        setTimeout(() => {
            window.location.reload()
        }, 500)
    }

    return (
        <div className={styles.versionInfoOverlay} onClick={onClose}>
            <div className={styles.versionInfoContainer} onClick={e => e.stopPropagation()}>
                <div className={styles.versionInfoHeader}>
                    <h3>About Treechat</h3>
                    <button className={styles.closeButton} onClick={onClose}>
                        ×
                    </button>
                </div>
                <div className={styles.versionInfoContent}>
                    <div className={styles.versionRow}>
                        <span className={styles.versionLabel}>Version:</span>
                        <span className={styles.versionValue}>
                            {window.Knov?.version?.gitCommitHash?.substring(0, 7) || 'Unknown'}
                        </span>
                    </div>
                    <div className={styles.versionRow}>
                        <span className={styles.versionLabel}>Built:</span>
                        <span className={styles.versionValue}>
                            {formatDate(window.Knov?.version?.buildTimeUnixTimestamp)}
                        </span>
                    </div>

                    {isUpdateAvailable && (
                        <div className={styles.updateSection}>
                            <div className={styles.updateMessage}>A newer version is available</div>
                            <button
                                className={styles.updateButton}
                                onClick={handleRefresh}
                                disabled={isRefreshing}
                            >
                                {isRefreshing ? 'Refreshing...' : 'Update to latest'}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
