import React, { useCallback } from 'react'
import cn from 'classnames'
import styles from 'components/panels/panel-container.module.scss'
import { getCenterIndex, isPanelCenter, isPanelRight } from 'state/imperativeApis/swiperApi'
import useStore from 'state/knovStore'
import { PanelState } from 'state/PanelState'
import useRemovePanel from 'refactor/hooks/useRemovePanel'

interface RemoveButtonProps {
    panel: PanelState
    index: number
}

export default React.memo(function RemoveButton({ panel, index }: RemoveButtonProps) {
    const centerIndex = useStore(state => getCenterIndex())
    const removePanel = useRemovePanel(panel.panelId)
    const shouldShow = index !== centerIndex

    const handleRemove = useCallback(() => {
        if (isPanelCenter(panel.panelId) && useStore.getState().panels?.state?.length < 4) {
            // Convert to empty instead of removing if we've reached the smallest amount of panels necessary.
            useStore.getState().panels.clearPanel(panel.panelId)
        } else {
            removePanel() // Let the hook handle the direction internally
        }

        const panelToLeft = useStore.getState().panels.getPanelToLeft(panel.panelId)
        useStore.getState().set(draft => {
            draft.selectedAnswers[panel.panelId] = null
            draft.selectedAnswers[panelToLeft.panelId] = null
        })
    }, [panel.panelId])

    if (!shouldShow) return null

    return (
        <div className={styles.floatingControlContainer} onClick={handleRemove}>
            <div className={cn(styles.closeButton)}>
                <div className={styles.closeButtonFace}>
                    <i className="fa fa-times" />
                </div>
            </div>
        </div>
    )
})
