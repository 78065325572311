import React, { useEffect, useState, useLayoutEffect } from 'react'
import cn from 'classnames'
import queryClient from 'api/queryClient'
import useStore from 'state/knovStore'
import useGetQuests, { serializeFilterKey } from 'refactor/hooks/api/useGetQuests'
import usePanelContext from 'refactor/hooks/usePanelContext'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import SearchHeader from 'components/panels/SearchHeader'
import UserProfile from 'components/panels/UserProfile'
import Quest from 'components/quests/Quest'
import QuestInView from 'components/quests/QuestInView'
import ThinQuest from 'components/quests/ThinQuest'
import People from 'components/people/People'
import styles from 'components/panels/panel.module.scss'
import { logEv } from 'lib/log'
import { withDepth } from 'components/filters/useStreamFilters'

interface QuestStreamProps {
    filter: any
}

function Loading() {
    return (
        <div className={styles.loader}>
            <div className={cn(styles.loadingIcon)}>
                <i className="fa fa-circle-o-notch fa-spin fa-lg" />
            </div>
        </div>
    )
}

export default function QuestStream({ filter }: QuestStreamProps) {
    const { panel, scrollContainerRef, setIsRefreshing } = usePanelContext()
    const panelId = panel.panelId
    const params = filter?.notifications ? { clear: true } : {}
    const [isRefreshing, setLocalIsRefreshing] = useState(false)

    // Use the shared refresh state if available, otherwise fallback to local state
    const handleSetIsRefreshing = value => {
        if (setIsRefreshing) {
            setIsRefreshing(value)
        } else {
            setLocalIsRefreshing(value)
        }
    }

    useEffect(function onMount() {
        // Invalidate and refetch quests when the panel mounts
        const filterKey = serializeFilterKey(filter)
        console.log('quest stream filterKey', filterKey)
        queryClient.refetchQueries({ queryKey: ['quests', filterKey], exact: true })
        // Clear numNoti when notification panel loads.
        if (filter?.notifications) useStore.getState().set({ numNoti: 0 })
        logEv('STREAM VIEW', { filter })
    }, [])

    filter = withDepth(filter)
    const { quests, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useGetQuests(
        filter,
        { enabled: true, params },
    )

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current
        if (!scrollContainer) return

        const onScroll = () => {
            if (scrollContainer) {
                const { scrollTop, scrollHeight, clientHeight } = scrollContainer
                if (
                    scrollTop + clientHeight >= scrollHeight - 500 &&
                    hasNextPage &&
                    !isFetchingNextPage
                ) {
                    fetchNextPage()
                }
            }
        }

        scrollContainer.addEventListener('scroll', onScroll)

        return () => {
            scrollContainer.removeEventListener('scroll', onScroll)
        }
    }, [hasNextPage, isFetchingNextPage])

    const isStarred = filter.starred
    const isPeople = filter.people
    const isThinQuest = isStarred || isPeople
    const isUserProfile = filter.user

    return (
        <div className={styles.questStreamComp}>
            <div
                className={cn(
                    isThinQuest ? styles.thinSearchHeaderContainer : styles.searchHeaderContainer,
                )}
            >
                {isUserProfile ? (
                    <UserProfile
                        filter={filter}
                        setIsRefreshing={handleSetIsRefreshing}
                        quest={quests?.[0]}
                        panel={panel}
                    />
                ) : (
                    <SearchHeader
                        filter={filter}
                        setIsRefreshing={handleSetIsRefreshing}
                        quest={quests?.[0]}
                    />
                )}
            </div>

            {isFetching && !quests.length ? (
                <Loading />
            ) : (
                <ErrorBoundary label={`QuestList with filter ${JSON.stringify(filter)}`}>
                    <div className={cn(styles.questList, 'quest-list')}>
                        {quests?.map((quest, ix) => {
                            const questId = quest?.id
                            if (!questId) return null

                            return (
                                <QuestInView key={questId} questId={questId}>
                                    <div
                                        key={`${quest.id}`}
                                        className={cn(
                                            isThinQuest
                                                ? styles.thinQuestContainer
                                                : styles.questContainer,
                                            ix === quests.length - 1 && styles.lastQuestContainer,
                                            'quest-container',
                                        )}
                                    >
                                        {isPeople ? (
                                            <People
                                                quest={quest}
                                                contextStyles={styles.peopleQuest}
                                            />
                                        ) : isStarred ? (
                                            <ThinQuest
                                                quest={quest}
                                                contextStyles={styles.thinQuest}
                                                showStream={true}
                                            />
                                        ) : (
                                            <Quest
                                                panelId={panelId}
                                                panel={panel}
                                                quest={quest}
                                                filter={filter}
                                                query={filter?.query}
                                                ix={ix}
                                            />
                                        )}
                                    </div>
                                </QuestInView>
                            )
                        })}
                    </div>
                </ErrorBoundary>
            )}
        </div>
    )
}
