import React from 'react'
import { v4 as uuid } from 'uuid'
import cn from 'classnames'
import styles from './quest-header.module.scss'
import { Time } from 'components/users/UserName'
import Perms from 'components/quests/Perms'
import ThreadControl from 'components/quests/Quest/ThreadControl'
import questModel from 'components/quests/questModel'
import useInsertLeft from 'refactor/hooks/useInsertLeft'
import useSearchHeaderHighlight from 'refactor/hooks/useSearchHeaderHighlight'
import { withDepth } from 'components/filters/useStreamFilters'
import { getSwiperRef, slideTo, getCenterIndex } from 'state/imperativeApis/swiperApi'
import useStore from 'state/knovStore'
import { isMobile } from 'react-device-detect'

const QuestHeader = ({
    quest,
    filter,
    panel,
    showPermsOptions,
    setShowPermsOptions,
    showExtendedOptions,
    setShowExtendedOptions,
    lastAnswerCreatedAt,
    isDraft,
    showStatus,
    isBsv,
}) => {
    const questFilter = filter?.questId ? questModel.getFilter(quest) : filter

    const showStreamRoot =
        (false && questFilter?.public) ||
        questFilter?.treechat ||
        questFilter?.hodlocker ||
        questFilter?.twetch

    const insertLeft = useInsertLeft()
    const highlightSearchHeader = useSearchHeaderHighlight()

    const onClickStream = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        let targetFilter = questModel.getFilter(quest)

        const swiperRef = getSwiperRef()
        const adjacentPanel = useStore.getState().panels.getPanelToLeft(panel.panelId)
        const adjacentFilter = adjacentPanel?.filter
        const panelIndex = useStore
            .getState()
            .panels.state.findIndex(p => p.panelId === adjacentPanel?.panelId)
        const isOffScreen = panelIndex < swiperRef.current.activeIndex

        // Check if adjacent panel already has the target filter
        if (adjacentFilter && JSON.stringify(adjacentFilter) === JSON.stringify(targetFilter)) {
            if (isMobile || isOffScreen) {
                slideTo(getCenterIndex() - 1)
            } else {
                adjacentPanel.shake()
                highlightSearchHeader(adjacentPanel.panelId)
            }
        } else {
            const newPanelId = uuid()
            insertLeft({ panelId: newPanelId, filter: targetFilter })
            highlightSearchHeader(newPanelId)
        }
    }

    return (
        <div className={cn(styles.questHeaderComp)} onClick={onClickStream}>
            <div className={styles.threadControlContainer}>
                <div className={styles.leftControlContainer}>
                    {false && showStreamRoot && (
                        <div className={styles.streamRootPath}>
                            <Perms
                                quest={quest}
                                filter={filter}
                                panel={panel}
                                showPermsOptions={showPermsOptions}
                                setShowPermsOptions={setShowPermsOptions}
                                isBsv={isBsv}
                                isRoot={true}
                                contextStyles={styles.streamRoot}
                            />
                            <div className={styles.breadSlash}>/</div>
                        </div>
                    )}
                    <div className={styles.permsContainer}>
                        <Perms
                            quest={quest}
                            filter={filter}
                            panel={panel}
                            showPermsOptions={showPermsOptions}
                            setShowPermsOptions={setShowPermsOptions}
                            isBsv={isBsv}
                        />
                    </div>
                </div>

                {true && (
                    <div className={styles.rightControlContainer}>
                        <div className={styles.cornerControlsContainer}>
                            {!isDraft && (
                                <>
                                    <div className={styles.timeContainer}>
                                        <Time
                                            time={lastAnswerCreatedAt}
                                            tip={'Latest thread message.'}
                                        />
                                    </div>

                                    <div className={styles.typeControlContainer}>
                                        <ThreadControl
                                            quest={quest}
                                            showTypeOptions={showExtendedOptions}
                                            setShowTypeOptions={setShowExtendedOptions}
                                        />

                                        {showStatus && <div className={styles.status}></div>}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default QuestHeader
