import { Quill } from 'react-quill-new'
import styles from '../mentions.module.scss'
import useStore from 'state/knovStore'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import { isMobile, isIOS } from 'react-device-detect'

const Embed = Quill.import('blots/embed') as any

// Custom Mention Blot Implementation
class CustomMentionBlot extends Embed {
    static blotName = 'mention'
    static tagName = 'span'
    static className = styles.mention

    static create(value) {
        // Create the container span with mention class
        const node = super.create(value)
        node.innerHTML = '' // Important: Clear any existing content

        // On iOS, we need different handling for contentEditable
        if (isIOS) {
            // Make the mention editable but with restricted behavior
            node.contentEditable = 'true'
            node.setAttribute('data-mention-ios', 'true')
            // Prevent default iOS text editing behavior
            node.addEventListener('input', e => {
                e.preventDefault()
                return false
            })
        } else {
            node.contentEditable = 'false'
        }

        node.dataset.mention = 'true'

        // Set additional styling to isolate the mention
        node.style.display = 'inline-block'
        node.style.position = 'relative'
        node.style.maxWidth = '100%'
        node.style.isolation = 'isolate'

        // Add the non-CSS module class for Quill compatibility
        node.classList.add('mention')

        // Create and configure the mention wrapper element
        const wrapper = document.createElement('span')
        wrapper.className = styles.mentionWrapper
        wrapper.classList.add('mention-wrapper')
        wrapper.style.isolation = 'isolate'

        // Create prefix element (@ or @@)
        const prefix = document.createElement('span')
        prefix.className = styles.mentionPrefix
        prefix.classList.add('mention-prefix')
        prefix.textContent = value.denotationChar || '@'

        // Create content element (username or model name)
        const content = document.createElement('span')
        content.className = styles.mentionContent
        content.classList.add('mention-content')
        // BACKWARD COMPATIBILITY and BUG FIX : for a brief period new mentions had no content, only value.
        let contentText = value.content || value.value || ''
        // Remove @ if it exists at the start
        if (contentText.startsWith('@')) {
            contentText = contentText.substring(1)
        }
        content.textContent = contentText

        //  BACKWARD COMPATIBILITY : old mentions had denotationChar as empty string
        if (value.denotationChar === '@' || value.denotationChar === '') {
            node.classList.add(styles.userMention)
            node.classList.add('user-mention')
        }
        // Add model-specific class if it's a model mention
        if (value.denotationChar === '@@') {
            node.classList.add(styles.modelMention)
            node.classList.add('model-mention')
        }

        // Assemble the elements
        wrapper.appendChild(prefix)
        wrapper.appendChild(content)
        node.appendChild(wrapper)

        // Add special attribute to help with serialization
        node.setAttribute('data-mention-id', value.id || '')
        node.setAttribute('data-mention-value', value.value || '')
        node.setAttribute('data-mention-content', value.content || '')
        node.setAttribute('data-mention-type', value.denotationChar === '@@' ? 'model' : 'user')
        node.setAttribute('data-mention-link', value.link || '')

        // Create explicit boundary markers for iOS
        if (isIOS) {
            const preBoundary = document.createElement('span')
            preBoundary.className = 'mention-boundary pre'
            preBoundary.textContent = '\u200B' // Zero-width space
            node.insertBefore(preBoundary, node.firstChild)

            const postBoundary = document.createElement('span')
            postBoundary.className = 'mention-boundary post'
            postBoundary.textContent = '\u200B' // Zero-width space
            node.appendChild(postBoundary)
        }

        node.onclick = e => {
            e.preventDefault()
            e.stopPropagation()

            const { panelId } = node.closest('.answer-comp')?.dataset
            // Handle different types of mentions
            if (value?.type === 'user' && value?.id) {
                // Original user mention behavior
                const panels = useStore.getState().panels
                // Inserting vs Appending?
                const indexBeforeChange = panels.getIndexById(panelId)
                const clickedRightPanel = indexBeforeChange > getCenterIndex()
                // Optimization: start the slide animation before inserting the panel.
                panels.insertPanelRight(
                    panelId,
                    { filter: { user: value.id } },
                    { animate: !isMobile && !clickedRightPanel },
                )
            }
        }

        return node
    }

    static value(domNode) {
        return {
            id: domNode.dataset.mentionId || '',
            value: domNode.dataset.mentionValue || '',
            content: domNode.dataset.mentionContent || '',
            denotationChar: domNode.dataset.mentionType === 'model' ? '@@' : '@',
            type: domNode.dataset.mentionType || 'user',
            link: domNode.dataset.mentionLink || '',
        }
    }

    // Override the length method to account for the zero-width spaces on iOS
    static getLength(node) {
        return 1
    }
}

export default CustomMentionBlot
