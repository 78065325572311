import React, { useState, useRef, useEffect, useMemo } from 'react'
import useStore from 'state/knovStore'
import cn from 'classnames'
import styles from 'components/panels/user-profile.module.scss'
import FilterOptions from 'components/filters/FilterOptions'
import SearchBar from 'components/panels/SearchBar'
import StreamOrder from 'components/panels/StreamOrder'
import StreamOptions from 'components/panels/StreamOptions'
import UserIcon from 'components/users/UserIcon'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useSlideToPanel from 'refactor/hooks/useSlideToPanel'
import queryClient from 'api/queryClient'
import { serializeFilterKey } from 'refactor/hooks/api/useGetQuests'
import useGetUser from 'refactor/hooks/api/useGetUser'
import { Participants, nonUserParticipants } from 'components/people/People'
import { getCachedQuest } from 'state/cache'
import { getDepth, withDepth } from 'components/filters/useStreamFilters'
import { clearNotificationCount } from 'utils/notificationUtils'
import WithTooltip from 'components/shared/WithTooltip'
import { isMobile } from 'react-device-detect'

interface UserProfileProps {
    filter: any
    panel: any
    quest?: any
    query?: string
    hideDuringScroll?: boolean
    setIsRefreshing?: (value: boolean) => void
    panelId?: string
}

export default function UserProfile(props: UserProfileProps) {
    const [hover, setHover] = useState(false)
    const { panel, hide } = usePanelContext()
    const panelId = panel.panelId
    const filter = panel.filter
    const query = filter?.query

    const DEBUG = useStore(state => state.DEBUG)
    const openEditProfileModal = useStore(state => state.actions.modalActions.openEditProfileModal)
    // Use a type assertion to avoid TypeScript error
    const animationState = useStore(state => state.searchHeaderAnimationStates?.[panelId]?.state)

    const [showFilterOptions, setShowFilterOptions] = useState<boolean>()

    const showSearchBar =
        !filter?.notifications &&
        !filter?.history &&
        !filter?.starred &&
        !filter?.people &&
        !filter?.participants

    const isMeme = filter?.meme
    const searchBarPlaceholder = isMeme ? 'Search Meme' : 'Search Stream'
    const filterUserId = filter?.user
    const { user: filterUser } = useGetUser(filterUserId)
    const minStyles =
        filter?.notifications ||
        filter?.history ||
        filter?.starred ||
        filter.people ||
        filter?.participants
            ? styles.min
            : null

    const memeControlContainerStyles = isMeme ? styles.memeControlContainer : null

    const slideToPanel = useSlideToPanel()

    const refreshStream = async () => {
        if (props.setIsRefreshing) {
            props.setIsRefreshing(true)
        }
        // Invalidate and refetch quests when the panel mounts
        const newFilter = withDepth(filter)
        const filterKey = serializeFilterKey(newFilter)

        // clear notification count if we're refreshing the notifications stream
        if (newFilter?.notifications) {
            await clearNotificationCount()
        }

        await queryClient.invalidateQueries({ queryKey: ['quests', filterKey], exact: true })
        if (props.setIsRefreshing) {
            props.setIsRefreshing(false)
        }
    }

    const onClickStream = (ev: React.MouseEvent) => {
        ev.stopPropagation()
        ev.preventDefault()

        refreshStream()
    }

    const lastAction = useStore.getState().panels?.lastAction

    // We get the participantQuest from the prior panel via lastAction so we don't have to wait for the quest.
    let participantQuest = props.quest
    if (
        !participantQuest &&
        panel?.panelId === lastAction?.insert?.panelId &&
        lastAction?.questId
    ) {
        participantQuest = getCachedQuest(lastAction.questId)
    }
    const participants = useMemo(() => nonUserParticipants(participantQuest), [participantQuest])
    const showStream = !filter?.participants
    const showStreamOptions = !(
        filter.people ||
        filter?.participants ||
        filter?.meme ||
        filter?.notifications ||
        filter?.history ||
        filter?.starred
    )

    const animationClass =
        (animationState === 'fadeIn' && styles.selectedAnimationFadeIn) ||
        (animationState === 'fadeOut' && styles.selectedAnimationFadeOut) ||
        ''

    // Truncate user description to 160 characters
    const truncateDescription = (description: string) => {
        if (!description) return 'A Treechatter'
        if (description.length <= 160) return description
        return `${description.substring(0, 160)}...`
    }

    const userDescription = truncateDescription(filterUser?.bio || filterUser?.description || '')

    // Check if this is the current user's profile
    const isCurrentUserProfile = filterUserId === gon.currentUser?.id

    return (
        <div
            className={cn(styles.userProfileComp, minStyles, animationClass)}
            style={{ visibility: hide || props.hideDuringScroll ? 'hidden' : 'visible' }}
            onClick={ev => slideToPanel()}
        >
            {DEBUG && (
                <div className={styles.debugContainer}>
                    <span className={styles.debugText}>
                        Filter:{' '}
                        <span
                            style={{ fontFamily: 'monospace', wordWrap: 'break-word' }}
                            onClick={() => {
                                const text = JSON.stringify(filter)
                                navigator.clipboard.writeText(text)
                                // briefly bold the text
                                const spanElement = document.getElementById('filterText')
                                if (spanElement) {
                                    spanElement.style.fontWeight = 'bold'
                                    setTimeout(() => {
                                        spanElement.style.fontWeight = 'normal'
                                    }, 500)
                                }
                            }}
                            id="filterText"
                        >
                            {JSON.stringify(filter)}
                        </span>
                    </span>
                </div>
            )}

            {isCurrentUserProfile && (
                <div
                    className={styles.settingsIconContainer}
                    onClick={e => {
                        e.stopPropagation()
                        openEditProfileModal()
                    }}
                >
                    <WithTooltip tip="Edit your profile">
                        <i className="fa fa-cog" />
                    </WithTooltip>
                </div>
            )}

            {showStream && (
                <div className={cn(styles.streamControlContainer, memeControlContainerStyles)}>
                    <div className={styles.filterContainer}>
                        <div
                            className={styles.userIconContainer}
                            onClick={onClickStream}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <UserIcon
                                user={filterUser}
                                size="80"
                                contextStyles={cn(
                                    styles.userIcon,
                                    isMobile && styles.mobileUserIcon,
                                )}
                                clickable
                            />
                        </div>

                        <div className={styles.userInfoContainer}>
                            <div className={styles.userName}>
                                {filterUser?.username || filterUser?.name || 'User'}
                            </div>
                            <div className={styles.userDescription}>{userDescription}</div>
                        </div>
                    </div>
                </div>
            )}

            {showFilterOptions && (
                <div className={styles.filterOptionsContainer}>
                    <FilterOptions
                        origin="search-header"
                        filter={filter}
                        panelId={panelId}
                        panel={props.panel}
                        query={props.query}
                        close={() => setShowFilterOptions(!showFilterOptions)}
                        show={['default', 'teams']}
                        theme="dark"
                        setShowFilterOptions={setShowFilterOptions}
                        showNoti
                        blur
                    />
                </div>
            )}

            {showSearchBar && (
                <>
                    <div className={styles.searchBarContainer} onClick={ev => ev.stopPropagation()}>
                        <div className={styles.searchAndOptionsRow}>
                            <SearchBar
                                key={Object(filter).keys?.join('_')}
                                filter={filter}
                                query={query}
                                panel={props.panel}
                                placeholder={searchBarPlaceholder}
                                onHandlersReady={handlers => {
                                    // Store search handlers for the outside button
                                    ;(window as any).searchHandlers = handlers
                                }}
                            />

                            <div
                                className={cn(
                                    styles.externalSearchButton,
                                    (window as any).searchHandlers?.hasQuery ? styles.active : '',
                                )}
                                onClick={() => (window as any).searchHandlers?.search('relev')}
                            >
                                <i className="fa fa-search" />
                            </div>

                            {showStreamOptions && (
                                <div className={styles.streamOptionsContainer}>
                                    <StreamOptions panelId={panelId} filter={filter} />
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        className={cn(styles.streamOrderContainer)}
                        onClick={ev => ev.stopPropagation()}
                    >
                        <StreamOrder
                            filter={filter}
                            panelId={panelId}
                            panel={props.panel}
                            query={query}
                        />
                    </div>
                </>
            )}

            {filter?.participants && participants && (
                <div className={styles.participantsContainer}>
                    {participants && (
                        <Participants
                            participants={participants}
                            quest={participantQuest}
                            contextStyles={styles.participants}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
