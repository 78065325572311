import store from 'state/knovStore'
import { isMobile } from 'react-device-detect'

/**
 * Non-blocking confirm dialog that mimics the browser's native confirm API
 * Uses the existing modal system and Confirm component
 *
 * @param {string} message - The message to display in the confirm dialog
 * @param {Object} options - Optional configuration
 * @param {string} options.confirmLabel - Custom label for the confirm button (default: "OK")
 * @param {boolean} options.hideCancel - Whether to hide the cancel button (default: false)
 * @returns {Promise<boolean>} - Resolves to true if confirmed, false if canceled
 */
export const confirmAsync = (
    message: string,
    options: { confirmLabel?: string; cancelMessage?: string; hideCancel?: boolean } = {},
): Promise<boolean> => {
    return new Promise(resolve => {
        const { openConfirmModal } = store.getState().actions.modalActions

        openConfirmModal(
            message,
            options.confirmLabel || 'OK',
            () => {
                resolve(true)
            },
            isMobile
                ? {
                      customStyles: { height: '20vh' },
                  }
                : {},
        )
    })
}
