import { useQuery } from '@tanstack/react-query'
import api, { IOrdinal as TreechatOrdinal } from 'api/api'
import useWalletDetails from 'api/useWalletDetails'
import cn from 'classnames'
import { insertCenter } from 'components/filters/useStreamFilters'
import OrdinalsIcon from 'components/shared/OrdinalsIcon'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import useStore from 'state/knovStore'
import styles from './ordinals-viewer.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'

interface OrdinalsViewerProps {
    modalParams?: any
    closeModal?: () => void
}

interface GorillaPoolOrdinal {
    txid: string
    vout: number
    outpoint: string
    origin: {
        data?: {
            insc?: {
                file?: {
                    type?: string
                    size?: number
                }
                text?: string
            }
            map?: {
                name?: string
                type?: string
            }
        }
    }
}

interface CombinedOrdinal extends GorillaPoolOrdinal {
    treechatData?: TreechatOrdinal
    status: 'confirmed' | 'pending'
}

interface OrdinalPreview {
    ordinal: CombinedOrdinal
    content: string | null
    isLoading?: boolean
    error?: string | null
}

const fetchGorillaPoolOrdinals = async (address: string) => {
    const response = await fetch(
        `https://ordinals.gorillapool.io/api/txos/address/${address}/unspent`,
    )
    if (!response.ok) {
        throw new Error('Failed to fetch ordinals')
    }
    return response.json()
}

const fetchOrdinalContent = async (outpoint: string) => {
    try {
        const response = await fetch(`https://ordfs.network/${outpoint}`)
        if (!response.ok) {
            throw new Error(`Failed to fetch content: ${response.statusText}`)
        }
        const contentType = response.headers.get('content-type')
        if (contentType?.startsWith('text/')) {
            const text = await response.text()
            // Limit text length to prevent huge content from breaking the UI
            return text.slice(0, 100000)
        }
        return null
    } catch (error) {
        throw error
    }
}

const OrdinalsViewer: React.FC<OrdinalsViewerProps> = ({ modalParams, closeModal }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const currentUserBsvWalletType = useStore(state => state.currentUserBsvWalletType)
    const { data: walletDetailsData, isLoading: isInitialLoading } =
        useWalletDetails(currentUserBsvWalletType)
    const { ordinalsAddress } = walletDetailsData || {}

    const [selectedOrdinal, setSelectedOrdinal] = useState<OrdinalPreview | null>(null)
    const [isMetadataExpanded, setIsMetadataExpanded] = useState(false)
    const activeModal = useStore(state => state.modalParams.type)
    const ordinalsModalIsOpen = activeModal === 'OrdinalsViewer'

    // ref to track if a treechat ordinals request is in progress
    const currentlyRefetchingTreechatOrds = useRef(false)

    // ref to track which external ordinalIds have already been processed by this comp
    const externallySelectedOrdinalIds = useRef<Set<string>>(new Set())

    const {
        data: gorillaOrdinals,
        isLoading: isGorillaLoading,
        refetch: refetchGorillaPoolOrdinals,
    } = useQuery({
        queryKey: ['gorillaOrdinals', ordinalsAddress],
        queryFn: () => fetchGorillaPoolOrdinals(ordinalsAddress),
        enabled: !!ordinalsAddress,
    })

    const {
        data: treechatOrdinals,
        isLoading: isTreechatLoading,
        refetch: refetchTreechatOrdinals,
    } = useQuery({
        queryKey: ['treechatOrdinals', gon.currentUser?.id],
        queryFn: async () => {
            currentlyRefetchingTreechatOrds.current = true
            try {
                const result = await api.listOrdinals()
                return result?.ordinals
            } finally {
                currentlyRefetchingTreechatOrds.current = false
            }
        },
        refetchInterval: 30000,
    })

    const isLoading = isInitialLoading || isGorillaLoading || isTreechatLoading

    // fn to safely refetch treechat ordinals if not already fetching
    const safeRefetchTreechatOrds = () => {
        if (!currentlyRefetchingTreechatOrds.current) {
            refetchTreechatOrdinals()
        }
    }

    // Trigger refetch when modal is shown or interacted with
    useEffect(() => {
        if (ordinalsModalIsOpen) {
            if (ordinalsAddress) {
                refetchGorillaPoolOrdinals()
            }
            safeRefetchTreechatOrds()
        }
    }, [ordinalsAddress, ordinalsModalIsOpen])

    // Add event listeners for user interaction with the modal
    useEffect(() => {
        if (!ordinalsModalIsOpen) return

        const handleUserInteraction = () => {
            safeRefetchTreechatOrds()
        }

        // Debounce the interaction handler to prevent too many requests
        let interactionTimeout: number
        const debouncedInteractionHandler = () => {
            clearTimeout(interactionTimeout)
            interactionTimeout = setTimeout(handleUserInteraction, 300)
        }

        // Add event listeners for user interactions
        const modalElement = document.querySelector(`.${styles.container}`)
        if (modalElement) {
            modalElement.addEventListener('click', debouncedInteractionHandler)
            modalElement.addEventListener('scroll', debouncedInteractionHandler)
        }

        return () => {
            clearTimeout(interactionTimeout)
            if (modalElement) {
                modalElement.removeEventListener('click', debouncedInteractionHandler)
                modalElement.removeEventListener('scroll', debouncedInteractionHandler)
            }
        }
    }, [ordinalsModalIsOpen])

    // normalize and combine ordinals
    const combinedOrdinals = useMemo(() => {
        const ordinalMap = new Map<string, CombinedOrdinal>()

        // add gorillapool ordinals first
        if (Array.isArray(gorillaOrdinals)) {
            gorillaOrdinals.forEach(ord => {
                ordinalMap.set(ord.outpoint, {
                    ...ord,
                    status: 'confirmed',
                })
            })
        }

        // merge in treechat ordinals
        if (Array.isArray(treechatOrdinals)) {
            treechatOrdinals.forEach(treeOrd => {
                const existing = ordinalMap.get(treeOrd.origin)

                if (existing) {
                    ordinalMap.set(treeOrd.origin, {
                        ...existing,
                        treechatData: treeOrd,
                        status: 'confirmed',
                    })
                } else {
                    // create a pending ordinal entry
                    // extract txid and vout from origin (format: {{txid}}_{{vout}})
                    const origin = treeOrd?.origin ?? ''
                    const [txid, vout] = origin.split('_')
                    ordinalMap.set(treeOrd.origin, {
                        txid,
                        vout: parseInt(vout, 10),
                        outpoint: treeOrd.origin,
                        origin: {
                            data: {
                                insc: {
                                    file: {
                                        type: 'image/jpeg', // img as default type for treechat ordinals for now
                                    },
                                },
                            },
                        },
                        treechatData: treeOrd,
                        status: 'pending',
                    })
                }
            })
        }

        const result = Array.from(ordinalMap.values()).sort((a, b) => {
            // First sort by status (pending first)
            if (a.status === 'pending' && b.status !== 'pending') return -1
            if (a.status !== 'pending' && b.status === 'pending') return 1

            // Then sort by creation date if available (newest first)
            const aDate = a.treechatData?.created_at || ''
            const bDate = b.treechatData?.created_at || ''
            return bDate.localeCompare(aDate)
        })

        return result
    }, [gorillaOrdinals, treechatOrdinals])

    // handle externally selected ordinal by ordinalid
    useEffect(() => {
        if (ordinalsModalIsOpen && modalParams?.ordinalId && combinedOrdinals.length > 0) {
            // only process this ordinalid if we haven't seen it before
            if (!externallySelectedOrdinalIds.current.has(modalParams.ordinalId)) {
                // find the ordinal with the matching origin/outpoint
                const targetOrdinal = combinedOrdinals.find(
                    ordinal => ordinal.outpoint === modalParams.ordinalId,
                )

                if (targetOrdinal) {
                    // if found, set it as the selected ordinal
                    setSelectedOrdinal({
                        ordinal: targetOrdinal,
                        content: null,
                        isLoading: false,
                    })

                    // mark that this external ordinal has already been opened once
                    externallySelectedOrdinalIds.current.add(modalParams.ordinalId)
                }
            }
        }
    }, [ordinalsModalIsOpen, modalParams?.ordinalId, combinedOrdinals])

    // reset processed ordinalids when modal is closed
    useEffect(() => {
        if (!ordinalsModalIsOpen) {
            externallySelectedOrdinalIds.current.clear()
        }
    }, [ordinalsModalIsOpen])

    // Update URL when selected ordinal changes
    useEffect(() => {
        if (selectedOrdinal && ordinalsModalIsOpen) {
            const newUrl = `/modal/ordinals/${selectedOrdinal.ordinal.outpoint}`
            // Only update if URL is different to avoid unnecessary history entries
            if (location.pathname !== newUrl) {
                window.history.replaceState({}, '', newUrl)
            }
        }
    }, [selectedOrdinal, ordinalsModalIsOpen, location.pathname])

    const handleOrdinalClick = async (ordinal: CombinedOrdinal) => {
        // refresh the treechat ordinals data when clicking on an ordinal
        safeRefetchTreechatOrds()

        const fileType = ordinal.origin?.data?.insc?.file?.type || ''
        if (fileType.startsWith('text/')) {
            // Set loading state immediately
            setSelectedOrdinal({ ordinal, content: null, isLoading: true })
            try {
                const content = await fetchOrdinalContent(ordinal.outpoint)
                setSelectedOrdinal({ ordinal, content, isLoading: false })
            } catch (error) {
                setSelectedOrdinal({
                    ordinal,
                    content: null,
                    isLoading: false,
                    error: error instanceof Error ? error.message : 'Failed to load content',
                })
            }
        } else {
            setSelectedOrdinal({ ordinal, content: null })
        }
    }

    const handleSend = (ordinal: CombinedOrdinal) => {
        // TODO: Implement send functionality
        console.log('Sending ordinal:', ordinal)
    }

    const handleLightboxClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        setSelectedOrdinal(null)
        // Update URL back to the main ordinals view
        window.history.replaceState({}, '', '/modal/ordinals')
        // refresh the treechat ordinals data when closing the lightbox
        safeRefetchTreechatOrds()
    }

    const handleContentClick = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    const renderPreview = (ordinal: CombinedOrdinal) => {
        const fileType = ordinal.origin?.data?.insc?.file?.type || ''
        const previewUrl = `https://ordfs.network/${ordinal.outpoint}`

        if (fileType.startsWith('image/')) {
            return (
                <img
                    src={previewUrl}
                    alt="Ordinal preview"
                    className={styles.preview}
                    onError={e => {
                        const target = e.target as HTMLImageElement
                        target.src = '' // Clear the broken image
                        target.style.background = '#f5f5f5'
                        target.style.display = 'flex'
                        target.style.alignItems = 'center'
                        target.style.justifyContent = 'center'
                        target.style.color = 'gray'
                        target.style.fontFamily = 'monospace'
                        target.style.fontSize = '12px'
                        target.style.padding = '10px'
                        target.style.textAlign = 'center'
                        target.innerText = 'Failed to load image'
                    }}
                />
            )
        } else if (fileType.startsWith('text/')) {
            return (
                <div className={styles.preview} style={{ padding: '10px', background: '#f5f5f5' }}>
                    {ordinal.origin?.data?.insc?.text ? (
                        <div className={styles.textPreview}>{ordinal.origin.data.insc.text}</div>
                    ) : (
                        <span style={{ color: 'gray', fontFamily: 'monospace' }}>
                            [Long text content (click to view)]
                        </span>
                    )}
                </div>
            )
        } else {
            return (
                <div
                    className={styles.preview}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    {fileType || 'Unknown type'}
                </div>
            )
        }
    }

    const renderMetadata = (ordinal: CombinedOrdinal) => {
        const metadata = [
            {
                label: 'Outpoint',
                value: ordinal.outpoint,
                primary: true,
            },
            {
                label: 'Transaction ID',
                value: ordinal.txid,
                primary: false,
            },
            {
                label: 'Output Index',
                value: ordinal.vout.toString(),
                primary: false,
            },
        ]

        // Add optional metadata if present
        if (ordinal.origin?.data?.map?.name) {
            metadata.push({
                label: 'Name',
                value: ordinal.origin.data.map.name,
                primary: true,
            })
        }

        if (ordinal.origin?.data?.map?.type) {
            metadata.push({
                label: 'Map Type',
                value: ordinal.origin.data.map.type,
                primary: true,
            })
        }

        if (ordinal.origin?.data?.insc?.file?.type) {
            metadata.push({
                label: 'File Type',
                value: ordinal.origin.data.insc.file.type,
                primary: true,
            })
        }

        if (ordinal.origin?.data?.insc?.file?.size) {
            metadata.push({
                label: 'File Size',
                value: `${(ordinal.origin.data.insc.file.size / 1024).toFixed(2)} KB`,
                primary: false,
            })
        }

        return (
            <div className={styles.metadataSection}>
                <div
                    className={styles.metadataHeader}
                    onClick={() => setIsMetadataExpanded(!isMetadataExpanded)}
                >
                    <span>Ordinal Details</span>
                    <i
                        className={`fa fa-chevron-down ${styles.icon} ${
                            isMetadataExpanded ? styles.expanded : ''
                        }`}
                    />
                </div>
                <div
                    className={`${styles.metadataBody} ${
                        isMetadataExpanded ? styles.expanded : ''
                    }`}
                >
                    <div className={styles.metadataContent}>
                        {metadata.map((item, index) => (
                            <div
                                key={index}
                                className={`${styles.metadataItem} ${
                                    !item.primary ? styles.secondary : ''
                                }`}
                            >
                                <label>{item.label}</label>
                                <div className={styles.value}>{item.value}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    {/* <div className={styles.buttonContainer}>
                        <button className={styles.sendButton} onClick={() => handleSend(ordinal)}>
                            Send
                        </button>
                    </div> */}
                    <button
                        className={cn(styles.viewButton, styles.oneSatButton)}
                        onClick={() => {
                            if (ordinal.outpoint) {
                                window.open(
                                    `https://alpha.1satordinals.com/outpoint/${ordinal.outpoint}/timeline`,
                                    '_blank',
                                )
                            }
                        }}
                    >
                        <span className={styles.buttonIcon}>
                            <OrdinalsIcon size={16} />
                        </span>
                        View on 1Sat
                    </button>
                </div>
            </div>
        )
    }

    const OrdinalPreview: React.FC<{ ordinal: CombinedOrdinal }> = ({ ordinal }) => {
        const handleOrdinalsIconClick = (e: React.MouseEvent) => {
            e.stopPropagation()
            if (ordinal.treechatData && ordinal.status === 'confirmed' && closeModal) {
                // Close the modal
                closeModal()

                // Get quest_id and answer_id from the treechatData
                const { quest_id, answer_id } = ordinal.treechatData

                // Use insertRight to navigate to the answer's quest and answer id
                if (quest_id && answer_id) {
                    insertCenter({
                        questId: quest_id,
                        answerId: answer_id,
                    })
                }
            }
        }

        return (
            <div className={styles.previewContainer}>
                {renderPreview(ordinal)}
                <div className={styles.statusIndicator}>
                    {ordinal.treechatData && ordinal.status === 'confirmed' && (
                        <span
                            title="Treechat Ordinal"
                            onClick={handleOrdinalsIconClick}
                            style={{ cursor: 'pointer' }}
                        >
                            <OrdinalsIcon size={20} />
                        </span>
                    )}
                    {ordinal.status === 'pending' && (
                        <span title="Pending Confirmation">
                            <i className="fa fa-clock-o" />
                        </span>
                    )}
                </div>
            </div>
        )
    }

    const renderLightboxContent = () => {
        if (!selectedOrdinal) return null

        const fileType = selectedOrdinal.ordinal.origin?.data?.insc?.file?.type || ''
        const previewUrl = `https://ordfs.network/${selectedOrdinal.ordinal.outpoint}`

        return (
            <div className={styles.lightboxContent} onClick={handleContentClick}>
                <button className={styles.closeButton} onClick={() => setSelectedOrdinal(null)}>
                    <i className="fa fa-times" />
                </button>

                <div className={styles.previewSection}>
                    {fileType.startsWith('image/') ? (
                        <img
                            src={previewUrl}
                            alt="Ordinal preview"
                            onError={e => {
                                const target = e.target as HTMLImageElement
                                target.style.display = 'none'
                                const parent = target.parentElement
                                if (parent) {
                                    const errorDiv = document.createElement('div')
                                    errorDiv.className = styles.textContent
                                    errorDiv.innerText = 'Failed to load image'
                                    parent.appendChild(errorDiv)
                                }
                            }}
                        />
                    ) : fileType.startsWith('text/') ? (
                        selectedOrdinal.isLoading ? (
                            <div className={styles.textContent}>
                                <i
                                    className="fa fa-spinner fa-spin"
                                    style={{ marginRight: '8px' }}
                                />
                                Loading text content...
                            </div>
                        ) : selectedOrdinal.error ? (
                            <div className={styles.textContent} style={{ color: 'red' }}>
                                Error: {selectedOrdinal.error}
                            </div>
                        ) : (
                            <div className={styles.textContent}>
                                {selectedOrdinal.content || 'No text content found'}
                            </div>
                        )
                    ) : (
                        <div className={styles.textContent}>
                            Unsupported content type: {fileType}
                        </div>
                    )}
                </div>

                {renderMetadata(selectedOrdinal.ordinal)}
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>
                {isLoading ? 'Loading Ordinals...' : `Ordinals for ${ordinalsAddress}`}
            </h1>

            {isLoading ? (
                <div className={styles.loading}>
                    <i className="fa fa-spinner fa-spin" />
                </div>
            ) : combinedOrdinals.length > 0 ? (
                <div className={styles.gallery}>
                    {combinedOrdinals.map((ordinal: CombinedOrdinal) => (
                        <div
                            key={ordinal.outpoint}
                            className={styles.ordinalCard}
                            onClick={() => handleOrdinalClick(ordinal)}
                        >
                            <OrdinalPreview ordinal={ordinal} />
                            <div className={styles.details}>
                                <div className={styles.type}>
                                    {ordinal.origin?.data?.insc?.file?.type || 'Unknown type'}
                                </div>
                                <div className={styles.info}>
                                    {ordinal.origin?.data?.map?.name || ordinal.outpoint}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                !isLoading && <div className={styles.loading}>No ordinals found</div>
            )}

            {selectedOrdinal && (
                <div className={styles.lightbox} onClick={handleLightboxClick}>
                    {renderLightboxContent()}
                </div>
            )}
        </div>
    )
}

export default OrdinalsViewer
