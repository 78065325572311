import React, { useState, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import cn from 'classnames'
import useGetAnswer from 'refactor/hooks/api/useGetAnswer'
import { getCachedAnswer } from 'state/cache'
import useAnswerChannel from 'state/channels/useAnswerChannel'
import styles from 'components/answers/Answer/answer.module.scss'
import { getValue, getText } from '../../lib/value'
import { ANSWER_HEIGHT } from 'state/PanelState'

const AnswerInView = ({ answerId, visibleDefault = false, children, isActive }) => {
    //console.log('AnswerInView', answerId, visibleDefault, children, isActive)
    let answer = useGetAnswer(answerId)
    const { subscribe, unsubscribe } = useAnswerChannel(answerId)
    const [isVisible, setIsVisible] = useState(visibleDefault)
    let minHeight = ANSWER_HEIGHT
    const [height, setHeight] = useState(isActive ? minHeight : 0)

    const COLLAPSE_POST_LENGTH = 300
    const [textIsCollapsed, setTextIsCollapsed] = useState(() => {
        const textLength = getText(getValue(answer))?.length || 0
        return textLength > COLLAPSE_POST_LENGTH
    })

    //const scrollContainer = panel.getPanelRef()?.querySelector('.scroll-container')

    const handleInViewChange = useCallback(
        (inView, entry) => {
            if (inView) {
                setIsVisible(true)
                subscribe()
            } else {
                if (entry.target.clientHeight > height) {
                    setHeight(entry.target.clientHeight)
                }
                setIsVisible(false)
                unsubscribe()
            }
        },
        [height, subscribe, unsubscribe],
    )

    const { ref, inView, entry } = useInView({
        root: document,
        rootMargin: '300px 0px 300px 0px',
        threshold: 0.1,
        onChange: handleInViewChange,
    })

    const inViewCallback = () => {
        if (entry?.target) {
            //console.log('inViewCallback', entry.target.clientHeight)
            setHeight(entry.target.clientHeight)
        }
        answer = getCachedAnswer(answerId)
    }

    return (
        <div
            ref={ref}
            className={cn(`answer-in-view-${answerId}`)}
            style={{
                minHeight: height,
            }}
        >
            {isVisible &&
                React.cloneElement(children, {
                    // key is used to force a re-render when textIsCollapsed changes
                    key: `answer-${answerId}-${textIsCollapsed}`,
                    answer,
                    inViewCallback,
                    textIsCollapsed,
                    setTextIsCollapsed,
                    COLLAPSE_POST_LENGTH,
                })}
        </div>
    )
}

export default AnswerInView
