import React from 'react'
import cn from 'classnames'
import styles from './answer-gate.module.scss'

const AnswerGate = ({ childCount, hover, isSelected }) => {
    return (
        <div
            className={cn(styles.answerGate, hover && styles.hover, isSelected && styles.selected)}
        >
            <span className={styles.count}>
                {childCount === 0 && <i className="fa fa-comment-o" />}
                {childCount > 0 && Math.min(childCount, 99)}
            </span>
            {childCount > 99 && <span className={styles.gateOverflow}>+</span>}
        </div>
    )
}

export default AnswerGate
