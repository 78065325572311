import React, { useState, useRef, useEffect } from 'react'
import styles from 'components/quests/knov-agent-button.module.scss'
import cn from 'classnames'
import WithTooltip from 'components/shared/WithTooltip'
import { isMobile } from 'react-device-detect'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useStore from 'state/knovStore'
import { isPanelCenter } from 'state/imperativeApis/swiperApi'
import ReactQuill, { Quill } from 'react-quill-new'
import BotImage from 'assets/knov-answer.svg'
import WhiteBotImage from 'assets/knov-white.svg'

// Access Delta through Quill.import
const Delta = Quill.import('delta')

// Define image paths as constants
const BotImagePath = BotImage
const WhiteBotImagePath = WhiteBotImage

export enum LLMModels {
    CLAUDE_SONNET_37 = 'claude-sonnet-3.7',
    GPT_4_O = 'gpt-4o',
    CLAUDE_SONNET = 'claude-sonnet-3.5',
    PERPLEXITY_SONAR_HUGE = 'perplexity-sonar-online',
    GEMINI_PRO_25 = 'gemini-pro-2.5',
    GROQ_LLAMA3 = 'groq-llama3',
    LLAMA31_405B = 'llama-3.1-405b',
    O3_MINI = 'o3-mini',
    // MIXTRAL = 'mixtral',
    // MIXTRAL_DOLPHIN = 'mixtral-dolphin',
    MIXTRAL_UNCENSORED = 'mixtral-uncensored',
    // CLAUDE = 'claude',
    // CLAUDE_2 = 'claude-2',
    // CLAUDE_INSTANT = 'claude-instant',
    CLAUDE_OPUS = 'claude-opus',
    O1_PREVIEW = 'o1-preview',
    // CLAUDE_HAIKU = 'claude-haiku',
    // GPT_3_5 = 'gpt-3.5-turbo',
    DEEPSEEK_R1 = 'deepseek-r1',
    GPT_4 = 'gpt-4',
    ASSHOLE = 'a$$hole',
    XAI_GROK = 'xai-grok-2',
    GEMINI_FLASH = 'gemini-flash',
    GEMINI_PRO = 'gemini-pro',
}

export const defaultModel = LLMModels.CLAUDE_SONNET_37

export default function KnovAgentButtonUIMain({
    models = Object.values(LLMModels),
    contextStyles,
    active,
    imageStyles,
    setAgentModel,
    editorRef = null,
    onMenuStateChange = null,
}) {
    const { panelId, hide } = usePanelContext()
    const panel = useStore.getState().panels.getPanel(panelId)
    const [showMenu, setShowMenu] = useState(false)
    const [agentHover, setAgentHover] = useState(false)
    const [modelHover, setModelHover] = useState(false)
    const [menuDirection, setMenuDirection] = useState('up')
    const [menuMaxHeight, setMenuMaxHeight] = useState(0)
    const buttonRef = useRef(null)
    const menuRef = useRef(null)
    // 85 is the height of the control panel in the center panel, other panels have no control panel.
    const CONTROL_TOP = isPanelCenter(panelId) ? 85 : 0
    // Safety padding to avoid menu being flush with screen edges
    const SAFETY_PADDING = 60

    const isMobile = window.innerWidth <= 500

    // Notify parent component when menu state changes
    useEffect(() => {
        // Only notify after a short delay - enough time to let the menu render and become scrollable
        if (onMenuStateChange) {
            if (showMenu) {
                // For opening the menu, wait until it's fully initialized before notifying parent
                const timer = setTimeout(() => {
                    onMenuStateChange(true)
                }, 50)
                return () => clearTimeout(timer)
            } else {
                // For closing, notify immediately
                onMenuStateChange(false)
            }
        }
    }, [showMenu, onMenuStateChange])

    // Add click outside handler to close the menu
    useEffect(() => {
        if (showMenu) {
            const handleClickOutside = event => {
                if (
                    menuRef.current &&
                    !menuRef.current.contains(event.target) &&
                    buttonRef.current &&
                    !buttonRef.current.contains(event.target)
                ) {
                    setShowMenu(false)
                }
            }

            document.addEventListener('mousedown', handleClickOutside)
            document.addEventListener('touchstart', handleClickOutside)

            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
                document.removeEventListener('touchstart', handleClickOutside)
            }
        }
    }, [showMenu])

    useEffect(() => {
        if (showMenu && buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect()
            const spaceAbove = buttonRect.top - SAFETY_PADDING
            const spaceBelow = window.innerHeight - buttonRect.bottom - CONTROL_TOP - SAFETY_PADDING

            // Calculate max heights for both directions
            const maxHeightAbove = spaceAbove
            const maxHeightBelow = spaceBelow

            // Choose direction with more space
            if (maxHeightBelow >= maxHeightAbove) {
                setMenuDirection('down')
                setMenuMaxHeight(maxHeightBelow)
            } else {
                setMenuDirection('up')
                setMenuMaxHeight(maxHeightAbove)
            }

            // Auto-focus the menu after it renders and simulate a touch for iOS
            setTimeout(() => {
                if (menuRef.current) {
                    // Focus the menu
                    menuRef.current.focus()

                    if (isMobile) {
                        // For iOS, force the scroll container to be initialized
                        // by programmatically scrolling it a tiny amount and back
                        const menu = menuRef.current
                        const originalScroll = menu.scrollTop
                        menu.scrollTop = 1
                        setTimeout(() => {
                            menu.scrollTop = originalScroll
                        }, 5)

                        // Force iOS to recognize this as a scrollable area
                        try {
                            // Try to scroll to activate iOS momentum scrolling
                            menu.style.webkitOverflowScrolling = 'auto'
                            setTimeout(() => {
                                menu.style.webkitOverflowScrolling = 'touch'
                            }, 10)
                        } catch (e) {
                            // Ignore any errors in case of older browsers
                        }
                    }
                }
            }, 10)
        }
    }, [showMenu, CONTROL_TOP])

    // Add enhanced touch handling for the menu
    useEffect(() => {
        if (showMenu && menuRef.current) {
            // Setup enhanced touch handlers
            const menu = menuRef.current
            let isScrolling = false
            let touchInProgress = false

            // Manual scroll handler function
            const handleTouchStart = e => {
                // Don't stop propagation for clicks on menu items
                if (e.target.closest(`.${styles.knovAgentMenuItem}`)) {
                    return
                }

                e.stopPropagation()
                touchInProgress = true
                // Focus menu on touch to ensure scroll works
                menu.focus()
            }

            const handleTouchMove = e => {
                e.stopPropagation()
                isScrolling = true
            }

            const handleTouchEnd = e => {
                // Don't interfere with clicks on menu items
                if (e.target.closest(`.${styles.knovAgentMenuItem}`)) {
                    return
                }

                touchInProgress = false
                // Reset scrolling flag after a delay to allow blur handling to check it
                setTimeout(() => {
                    isScrolling = false
                }, 100)
            }

            // Track scrolling state
            const handleScroll = () => {
                isScrolling = true
                // Reset scrolling flag after scroll stops
                clearTimeout(menu.scrollTimeout)
                menu.scrollTimeout = setTimeout(() => {
                    isScrolling = false
                }, 150)
            }

            // Custom blur handler
            const handleBlur = e => {
                // Don't handle blur if the related target is a menu item
                if (e.relatedTarget && e.relatedTarget.closest(`.${styles.knovAgentMenuItem}`)) {
                    return
                }

                // If we're scrolling or touch is in progress, don't close the menu
                if (isScrolling || touchInProgress) {
                    // Prevent blur by re-focusing
                    setTimeout(() => menu.focus(), 0)
                    return
                }

                // Otherwise, close the menu
                setShowMenu(false)
            }

            // Use DOM event listeners for better control
            menu.addEventListener('touchstart', handleTouchStart, { passive: false })
            menu.addEventListener('touchmove', handleTouchMove, { passive: true })
            menu.addEventListener('touchend', handleTouchEnd, { passive: true })
            menu.addEventListener('scroll', handleScroll, { passive: true })
            menu.addEventListener('blur', handleBlur)

            // Auto-focus the menu immediately
            menu.focus()

            return () => {
                // Clean up
                menu.removeEventListener('touchstart', handleTouchStart)
                menu.removeEventListener('touchmove', handleTouchMove)
                menu.removeEventListener('touchend', handleTouchEnd)
                menu.removeEventListener('scroll', handleScroll)
                menu.removeEventListener('blur', handleBlur)
                clearTimeout(menu.scrollTimeout)
            }
        }
    }, [showMenu])

    // Prevent native browser pull-to-refresh when menu is open
    useEffect(() => {
        if (!showMenu) return

        // Use WeakMap to store touch positions without modifying the DOM element
        const touchStartMap = new WeakMap<HTMLElement, number>()

        // Store initial touch position
        const handleTouchStart = (e: TouchEvent) => {
            const touch = e.touches[0]
            if (touch) {
                touchStartMap.set(document.body, touch.clientY)
            }
        }

        // Prevent browser default for pull-down gestures
        const preventDefaultPullToRefresh = (e: TouchEvent) => {
            // Get touch position
            const touch = e.touches[0]
            if (!touch) return

            // Get initial touch position from WeakMap
            const startY = touchStartMap.get(document.body)
            if (startY === undefined) return

            // Calculate direction (positive = pulling down)
            const deltaY = touch.clientY - startY
            const isPullingDown = deltaY > 0

            // When menu is open, always prevent pull-to-refresh
            if (isPullingDown && menuRef.current) {
                e.preventDefault()
            }
        }

        // Reset touch position tracking
        const resetTouchStart = () => {
            touchStartMap.delete(document.body)
        }

        // Add touch event listeners to document body to catch all touches
        document.body.addEventListener('touchstart', handleTouchStart, { passive: true })
        document.body.addEventListener('touchmove', preventDefaultPullToRefresh, { passive: false })
        document.body.addEventListener('touchend', resetTouchStart, { passive: true })
        document.body.addEventListener('touchcancel', resetTouchStart, { passive: true })

        return () => {
            // Clean up event listeners
            document.body.removeEventListener('touchstart', handleTouchStart)
            document.body.removeEventListener('touchmove', preventDefaultPullToRefresh)
            document.body.removeEventListener('touchend', resetTouchStart)
            document.body.removeEventListener('touchcancel', resetTouchStart)
            touchStartMap.delete(document.body)
        }
    }, [showMenu])

    const activeStyles = active || (!isMobile && agentHover) ? styles.active : null

    return (
        <div className={styles.knovAgentButtonContainer}>
            <div
                className={cn(styles.knovAgentButtonUIMainComp, contextStyles, activeStyles)}
                ref={buttonRef}
                onMouseEnter={() => !isMobile && setAgentHover(true)}
                onMouseLeave={() => !isMobile && setAgentHover(false)}
            >
                <WithTooltip tip={active ? 'Remove agent.' : 'Invite agent.'}>
                    <div
                        className={cn(styles.toggleAgent, agentHover && styles.hover)}
                        onClick={e => {
                            // Close menu if open to prevent UI flicker
                            if (showMenu) {
                                setShowMenu(false)
                            }

                            // Simply toggle the model state
                            setAgentModel(active ? null : defaultModel)
                        }}
                        tabIndex={-1}
                        onMouseDown={e => e.preventDefault()}
                    >
                        <img
                            className={cn(
                                'white-bot',
                                styles.agentImage,
                                imageStyles,
                                //activeAgentStyles && !hide && styles.visible,
                            )}
                            src={WhiteBotImagePath}
                        />

                        <img
                            className={cn(
                                'bot',
                                styles.agentImage,
                                imageStyles,
                                styles.visible,
                                //!activeAgentStyles && !hide && styles.visible,
                            )}
                            src={BotImagePath}
                        />
                    </div>
                </WithTooltip>

                <div
                    className={cn(
                        styles.separator,
                        (active || agentHover || modelHover) && styles.activeSeparator,
                    )}
                />

                <WithTooltip tip="Choose agent model.">
                    <div
                        className={cn(styles.knovAgentModel)}
                        onClick={() => setShowMenu(!showMenu)}
                        tabIndex={-1}
                        onMouseDown={e => e.preventDefault()}
                    >
                        <i className="fa fa-ellipsis-v" />
                    </div>
                </WithTooltip>
            </div>

            {showMenu && (
                <div
                    className={cn(
                        styles.knovAgentMenu,
                        menuDirection === 'down' && styles.knovAgentMenuDown,
                    )}
                    style={{
                        maxHeight: `${menuMaxHeight}px`,
                        overflowY: 'auto',
                        WebkitOverflowScrolling: 'touch', // Initial direct style to ensure immediate iOS scroll
                    }}
                    ref={node => {
                        if (node && !menuRef.current && isMobile) {
                            // Capture the ref and immediately make it scrollable on iOS
                            menuRef.current = node

                            // Immediately force iOS to recognize as scrollable
                            requestAnimationFrame(() => {
                                node.style.webkitOverflowScrolling = 'touch'
                                node.scrollTop = 1

                                requestAnimationFrame(() => {
                                    node.focus()
                                    node.scrollTop = 0
                                })
                            })
                        } else {
                            menuRef.current = node
                        }
                    }}
                    onScroll={e => e.stopPropagation()}
                    tabIndex={0}
                >
                    {models.map((model, index) => {
                        const activeModelStyles =
                            active === model || (!active && defaultModel === model)
                                ? styles.activeModel
                                : null
                        return (
                            <div
                                key={index}
                                className={cn(styles.knovAgentMenuItem, activeModelStyles)}
                                onClick={e => {
                                    e.stopPropagation()
                                    setAgentModel(model)
                                    setShowMenu(false)
                                }}
                                tabIndex={-1}
                                onMouseDown={e => e.preventDefault()}
                            >
                                {model}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
