import React, { forwardRef, useEffect, useLayoutEffect, useRef, MutableRefObject } from 'react'
import Quill from 'quill'
import { isMobile, isAndroid } from 'react-device-detect'
import { normalizeExistingDelta } from './quill/quillHelpers'
import { isEqual } from 'lodash'
import styles from './quill-editor.module.scss'
import { getStableText } from './quill/quillHelpers'

// Import Delta directly from Quill
const Delta = Quill.import('delta')

// Supported formats
export const formats = [
    'color',
    'bold',
    'italic',
    'link',
    'mention',
    'header',
    'code',
    'strike',
    'script',
    'underline',
    'blockquote',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'image',
    'video',
]

// Configuration for Quill modules
export const getQuillModulesConfig = (props: any = {}) => {
    const enterPostsImmediatelyEnabled = (window as any)?.gon?.currentUser?.features?.some?.(
        f => f.name === 'enter_key_posts_immediately',
    )

    const config = {
        keyboard: {
            bindings: {
                tab: false,
                enter: {
                    key: 'Enter',
                    shiftKey: true,
                },
            },
        },
        autoLinks: true,
        clipboard: {
            matchVisual: false,
        },
    }

    // Handle enter key posts immediately feature
    if (enterPostsImmediatelyEnabled || props.type === 'search') {
        delete config.keyboard.bindings.enter.shiftKey
    }

    // Handle import case
    if (props.type === 'import') {
        delete config.keyboard.bindings.enter
    }

    return config
}

// Editor is an uncontrolled React component
const QuillEditor = forwardRef<
    Quill,
    {
        defaultValue?: any
        readOnly?: boolean
        placeholder?: string
        onTextChange?: (delta: any, oldContents: any, source: string) => void
        onSelectionChange?: (range: any, oldRange: any, source: string) => void
        onKeyDown?: (quill: Quill, event: KeyboardEvent) => void
        onFocus?: (quill: Quill) => void
        onBlur?: (quill: Quill) => void
        onEditorLoad?: (quill: Quill) => void
        style?: React.CSSProperties
        theme?: string | null
        [key: string]: any
    }
>((props, ref) => {
    const {
        defaultValue,
        readOnly = false,
        placeholder = '',
        onTextChange,
        onSelectionChange,
        onKeyDown,
        onFocus,
        onBlur,
        onEditorLoad,
        style,
        theme = null,
        ...otherProps
    } = props

    const containerRef = useRef<HTMLDivElement>(null)
    const editorRef = useRef<HTMLDivElement | null>(null)
    const defaultValueRef = useRef(defaultValue)
    const onTextChangeRef = useRef(onTextChange)
    const onSelectionChangeRef = useRef(onSelectionChange)
    const onKeyDownRef = useRef(onKeyDown)
    const onFocusRef = useRef(onFocus)
    const onBlurRef = useRef(onBlur)
    const previousDefaultValueRef = useRef(defaultValue)

    // Keep callback refs updated with useLayoutEffect
    useLayoutEffect(() => {
        onTextChangeRef.current = onTextChange
        onSelectionChangeRef.current = onSelectionChange
        onKeyDownRef.current = onKeyDown
        onFocusRef.current = onFocus
        onBlurRef.current = onBlur
    })

    // Enable/disable the editor when readOnly changes
    useEffect(() => {
        if (ref) {
            // Handle both callback and object refs
            const quillInstance =
                typeof ref === 'function'
                    ? null // Can't access a callback ref's current
                    : ref.current

            if (quillInstance) {
                quillInstance.enable(!readOnly)
            }
        }
    }, [ref, readOnly])

    // Watch for changes to defaultValue and update editor content when needed
    useEffect(() => {
        // Skip effect during initial render
        if (defaultValueRef.current === defaultValue) return

        // Get quill instance from ref
        const quillInstance = typeof ref === 'function' ? null : ref.current
        if (!quillInstance) return // Exit if quill instance isn't ready

        const normalizedPrevValue = normalizeExistingDelta(
            previousDefaultValueRef.current || { ops: [] },
        )
        const normalizedNewValue = normalizeExistingDelta(defaultValue || { ops: [] })

        // Get current editor content for comparison
        const currentEditorContent = normalizeExistingDelta(quillInstance.getContents())

        // Only update if there's a meaningful difference between:
        // 1. The new defaultValue and the previous defaultValue AND
        // 2. The new defaultValue and the current actual content in the editor
        const hasChangedFromPrevious = !isEqual(normalizedPrevValue, normalizedNewValue)
        const isDifferentFromCurrent = !isEqual(currentEditorContent, normalizedNewValue)

        // Log comparison results for debugging
        // console.log('[QuillEditor defaultValue useEffect]', {
        //     hasChangedFromPrevious,
        //     isDifferentFromCurrent,
        //     // normalizedNewValue: JSON.stringify(normalizedNewValue),
        //     // currentEditorContent: JSON.stringify(currentEditorContent),
        // });

        if (hasChangedFromPrevious && isDifferentFromCurrent) {
            // console.log('[QuillEditor defaultValue useEffect] - Applying new defaultValue');
            // Store current selection
            const currentSelection = quillInstance.getSelection()

            // Update content
            quillInstance.setContents(normalizedNewValue) // Source: 'api' implicitly

            // Restore selection if it existed
            if (currentSelection) {
                setTimeout(() => {
                    // Avoid setting selection if editor no longer has focus
                    if (quillInstance.hasFocus()) {
                        quillInstance.setSelection(currentSelection)
                    }
                }, 0)
            }
        } else {
            // console.log('[QuillEditor defaultValue useEffect] - Skipping update, content matches current or previous');
        }
        // Update the reference to previous value regardless of update,
        // to correctly track subsequent changes.
        previousDefaultValueRef.current = defaultValue
    }, [defaultValue, ref])

    // Initialize Quill on mount
    useEffect(() => {
        const container = containerRef.current
        if (!container) return

        // Create editor container dynamically
        const editorContainer = container.appendChild(container.ownerDocument.createElement('div'))

        // Store a reference to the editor container
        editorRef.current = editorContainer

        // Apply any styles passed to the component
        if (style) {
            Object.assign(editorContainer.style, style)
        }

        editorContainer.className = 'quill-editor-content'

        // Configure Quill
        const editorModules = getQuillModulesConfig(props)

        // Create the Quill instance
        const quill = new Quill(editorContainer, {
            theme: theme,
            placeholder: readOnly ? '' : placeholder,
            readOnly: readOnly,
            modules: editorModules,
            formats: formats,
            // Add bounds property for mobile to improve positioning
            bounds: container,
        })

        // Expose the Quill instance directly through ref
        if (typeof ref === 'function') {
            ref(quill)
        } else if (ref) {
            ;(ref as MutableRefObject<Quill>).current = quill
        }

        // Set initial content if defaultValue is provided
        if (defaultValueRef.current) {
            const normalizedValue = normalizeExistingDelta(defaultValueRef.current)
            quill.setContents(normalizedValue)
        }

        // Notify parent that editor is loaded
        if (onEditorLoad) {
            onEditorLoad(quill)
        }

        // Set up event handlers
        quill.on(Quill.events.TEXT_CHANGE, (...args) => {
            onTextChangeRef.current?.(...args)
        })

        quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
            onSelectionChangeRef.current?.(...args)
        })

        // Handle key events
        const handleKeyDown = e => {
            if (onKeyDownRef.current) {
                onKeyDownRef.current(quill, e)
            }
        }
        quill.root.addEventListener('keydown', handleKeyDown)

        // Add Android-specific input handler
        let lastInputWasAt = false
        let handleAndroidInput

        if (isAndroid) {
            // Use the input event which fires after each character is committed
            handleAndroidInput = () => {
                const selection = quill.getSelection()
                if (!selection) return

                const text = getStableText(quill)
                const pos = selection.index

                // Check if we just typed @ (flagged by previous keydown)
                if (lastInputWasAt) {
                    // Reset flag after handling
                    lastInputWasAt = false

                    // Force a text-change event to ensure proper handling
                    // A no-op change that causes the editor to refresh
                    const delta = new Delta().retain(quill.getLength())
                    quill.updateContents(delta, 'user')
                    // Set selection pos + 1 bc the character is already inserted, IME just doesnt know about it.
                    quill.setSelection(pos + 1, 0, 'user')
                }

                //console.log('lastInputWasAt condition', text, pos, `'${text[pos - 1]}'`)
                // Check if this input is an @ character
                if (pos > 0 && text[pos - 1] === '@') {
                    // Set flag for next input event
                    lastInputWasAt = true
                    //console.log('lastInputWasAt ' + lastInputWasAt + "'")
                }
            }

            // Add the input handler which fires after text is committed to the editor
            quill.root.addEventListener('input', handleAndroidInput)
        }

        // Improved focus/blur handlers
        const handleFocus = () => {
            if (onFocusRef.current) {
                requestAnimationFrame(() => {
                    onFocusRef.current(quill)
                })
            }
        }

        const handleBlur = () => {
            if (onBlurRef.current) {
                requestAnimationFrame(() => {
                    onBlurRef.current(quill)
                })
            }
        }

        // Add proper event listeners
        quill.root.addEventListener('focus', handleFocus)
        quill.root.addEventListener('blur', handleBlur)

        // Clean up on unmount
        return () => {
            // Remove all event listeners
            quill.root.removeEventListener('focus', handleFocus)
            quill.root.removeEventListener('blur', handleBlur)
            quill.root.removeEventListener('keydown', handleKeyDown)

            // Remove Android-specific handler if it exists
            if (isAndroid && handleAndroidInput) {
                quill.root.removeEventListener('input', handleAndroidInput)
            }

            // Clear the ref and container
            if (typeof ref === 'function') {
                ref(null)
            } else if (ref) {
                ;(ref as MutableRefObject<Quill>).current = null
            }

            // Clean up DOM
            if (container) {
                container.innerHTML = ''
            }

            // Clear refs
            editorRef.current = null
        }
    }, [ref, readOnly]) // Include readOnly in dependencies to properly reinitialize on changes

    return (
        <div
            ref={containerRef}
            className={`quill-container ${styles.quillContainer}`}
            {...otherProps}
        />
    )
})

// Add displayName for better debugging
QuillEditor.displayName = 'QuillEditor'

export default QuillEditor
