import React from 'react'
import cn from 'classnames'
import styles from './floating-filter-icon.module.scss'
import FilterIcon from 'components/filters/FilterIcon'
import UserIcon from 'components/users/UserIcon'
import { PanelState } from 'state/PanelState'
import { getCachedQuest } from 'state/cache'

interface FloatingFilterIconProps {
    panel: PanelState
    onScrollToTop: () => void
}

export default function FloatingFilterIcon({ panel, onScrollToTop }: FloatingFilterIconProps) {
    const quest = panel.filter?.questId ? getCachedQuest(panel.filter.questId) : null
    const parentUser = quest?.parent?.user

    return (
        <div
            className={cn(styles.floatingFilterComp, !parentUser && styles.singleIcon)}
            onClick={onScrollToTop}
        >
            <FilterIcon
                disable={true}
                filter={panel.filter}
                panel={panel}
                user={gon?.currentUser}
                contextStyles={cn(styles.filterIcon, !parentUser && styles.filterIconSingle)}
                fontIcon={styles.fontIcon}
                privateIcon={styles.privateIcon}
                notiIcon={styles.notiIcon}
                publicIcon={styles.publicIcon}
                allIcon={styles.allIcon}
                historyIcon={styles.historyIcon}
                hodlockerIcon={styles.hodlockerIcon}
                twetchIcon={styles.twetchIcon}
                type="control"
                showNumNoti
            />
            {parentUser && (
                <UserIcon user={parentUser} contextStyles={styles.userIcon} size="small" />
            )}
        </div>
    )
}
