import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import api from 'api/api'
import styles from './wallet-history.module.scss'
import cn from 'classnames'
import SatsIcon from '../shared/SatsIcon'
import { UserStream } from 'components/filters/FilterIcon'
import useStreamFilters from 'components/filters/useStreamFilters'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import { motion, AnimatePresence } from 'framer-motion'

const Amount = ({ amount, contextStyles }) => {
    const formatAmount = num => {
        return Math.abs(num)
            .toString()
            .split('')
            .reverse()
            .reduce((acc, digit, index) => {
                // Add span with margin every 3 digits, except at the start
                if (index > 0 && index % 3 === 0) {
                    return [...acc, <span key={index} className={styles.digitSeparator} />, digit]
                }
                return [...acc, digit]
            }, [])
            .reverse()
    }

    return <span className={contextStyles}>{formatAmount(amount)}</span>
}

const WalletTabs = ({ activeTab, setActiveTab }) => {
    return (
        <div className={styles.tabs}>
            <button
                className={cn(styles.tab, styles.allTab, activeTab === 'all' && styles.active)}
                onClick={() => setActiveTab('all')}
            >
                All
            </button>
            <button
                className={cn(
                    styles.tab,
                    styles.receivedTab,
                    activeTab === 'received' && styles.active,
                )}
                onClick={() => setActiveTab('received')}
            >
                Received
            </button>
            <button
                className={cn(styles.tab, styles.sentTab, activeTab === 'sent' && styles.active)}
                onClick={() => setActiveTab('sent')}
            >
                Given
            </button>
            <button
                className={cn(styles.tab, styles.boostTab, activeTab === 'boost' && styles.active)}
                onClick={() => setActiveTab('boost')}
            >
                Boosted
            </button>
        </div>
    )
}

const WalletTotal = ({ total, activeTab }) => {
    return (
        <div
            className={cn(styles.total, {
                [styles.allTotal]: activeTab === 'all' && total === 0,
                [styles.receivedTotal]:
                    (activeTab === 'all' && total > 0) || activeTab === 'received',
                [styles.sentTotal]: (activeTab === 'all' && total < 0) || activeTab === 'sent',
                [styles.boostTotal]: activeTab === 'boost',
            })}
        >
            <span className={styles.totalLabel}>
                {activeTab === 'all'
                    ? total === 0
                        ? 'Net'
                        : total > 0
                        ? 'Net Creator'
                        : 'Net Giver'
                    : activeTab === 'received'
                    ? 'Total Received'
                    : activeTab === 'boost'
                    ? 'Total Boosted'
                    : 'Total Given'}
            </span>
            <div className={styles.totalAmount}>
                <SatsIcon
                    showBars={true}
                    contextStyles={cn(styles.satsIcon, styles.totalSatsIcon, {
                        [styles.sentSatsIcon]:
                            (activeTab === 'all' && total < 0) || activeTab === 'sent',
                        [styles.receivedSatsIcon]:
                            (activeTab === 'all' && total > 0) || activeTab === 'received',
                        [styles.boostSatsIcon]: activeTab === 'boost',
                        [styles.allSatsIcon]: activeTab === 'all' && total === 0,
                    })}
                />
                <Amount amount={total} contextStyles={undefined} />
            </div>
        </div>
    )
}

const Time = ({ date }) => {
    const getTimeAgo = date => {
        const seconds = Math.floor((new Date() - new Date(date)) / 1000)

        const intervals = [
            { label: 'y', seconds: 31536000 },
            { label: 'w', seconds: 604800 },
            { label: 'd', seconds: 86400 },
            { label: 'h', seconds: 3600 },
            { label: 'm', seconds: 60 },
            { label: 's', seconds: 1 },
        ]

        for (let interval of intervals) {
            const value = Math.floor(seconds / interval.seconds)
            if (value >= 1) {
                return `${value}${interval.label}`
            }
        }

        return 'now'
    }

    return <span>{getTimeAgo(date)}</span>
}

// Add this helper function to determine the time group
const getTimeGroup = date => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000)

    if (seconds < 86400) return 'today' // < 1 day
    if (seconds < 604800) return 'this_week' // < 1 week
    if (seconds < 2592000) return 'this_month' // < 1 month
    if (seconds < 31536000) return 'this_year' // < 1 year
    return 'older'
}

// Add this component for the group label
const TimeGroupLabel = ({ group }) => {
    const labels = {
        today: 'Today',
        this_week: 'This Week',
        this_month: 'This Month',
        this_year: 'This Year',
        older: 'Older',
    }

    return <div className={styles.timeGroupLabel}>{labels[group]}</div>
}

function WalletHistory() {
    const currentUser = gon?.currentUser
    const [activeTab, setActiveTab] = useState('all')
    const streamFilters = useStreamFilters(undefined, { append: true })

    const { data: history = [], isLoading: historyLoading } = useQuery({
        queryKey: ['bsv-upvalue-history', currentUser?.id],
        queryFn: async () => {
            if (!currentUser?.id) return []
            const upvalues = await api.getBsvUpvalueHistory(currentUser.id)
            return upvalues || []
        },
        enabled: !!currentUser?.id,
    })

    const { data: totalData, isLoading: totalLoading } = useQuery({
        queryKey: ['bsv-upvalue-total', currentUser?.id],
        queryFn: async () => {
            if (!currentUser?.id) return { total: 0, received: 0, given: 0 }
            const result = await api.getBsvUpvalueTotal(currentUser.id)
            return result || { total: 0, received: 0, given: 0 }
        },
        enabled: !!currentUser?.id,
    })

    const handleItemClick = item => {
        if (item.answer?.child_quests?.[0]?.id) {
            streamFilters.selectNewFilter({ questId: item.answer.child_quests[0].id })
        }
    }

    const getTotal = () => {
        if (!totalData) return 0

        let result = 0
        if (activeTab === 'all') result = totalData.received - totalData.given - totalData.boost
        else if (activeTab === 'boost') result = totalData.boost
        else if (activeTab === 'received') result = totalData.received
        else if (activeTab === 'sent') result = totalData.given

        return result
    }

    const filteredHistory = history.filter(item => {
        if (activeTab === 'boost') return item.is_boost
        if (activeTab === 'sent')
            return (
                currentUser.id === item.from_user_id &&
                currentUser.id !== item.to_user_id &&
                !item.is_boost
            )
        if (activeTab === 'received')
            return (
                currentUser.id !== item.from_user_id &&
                currentUser.id === item.to_user_id &&
                !item.is_boost
            )
        return true // 'all' tab
    })

    if (!history.length) {
        return (
            <div className={styles.walletHistory}>
                <h3 className={styles.title}>Wallet History</h3>
                <div className={styles.empty}>No upvalues yet</div>
            </div>
        )
    }

    const total = getTotal()

    return (
        <div className={styles.walletHistory}>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>Upvalues</div>
                </div>
                <WalletTotal total={total} activeTab={activeTab} />
                <WalletTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>

            <div className={styles.historyList}>
                <AnimatePresence initial={false}>
                    {Object.entries(
                        filteredHistory.reduce((groups, item) => {
                            const group = getTimeGroup(item.created_at)
                            if (!groups[group]) groups[group] = []
                            groups[group].push(item)
                            return groups
                        }, {}),
                    ).map(([group, items]) => (
                        <motion.div
                            key={group}
                            className={styles.timeGroup}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 0.3 }}
                        >
                            <TimeGroupLabel group={group} />
                            <AnimatePresence mode="popLayout">
                                {items.map(item => {
                                    const transactionType =
                                        currentUser.id === item.from_user_id ? 'Sent' : 'Received'
                                    const otherUser =
                                        transactionType === 'Sent' ? item.to_user : item.from_user

                                    return (
                                        <motion.div
                                            key={item.id}
                                            layout
                                            className={cn(styles.historyItem, {
                                                [styles.sent]:
                                                    transactionType === 'Sent' && !item.is_boost,
                                                [styles.received]:
                                                    transactionType === 'Received' &&
                                                    !item.is_boost,
                                                [styles.boost]: item.is_boost,
                                            })}
                                            onClick={() => handleItemClick(item)}
                                            initial={{ opacity: 0, y: -20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: 20 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <div className={styles.timestamp}>
                                                <Time date={item.created_at} />
                                            </div>
                                            <div
                                                className={cn(styles.amount, {
                                                    [styles.sent]:
                                                        transactionType === 'Sent' &&
                                                        !item.is_boost,
                                                    [styles.received]:
                                                        transactionType === 'Received' &&
                                                        !item.is_boost,
                                                    [styles.boost]: item.is_boost,
                                                })}
                                            >
                                                <SatsIcon
                                                    showBars={true}
                                                    contextStyles={cn(styles.satsIcon, {
                                                        [styles.sentSatsIcon]:
                                                            transactionType === 'Sent' &&
                                                            !item.is_boost,
                                                        [styles.receivedSatsIcon]:
                                                            transactionType === 'Received' &&
                                                            !item.is_boost,
                                                        [styles.boostSatsIcon]: item.is_boost,
                                                    })}
                                                />
                                                <Amount
                                                    amount={Math.floor(
                                                        item.is_boost
                                                            ? item.fee
                                                            : item.amount - (item.fee || 0),
                                                    )}
                                                    contextStyles={undefined}
                                                />
                                            </div>
                                            {otherUser && (
                                                <div className={styles.userInfo}>
                                                    <span className={styles.userLabel}>
                                                        {transactionType === 'Sent' ? 'TO' : 'FROM'}
                                                    </span>
                                                    <UserStream
                                                        user={otherUser}
                                                        contextStyles={styles.userIcon}
                                                        disable={false}
                                                        size={'tiny'}
                                                    />
                                                    <span className={styles.username}>
                                                        {otherUser.name}
                                                    </span>
                                                </div>
                                            )}
                                        </motion.div>
                                    )
                                })}
                            </AnimatePresence>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </div>
        </div>
    )
}

export default function WalletHistoryWrapper() {
    return (
        <ErrorBoundary label="WalletHistory">
            <WalletHistory />
        </ErrorBoundary>
    )
}
