import React, { useState } from 'react'
import cn from 'classnames'
import styles from './user-icon.module.scss'
import Gravatar from 'react-gravatar'
import Bot from 'assets/knov.svg'
import SpaceIcon from 'components/spaces/SpaceIcon'

export default function UserIcon(props, ref) {
    const { user, showKnovigator, virtualUserIcon } = props
    const clickableStyles = props.clickable ? styles.clickable : null
    const [hasAvatarError, setHasAvatarError] = useState(false)

    // Determine icon size based on space options layout
    const size = props.size || (gon?.currentUser?.space_options?.layout === 'full' ? '40' : '32')

    let userPic

    if (showKnovigator) {
        userPic = (
            <div className="user-icon">
                <SpaceIcon contextStyles={styles.spaceIcon} />
            </div>
        )
    } else if (props.agentIcon) {
        userPic = (
            <div className={cn(styles.userIconComp, props.contextStyles)}>
                <img src={Bot} />
            </div>
        )
    } else if (virtualUserIcon) {
        userPic = (
            <div className={cn(styles.userIconComp, props.contextStyles)}>
                <img src={virtualUserIcon} />
            </div>
        )
    } else if (user) {
        let queryString = ''
        if (props.updatedAt) {
            queryString = `?updated_at=${props.updatedAt}&break_cache=true`
        }
        if (props.size) {
            queryString = queryString ? `${queryString}&size=${props.size}` : `?size=${props.size}`
        }
        if (hasAvatarError) {
            queryString = queryString ? `${queryString}&break_cache=true` : `?break_cache=true`
        }

        const avatarUrl = `${window.knovApiUrl}/api/v1/users/${user.id}/avatar${queryString}`
        userPic = (
            <div className={cn(styles.userIconComp, props.contextStyles)}>
                <img
                    key={avatarUrl}
                    src={avatarUrl}
                    className={styles.userImage}
                    onError={() => {
                        setHasAvatarError(true)
                    }}
                />
            </div>
        )
    } else if (user?.md5_email) {
        userPic = (
            <div className={cn(styles.userIconComp, clickableStyles, props.contextStyles)}>
                <Gravatar md5={user.md5_email} />
            </div>
        )
    } else {
        userPic = <div className={cn(props.contextStyles, styles.userPlaceholder)} />
    }

    return <div className="user-icon-comp">{userPic}</div>
}
