import React from 'react'
import UserIcon from 'components/users/UserIcon'
import UserName from 'components/users/UserName'
import CommonEditor from 'components/shared/CommonEditor'
import VideoButton from 'components/answers/AnswerControls/VideoButton'
import MobileVideoButton from 'components/answers/AnswerControls/MobileVideoButton'
import RecordScreenButton from 'components/answers/AnswerControls/RecordScreenButton'
import AddImageButton from 'components/answers/AnswerControls/AddImageButton'
import AddFileButton from 'components/answers/AnswerControls/AddFileButton'
import FileEmbed from './FileEmbed'
import Connector from '../state/Connector'
import PostEmbed from './PostEmbed'
import api from '../../api/api'
import { LinkPreview } from 'components/shared/LinkPreview'
import { getText, getImages } from '../../lib/value'
import { isMobile } from 'react-device-detect'
import answerModel from 'components/answers/answerModel'
import styles from './new-answer.module.scss'
import cn from 'classnames'
import * as cache from 'state/cache'
import { fileHandler } from 'components/answers/uploader'
import { debugCopy } from 'utils'
import { isEqual, debounce, toNumber } from 'lodash'
import KnovAgentButtonUIMain, { defaultModel } from '../quests/KnovAgentButtonUIMain'
import AiMagicWandMenuButton from '../quests/AiMagicWandMenuButton'
import { cacheQuest, cacheAnswer } from 'state/cache'
import useGetAnswer from 'refactor/hooks/api/useGetAnswer'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import { isPanelLeft, isPanelCenter, isPanelRight } from 'state/imperativeApis/swiperApi'
import usePanelContext from 'refactor/hooks/usePanelContext'
import StreamIcon from '../quests/StreamIcon'
import questModel from 'components/quests/questModel'
import { v4 as uuid } from 'uuid'
import useInsertRight from 'refactor/hooks/useInsertRight'
import useStore from 'state/knovStore'
import { initWallet } from 'wallets/wallets'
import { createTxSubscription } from 'state/channels/useUserChannel'
import Quill from 'quill'
import { defer } from 'lodash'
import Bot from 'assets/knov-answer.svg'
import { confirmAsync } from 'components/shared/confirmUtils'
import { generateFingerprint } from '../../utils'

const Delta = Quill.import('delta')

const stateSelector = (state, props) => ({
    // Override answer from cache.
    //newAnswer: props.newAnswer,
    activeSpaceId: state.activeSpaceId,
    globalAgentModel: state.questAgentModels?.[props.quest?.id],
    knovBotPendingStatus: state.knovBotPendingStatus?.[props.quest?.id],
    set: state.set,
    DEBUG: state.DEBUG,
    currentUserBsvWalletType: state.currentUserBsvWalletType,
})

const INTERRUPT_POSTING = Symbol('INTERRUPT_POST') // sentinel value for interrupting post action

class NewAnswer extends React.PureComponent {
    constructor(props) {
        super(props)

        this.CHAR_LIMIT = 280
        this.ORIG_COLOR = 'rgb(102, 102, 102)'
        this.LIMIT_COLOR = 'red'

        // debounced version of the save method to prevent excessive saving
        this.debouncedSaveDraft = debounce(this.saveDraftToLocalStorage, 500)
        this.state = {}
    }

    componentWillUnmount = () => {
        // cancel any pending debounced saves on unmount
        if (this.debouncedSaveDraft && this.debouncedSaveDraft.cancel) {
            this.debouncedSaveDraft.cancel()
        }
        this.clearTxSubscription()
    }

    clearTxSubscription = () => {
        if (this.txUnsubscribe) {
            this.txUnsubscribe()
            this.txUnsubscribe = null
        }
    }

    hasFiles = () => !!this.props.newAnswer?.file_urls?.length
    hasVideo = () => !!this.props.newAnswer?.recording_url
    hasImage = deltaJson => !!this.props.newAnswer?.answer_image_url || getImages(deltaJson)

    getNewAnswer = () => {
        const now = new Date()

        // Set flag to trim whitespace during submission
        this._preparingForSubmission = true
        const content = this.getContent()
        const deltaJson = this.getDeltaJson()
        this._preparingForSubmission = false

        return {
            ...this.props.newAnswer,
            is_draft: false,
            space_id: this.props.activeSpaceId,
            content,
            delta_json: deltaJson,
            created_at: now,
            updated_at: now,
            position: new Date().getTime(),
            isLocal: true,
            ...(this.props.globalAgentModel ? { agent_model: this.props.globalAgentModel } : {}),
        }
    }

    postAnswer = async (skipValidation = false) => {
        if (!this.ensureCurrentUser()) return

        const quest = this.props.quest
        // TODO is this getting the local child_quest? for selected answers?
        const newAnswer = {
            ...this.getNewAnswer(),
        }

        if (answerModel.hasContent(newAnswer) || skipValidation) {
            // remove the draft from local storage since we're posting the answer
            this.clearDraftFromLocalStorage()

            cacheAnswer(newAnswer)
            let draftQuest
            if (quest.is_draft) {
                draftQuest = {
                    ...quest,
                    is_draft: false, // This publishes the quest in the panel and make react-query update from server on next useGetQuest().
                    created_at: newAnswer.created_at,
                    updated_at: newAnswer.updated_at,
                    parent: newAnswer,
                    new_answer: null,
                    new_answer_id: null,
                    isLocalRootQuest: true,
                }
                cacheQuest(draftQuest)
                this.props.focusNewAnswer()
                history.pushState({}, null, quest.path)

                // TODO useMutate?
                const apiQuest = await api.createQuest(draftQuest)
                logEv('POST_ROOT')

                // Ensure the agent status is preserved on the server
                if (this.props.globalAgentModel) {
                    // Set the agent model for the new quest ID
                    const questId = apiQuest?.id
                    if (questId) {
                        const newQuestAgentModels = {
                            ...useStore.getState().questAgentModels,
                            [questId]: this.props.globalAgentModel,
                        }

                        // Update server-side
                        api.updateUserSpaceOptions({
                            quest_agent_models: newQuestAgentModels,
                        })

                        // Update local state
                        this.props.set({
                            questAgentModels: newQuestAgentModels,
                        })
                    }
                }

                // We have to do this one here bc we can't rely on onMount in QuestContainer since we mount a local is_draft queest and it doesn't exist yet on the backend so the view action call will 404.
                apiQuest && (await api.userViewsQuest(apiQuest.id))
                const actionsQueryKey = ['actions']
                queryClient.invalidateQueries({ queryKey: actionsQueryKey })
                queryClient.refetchQueries({ queryKey: actionsQueryKey }, { exact: true })
            } else {
                draftQuest = {
                    ...quest,
                    updated_at: newAnswer.updated_at,
                    sorted_answer_ids: [...(quest.sorted_answer_ids || []), newAnswer.id],
                    sorted_answers: [...(quest.sorted_answers || []), newAnswer],
                    new_answer: null,
                    new_answer_id: null,
                    isLocalChildQuest: true,
                }
                // Make sure newQuest is cached first bc it has newAnswers with child_quests.
                // newAnswer.child_quests is needed for instant local branching until server roundtrip.
                // skip caching parent to avoid clobbering it's lock amt with newAnswer's (empty) lock amt.
                cacheQuest(draftQuest, { skipParent: true })
                // addAnswer handles quest answer collapse.
                this.props.addAnswer(newAnswer)

                if (newAnswer?.id) {
                    api.createAnswer(newAnswer)
                    logEv('POST_ANSWER')
                }

                if (answerModel.hasLLMRequest(newAnswer) && !questModel.hasLLMReply(quest)) {
                    const rightPanelId = uuid()
                    const childQuestId = newAnswer.child_quests[0].id

                    // If this is an AI quest, set up the agent model for the child quest
                    if (this.props.globalAgentModel) {
                        this.props.set(state => {
                            //console.log('setting questAgentModels', state.questAgentModels)
                            delete state.questAgentModels[quest.id]
                            state.questAgentModels[childQuestId] = this.props.globalAgentModel
                            state.selectedAnswers[rightPanelId] = {
                                answerId: newAnswer.id,
                            }

                            // Update user space options on the server
                            api.updateUserSpaceOptions({
                                quest_agent_models: state.questAgentModels,
                            })
                        })

                        // Move insertRight outside of set callback
                        //console.log('inserting right panel')
                        this.props.insertRight({
                            panelId: rightPanelId,
                            filter: { questId: childQuestId, answerId: newAnswer.id },
                        })
                    }
                }
            }

            // Dont clear the editor if title to prevent flicker. Title will re-render another new answer comp.
            if (!this.props.isTitle && this.richEditor.current) {
                const editor = this.richEditor.current.getEditor()

                // Instead of completely clearing, we need to preserve model mention if agent model is enabled
                if (this.props.globalAgentModel && questModel.hasLLMReply(quest)) {
                    // Create a model mention delta
                    const modelMention = {
                        id: this.props.globalAgentModel,
                        value: this.props.globalAgentModel,
                        denotationChar: '@@',
                        type: 'model',
                        link: `/models/${this.props.globalAgentModel}`,
                        content: this.props.globalAgentModel,
                    }

                    const newDelta = new Delta().insert({ mention: modelMention }).insert(' ') // Add space after mention

                    // Update the editor content using setContents with USER source
                    editor.setContents(newDelta, Quill.sources.USER)

                    // Use defer to ensure proper timing of selection
                    defer(() => {
                        // Position cursor after mention and space
                        editor.setSelection(2, 0, Quill.sources.USER)
                        editor.focus()
                    })

                    // Update our state to reflect empty linkPreviews
                    this.setState({
                        linkPreviews: [],
                    })
                } else {
                    // Clear editor if no agent
                    editor.setText('')

                    // Update our state to reflect empty linkPreviews
                    this.setState({
                        linkPreviews: [],
                    })
                }
            }
        }
    }

    getEditor = () => this.richEditor.current?.getEditor()

    getDeltaJson = () => {
        const contents = this.getEditor()?.getContents()
        if (!contents) return contents

        // Only remove trailing whitespace when specifically preparing for submission
        if (this._preparingForSubmission) {
            const ops = contents.ops
            if (ops && ops.length > 0) {
                const lastOp = ops[ops.length - 1]
                if (typeof lastOp.insert === 'string') {
                    lastOp.insert = lastOp.insert.trimEnd()
                    // Remove the last operation if it's now empty
                    if (lastOp.insert === '') {
                        ops.pop()
                    }
                }
            }
        }
        return { ops: contents.ops }
    }

    /**
     * Extracts text content from the delta JSON, with optional username wrapping.
     * @param {Object} [options] - Optional configuration.
     * @param {boolean} [options.wrapUsernames=false] - Whether to wrap usernames in square brackets.
     * @returns {string} The extracted text content.
     */
    getContent = (options = {}) => getText(this.getDeltaJson() || {}, options).trim()

    ensureCurrentUser = () => {
        if (gon.currentUser) {
            return true
        } else {
            alert('Please login first!')
            // $('#auth-modal').modal('show')
            return false
        }
    }

    processMentions = async delta => {
        const mentions = delta.ops.reduce(
            (acc, cur) => (cur.insert?.mention?.value ? [...acc, cur.insert.mention?.value] : acc),
            [],
        )

        const teamId = this?.props?.quest?.team?.id
        let team
        if (teamId) team = await api.getTeam(teamId)

        if (team) {
            const toAdd = []
            mentions.forEach(mentionedName => {
                if (!team?.users?.find(u => u?.name === mentionedName)) toAdd.push(mentionedName)
            })

            if (toAdd?.length > 0) {
                let msg =
                    toAdd?.length == 1
                        ? `@${toAdd[0]} is not part of stream '${team?.name}'. Would you like to add them?\n`
                        : `The following users aren't part of stream '${
                              team?.name
                          }'. Would you like to add them?\n\n${toAdd.map(u => `- @${u}`).join('\n')}
                        `

                msg += "\nPress 'OK' to add, or 'Cancel' to message only."

                const inviteUsers = await confirmAsync(msg)
                if (inviteUsers) api.addToTeam({ names: toAdd }, teamId)
            }
        }

        return mentions
    }

    onPost = async () => {
        if (!this.ensureCurrentUser()) return

        // Immediately focus the editor to prevent keyboard dismissal on mobile
        if (isMobile && this.richEditor.current) {
            const editor = this.richEditor.current.getEditor()
            editor.focus()
        }

        if (
            this?.props?.quest?.public &&
            window.gon?.currentUser?.features?.some?.(
                f => f.name === 'confirm_before_posting_publicly',
            )
        ) {
            if (!(await confirmAsync('Post this message publicly?'))) {
                return
            }
        }

        const content = this.getContent()
        const delimitedContent = this.getContent({ wrapUsernames: true })

        // Set flag to trim whitespace during submission
        this._preparingForSubmission = true
        const deltaJson = this.getDeltaJson()
        this._preparingForSubmission = false

        // TODO this errors out if user is not a stream admin, need to fix.
        //this.processMentions(deltaJson)
        this.handleClientSideActionTags(delimitedContent, deltaJson).then(result => {
            if (result !== INTERRUPT_POSTING) {
                this.postAnswer()
            }
        })
    }

    handleClientSideActionTags = async (delimitedContent, deltaJson) => {
        const actionTagPresentRegex = /!([A-Za-z_-]+)/
        const match = delimitedContent.match(actionTagPresentRegex)
        if (!match) return // No action tag, proceed with post

        const [_, actionTag] = match

        // action tag handlers can return the INTERRUPT_POSTING const to interrupt the posting process
        // any other return value will allow posting to proceed
        const clientSideActionTags = {
            pay: async (content, _deltaJson) => {
                // NOTE: this relies on the wrapUsernames: true flag being set in the content that's passed in
                // so that the usernames get surrounded with [] (eg @[username]) to allow for usernames with spaces
                // or special chars
                const payActionTagRegex =
                    /!pay\s*@\[([^\]]+)\]\s*([\d|.,]+)\s*(sat|sats|satoshi|satoshis|btc|bitcoin|bsv|bitcoinsv)?/gi
                const matches = [...content.matchAll(payActionTagRegex)]

                if (matches.length === 0) {
                    alert(
                        `It looks like you're trying to use the !pay action tag, but something's not quite right. \n\n` +
                            `You can use !pay by doing:\n\n!pay @username amount [unit] \n\n` +
                            `The unit is optional, and should be something like sats/satoshis or bsv/btc.` +
                            `We'll assume sats if you don't specify a unit.`,
                    )
                    return INTERRUPT_POSTING
                }

                const payments = await Promise.all(
                    matches.map(async ([_, mentionedUser, strAmount, unit]) => {
                        const amount = toNumber(strAmount.replace(/,/g, ''))
                        const amountInSats = (() => {
                            const lowerUnit = unit ? unit.toLowerCase() : 'sats'
                            const bitcoinUnits = ['btc', 'bitcoin', 'bsv', 'bitcoinsv']
                            if (bitcoinUnits.includes(lowerUnit)) {
                                return Math.floor(amount * 100000000)
                            }
                            return amount
                        })()

                        const { address } = await api.getBsvAddressForUser(mentionedUser)

                        const btcAmount = amountInSats / 100000000
                        const btcString =
                            btcAmount >= 0.001
                                ? btcAmount.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 8,
                                  })
                                : btcAmount.toFixed(8).replace(/\.?0+$/, '')

                        return { mentionedUser, address, amountInSats, btcString }
                    }),
                )

                const confirmationMessage = `Send the following payments?\n\n${payments
                    .map(
                        ({ mentionedUser, amountInSats, btcString }) =>
                            `@${mentionedUser}: ${btcString} BSV (${amountInSats.toLocaleString()} sats)`,
                    )
                    .join('\n')}`

                const shouldSendFunds = await confirmAsync(confirmationMessage)
                if (!shouldSendFunds) {
                    return INTERRUPT_POSTING
                }

                try {
                    const wallet = initWallet(this.props.currentUserBsvWalletType)

                    // Set up transaction status subscription
                    const unsubscribe = createTxSubscription(
                        txId => {
                            alert(`Transaction ${txId} sent.`)
                            // Clean up subscription
                            if (unsubscribe) unsubscribe()
                        },
                        errorMessage => {
                            alert(`Error sending transaction: ${errorMessage}`)
                            // Clean up subscription
                            if (unsubscribe) unsubscribe()
                        },
                    )

                    // Store the unsubscribe function temporarily on the component instance
                    // so we can clean it up later if needed
                    this.txUnsubscribe = unsubscribe

                    // Set a timeout to clean up the subscription after 2 minutes
                    // in case we don't receive any response
                    setTimeout(() => {
                        if (this.txUnsubscribe) {
                            this.txUnsubscribe()
                            this.txUnsubscribe = null
                        }
                    }, 2 * 60 * 1000)

                    // Extract usernames for toTreechatUsers parameter
                    const treechatUsers = payments.map(p => p.mentionedUser)

                    // Send all transactions
                    if (payments.length === 1) {
                        // If only one payment, send directly with the username
                        await wallet.sendBsv(
                            payments[0].address,
                            payments[0].amountInSats,
                            undefined,
                            treechatUsers,
                        )
                    } else {
                        // If multiple payments, use the first payment as the primary and rest as additional
                        const [firstPayment, ...additionalPayments] = payments
                        const finalAdditionalPayments = additionalPayments.map(p => ({
                            amount: p.amountInSats,
                            addr: p.address,
                        }))
                        //console.log('>>> new answer final payments: ', finalAdditionalPayments)
                        await wallet.sendBsv(
                            firstPayment.address,
                            firstPayment.amountInSats,
                            finalAdditionalPayments,
                            treechatUsers,
                        )
                    }

                    return
                } catch (error) {
                    //console.error('Error sending BSV:', error)
                    alert(`Error sending transaction: ${error.message}`)

                    // Clean up subscription if it exists
                    if (this.txUnsubscribe) {
                        this.txUnsubscribe()
                        this.txUnsubscribe = null
                    }

                    return INTERRUPT_POSTING
                }
            },
        }

        if (clientSideActionTags[actionTag]) {
            return clientSideActionTags[actionTag](delimitedContent, deltaJson)
        }
        // no handler for this action tag, proceed with normal posting process
        return
    }

    postOnVideo = async videoType => {
        let content, deltaJson
        if (this.richEditor.current) {
            content = this.getContent().trim()
            deltaJson = this.getDeltaJson()
        }
        if (!content) {
            content = `!${videoType}`
            deltaJson = { ops: [{ insert: content, attributes: { bold: true } }] }
        }
    }

    captureSetValue = setValue => {
        // We want to set value of Common Editor when post button is pressed so need to grab it from inside the functional comp.
        this.setEditorValue = setValue
    }

    onChange = (value, text) => {
        if (!gon.currentUser) {
            $('#auth-modal').modal('show')
            return
        }

        // Check if the content is empty to decide whether to save or clear draft
        const content = this.getContent()

        if (!content || content.trim().length === 0) {
            // If empty, clear the draft from local storage
            this.clearDraftFromLocalStorage()
        } else {
            // They added content, so save it to localstorage
            this.debouncedSaveDraft()
        }
    }

    tmpAnswerId = () => `tmp-id-new-answer-for-quest-${this.props.quest.id}`

    richEditor = React.createRef()

    focusEditor = () => {
        if (this.richEditor.current) {
            setTimeout(() => this.richEditor?.current?.focus())
        }
    }

    onMouseEnter = () => {
        this.setState({ hover: true })
    }

    onMouseLeave = () => {
        this.setState({ hover: false })
    }

    imgInputRef = React.createRef()
    imgPreviewRef = React.createRef()
    videoInputRef = React.createRef()
    videoPreviewRef = React.createRef()
    fileInputRef = React.createRef()

    fileHandler = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        const files = ev.target.files
        fileHandler(files?.[0], this.props.newAnswer.id)
    }

    videoHandler = video => {
        if (video) fileHandler(video, this.props.newAnswer.id)
    }

    removeFile = () => {
        if (this.fileInputRef && this.fileInputRef.current) this.fileInputRef.current.value = ''
        cache.updateCachedAnswer(this.props.newAnswer?.id, { files: [], file_urls: [] })
    }

    onClickMobileVideo = ev => {
        ev.stopPropagation()

        if (!this.ensureCurrentUser()) {
            ev.preventDefault()
            return
        }
    }

    setEmbeds = embeds => {
        const embedIds = (embeds || []).map(e => e.id)
        const currentEmbedIds = (this.props.newAnswer?.embeds || []).map(e => e.id)
        if (!isEqual(currentEmbedIds, embedIds)) {
            cache.updateCachedAnswer(this.props.newAnswer?.id, { embeds })
        }
    }

    setLinkPreviews = linkPreviews => this.setState({ linkPreviews: linkPreviews || [] })

    onFocus = () => {
        this.props.panelContext.setExternalHideScrollButton(true)
    }

    onBlur = () => {
        this.props.panelContext.setExternalHideScrollButton(false)
    }

    defaultValue = () => {
        let value = this.props.newAnswer?.delta_json || ''

        if (value.trim().length === 0) {
            const savedDraft = this.loadDraftFromLocalStorage()
            if (savedDraft && savedDraft.delta_json) {
                value = savedDraft.delta_json
            }
        }

        return value
    }

    // generate a per-quest localstorage key
    buildLocalstorageDraftKey = () => {
        const questId = this.props.quest?.id
        return questId ? `draft-answer-${questId}` : null
    }

    // save the current editor contents to local storage
    saveDraftToLocalStorage = () => {
        const content = this.getContent()
        const deltaJson = this.getDeltaJson()

        // Only save if there's actual content
        if (content && content.trim().length > 0) {
            const storageKey = this.buildLocalstorageDraftKey()
            if (storageKey) {
                const draftData = {
                    content,
                    delta_json: deltaJson,
                    timestamp: new Date().getTime(),
                }
                try {
                    localStorage.setItem(storageKey, JSON.stringify(draftData))
                } catch (error) {
                    console.error('Error saving draft to local storage:', error)
                }
            }
        }
    }

    // load a draft from local storage into the editor if it exists
    loadDraftFromLocalStorage = () => {
        const storageKey = this.buildLocalstorageDraftKey()
        if (storageKey) {
            try {
                const savedDraft = localStorage.getItem(storageKey)
                if (savedDraft) {
                    const draftData = JSON.parse(savedDraft)
                    return draftData
                }
            } catch (error) {
                console.error('Error loading draft from local storage:', error)
            }
        }
        return null
    }

    // clear the draft from local storage (e.g., after posting)
    clearDraftFromLocalStorage = () => {
        const storageKey = this.buildLocalstorageDraftKey()
        if (storageKey) {
            try {
                localStorage.removeItem(storageKey)
            } catch (error) {
                console.error('Error clearing draft from local storage:', error)
            }
        }
    }

    setAgentModel = model => {
        const questId = this.props.quest?.id

        // Update the store first - this will trigger the useEffect in CommonEditor
        this.props.set(state => {
            // If turning off AI, remove from questAgentModels
            // If turning on AI, add to questAgentModels with default model
            if (!!model) {
                state.questAgentModels[questId] = model
            } else {
                //console.log('toggleAIModel: removing from questAgentModels', questId)
                delete state.questAgentModels[questId]
            }
            // Then update the API
            api.updateUserSpaceOptions({
                quest_agent_models: state.questAgentModels,
            })
        })
    }

    componentDidMount = () => {
        const { panel } = this.props.panelContext
        const panelId = panel.panelId
        const isOnScreen = isMobile
            ? isPanelCenter(panelId)
            : isPanelLeft(panelId) || isPanelCenter(panelId) || isPanelRight(panelId)

        // No need to load saved drafts here, it will be done in onEditorLoad
    }

    onAgentMenuStateChange = isOpen => {
        this.setState({ menuOpen: isOpen })

        // Update global store to communicate with MobilePullToRefresh
        // Delay updating the global state slightly for iOS to initialize scrolling
        setTimeout(
            () => {
                this.props.set(state => {
                    state.anyMenuOpen = isOpen
                })
            },
            isOpen ? 100 : 0,
        ) // Only delay when opening, not when closing
    }

    render() {
        const debug = this.props.DEBUG
        const quest = this.props.quest
        const newAnswer = this.props.newAnswer
        // TODO need to handle postAnswer with content param when clicking button!!!! So need access to editor value.
        const hoverClass = this.state.hover ? 'is-hover' : ''
        const mobileClass = isMobile ? 'is-mobile' : ''

        const getShortcutText = () => {
            if (
                gon.currentUser?.features?.some(
                    feature => feature.name === 'enter_key_posts_immediately',
                )
            ) {
                return '↵'
            }
            if (isMobile) return '⇧↵'
            return navigator.platform.toLowerCase().includes('mac') ? '⌘↵' : '⌃↵'
        }

        const embeds = newAnswer?.embeds || []

        const isValid = answerModel.hasContent(this.getNewAnswer())
        const validStyles = isValid ? styles.validBtn : null

        const isPublic = questModel.isPublic(quest)

        const imgUrl = newAnswer?.answer_image_url
        const videoUrl = newAnswer?.recording_url

        const payloadContainerStyles = styles.payloadContainer

        const linkPreviews = this.state.linkPreviews || []

        const titleClass = this.props.isTitle ? 'new-title' : ''
        const titleEditorContainerStyles = this.props.isTitle ? styles.titleEditorContainer : null

        const fileUrl = newAnswer?.file_urls?.[0]

        const agentAwaiting = !!this.props.globalAgentModel ? 'awaiting' : null
        const agentModel = this.props.globalAgentModel || defaultModel
        const agentStatus = this.props.knovBotPendingStatus || agentAwaiting

        return (
            <div
                className={cn(
                    styles.newAnswerComp,
                    `new-answer-comp ${hoverClass} ${mobileClass}`,
                    titleClass,
                )}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                {debug && (
                    <div
                        style={{
                            background: '#eaf0ff',
                            marginTop: -5,
                            marginBottom: 15,
                            marginLeft: 50,
                        }}
                    >
                        <strong style={{ marginLeft: 5 }}>Q:</strong>
                        <span onClick={() => debugCopy(quest?.id)}>{quest?.id}</span>
                        <strong style={{ marginLeft: 10 }}>D:</strong>
                        <span onClick={() => debugCopy(newAnswer?.id)}>{newAnswer?.id}</span>
                        <strong style={{ marginLeft: 10 }}>CQ:</strong>
                        <span onClick={() => debugCopy(newAnswer?.child_quests?.[0]?.id)}>
                            {newAnswer?.child_quests?.[0]?.id}
                        </span>
                    </div>
                )}

                {false && !!agentStatus && (
                    <NewAnswerStatus status={agentStatus} agentModel={agentModel} />
                )}

                <div
                    className={cn(
                        styles.questHeader,
                        this.props.isTitle && styles.titleQuestHeader,
                    )}
                >
                    <div
                        className={cn(
                            styles.userContainer,
                            this.props.isTitle && styles.titleUserContainer,
                        )}
                    >
                        <div
                            className={cn(
                                styles.userIconContainer,
                                this.props.isTitle && styles.titleUserIconContainer,
                            )}
                        >
                            <UserIcon
                                user={gon.currentUser}
                                showKnovigator={false}
                                contextStyles={cn(
                                    styles.userIcon,
                                    this.props.isTitle && styles.titleUserIcon,
                                )}
                                size={'small'}
                            />
                        </div>

                        <div className={cn(styles.userNameContainer)}>
                            <UserName
                                user={gon.currentUser}
                                answerCreatedAt={'now'}
                                contextStyles={cn(
                                    styles.userName,
                                    this.props.isTitle && styles.titleUserName,
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div
                    className={cn(
                        styles.userPostContainer,
                        this.props.isTitle && styles.titleUserPostContainer,
                        embeds?.length > 0 && styles.hasEmbedUserPostContainer,
                    )}
                >
                    <div className={cn(styles.editorContainer, titleEditorContainerStyles)}>
                        <CommonEditor
                            ref={this.richEditor}
                            type={'new-answer'}
                            quest={quest}
                            defaultValue={(() => {
                                const savedDraft = this.loadDraftFromLocalStorage()
                                return savedDraft?.delta_json || ''
                            })()}
                            setAgentModel={this.setAgentModel}
                            globalAgentModel={this.props.globalAgentModel}
                            readOnly={false}
                            placeholder={`${
                                this.props.placeholder || 'Post new message:'
                            } ${getShortcutText()}`}
                            setEmbeds={this.setEmbeds}
                            linkPreviewsFingerprint={generateFingerprint(linkPreviews)}
                            embedsFingerprint={generateFingerprint(embeds, ',', 'id')}
                            generateFingerprint={generateFingerprint}
                            setLinkPreviews={this.setLinkPreviews}
                            imgHandler={this.fileHandler}
                            postHandler={this.onPost}
                            onChange={this.onChange}
                            captureSetValue={this.captureSetValue}
                            active={this.props.active}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onEditorLoad={this.onEditorLoad}
                        />

                        {linkPreviews &&
                            linkPreviews.map((href, i) => (
                                <div key={i} style={{ marginTop: '15px' }}>
                                    <LinkPreview url={href} />
                                </div>
                            ))}
                    </div>
                </div>

                {!!embeds?.length && (
                    <div className={styles.embedsContainer}>
                        {embeds.map((embed, idx) => {
                            return (
                                <div key={idx}>
                                    <PostEmbed
                                        label="QUOTE"
                                        isQuote={true}
                                        panel={this.props.panel}
                                        parentAnswer={this.props.newAnswer}
                                        containerAnswer={this.props.newAnswer}
                                        answer={embed}
                                        containerQuest={this.props.quest}
                                        delete={this.deleteEmbed}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )}

                {fileUrl && (
                    <div
                        key={`new-answer-files-${this.props.quest.id}`}
                        className={styles.filesContainer}
                    >
                        <FileEmbed
                            file={fileUrl}
                            canDelete={gon.currentUser?.id === this.props.quest.user_id}
                            showDelete={true}
                            removeFile={this.removeFile}
                        />
                    </div>
                )}

                {videoUrl && (
                    <div
                        className={cn(
                            styles.videoContainer,
                            isMobile && styles.mobileVideoContainer,
                        )}
                        onClick={ev => ev.stopPropagation()}
                    >
                        <video ref={this.videoPreviewRef} controls playsInline src={videoUrl} />
                    </div>
                )}

                {imgUrl && (
                    <div className={payloadContainerStyles}>
                        <img src={imgUrl} />
                    </div>
                )}

                <div className={styles.btnContainer}>
                    {/* Top row: AI buttons and Post button */}
                    <div className={styles.buttons}>
                        <div className={styles.agentButtonContainer}>
                            <AiMagicWandMenuButton
                                contextStyles={styles.knovAgent}
                                imageStyles={styles.knovAgentImage}
                                getEditor={this.getEditor}
                            />
                        </div>

                        <div className={styles.agentButtonContainer}>
                            <KnovAgentButtonUIMain
                                contextStyles={styles.knovAgentButtonContainer}
                                imageStyles={styles.knovAgentImage}
                                setAgentModel={this.setAgentModel}
                                active={this.props.globalAgentModel}
                                editorRef={this.richEditor}
                                onMenuStateChange={this.onAgentMenuStateChange}
                            />
                        </div>

                        <div
                            id="post-btn"
                            className={cn(styles.postAnswerBtn, validStyles)}
                            onClick={this.onPost}
                            tabIndex="-1"
                        >
                            <StreamIcon
                                quest={this.props.quest}
                                isRoot={false}
                                contextStyles={cn(
                                    styles.streamIcon,
                                    isValid && styles.validStreamIcon,
                                    isPublic && styles.publicIcon,
                                )}
                            />
                            <div className={styles.label}>Post</div>
                        </div>
                    </div>

                    {/* Bottom row: Media buttons */}
                    <div className={styles.buttons} style={{ marginTop: '8px' }}>
                        <div className={styles.newAnswerBtnContainer}>
                            <AddFileButton
                                btnId={`${this.props.quest.id}-${this.props.panel}`}
                                ref={this.fileInputRef}
                                fileHandler={this.fileHandler}
                            />
                        </div>

                        {isMobile && (
                            <div className={styles.newAnswerBtnContainer}>
                                <AddImageButton
                                    btnId={`${this.props.quest.id}-${this.props.panel}`}
                                    ref={this.imgInputRef}
                                    imgHandler={this.fileHandler}
                                />
                            </div>
                        )}

                        <div
                            className={styles.newAnswerBtnContainer}
                            style={isMobile ? { marginRight: 0 } : undefined}
                        >
                            {isMobile ? (
                                <MobileVideoButton
                                    ref={this.videoInputRef}
                                    btnId={`video-input-new-answer-for-quest-${this.props.quest.id}-${this.props.panel}`}
                                    quest={this.props.quest}
                                    onClick={this.onClickMobileVideo}
                                    videoHandler={this.fileHandler}
                                />
                            ) : (
                                <VideoButton
                                    btnId={`video-input-new-answer-for-quest-${this.props.quest.id}-${this.props.panel}`}
                                    quest={this.props.quest}
                                    videoHandler={this.videoHandler}
                                />
                            )}
                        </div>

                        {!isMobile && (
                            <div
                                className={styles.newAnswerBtnContainer}
                                style={{ marginRight: 0 }}
                            >
                                <RecordScreenButton
                                    quest={this.props.quest}
                                    videoHandler={this.videoHandler}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
function NewAnswerStatus(props) {
    const status = props.status

    return (
        <div
            className={cn(
                styles.newMessageStatus,
                props.showBorderTop && styles.newMessageStatusBorderTop,
                props.showBorderBottom && styles.newMessageStatusBorderBottom,
            )}
        >
            <img className={cn('bot', styles.agentImage)} src={Bot} />

            {status === 'awaiting' && (
                <>
                    <span className={styles.agentName}>Tree</span>
                    <span>{`${props.agentModel} is ready.`}</span>
                </>
            )}

            {status === 'pending' && (
                <>
                    <span className={styles.agentName}>Tree</span>
                    <span>{`${props.agentModel} is thinking...`}</span>
                </>
            )}

            {status === 'error' && (
                <>
                    <span className={styles.agentName}>Tree</span>
                    <span>{`${props.agentModel} encountered an error.`}</span>
                </>
            )}
        </div>
    )
}

export default Connector(stateSelector, props => ({
    newAnswer: useGetAnswer(props.newAnswer?.id, { cacheOnly: true }),
    panelContext: usePanelContext(),
    insertRight: useInsertRight().insertRight,
}))(
    React.forwardRef((props, ref) => (
        <ErrorBoundary label={`NewAnswer ${props.answer?.id}`}>
            <NewAnswer ref={ref} {...props} />
        </ErrorBoundary>
    )),
)
