// Scroll gesture analyzer constants
export const DELTA_WINDOW_SIZE = 15 // Number of delta values to keep for analysis
export const MIN_DELTA_THRESHOLD = 50 // Minimum sum of absolute delta values to consider a significant gesture
export const DERIVATIVE_THRESHOLD = 0.15 // Threshold to determine if derivative is close to zero (increased from 0.1)
export const OUTLIER_THRESHOLD = 3.0 // Multiplier for standard deviation to identify outliers
export const SMOOTHING_FACTOR = 0.3 // Exponential smoothing factor (higher = less smoothing)

/**
 * ScrollGestureAnalyzer - Analyzes scroll gesture patterns to identify different phases
 *
 * This function takes a configurable amount of event deltaY values and analyzes
 * the pattern to identify whether it's a new user gesture or momentum scrolling.
 * It calculates derivatives to identify three potential phases:
 * 1. Initial positive derivative phase (acceleration) - new gesture
 * 2. Zero derivative phase (constant velocity)
 * 3. Negative derivative phase (deceleration) - momentum scrolling
 *
 * @param newDeltaY - The latest deltaY value to analyze
 * @param windowSize - Size of the deltaY window to maintain (default: 10)
 * @param deltaThreshold - Minimum sum of absolute deltas to consider a significant gesture (default: 50)
 * @returns An object with analysis results
 */
export class ScrollGestureAnalyzer {
    private deltaYs: number[] = []
    private rawDerivatives: number[] = [] // Store raw derivatives before smoothing
    private smoothedDerivatives: number[] = [] // Store smoothed derivatives
    private windowSize: number
    private deltaThreshold: number
    private derivativeThreshold: number
    private lastPhase: 'acceleration' | 'constant' | 'deceleration' | 'unknown' = 'constant'
    private gestureStartTime: number = 0
    private isNewGestureStarted: boolean = false
    private outlierThreshold: number
    private smoothingFactor: number
    private lastSmoothedValue: number = 0

    // Add acceleration tracking for more reliable detection
    private consecutiveAccelerationSamples: number = 0
    private consecutiveDecelerationSamples: number = 0
    private consecutiveConstantSamples: number = 0

    // Higher threshold to enter acceleration phase, lower to exit (hysteresis)
    private requiredAccelerationSamples: number = 4
    private requiredDecelerationSamples: number = 2
    private requiredConstantSamples: number = 3

    // Last detection time to avoid rapid phase switching
    private lastPhaseChangeTime: number = 0
    // Minimum milliseconds between phase changes
    private phaseChangeMinTimeGap: number = 80

    constructor(
        windowSize: number = DELTA_WINDOW_SIZE,
        deltaThreshold: number = MIN_DELTA_THRESHOLD,
        derivativeThreshold: number = DERIVATIVE_THRESHOLD * 1.2, // Increased from 1.1
        outlierThreshold: number = OUTLIER_THRESHOLD,
        smoothingFactor: number = SMOOTHING_FACTOR * 0.85,
    ) {
        this.windowSize = windowSize
        this.deltaThreshold = deltaThreshold
        this.derivativeThreshold = derivativeThreshold
        this.outlierThreshold = outlierThreshold
        this.smoothingFactor = smoothingFactor
    }

    /**
     * Calculate the mean of an array of numbers
     */
    private calculateMean(values: number[]): number {
        if (values.length === 0) return 0
        return values.reduce((sum, val) => sum + val, 0) / values.length
    }

    /**
     * Calculate the standard deviation of an array of numbers
     */
    private calculateStdDev(values: number[], mean: number): number {
        if (values.length <= 1) return 0
        const variance =
            values.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / values.length
        return Math.sqrt(variance)
    }

    /**
     * Remove outliers from an array using the Z-score method
     */
    private removeOutliers(values: number[]): number[] {
        if (values.length <= 3) return values // Need at least 4 values for meaningful outlier detection

        const mean = this.calculateMean(values)
        const stdDev = this.calculateStdDev(values, mean)

        // If standard deviation is very small, there are no outliers
        if (stdDev < 0.001) return values

        // Filter out values that are more than threshold standard deviations from the mean
        return values.filter(val => Math.abs(val - mean) / stdDev <= this.outlierThreshold)
    }

    /**
     * Apply exponential smoothing to a new value
     */
    private exponentialSmooth(newValue: number): number {
        // If this is the first value, just return it
        if (this.smoothedDerivatives.length === 0) {
            this.lastSmoothedValue = newValue
            return newValue
        }

        // Apply exponential smoothing formula: smoothed = α * current + (1-α) * previous_smoothed
        const smoothed =
            this.smoothingFactor * newValue + (1 - this.smoothingFactor) * this.lastSmoothedValue
        this.lastSmoothedValue = smoothed
        return smoothed
    }

    /**
     * Calculate a moving average over a window of values
     */
    private calculateMovingAverage(values: number[], windowSize: number = 3): number {
        if (values.length === 0) return 0
        if (values.length < windowSize) windowSize = values.length

        const windowValues = values.slice(-windowSize)
        return this.calculateMean(windowValues)
    }

    /**
     * Add a new deltaY value and analyze the updated window
     */
    public addDelta(deltaY: number): {
        isAcceleration: boolean
        isMomentum: boolean
        isConstant: boolean
        phase: 'acceleration' | 'constant' | 'deceleration' | 'unknown'
        sufficientData: boolean
        totalDelta: number
    } {
        // Add absolute value of deltaY to the window
        const absDeltaY = Math.abs(deltaY)
        this.deltaYs.push(absDeltaY)

        // Keep window size limited
        if (this.deltaYs.length > this.windowSize) {
            this.deltaYs.shift()
        }

        // Calculate derivatives only when we have a full window of values
        if (this.deltaYs.length >= this.windowSize) {
            // Apply outlier removal to deltaYs first - applying more aggressive filtering
            const filteredDeltaYs = this.removeOutliers([...this.deltaYs])

            // Calculate derivative between adjacent values using filtered deltaYs
            // Use more points for derivative calculation to smooth out noise
            if (filteredDeltaYs.length >= 3) {
                // Use a 3-point derivative instead of just adjacent points
                const newRawDerivative =
                    (filteredDeltaYs[filteredDeltaYs.length - 1] -
                        filteredDeltaYs[filteredDeltaYs.length - 3]) /
                    2
                this.rawDerivatives.push(newRawDerivative)
            } else {
                // Fall back to 2-point derivative if not enough points
                const newRawDerivative =
                    filteredDeltaYs[filteredDeltaYs.length - 1] -
                    filteredDeltaYs[filteredDeltaYs.length - 2]
                this.rawDerivatives.push(newRawDerivative)
            }

            if (this.rawDerivatives.length > this.windowSize - 1) {
                this.rawDerivatives.shift()
            }

            // Apply more aggressive smoothing techniques to the derivatives

            // Calculate a moving average of the raw derivatives with larger window
            const movingAvgDerivative = this.calculateMovingAverage(
                this.rawDerivatives,
                Math.min(5, this.rawDerivatives.length),
            ) // Use 5-point moving average or all available points

            // Apply exponential smoothing for additional noise reduction
            const smoothedDerivative = this.exponentialSmooth(movingAvgDerivative)

            // Store the smoothed derivative
            this.smoothedDerivatives.push(smoothedDerivative)

            if (this.smoothedDerivatives.length > this.windowSize - 1) {
                this.smoothedDerivatives.shift()
            }
        }

        // We need a full window of deltaY values for meaningful analysis
        const sufficientData = this.deltaYs.length >= this.windowSize

        // Calculate total absolute delta to check if gesture is significant
        const totalDelta = this.deltaYs.reduce((sum, current) => sum + current, 0)

        // Determine the current phase based on smoothed derivatives
        let phase = this.lastPhase
        let rawPhase = 'unknown' as 'acceleration' | 'constant' | 'deceleration' | 'unknown'

        if (sufficientData && this.smoothedDerivatives.length >= 3) {
            // Use more data points for trend analysis - look at the last 4 samples instead of 3
            const recentSmoothedDerivatives = this.smoothedDerivatives.slice(-4)
            const avgSmoothedDerivative = this.calculateMean(recentSmoothedDerivatives)

            // Calculate the absolute derivative to check against thresholds
            const absDerivative = Math.abs(avgSmoothedDerivative)

            // Apply hysteresis thresholds - require stronger derivatives to change phases
            // but once in a phase, allow weaker derivatives to maintain that phase
            const enterAccelerationThreshold = this.derivativeThreshold * 1.25 // Increased from 1.1 to 1.25
            const exitAccelerationThreshold = this.derivativeThreshold * 0.85 // 15% lower to exit (was 10%)
            const enterDecelerationThreshold = this.derivativeThreshold * 1.1 // 10% higher to enter (down from 20%)
            const exitDecelerationThreshold = this.derivativeThreshold * 0.85 // 15% lower to exit (was 10%)

            // Determine raw phase based on the derivative with hysteresis
            if (
                avgSmoothedDerivative >
                (phase === 'acceleration' ? exitAccelerationThreshold : enterAccelerationThreshold)
            ) {
                // Only increment counter if derivative is significantly positive
                rawPhase = 'acceleration'
                this.consecutiveAccelerationSamples++
                this.consecutiveDecelerationSamples = 0
                this.consecutiveConstantSamples = 0
            } else if (
                avgSmoothedDerivative <
                -(phase === 'deceleration' ? exitDecelerationThreshold : enterDecelerationThreshold)
            ) {
                // Only increment counter if derivative is significantly negative
                rawPhase = 'deceleration'
                this.consecutiveAccelerationSamples = 0
                this.consecutiveDecelerationSamples++
                this.consecutiveConstantSamples = 0
            } else {
                // In the neutral zone - too small to call it acceleration or deceleration
                rawPhase = 'constant'
                this.consecutiveAccelerationSamples = Math.max(
                    0,
                    this.consecutiveAccelerationSamples - 1,
                )
                this.consecutiveDecelerationSamples = Math.max(
                    0,
                    this.consecutiveDecelerationSamples - 1,
                )
                this.consecutiveConstantSamples++
            }

            // Apply hysteresis - require more samples to enter a phase than to stay in it
            const now = Date.now()
            const timeGapSinceLastChange = now - this.lastPhaseChangeTime

            // Only consider phase changes after a minimum time gap to avoid oscillation
            if (timeGapSinceLastChange >= this.phaseChangeMinTimeGap) {
                // Calculate sum of deltas to gauge total velocity/magnitude of the gesture
                // This helps distinguish meaningful gestures from small jittery movements
                const recentDeltaSum = this.deltaYs.slice(-5).reduce((sum, delta) => sum + delta, 0)
                const velocityThreshold = this.deltaThreshold * 0.35 // Increased from 0.25 to 0.35

                // More evidence needed to enter acceleration (hysteresis)
                if (
                    phase !== 'acceleration' &&
                    this.consecutiveAccelerationSamples >= this.requiredAccelerationSamples &&
                    recentDeltaSum > velocityThreshold
                ) {
                    // Only change if velocity is significant
                    phase = 'acceleration'
                    this.lastPhaseChangeTime = now
                }
                // Less evidence needed to enter deceleration (quick exit from acceleration)
                else if (
                    phase !== 'deceleration' &&
                    this.consecutiveDecelerationSamples >= this.requiredDecelerationSamples &&
                    recentDeltaSum > velocityThreshold * 0.75
                ) {
                    // Lowered from 80% to 75% of velocity threshold
                    phase = 'deceleration'
                    this.lastPhaseChangeTime = now
                }
                // If we've been in constant phase for a while, confirm it
                else if (
                    phase !== 'constant' &&
                    this.consecutiveConstantSamples >= this.requiredConstantSamples
                ) {
                    phase = 'constant'
                    this.lastPhaseChangeTime = now
                }
            }

            // If all counts are 0, maintain previous phase to prevent oscillation
            if (
                this.consecutiveAccelerationSamples === 0 &&
                this.consecutiveDecelerationSamples === 0 &&
                this.consecutiveConstantSamples === 0
            ) {
                // Keep current phase for stability
            }

            // Update last phase
            this.lastPhase = phase
        }

        return {
            isAcceleration: phase === 'acceleration',
            isMomentum: phase === 'deceleration', // Momentum is identified by deceleration phase
            isConstant: phase === 'constant',
            phase,
            sufficientData,
            totalDelta,
        }
    }

    /**
     * Check if we're in an active gesture (not too much time has passed since start)
     */
    public isInActiveGesture(): boolean {
        return this.isNewGestureStarted && Date.now() - this.gestureStartTime < 1000
    }

    /**
     * Clear all stored values and reset the analyzer
     */
    public reset(): void {
        this.deltaYs = []
        this.rawDerivatives = []
        this.smoothedDerivatives = []
        this.lastPhase = 'constant'
        this.isNewGestureStarted = false
        this.lastSmoothedValue = 0

        // Reset detection counters
        this.consecutiveAccelerationSamples = 0
        this.consecutiveDecelerationSamples = 0
        this.consecutiveConstantSamples = 0

        // Don't reset the last phase change time to avoid immediate phase changes after reset
    }
}
