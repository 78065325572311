import React, { useState } from 'react'
import cn from 'classnames'
import styles from 'components/panels/stream-control.module.scss'
import useGetQuest from 'refactor/hooks/api/useGetQuest'
import TreechatIconPng from 'components/shared/icons/TreechatIconPng'

export default function StreamControl({
    panel,
    filter,
    showFilterOptions,
    setShowFilterOptions,
    contextStyles,
}) {
    // We need to re-render when quest loads to derive a filter from it when quest loads.
    // When filter is a stream this will be a no-op.
    const quest = useGetQuest(filter?.questId)
    const [hover, setHover] = useState(false)

    return (
        <>
            <div
                className={cn(styles.streamControlComp, contextStyles)}
                onClick={() => setShowFilterOptions(!showFilterOptions)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {showFilterOptions ? (
                    <i className="fa fa-remove" styles={{ marginTop: 2 }} />
                ) : (
                    <TreechatIconPng contextStyles={cn(contextStyles, styles.treechatIcon)} />
                )}
            </div>
        </>
    )
}
