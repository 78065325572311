import api from 'api/api'
import cn from 'classnames'
import OrdinalsIcon from 'components/shared/OrdinalsIcon'
import WithTooltip from 'components/shared/WithTooltip'
import React, { useRef, useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import useUserHasFeature from 'refactor/hooks/useUserHasFeature'
import useStore from 'state/knovStore'
import styles from '../answer.module.scss'
import { OrdinalInfoPopup } from './OrdinalInfoPopup'
import { confirmAsync } from 'components/shared/confirmUtils'
import { useBsvExchangeRate } from 'api/useBsvExchangeRate'
import { SATOSHIS } from 'lib/bsv-util'

/**
 * Check if the answer's image was pasted in, in which case we can use the local link and not go to the server.
 * @param {import('types/answers').Answer} answer - The answer object to check
 * @returns {boolean} - Whether the answer has a blob URL
 */
const hasBlob = answer => {
    const url = answer?.answer_image_url
    return typeof url === 'string' && (url.startsWith('blob:') || url.startsWith('data:'))
}

/**
 * Component that displays an answer's image with associated controls and overlays
 * @type {React.ForwardRefExoticComponent<{
 *   answer: import('types/answers').Answer,
 *   draftAnswer?: import('types/answers').Answer,
 *   toggle: () => void,
 *   isThin?: boolean,
 *   showDownload?: boolean,
 *   isEmbed?: boolean,
 *   isTitle?: boolean,
 *   isActive?: boolean,
 *   path?: string,
 *   imageSize?: string,
 *   uiId?: string,
 *   hasSeenGenerating?: boolean,
 *   contextStyles?: object
 * } & React.RefAttributes<HTMLImageElement>>}
 */
const AnswerImage = React.forwardRef((props, ref) => {
    const [hasImageError, setHasImageError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    // Add state for ordinal info popup
    const [isOrdinalInfoVisible, setIsOrdinalInfoVisible] = useState(false)
    // Add ref for ordinal button to position the popup
    const ordinalButtonRef = useRef(null)

    const DEBUG = useStore(state => state.DEBUG)
    const ordinalsEnabled = useUserHasFeature('ordinals_wallet')
    const openOrdinalsModal = useStore(state => state.actions.modalActions.openOrdinalsModal)
    const answer = props.answer
    const draftAnswer = props.draftAnswer
    const isThin = props?.isThin || false
    let url

    // Get current BSV exchange rate
    const bsvExchangeRate = useBsvExchangeRate()

    if (hasBlob(draftAnswer)) {
        url = draftAnswer.answer_image_url
    } else if (hasBlob(answer)) {
        url = answer.answer_image_url
    } else {
        url = `${window.knovApiUrl}/api/v1/answers/${answer?.id}/image`
        const imageSize = props.imageSize

        let imageQueryString = `?updated_at=${parseInt(new Date(answer.updated_at).getTime())}`
        if (imageSize) {
            imageQueryString = imageQueryString
                ? `${imageQueryString}&size=${imageSize}`
                : `?size=${imageSize}`
        }
        if (hasImageError) {
            imageQueryString = imageQueryString
                ? `${imageQueryString}&break_cache=true`
                : '?break_cache=true'
        }
        url = `${url}${imageQueryString}`
    }

    const isActive = props.isActive
    const payloadContainerStyles = isMobile
        ? styles.mobilePayloadContainer
        : styles.payloadContainer

    const streamPayloadContainerStyles = isActive ? null : styles.streamPayloadContainer
    const downloadStyles = props.showDownload ? { display: 'flex' } : { display: 'none' }
    const embedImageStyles = props.isEmbed ? styles.embedImagePayload : null

    let name
    const path = props.path
    if (path) {
        const arr = path.split('/')
        name = arr[arr.length - 1]
    } else {
        name = 'knov'
    }

    // this will contain the corresponding BsvAttachment for this image if there is one
    const matchingBsvAttachment = answer?.bsv_attachments?.find(
        att =>
            att.attachment_url == answer.answer_image_url ||
            answer.image_urls?.some(img => img.attachment_id === att.attachment_id),
    )

    const renderBsvAttachmentStatus = matchingBsvAttachment => {
        const bsvTx = matchingBsvAttachment?.bsv_tx
        const txId = bsvTx?.tx_id

        // Check if we have a pending transaction that's more than 5 seconds old
        const isAwaitingResubmission =
            bsvTx && !txId && (new Date() - new Date(bsvTx.created_at)) / 1000 > 5

        // Show different status for old pending transactions
        if (isAwaitingResubmission) {
            const retryInfo = bsvTx.retries > 0 ? ` (${bsvTx.retries} retries)` : ''
            return (
                <WithTooltip
                    tip={`Treechat wasn't able to post this onchain immediately but will try again on the next block ${retryInfo}`}
                >
                    <i className="fa fa-clock-o" />
                </WithTooltip>
            )
        }

        // Show spinner for new pending transactions
        if (!txId) {
            return (
                <WithTooltip tip="Transaction pending">
                    <i className="fa fa-spinner fa-spin" />
                </WithTooltip>
            )
        }

        const isUnconfirmed = !bsvTx?.confirmed

        return (
            <WithTooltip tip={`${isUnconfirmed ? 'Unconfirmed' : 'Confirmed'} tx: ${txId}`}>
                <a
                    href={`https://whatsonchain.com/tx/${txId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={ev => ev.stopPropagation()}
                >
                    <i className={`fa fa-cube`} />
                </a>
            </WithTooltip>
        )
    }

    const isAnswerOwner = answer?.user_id === window.gon?.currentUser?.id

    const handleMintOrdinal = async ev => {
        ev.stopPropagation()
        if (!matchingBsvAttachment) return

        // Fixed price of $1 to mint an ordinal
        const mintPriceUsd = 1
        // Calculate equivalent in satoshis (using SATOSHIS constant from bsv-util)
        const mintPriceSats = Math.round((mintPriceUsd / bsvExchangeRate) * SATOSHIS)

        const confirmMintRes = await confirmAsync(
            `Mint this ordinal for $${mintPriceUsd}USD (${mintPriceSats.toLocaleString()} sats)?`,
        )

        if (!confirmMintRes) return

        setIsLoading(true)

        try {
            const mintResult = await api.mintOrdinal(matchingBsvAttachment.id, {
                treechat_fee_addr: window.gon.currentUser?.address,
                treechat_fee_amount_sats: mintPriceSats,
            })
            const { job_id: jobId } = mintResult
            if (jobId) {
                console.log(`>>> ordinal mint job started with id: ${jobId}`)
                // hack to work around race condition (for now)
                // TODO: have the BsvOrdinal id get sent over redis subscription and wait for it here
                await new Promise(resolve => setTimeout(resolve, 2500))
                const confRes = await confirmAsync('Ordinal minted succesfully, view in wallet?')
                if (confRes) {
                    openOrdinalsModal()
                }
            } else {
                throw new Error('Failed to mint ordinal')
            }
        } catch (error) {
            alert(`Failed to mint ordinal: ${error}`)
            console.error('Failed to mint ordinal:', error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            {url && (
                <div
                    style={props.hasSeenGenerating ? { minHeight: 588 } : {}}
                    className={cn(
                        payloadContainerStyles,
                        props.isTitle && styles.titlePayloadContainer,
                        styles.imagePayloadContainer,
                        streamPayloadContainerStyles,
                        embedImageStyles,
                        props.contextStyles,
                    )}
                >
                    <img
                        ref={ref}
                        onClick={ev => {
                            ev.preventDefault()
                            ev.stopPropagation()
                            props.toggle()
                        }}
                        src={url}
                        data-zoomable={props.uiId}
                        onError={e => {
                            //console.log('>>>>>>IMAGE ERROR', e)
                            setHasImageError(true)
                        }}
                    />
                    {matchingBsvAttachment && !isThin && (
                        <div className={styles.bsvStatusOverlay}>
                            {renderBsvAttachmentStatus(matchingBsvAttachment)}
                            {
                                // remove the false if debugging bsv attachments
                                false && DEBUG && (
                                    <pre style={{ width: '200px' }}>
                                        {JSON.stringify(matchingBsvAttachment, null, 2)}
                                    </pre>
                                )
                            }
                        </div>
                    )}
                    {!isThin && (
                        <>
                            {answer?.image_bsv_ordinal?.id ? (
                                <div
                                    className={styles.ordinalButtonOverlay}
                                    onClick={ev => {
                                        ev.stopPropagation()
                                        // Show ordinal info popup instead of directly opening modal
                                        setIsOrdinalInfoVisible(!isOrdinalInfoVisible)
                                    }}
                                    ref={ordinalButtonRef}
                                >
                                    <OrdinalsIcon size={20} />
                                    {isOrdinalInfoVisible && (
                                        <OrdinalInfoPopup
                                            ordinal={answer.image_bsv_ordinal}
                                            onClose={() => setIsOrdinalInfoVisible(false)}
                                            openOrdinalsModal={openOrdinalsModal}
                                            buttonRef={ordinalButtonRef}
                                        />
                                    )}
                                </div>
                            ) : ordinalsEnabled &&
                              isAnswerOwner &&
                              // only allow minting on public posts for now (due to implementation details)
                              // TODO: split up matchingBsvAttachment and mint machinery to allow minting private posts
                              matchingBsvAttachment ? (
                                <div className={styles.mintButtonOverlay}>
                                    <WithTooltip tip="Mint as NFT">
                                        <div
                                            className={cn(styles.mintButton, {
                                                [styles.loading]: isLoading,
                                            })}
                                            onClick={handleMintOrdinal}
                                        >
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-spin" />
                                            ) : (
                                                <>
                                                    <span className={styles.buttonIcon}>
                                                        <OrdinalsIcon size={16} />
                                                    </span>
                                                    MINT
                                                </>
                                            )}
                                        </div>
                                    </WithTooltip>
                                </div>
                            ) : null}
                        </>
                    )}
                    {props.showDownload && (
                        <div
                            style={downloadStyles}
                            className={styles.downloadLinkContainer}
                            onClick={ev => {
                                ev.stopPropagation()
                            }}
                        >
                            <a href={url} download={name}>
                                Download
                            </a>
                        </div>
                    )}
                </div>
            )}
        </>
    )
})

export default AnswerImage
