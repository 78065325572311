import React, { useEffect } from 'react'
import api from '../../api/api'
import useStore from 'state/knovStore'

let recorder = null

export default function ScreenRecorder(props) {
    const updateQuestAnswer = useStore(state => state.updateQuestAnswer)
    const updateQuest = useStore(state => state.updateQuest)
    const closeModal = useStore(state => state.actions.modalActions.closeModal)

    var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
    var iOS =
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

    // let preview = useRef(null)
    // let stopButton = useRef(null)

    useEffect(() => {
        if (window.MediaRecorder && !(isSafari && iOS)) {
            console.log('preview ready')
            navigator.mediaDevices
                .getUserMedia({
                    audio: true,
                })
                .then(audioStream => {
                    return navigator.mediaDevices
                        .getDisplayMedia({
                            video: {
                                cursor: 'always',
                            },
                        })
                        .then(stream => {
                            stream.addTrack(audioStream.getAudioTracks()[0])
                            // preview.current.srcObject = stream;
                            // preview.current.captureStream = preview.current.captureStream || preview.current.mozCaptureStream;
                            let data = []
                            stream.getTracks().forEach(
                                track =>
                                    (track.onended = () => {
                                        stop(stream)
                                    }),
                            )
                            console.log('new recorder')
                            recorder = new MediaRecorder(stream)
                            recorder.ondataavailable = event => data.push(event.data)
                            recorder.start()

                            let stopped = new Promise((resolve, reject) => {
                                recorder.onstop = resolve
                                recorder.onerror = event => reject(event.name)
                            })
                            return stopped.then(() => data)
                        })
                        .then(async recordedChunks => {
                            let recordedBlob = new Blob(recordedChunks, { type: 'video/webm' })
                            closeModal()
                            console.log(
                                'Successfully recorded ' +
                                    recordedBlob.size +
                                    ' bytes of ' +
                                    recordedBlob.type +
                                    ' media.',
                            )
                            let answer = await props.modalParams.videoHandler(recordedBlob)
                        })
                        .catch(console.log)
                })
        }
    }, [props.modalParams])

    function stop(stream) {
        recorder.state == 'recording' && recorder.stop()
        stream.getTracks().forEach(track => track.stop())
    }

    // function onStop(ev) {
    //     stop(preview.current.srcObject)
    // }

    let recordingModalBody
    if (window.MediaRecorder && !(isSafari && iOS)) {
        recordingModalBody = <div className="video-recorder-comp"></div>
    }

    return recordingModalBody
}
