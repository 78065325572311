import React, { useCallback } from 'react'
import useStore from 'state/knovStore'
import cn from 'classnames'
import useStreamHeader from 'components/filters/useStreamHeader'
import usePanelContext from 'refactor/hooks/usePanelContext'
import {
    getCenterPanel,
    isPanelCenter,
    isPanelLeft,
    isPanelRight,
} from 'state/imperativeApis/swiperApi'
import useInsertLeft from 'refactor/hooks/useInsertLeft'
import useInsertRight from 'refactor/hooks/useInsertRight'
import styles from 'components/panels/panel-header.module.scss'
import { isMobile } from 'react-device-detect'
import useRemovePanel from 'refactor/hooks/useRemovePanel'

export default function PanelHeader(props) {
    useStore(state => state.activeIndex)

    const { panel } = usePanelContext()
    const panelId = panel.panelId
    const filter = panel.filter

    const header = useStreamHeader(filter)
    const removePanel = useRemovePanel()

    const clearPanel = useCallback(ev => {
        if (isPanelCenter(panelId) && useStore.getState().panels?.state?.length < 4)
            // Convert to empty instead of removing if we've reached the smallest amount of panels necessary.
            useStore.getState().panels.clearPanel(panelId)
        else removePanel()

        const panelToLeft = useStore.getState().panels.getPanelToLeft(panel.panelId)
        useStore.getState().set(draft => {
            draft.selectedAnswers[panelId] = null
            draft.selectedAnswers[panelToLeft.panelId] = null
        })
    }, [])

    const centerPanel = getCenterPanel()
    // Insert from the perspective of the center panel.
    const insertLeft = useInsertLeft(centerPanel?.panelId)
    const { insertRight } = useInsertRight(centerPanel?.panelId)

    return (
        <div className={styles.panelHeaderComp}>
            {!isMobile && isPanelRight(panelId) && (
                <div
                    className={cn(styles.headerControlContainer, styles.headerControlContainerLeft)}
                >
                    <div
                        className={cn(styles.headerControl, styles.insertControl)}
                        onClick={() => insertRight({ empty: true })}
                    >
                        <i className="fa fa-arrow-left" />
                    </div>
                </div>
            )}

            <div className={styles.headerContainer}>
                <div className={styles.headerLabel}>{header}</div>
            </div>

            <div className={styles.headerControlContainer}>
                {!isMobile && isPanelLeft(panelId) && (
                    <div
                        className={cn(styles.headerControl, styles.insertControl)}
                        onClick={ev => insertLeft({ empty: true })}
                    >
                        <i className="fa fa-arrow-left" />
                    </div>
                )}
            </div>
        </div>
    )
}
