import cn from 'classnames'
import OrdinalsIcon from 'components/shared/OrdinalsIcon'
import UserHandleFromId from 'components/shared/UserHandleFromId'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Wallet } from 'lucide-react'
import styles from '../answer.module.scss'
import { OrdinalInfoPopupPortal } from './OrdinalInfoPopupPortal'
import { Ordinal } from './types'

interface OrdinalInfoPopupProps {
    /** The ordinal to display information about */
    ordinal: Ordinal
    /** Function to call when the popup is closed */
    onClose: () => void
    /** Function to open the ordinals modal */
    openOrdinalsModal: (origin?: string) => void
    /** Reference to the button that triggered the popup */
    buttonRef: React.RefObject<HTMLDivElement>
}

/**
 * Popup component that displays information about an ordinal.
 * Uses a portal to ensure it's not clipped by container overflow.
 */
export const OrdinalInfoPopup: React.FC<OrdinalInfoPopupProps> = ({
    ordinal,
    onClose,
    openOrdinalsModal,
    buttonRef,
}) => {
    const [ordinalDetails, setOrdinalDetails] = useState<Ordinal | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [popupPosition, setPopupPosition] = useState<{ top: number; left: number }>({
        top: 0,
        left: 0,
    })
    const [copySuccess, setCopySuccess] = useState<boolean>(false)
    const popupRef = useRef<HTMLDivElement>(null)

    // Calculate position based on button ref and ensure it's visible on screen
    const calculateSafePosition = (): { top: number; left: number } => {
        if (!buttonRef?.current) return { top: 0, left: 0 }

        const buttonRect = buttonRef.current.getBoundingClientRect()
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight
        const popupWidth = 280 // Match width from CSS
        const popupHeight = 300 // Approximate height

        // Initial position - above the button
        let top = buttonRect.top - 10
        let left = buttonRect.left - 240 + buttonRect.width

        // Check if popup would be off-screen to the right
        if (left + popupWidth > windowWidth) {
            left = windowWidth - popupWidth - 10 // 10px margin from right edge
        }

        // Check if popup would be off-screen to the left
        if (left < 10) {
            left = 10 // 10px margin from left edge
        }

        // Check if popup would be off-screen at the top
        if (top < 10) {
            // Position below the button instead
            top = buttonRect.bottom + 10
        }

        // Check if popup would be off-screen at the bottom
        if (top + popupHeight > windowHeight) {
            // If positioning below would be off-screen,
            // and there's more space above than below, position above
            if (buttonRect.top > windowHeight - buttonRect.bottom) {
                top = Math.max(10, buttonRect.top - popupHeight - 10)
            } else {
                // Otherwise, position as high as possible while keeping on screen
                top = windowHeight - popupHeight - 10
            }
        }

        return { top, left }
    }

    // Update position whenever window is resized
    useEffect(() => {
        const updatePosition = (): void => {
            setPopupPosition(calculateSafePosition())
        }

        // Set initial position
        updatePosition()

        // Update on resize
        window.addEventListener('resize', updatePosition)
        return () => {
            window.removeEventListener('resize', updatePosition)
        }
    }, [buttonRef])

    useEffect(() => {
        const fetchOrdinalDetails = async (): Promise<void> => {
            setIsLoading(true)
            try {
                setOrdinalDetails(ordinal)
            } catch (error) {
                console.error('Error fetching ordinal details:', error)
            } finally {
                setIsLoading(false)
            }
        }

        fetchOrdinalDetails()

        // Add event listener to close popup when clicking outside
        const handleClickOutside = (event: MouseEvent): void => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target as Node) &&
                buttonRef?.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                onClose()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ordinal, onClose, buttonRef])

    const isOwner = ordinal?.treechat_owner_id === String(window.gon?.currentUser?.id)

    // Format the origin to display in a readable way
    const formatOrigin = (origin?: string): string => {
        if (!origin) return 'Unknown'
        // Keep the full string including underscore and vout, just truncate the middle
        if (origin.length > 15) {
            return `${origin.substring(0, 6)}...${origin.substring(origin.length - 6)}`
        }
        return origin
    }

    // Render owner information
    const renderOwner = (): React.ReactNode => {
        if (isOwner) return 'You'

        // If there's a treechat owner ID, use the UserHandleFromId component
        if (ordinal?.treechat_owner_id) {
            return <UserHandleFromId userId={ordinal.treechat_owner_id} afterClick={onClose} />
        }

        // Fall back to the owner_name if provided
        if (ordinal?.owner_name) {
            return ordinal.owner_name
        }

        return 'Someone else'
    }

    // Handle copying origin to clipboard
    const handleCopyOrigin = (): void => {
        if (!ordinal?.origin) return

        navigator.clipboard
            .writeText(ordinal.origin)
            .then(() => {
                setCopySuccess(true)
                // Reset copy success after 1.5 seconds
                setTimeout(() => setCopySuccess(false), 1500)
            })
            .catch(err => {
                console.error('Failed to copy text: ', err)
            })
    }

    return (
        <OrdinalInfoPopupPortal>
            <div
                className={cn(styles.ordinalInfoPopup, {
                    [styles.mobileOrdinalInfoPopup]: isMobile,
                })}
                onClick={(e: React.MouseEvent): void => e.stopPropagation()}
                style={{
                    position: 'fixed',
                    top: `${popupPosition.top}px`,
                    left: `${popupPosition.left}px`,
                    zIndex: 9999,
                }}
                ref={popupRef}
            >
                <div className={styles.ordinalInfoHeader}>
                    <h4>Ordinal Information</h4>
                    <button className={styles.closeButton} onClick={onClose}>
                        ×
                    </button>
                </div>

                <div className={styles.ordinalInfoContent}>
                    {isLoading ? (
                        <div className={styles.loading}>
                            <i className="fa fa-spinner fa-spin" />
                            <span>Loading ordinal details...</span>
                        </div>
                    ) : (
                        <>
                            <div className={styles.ordinalInfoRow}>
                                <span className={styles.label}>Origin:</span>
                                <div className={styles.valueWithCopy}>
                                    <span>{formatOrigin(ordinal?.origin)}</span>
                                    <button
                                        className={styles.copyButton}
                                        onClick={handleCopyOrigin}
                                        title="Copy full origin to clipboard"
                                    >
                                        {copySuccess ? (
                                            <i className="fa fa-check" />
                                        ) : (
                                            <i className="fa fa-clipboard" />
                                        )}
                                    </button>
                                </div>
                            </div>
                            {ordinal?.inscription_id && (
                                <div className={styles.ordinalInfoRow}>
                                    <span className={styles.label}>Inscription:</span>
                                    <span className={styles.value}>
                                        {ordinal.inscription_id.substring(0, 10)}...
                                    </span>
                                </div>
                            )}
                            <div className={styles.ordinalInfoRow}>
                                <span className={styles.label}>Owner:</span>
                                <span className={styles.value}>{renderOwner()}</span>
                            </div>
                            {ordinal?.inscription_number && (
                                <div className={styles.ordinalInfoRow}>
                                    <span className={styles.label}>Number:</span>
                                    <span className={styles.value}>
                                        {String(ordinal.inscription_number)}
                                    </span>
                                </div>
                            )}
                            {ordinal?.content_type && (
                                <div className={styles.ordinalInfoRow}>
                                    <span className={styles.label}>Type:</span>
                                    <span className={styles.value}>{ordinal.content_type}</span>
                                </div>
                            )}

                            <div className={styles.ordinalInfoActions}>
                                {isOwner && (
                                    <button
                                        className={styles.viewButton}
                                        onClick={() => {
                                            onClose()
                                            openOrdinalsModal(ordinal.origin)
                                        }}
                                    >
                                        <span className={styles.buttonIcon}>
                                            <Wallet size={16} />
                                        </span>
                                        View in Wallet
                                    </button>
                                )}
                                {ordinal?.origin && (
                                    <button
                                        className={cn(styles.viewButton, styles.oneSatButton)}
                                        onClick={() => {
                                            const outpoint = ordinal.origin.split(':')[0]
                                            window.open(
                                                `https://alpha.1satordinals.com/outpoint/${outpoint}/timeline`,
                                                '_blank',
                                            )
                                        }}
                                    >
                                        <span className={styles.buttonIcon}>
                                            <OrdinalsIcon size={16} />
                                        </span>
                                        View on 1Sat
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </OrdinalInfoPopupPortal>
    )
}
