import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import Quill from 'quill'
import api from 'api/api'
import useStore from 'state/knovStore'
import { map, uniq, debounce, defer, partial, compact, cloneDeep } from 'lodash'
import {
    KnovClipboard,
    dataUrlToBlob,
    normalizeExistingDelta,
} from 'components/shared/quill/quillHelpers'
import { extractUrls } from 'components/shared/LinkPreview'
import { updatePopupProps, getPopupProps } from 'components/AutocompletePopup'
import { UserSelector } from 'components/AutocompletePopup'
import { isEqual } from 'lodash'
import questModel from 'components/quests/questModel'
import { LLMModels } from 'components/quests/KnovAgentButtonUIMain'
import styles from './mentions.module.scss'
import { isEmpty, containsModelMention, createModelMention } from 'lib/value'
import { getStableText } from 'components/shared/quill/quillHelpers'
import CustomMentionBlot from './quill/CustomMentionBlot'
import MyLink from './quill/MyLink'
import AutoLinks from '../../lib/quill/auto_links'
import { isIOS, isMobile, isAndroid } from 'react-device-detect'
import cn from 'classnames'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import { getCenterIndex, getSwiperRef } from 'state/imperativeApis/swiperApi'
import QuillEditor from './QuillEditor'
import { generateFingerprint } from '../../utils/index'

// Import needed functions from QuillEditor
import { formats, getQuillModulesConfig } from './QuillEditor'

// Import Delta directly from Quill
const Delta = Quill.import('delta')

Quill.register('modules/autoLinks', AutoLinks)
Quill.register('modules/clipboard', KnovClipboard)

// Register custom formats with Quill
Quill.register('formats/link', MyLink, true)
Quill.register('formats/mention', CustomMentionBlot, true)

// Register barrier format
const BarrierInline = Quill.import('blots/inline') as any
class BarrierBlot extends BarrierInline {
    static blotName = 'barrier'
    static tagName = 'span'
    static className = 'mention-barrier' // Keep this as a string since Quill expects a simple class name
}
Quill.register('formats/barrier', BarrierBlot)

// Register custom clipboard handling
const Clipboard = Quill.import('modules/clipboard') as any
class MentionAwareClipboard extends Clipboard {
    convert(html: string) {
        const delta = super.convert(html)
        return normalizeExistingDelta(delta)
    }
}
Quill.register('modules/clipboard', MentionAwareClipboard, true)

const squashImage = ev => {
    if (ev.target && ev.target.className.includes('medium-zoom-image')) {
        ev.preventDefault()
        ev.stopPropagation()
    }
}

/** helper to remove trailing links from the delta json for aesthetics. meant
 *  to be called only when not in edit mode (lest the user be unable to edit
 *  their links)
 *
 * lots of corner cases and gotchas with this function, when making changes
 * here make sure to test the following scenarios:
 *
 * - msg contains only a link
 * - embed of a msg containing only a link
 * - a link followed by text
 * - text followed by a link
 * - a meme (or other "link", eg a mention) at the end of msg's text
 **/
function cleanTrailingLinks(contents) {
    if (!contents) return
    const lastOpIdx = contents?.ops?.length - 1
    const secondToLastIdx = contents?.ops?.length - 2
    const cleanContents = {
        ...contents,
        ops: compact(
            contents?.ops?.reduce(
                // if the 2nd to last item in the ops list (2nd to last because
                // quill always keeps a \n as the last) is a url and the last
                // item is whitespace then insert a `null` (which will later be
                // removed by compact()), otherwise copy the value over
                (acc, cur, idx) => {
                    const link =
                        idx === secondToLastIdx
                            ? contents?.ops?.[secondToLastIdx]?.attributes?.link
                            : null
                    return [
                        ...acc,
                        link &&
                        !link.startsWith('@v2quest_') &&
                        (contents?.ops?.[lastOpIdx]?.insert || '')?.trim() === ''
                            ? null
                            : cur,
                    ]
                },
                [],
            ),
        ),
    }
    return cleanContents
}

const confirmKeys = ['Enter', 'Tab', 'ArrowRight'] // a confirm key means pick from the autocomplete menu
//const continueKeys = [' ']
const cancelKeys = ['Escape', 'Space', ' ']
const navKeys = ['ArrowDown', 'ArrowUp']
const popupTrigger: string = '@'
const doublePopupTrigger: string = '@@'
const allowedChars = /[A-Za-z0-9_]+/

// Add a global tracker for keydown events
let lastKeyPressed = ''

// Add a utility variable to track the last text state
let lastTextBeforeCursor = ''

function getPopupInfo(quill) {
    // Safety check for quill instance
    if (!quill) {
        //console.error('getPopupInfo called with invalid quill instance')
        return {
            popupTrigger: '@',
            popupShouldShow: false,
            extractItemVal: () => '',
            text: '',
            quillText: '',
            textBeforeCursor: '',
            popupStartIdx: -1,
            popupInput: '',
            cursorPosIdx: 0,
            isDoublePopup: false,
            hasDeletedSecondAt: false,
            isBackspaceOnSingleAt: false,
        }
    }

    const quillText: string = quill?.getText() || ''
    const text = getStableText(quill) || ''
    const cursorPosIdx = quill?.getSelection()?.index || 0
    const textBeforeCursor = text?.slice(0, cursorPosIdx) || ''

    // Check if we've just deleted a character by comparing with last state
    const hasDeletedChar = lastTextBeforeCursor.length > textBeforeCursor.length

    // Check specific pattern: if we're at @ and the previous state was @@
    const hasDeletedSecondAt =
        hasDeletedChar && textBeforeCursor.endsWith('@') && lastTextBeforeCursor.endsWith('@@')

    // Update the last state for next check
    lastTextBeforeCursor = textBeforeCursor

    // Use regex to find @ patterns before cursor
    const doubleAtRegex = /@@([^@\s]*)$/
    const singleAtRegex = isAndroid ? /@([^@]*)$/ : /@([^@\s]*?)$/

    // Check for @@ followed by any text
    const doubleAtMatch = textBeforeCursor?.match(doubleAtRegex)

    // Check for single @ if:
    // 1. We don't have a double @ match, AND
    // 2. We haven't just deleted a character from @@
    const singleAtMatch =
        !doubleAtMatch && !hasDeletedSecondAt ? textBeforeCursor?.match(singleAtRegex) : null

    // MOST IMPORTANT CHECK: If we detect a single @ and the last key pressed was Backspace,
    // it's very likely the user just deleted from @@ to @
    const isBackspaceOnSingleAt =
        textBeforeCursor?.endsWith('@') &&
        !textBeforeCursor?.endsWith('@@') &&
        lastKeyPressed === 'Backspace'

    // Determine which trigger to use
    const isDoublePopup = !!doubleAtMatch
    const isSinglePopup = !!singleAtMatch && !isBackspaceOnSingleAt

    // Set appropriate values based on which popup we're showing
    // If we've just deleted from @@ to @, force popup to be hidden
    let popupShouldShow =
        isBackspaceOnSingleAt || hasDeletedSecondAt ? false : isDoublePopup || isSinglePopup
    let popupInput = ''
    let popupStartIdx = -1
    let currentTrigger = isDoublePopup ? doublePopupTrigger : popupTrigger

    if (isDoublePopup) {
        popupInput = doubleAtMatch[1] || ''
        popupStartIdx = textBeforeCursor.lastIndexOf('@@')
    } else if (isSinglePopup) {
        popupInput = singleAtMatch[1] || ''
        popupStartIdx = textBeforeCursor.lastIndexOf('@')
    }

    const extractItemVal = item => {
        // Safety check
        if (!item) return ''

        // We no longer need to differentiate between models and users here
        // since we're handling the proper insertion format in finalizeSelection
        // This simplifies the logic and makes it more consistent
        return item?.value || ''
    }

    const info = {
        popupTrigger: currentTrigger,
        popupShouldShow,
        extractItemVal,
        text,
        quillText,
        textBeforeCursor,
        popupStartIdx,
        popupInput,
        cursorPosIdx,
        isDoublePopup,
        hasDeletedSecondAt,
        isBackspaceOnSingleAt,
    }

    return info
}

function getPopupCoords(quill) {
    // TODO: use quill's own .getBounds() instead of the dom one
    const domSelection = window.getSelection().getRangeAt(0).getBoundingClientRect()
    let { top, left } = domSelection

    if (top == 0 && left == 0) {
        // if poupTrigger is the first char in the <input> getSelection()'s
        // getBoundingClientRect() always returns 0, so use the editor
        // element's getBoundingClientRect() instead
        const coords = quill.container.getBoundingClientRect()
        top = coords?.top
        left = coords?.left
    }
    return { top, left }
}

// Create a simple error logging wrapper to help with debugging
const logError = (message, data = {}) => {
    console.error(`[Mention System Error] ${message}`, data)

    // You could also send these errors to your monitoring system
    if ((window as any).Sentry) {
        try {
            ;(window as any).Sentry.captureMessage(message, {
                level: 'error',
                extra: data,
            })
        } catch (e) {
            // Ignore Sentry errors
        }
    }
}

// Update finalizeSelection with better error handling

// Move this into the component so it has access to props
const autocompletePopupHandlerFunction = (quill, userValues, modelValues, setAgentModel) => {
    // Make sure we have valid input data
    if (!quill || !Array.isArray(userValues) || !Array.isArray(modelValues)) {
        console.error('Invalid parameters for autocompletePopupHandler', {
            quill,
            userValues,
            modelValues,
        })
        return
    }

    const popupInfo = getPopupInfo(quill)
    const {
        popupTrigger,
        extractItemVal,
        popupShouldShow,
        popupStartIdx,
        popupInput,
        isDoublePopup,
        textBeforeCursor,
        hasDeletedSecondAt,
        isBackspaceOnSingleAt,
    } = popupInfo

    //console.log('autocompletePopupHandlerFunction popupInfo', popupInfo)

    // NEW: If backspace was pressed and we're at @ (not @@), always close the popup
    const lastChar = textBeforeCursor.slice(-1)
    if (lastChar === ' ') {
        // console.log('Detected backspace from @@ to @, forcing popup closed');
        updatePopupProps({
            ...getPopupProps(),
            doCancel: 'Space',
        })
        return
    }

    if (isBackspaceOnSingleAt || hasDeletedSecondAt) {
        // console.log('Detected backspace from @@ to @, forcing popup closed');
        updatePopupProps({
            ...getPopupProps(),
            showing: false,
        })
        return
    }

    const { top, left } = getPopupCoords(quill)

    // Always select the appropriate values list based on current trigger
    let values = isDoublePopup ? modelValues : userValues

    // Format values for display with appropriate prefix based on type
    if (isDoublePopup) {
        // For models, show @@ prefix
        values = values.map(model => ({
            ...model,
            // Add special flag to indicate this is a model (no user icon needed)
            isModel: true,
            // Add a renderHTML property to control how it appears in the popup
            // Add data-model attribute to help CSS target this specifically
            renderHTML: `<span class="${styles.modelCandidate}" data-item-type="model"><span class="${styles.modelPrefix}">@@</span>${model.content}</span>`,
        }))

        // Filter models if needed (only for double popup with input)
        if (popupInput) {
            const searchTerm = popupInput.toLowerCase()

            // Simple and effective filtering approach
            values = values.filter(model => {
                const modelName = model.content.toLowerCase()
                const searchableName = (model.searchableName || '').toLowerCase()

                return (
                    modelName.includes(searchTerm) ||
                    searchableName.includes(searchTerm.replace(/[^a-z0-9]/g, ''))
                )
            })

            // If no results, show a helpful message
            if (values.length === 0) {
                values = [
                    {
                        value: 'no-match',
                        content: 'No matching models found',
                        id: 'no-match',
                        isNoResults: true,
                        renderHTML: `<span class="${styles.noResults}">No matching models found</span>`,
                    },
                ]
            }
        }
    } else {
        // For users, show single @ prefix
        values = values.map(user => {
            // Make sure content doesn't already have @ to avoid double @@ for users
            const displayContent = user.content.startsWith('@')
                ? user.content.substring(1) // Remove the @ if it exists
                : user.content

            return {
                ...user,
                // Explicitly mark as user type
                isModel: false,
                // This ensures a single @ is shown in the popup
                renderHTML: `<span class="${styles.userCandidate}" data-item-type="user"><span class="${styles.userPrefix}">@</span>${displayContent}</span>`,
            }
        })

        // Filter users if needed
        if (popupInput) {
            const searchTerm = popupInput.toLowerCase()

            // Simple filtering for users
            values = values.filter(user => {
                // Extract name without @ for matching
                const userName = user.content.startsWith('@')
                    ? user.content.substring(1).toLowerCase()
                    : user.content.toLowerCase()

                return userName.includes(searchTerm)
            })

            // If no results, show a helpful message
            if (values.length === 0) {
                values = [
                    {
                        value: 'no-match',
                        content: 'No matching users found',
                        id: 'no-match',
                        isNoResults: true,
                        renderHTML: `<span class="${styles.noResults}">No matching users found</span>`,
                    },
                ]
            }
        }
    }

    const finalizeSelection = (quill, item, searchTerm, callback) => {
        // Add a null check to prevent the TypeError
        if (!item) {
            logError('finalizeSelection called with undefined item', { quill, searchTerm })
            return
        }

        if (item.isNoResults) return

        try {
            const triggerPosition = popupStartIdx
            const isDoubleAtModel = item.isModel === true || item.denotationChar === '@@'
            const triggerLength = isDoubleAtModel ? 2 : 1 // @ or @@

            //console.log('finalizeSelection: triggerPosition', triggerPosition, 'triggerLength', triggerLength, 'searchTerm.length', searchTerm, searchTerm.length)

            // Get current editor contents
            const newDelta = new Delta()

            // Retain content up to the trigger position
            if (triggerPosition > 0) {
                newDelta.retain(triggerPosition)
            }

            // Delete the trigger and search term
            newDelta.delete(triggerLength + (searchTerm.length || 0))

            //console.log('finalizeSelection: newDelta', newDelta)

            if (isDoubleAtModel) {
                // For model mentions: Only delete the trigger text
                // The handleModelMention effect will insert the mention in response to the state change

                // Apply the deletion
                quill.updateContents(newDelta, Quill.sources.USER)

                // Close the popup first
                updatePopupProps({
                    ...getPopupProps(),
                    showing: false,
                })

                // Make sure selection is positioned at the trigger position first
                // This ensures that when handleModelMention runs, it will insert at the right position
                quill.setSelection(triggerPosition, 0, Quill.sources.USER)

                // Use a small timeout to ensure the selection is set before we trigger the state change
                setTimeout(() => {
                    // Call the callback to update global state
                    // This will trigger handleModelMention to insert the mention
                    if (callback && item.id) {
                        callback(item)
                    }
                }, 100)
            } else {
                // For user mentions: Insert the mention directly in the editor
                const mentionObj = {
                    id: item.id,
                    value: item.value || item.id,
                    denotationChar: '@',
                    link: `/users/${item.id}`,
                    content: item.content,
                }

                // Insert the mention and a space
                newDelta.insert({ mention: mentionObj }).insert(' ')

                // On Android, we need to ensure the selection is properly set before updating content
                if (isAndroid) {
                    // First set the selection to ensure proper positioning
                    quill.setSelection(triggerPosition, 0, Quill.sources.USER)

                    // Use a small delay to ensure selection is set
                    setTimeout(() => {
                        // Update content with the mention
                        quill.updateContents(newDelta, Quill.sources.USER)

                        // Close the popup
                        updatePopupProps({
                            ...getPopupProps(),
                            showing: false,
                        })

                        // Calculate final position: trigger position + 1 (for mention) + 1 (for space)
                        const finalPosition = triggerPosition + 2

                        // Set final cursor position after a small delay
                        setTimeout(() => {
                            // Only set selection if there's not an active swiper transition
                            const swiperRef = getSwiperRef()?.current
                            const isSwiping = swiperRef && swiperRef.animating

                            if (!isSwiping) {
                                quill.setSelection(finalPosition, 0, Quill.sources.USER)
                            }
                        }, 50)
                    }, 50)
                } else {
                    //console.log('finalizeSelection: non-Android platform, using original flow', newDelta)
                    // For non-Android platforms, use the original flow
                    quill.updateContents(newDelta, Quill.sources.USER)

                    // Close the popup first
                    updatePopupProps({
                        ...getPopupProps(),
                        showing: false,
                    })

                    // Calculate final position: trigger position + 1 (for mention) + 1 (for space)
                    const finalPosition = triggerPosition + 2

                    // Use requestAnimationFrame for more predictable timing
                    requestAnimationFrame(() => {
                        // Only set selection if there's not an active swiper transition
                        const swiperRef = getSwiperRef()?.current
                        const isSwiping = swiperRef && swiperRef.animating

                        if (!isSwiping) {
                            // Set cursor position after both the mention and space
                            quill.setSelection(finalPosition, 0, Quill.sources.USER)
                        }
                    })
                }
            }
        } catch (error) {
            logError('Error in finalizeSelection', { error, item, searchTerm })
        }
    }

    // Update the boundFinalizeSelection to use the direct callback
    const boundFinalizeSelection = (item, searchTermParam) => {
        if (!item) {
            console.error('Invalid item in boundFinalizeSelection')
            return
        }
        // Pass the setAgentModel callback directly
        return finalizeSelection(quill, item, searchTermParam || popupInput, mention => {
            // If this is a model mention, notify parent components using the callback
            if (mention.denotationChar === '@@' && mention.id && setAgentModel) {
                setAgentModel(mention.id)
            }
        })
    }

    // Create custom renderItem functions based on whether we're showing models or users
    // This is the key change - we'll use a custom renderer for models that doesn't show user icons
    const renderItem = item => {
        if (isDoublePopup || item.isModel) {
            // For models, render a custom element without user icons
            return (
                <div className={styles.modelItem} data-item-type="model">
                    <span className={styles.modelPrefix}>@@</span>
                    <span className={styles.modelName}>{item.content}</span>
                </div>
            )
        } else {
            // For users, use the default UserSelector
            return <UserSelector user={item} />
        }
    }

    const renderHighlightedItem = item => {
        if (isDoublePopup || item.isModel) {
            // For models, render a custom element with highlighted styles
            return (
                <div className={`${styles.modelItem} ${styles.selected}`} data-item-type="model">
                    <span className={styles.modelPrefix}>@@</span>
                    <span className={styles.modelName}>{item.content}</span>
                </div>
            )
        } else {
            // For users, use the default highlighted UserSelector
            return <UserSelector user={item} selected={true} />
        }
    }

    updatePopupProps({
        ...getPopupProps(),
        popupTrigger,
        finalizeSelection: boundFinalizeSelection,
        extractItemVal,
        renderItem,
        renderHighlightedItem,
        ...(popupShouldShow
            ? {
                  showing: true,
                  y: top,
                  x: left,
                  inp: popupInput,
                  values: values,
                  isDoublePopup,
              }
            : {
                  showing: false,
              }),
    })
}

const CommonEditor = React.forwardRef<Quill, { [key: string]: any }>((props, ref) => {
    const edit = props?.readOnly ? '' : 'edit'
    const localQuillRef = useRef<Quill>(null)

    // Helper function to get the Quill editor instance
    const getQuillEditor = () => localQuillRef.current

    // Compute the default value for the uncontrolled editor
    const defaultValue = normalizeExistingDelta(props.defaultValue)
    //console.log('defaultValue', defaultValue)

    // Track previous model state to detect changes, and processing flag
    const isProcessingModelRef = useRef(false)
    const prevModelRef = useRef(props.globalAgentModel)
    const hasInitializedRef = useRef(false)

    // Replace the link preview refs with a single debounced function ref
    const handleLinksRef = useRef(null)
    // Add debounced reference for KnovQuest links
    const handleKnovQuestLinksRef = useRef(null)

    // Helper to get current content directly from editor
    const getCurrentContent = useCallback(() => {
        const quill = getQuillEditor()
        // If editor is unavailable, return defaultValue as fallback
        if (!quill) return defaultValue

        const contents = quill.getContents()
        return normalizeExistingDelta(contents)
    }, [defaultValue])

    // Add type for mention object
    interface ModelMention {
        denotationChar: string
        id: string
        value: string
        type: string
        link: string
    }

    // Add the getMentions function that returns an array of model IDs
    const getMentions = () => {
        const editor = getQuillEditor()
        if (!editor) return []
        const contents = editor.getContents()

        const mentions = []
        // Find all model mentions
        for (const op of contents.ops || []) {
            if (!op.insert || typeof op.insert !== 'object') continue
            const insert = op.insert as { mention?: ModelMention }
            if (insert.mention?.denotationChar === '@@') {
                mentions.push(insert.mention.id)
            }
        }

        return mentions // Return array of mention IDs
    }

    // Create checkForModelMention that returns boolean based on getMentions length
    const checkForModelMention = () => {
        return getMentions().length > 0
    }

    // Helper to get the first model mention ID or null
    const getFirstModelMention = () => {
        const mentions = getMentions()
        return mentions.length > 0 ? mentions[0] : null
    }

    const filter = props.quest ? questModel.getFilter(props.quest) : null
    const users = useStore(state => state.users || [])
        .filter(user => {
            if (filter?.team_ids) {
                return user?.space_team_ids?.includes(filter?.team_ids?.[0])
            }
            return true
        })
        .map(user => ({
            ...user,
            value: `@${user.name}`,
            content: `@${user.name}`,
            link: `/users/${user.name}`,
        }))

    // Create a simplified, formatted list of AI models and prepare user mentions
    const modelsList = Object.values(LLMModels).map(model => ({
        denotationChar: '@@',
        value: `${model}`, // Store just the model name without trigger
        rawValue: model, // The raw model name for references
        content: model, // Display name without trigger
        id: model,
        name: model, // For consistency with user records
        searchableName: (model as string).toLowerCase().replace(/[^a-z0-9]/g, ''), // For matching
        isModel: true, // Flag as model
    }))

    // Update how users are mapped to ensure proper formatting for mentions
    const userMentions = users.map(user => {
        // Ensure the name doesn't already have an @ to avoid double @
        const displayName = user.name.startsWith('@') ? user.name : user.name

        return {
            ...user,
            value: displayName, // Just the name without @ for the value
            content: displayName, // Just the name for display content
            link: `/users/${user.name}`,
            isModel: false, // Explicitly mark as not a model
        }
    })

    useEffect(
        function initGlobalQuillHandlers() {
            const editor = getQuillEditor()
            if (!editor) return

            const handleKeyDown = e => {
                const { showing: popupShowing, popupTrigger } = getPopupProps()
                //console.log('popupShowing', `'${popupShowing}'`)

                // Track the last key pressed globally for our popup logic
                lastKeyPressed = e.key
                //console.log('lastKeyPressed', `lastKeyPressed: '${lastKeyPressed}'`)

                // when popup is up make sure special keys are handled by
                // the popup's handler and don't bubble up to the newanswer
                // handler
                if (
                    popupShowing &&
                    (confirmKeys?.includes(e?.key) ||
                        //continueKeys?.includes(e?.key) ||
                        cancelKeys?.includes(e?.key) ||
                        navKeys?.includes(e?.key))
                ) {
                    e.preventDefault()
                    e.stopPropagation()

                    if (confirmKeys?.includes(e?.key)) {
                        updatePopupProps({ doSelect: e?.key })
                    }

                    //if (continueKeys?.includes(e?.key)) {
                    //    updatePopupProps({ doContinueKey: e?.key })
                    //}

                    if (navKeys?.includes(e?.key)) {
                        updatePopupProps({ doNavKey: e?.key })
                    }

                    if (cancelKeys?.includes(e?.key)) {
                        updatePopupProps({ doCancel: e?.key })

                        // Clean up any broken mention tags that might have been created
                        // This ensures the trigger text is kept as plain text when ESC is pressed
                        setTimeout(() => {
                            const selection = editor.getSelection()
                            if (selection) {
                                // Look for single @ or double @@ before cursor
                                const editorText = getStableText(editor)
                                const text = editorText.slice(0, selection.index)
                                const lastAt = text.lastIndexOf('@')
                                if (lastAt >= 0 && lastAt > text.length - 10) {
                                    // Check if we have a broken mention tag (just @ or @@ without content)
                                    const contents = editor.getContents(
                                        lastAt,
                                        selection.index - lastAt,
                                    )
                                    const hasIncompleteMention = contents.ops.some(op => {
                                        if (typeof op.insert !== 'object' || op.insert === null)
                                            return false
                                        return 'mention' in op.insert // If there's any mention object, we'll replace it
                                    })

                                    if (hasIncompleteMention) {
                                        // Replace the broken mention with plain text
                                        const isDoubleAt = lastAt > 0 && text[lastAt - 1] === '@'
                                        const plainText = isDoubleAt ? '@@' : '@'
                                        const delta = new Delta()
                                            .retain(lastAt)
                                            .delete(selection.index - lastAt)
                                            .insert(plainText)

                                        editor.updateContents(delta, 'user')
                                        editor.setSelection(lastAt + plainText.length, 0)
                                    }
                                }
                            }
                        }, 30)
                    }
                }
            }

            // handle data url imgs getting added. adapted from [1]
            // [1]: https://github.com/quilljs/quill/issues/1089#issuecomment-614313509
            editor.root.addEventListener('keydown', handleKeyDown)

            const handleMentionClick = debounce(event => {
                // Access the value from the CustomEvent detail
                const detail = event.detail

                // Handle different types of mentions
                if (detail?.type === 'user' && detail?.id) {
                    // Original user mention behavior

                    const panels = useStore.getState().panels
                    const panelId = detail.panelId
                    // Inserting vs Appending?
                    const indexBeforeChange = panels.getIndexById(panelId)
                    const clickedRightPanel = indexBeforeChange > getCenterIndex()
                    // Optimization: start the slide animation before inserting the panel.
                    panels.insertPanelRight(
                        panelId,
                        { filter: { user: detail.id } },
                        { animate: !isMobile && !clickedRightPanel },
                    )
                }
            }, 200)

            window.addEventListener('mention-clicked', handleMentionClick)

            // Cleanup function to remove event listener when component unmounts
            return () => {
                editor.root.removeEventListener('keydown', handleKeyDown)
                window.removeEventListener('mention-clicked', handleMentionClick)
            }
        },
        [getQuillEditor],
    )

    useEffect(() => {
        if (props.captureSetValue) {
            // Provide a method to set editor content programmatically
            props.captureSetValue(newValue => {
                const quill = getQuillEditor()
                if (quill) {
                    // Update the editor directly
                    const normalizedValue = normalizeExistingDelta(newValue)
                    quill.setContents(normalizedValue)
                }
            })
        }
    }, [props.captureSetValue])

    /* Quill (unhelpfully in our case) converts images pasted from the
     * clipboard into base64 encoded data urls which were polluting the db with
     * massive json objects. This function converts them to standard browser
     * file/blob objects, and then passes them to the parent's imgHandler fn
     * for normal processing */
    async function processBase64Img(base64Str) {
        if (typeof base64Str !== 'string' || base64Str.length < 100) {
            return base64Str
        }
        const blob = dataUrlToBlob(base64Str)

        // make a synthetic event to pass to the parent's imgHandler
        const pseudoEv = {
            preventDefault: () => {},
            stopPropagation: () => {},
            target: { files: [blob] },
        }
        props.imgHandler(pseudoEv)
    }

    // Add text state tracking
    let lastTextState = ''
    let isProcessingTextChange = false
    let pendingFormatting = false

    // Create the debounced function once and store it in the ref
    useEffect(() => {
        // Create the debounced function
        handleLinksRef.current = debounce(
            nonKnovLinks => {
                const editorFingerprint = generateFingerprint(nonKnovLinks)
                const hasChanges = props.linkPreviewsFingerprint !== editorFingerprint

                // Only update if links have actually changed
                if (hasChanges && props.setLinkPreviews) {
                    props.setLinkPreviews(uniq(nonKnovLinks))
                }
            },
            500,
            {
                leading: false,
                trailing: true,
            },
        )

        // Create debounced function for KnovQuest links
        handleKnovQuestLinksRef.current = debounce(
            async knovEmbedLinks => {
                const answer = props.answer
                const shortCircuit =
                    answer?.is_transcription || answer?.mp4_recording_url || answer?.recording_url
                if (shortCircuit) return // don't do this for transcriptions

                const editorFingerprint = generateFingerprint(knovEmbedLinks)
                const hasChanges = props.embedsFingerprint !== editorFingerprint

                if (hasChanges) {
                    // convert quest URLs to obscureIds, and then request quests from backend
                    const questPromises = knovEmbedLinks
                        ?.map(link => (link || '')?.split('/').pop())
                        ?.map(api.getQuest)

                    // and finally add the embedded quests to the draft
                    return Promise.all(questPromises).then(embeds => {
                        embeds = embeds.map(e => e?.parent).filter(e => !!e)
                        props?.setEmbeds?.(embeds)
                    })
                }
            },
            500,
            {
                leading: false,
                trailing: true,
            },
        )

        // Cleanup function
        return () => {
            if (handleLinksRef.current) {
                handleLinksRef.current.cancel()
            }
            if (handleKnovQuestLinksRef.current) {
                handleKnovQuestLinksRef.current.cancel()
            }
        }
    }, [props.linkPreviewsFingerprint, props.embedsFingerprint])

    // Handle text changes from Quill
    async function handleTextChange(delta, oldContents, source) {
        const quill = localQuillRef.current
        if (!quill) {
            return
        }

        // Handle image processing
        Array.from(quill.container.querySelectorAll('img[src^="data:"]:not(.loading)')).forEach(
            async img => {
                await processBase64Img(img.getAttribute('src'))
                img.remove()
            },
        )

        if (source === 'user') {
            // Prevent concurrent text change processing
            if (isProcessingTextChange) {
                return
            }
            isProcessingTextChange = true

            try {
                // Check for mention deletions
                const isDeletion = delta.ops.some(op => op.delete)
                if (isDeletion) {
                    const hasModelMention = checkForModelMention()
                    if (!hasModelMention && props.setAgentModel) {
                        props.setAgentModel(null)
                    }
                }

                // Get current text state
                const currentText = getStableText(quill)

                if (isAndroid) {
                    lastTextState = currentText
                    setTimeout(() => {
                        autocompletePopupHandlerFunction(
                            quill,
                            userMentions,
                            modelsList,
                            props.setAgentModel,
                        )
                    }, 30)
                } else if (currentText !== lastTextState) {
                    lastTextState = currentText
                    setTimeout(() => {
                        autocompletePopupHandlerFunction(
                            quill,
                            userMentions,
                            modelsList,
                            props.setAgentModel,
                        )
                    }, 30)
                }

                const { knovEmbedLinks, nonKnovLinks } = extractUrls(currentText)
                // Handle links and memes
                handleKnovQuestLinksRef?.current?.(knovEmbedLinks)
                handleLinksRef?.current?.(nonKnovLinks)

                processMemeMatches(quill)

                // Handle formatting with proper timing
                if (!pendingFormatting) {
                    pendingFormatting = true
                    requestAnimationFrame(() => {
                        const text = getStableText(quill)
                        const re = /\B\!\w+/g
                        let match

                        // Process formatting
                        while ((match = re.exec(text)) != null) {
                            quill.formatText(match.index, match[0].length, 'bold', true)
                            quill.removeFormat(match.index - 1, 1)
                            if (match.index + match[0].length < quill.getLength() - 1)
                                quill.removeFormat(match.index + match[0].length, 1)
                        }

                        pendingFormatting = false
                    })
                }

                // Notify parent of content changes
                if (props.onChange) {
                    const contents = quill.getContents()
                    const normalizedContents = normalizeExistingDelta(contents)
                    props.onChange(normalizedContents)
                }
            } finally {
                isProcessingTextChange = false
            }
        }
    }

    // Handle key down events
    function handleKeyDown(quill, ev) {
        const enterPostsImmediatelyEnabled = gon?.currentUser?.features?.some?.(
            f => f.name === 'enter_key_posts_immediately',
        )
        const isMac = navigator.platform.includes('Mac')
        const modifierKey = isMac ? ev.metaKey : ev.ctrlKey

        // determine if this key combo should trigger a post
        let shouldSubmit = false
        if (ev.key === 'Enter') {
            if (props.type === 'search') {
                // enter always submits while searching
                shouldSubmit = true
            } else if (enterPostsImmediatelyEnabled) {
                // post on enter, newline on shift+enter
                shouldSubmit = !ev.shiftKey
            } else {
                // post on cmd/ctrl+enter on desktop, shift+enter on mobile. newline on enter
                shouldSubmit = isMobile ? ev.shiftKey : modifierKey
            }
        }

        // handle post if conditions are met
        if (shouldSubmit && props.postHandler && props.type !== 'import') {
            props.postHandler()
            ev.preventDefault()
            ev.stopPropagation()
            return false
        }
    }

    const isMobileClass = isMobile ? 'is-mobile' : ''

    // Always get content directly from the editor
    const currentContent = getCurrentContent()
    const trailingLinksCleanedValue = cleanTrailingLinks(currentContent)
    const shouldHide =
        // hide the answer when cleanTrailingLinks() removed something but
        // we're not rendering in an embed and not currently being edited
        !isEqual(currentContent, trailingLinksCleanedValue) && !props.isEmbed && props.readOnly
    const showableValue = shouldHide ? trailingLinksCleanedValue : currentContent
    // Forward ref to the Quill instance
    useEffect(() => {
        // Expose the ref to parent components via forwardRef
        if (ref && typeof ref === 'object' && 'current' in ref) {
            const quill = localQuillRef.current
            if (quill) {
                // Add compatibility methods
                const enhancedQuill = quill as any
                enhancedQuill.getEditor = () => quill

                // Set the ref
                ref.current = enhancedQuill
            }
        }
    }, [ref, localQuillRef.current])

    // Register our custom formats before editor initialization
    useEffect(() => {
        // Add CSS class mapping to connect CSS modules with Quill's class expectations
        // This step ensures that when Quill applies its own classes, they match our CSS module classes
        const root = document.documentElement
        root.style.setProperty('--mention-class', styles.mention)
        root.style.setProperty('--model-mention-class', styles.modelMention)

        return () => {
            // Any cleanup code needed
        }
    }, []) // Empty dependency array so it only runs once

    // Unified mention management effect that handles globalAgentModel changes
    useEffect(
        function handleModelMention() {
            const editor = getQuillEditor()
            if (!editor || props.type !== 'new-answer') {
                return
            }

            const swiperRef = getSwiperRef()?.current
            if (!swiperRef) {
                return
            }

            let isSwiping = false
            let pendingUpdate = false

            // Track swiper state
            const handleTransitionStart = () => {
                isSwiping = true
            }

            // Queue the mention update to run after swiper transition
            const handleMentionUpdate = () => {
                // If swiping, just mark that an update is pending and return
                if (isSwiping) {
                    pendingUpdate = true
                    return
                }

                // Prevent reentrant calls
                if (isProcessingModelRef.current) {
                    return
                }

                isProcessingModelRef.current = true
                pendingUpdate = false

                const currentModel = props.globalAgentModel
                const prevModel = prevModelRef.current

                // Check if the current model exists in any mention
                const mentions = getMentions()
                const existingMention =
                    currentModel && mentions.includes(currentModel)
                        ? currentModel
                        : getFirstModelMention()

                // Check if we have a model mention in the initial delta_json
                const initialDeltaJson = props.value
                const hasInitialModelMention = initialDeltaJson?.ops?.some(
                    op => op.insert?.mention?.denotationChar === '@@',
                )

                // Detect if this is a user-initiated model change (toggle or selection)
                const isModelToggleOrChange = prevModel !== currentModel

                // Update the stored previous model value for next comparison
                prevModelRef.current = currentModel

                // Logic to decide when to modify the editor:
                // 1. If user toggled the model (from UI), always update
                // 2. If editor state doesn't match model state during initialization, respect initial content
                // 3. After initialization, sync model state with editor state

                const shouldModify =
                    // Case 1: User toggled/changed the model explicitly
                    isModelToggleOrChange ||
                    // Case 2 & 3: Content doesn't match desired state, but respect initial content
                    (currentModel !== existingMention &&
                        // Only ignore if it's first run AND has initial mention
                        !(hasInitialModelMention && !hasInitializedRef.current))

                if (shouldModify) {
                    if (currentModel) {
                        // Should have mention but doesn't - add it
                        const contents = editor.getContents()

                        // Get the selection right at this moment for the most accurate position
                        // This is especially important when called right after an autocomplete selection
                        const selection = editor.getSelection(true)
                        const currentPosition = selection ? selection.index : editor.getLength()

                        const modelMention = createModelMention(props.globalAgentModel)
                        let mentionDelta = null

                        if (isEmpty(contents)) {
                            mentionDelta = new Delta().insert({ mention: modelMention }).insert(' ')
                        } else {
                            // Create a delta that preserves content before and after the insertion point
                            mentionDelta = new Delta()

                            // First retain everything up to the insertion point
                            if (currentPosition > 0) {
                                mentionDelta.retain(currentPosition)

                                // Check if we need to add a space before the mention
                                if (currentPosition > 0) {
                                    // Get the character right before the cursor
                                    const textContent = getStableText(editor)
                                    const charBeforeCursor =
                                        currentPosition > 0
                                            ? textContent.charAt(currentPosition - 1)
                                            : ''

                                    // If the character before cursor is not a space or empty, add a space
                                    if (
                                        charBeforeCursor &&
                                        charBeforeCursor !== ' ' &&
                                        charBeforeCursor !== '\\n' // Escaped newline
                                    ) {
                                        mentionDelta.insert(' ')
                                    }
                                }
                            }

                            // Insert the mention and space
                            mentionDelta.insert({ mention: modelMention }).insert(' ')
                        }

                        // Perform all DOM updates in a batch using requestAnimationFrame
                        // to minimize layout thrashing - use shorter timing to be responsive
                        // after autocomplete selection
                        setTimeout(() => {
                            // Update editor content - silence the update to avoid triggering text-change handlers
                            editor.updateContents(mentionDelta, Quill.sources.USER)

                            // In next frame, handle selection and layout updates
                            requestAnimationFrame(() => {
                                // Only set selection if we're not currently swiping
                                if (!isSwiping) {
                                    const newPosition = currentPosition + 2 // Adjusted for potential preceding space + mention + trailing space? Check logic carefully
                                    editor.setSelection(newPosition, 0, Quill.sources.USER)
                                } else {
                                }

                                // Close any open popup
                                updatePopupProps({
                                    ...getPopupProps(),
                                    showing: false,
                                })

                                // Force swiper to update its layout only if not in transition
                                if (swiperRef && !isSwiping) {
                                    // Defer layout updates to next frame
                                    requestAnimationFrame(() => {
                                        swiperRef.update()
                                    })
                                } else {
                                }

                                // Mark this model state as processed
                                isProcessingModelRef.current = false
                            })
                        }, 10) // Very short timeout to ensure we get accurate position
                    } else if (existingMention) {
                        // Shouldn't have mention but does - remove it
                        removeModelMentions()
                        // Mark as processed after removal
                        isProcessingModelRef.current = false
                    } else {
                        // No changes needed
                        isProcessingModelRef.current = false
                    }
                } else {
                    // No changes needed, mark as processed
                    isProcessingModelRef.current = false
                }

                // Mark initialization as complete after first run
                if (!hasInitializedRef.current) {
                    hasInitializedRef.current = true
                }
            }

            const handleTransitionEnd = () => {
                isSwiping = false

                // If we had a pending update, process it now
                if (pendingUpdate) {
                    // Wait for next frame to ensure swiper is fully settled
                    requestAnimationFrame(() => {
                        handleMentionUpdate()
                    })
                }
            }

            // Set up event listeners
            swiperRef.on('setTransition', handleTransitionStart)
            swiperRef.on('slideChangeTransitionEnd', handleTransitionEnd)
            // Also listen for touchStart/End to better detect active user interaction
            swiperRef.on('touchStart', handleTransitionStart)
            swiperRef.on('touchEnd', handleTransitionEnd)

            // Listen for DOM mutations that might affect layout
            swiperRef.on('observerUpdate', () => {
                if (!isSwiping) {
                    swiperRef.update()
                }
            })

            // Run initial update if needed, but wait for next frame and check
            // if swiper is currently animating before performing updates
            requestAnimationFrame(() => {
                if (!swiperRef.animating) {
                    handleMentionUpdate()
                } else {
                    // If swiper is animating, mark pending and wait for transition end
                    pendingUpdate = true
                }
            })

            // Cleanup
            return () => {
                swiperRef.off('setTransition', handleTransitionStart)
                swiperRef.off('slideChangeTransitionEnd', handleTransitionEnd)
                swiperRef.off('touchStart', handleTransitionStart)
                swiperRef.off('touchEnd', handleTransitionEnd)
                swiperRef.off('observerUpdate') // Ensure observer listener is removed too
            }
        },
        [props.globalAgentModel, getQuillEditor], // Dependencies remain the same
    )

    // Function to remove all model mentions from the editor
    const removeModelMentions = () => {
        const editor = getQuillEditor()
        if (!editor) return

        // Only proceed if there's something to remove
        if (!checkForModelMention()) return

        const contents = editor.getContents()
        const ops = contents.ops || []
        const selection = editor.getSelection()
        const currentPosition = selection ? selection.index : 0

        // Create a new delta without model mentions
        let newOps = []
        let skipNextSpace = false
        let removedMentionCount = 0

        for (let i = 0; i < ops.length; i++) {
            const op = ops[i]

            // Check if this op is a model mention
            const isModelMention =
                op.insert &&
                typeof op.insert === 'object' &&
                'mention' in op.insert &&
                typeof op.insert.mention === 'object' &&
                op.insert.mention &&
                'denotationChar' in op.insert.mention &&
                (op.insert.mention as { denotationChar: string }).denotationChar === '@@'

            if (isModelMention) {
                removedMentionCount++
                skipNextSpace = true
                continue
            }

            // Handle the space after mention
            if (skipNextSpace && typeof op.insert === 'string' && op.insert.startsWith(' ')) {
                // Remove only the first space after mention
                if (op.insert.length > 1) {
                    newOps.push({ ...op, insert: op.insert.substring(1) })
                }
                skipNextSpace = false
                continue
            }

            newOps.push(op)
        }

        // If we removed mentions, update the editor
        if (removedMentionCount > 0) {
            const newDelta = new Delta()
            newDelta.ops = newOps

            // Use requestAnimationFrame for better timing and check for swiper state
            requestAnimationFrame(() => {
                // Get the swiper ref and check if it's animating
                const swiperRef = getSwiperRef()?.current
                const isSwiping = swiperRef && swiperRef.animating

                // Update content using SILENT source to prevent unnecessary handlers
                editor.setContents(newDelta, Quill.sources.USER)

                // Notify parent that model mention was removed
                if (props.setAgentModel) {
                    props.setAgentModel(null)
                }

                // Only update selection if we're not swiping
                if (!isSwiping) {
                    // Adjust position by subtracting 2 for each mention removed (mention + space)
                    const adjustedPosition = Math.max(0, currentPosition - removedMentionCount * 2)
                    editor.setSelection(adjustedPosition, 0, Quill.sources.USER)
                }

                // Update swiper if necessary, but only if not in transition
                if (swiperRef && !isSwiping) {
                    // Defer layout updates to next frame
                    requestAnimationFrame(() => {
                        swiperRef.update()
                    })
                }
            })
        }
    }

    return (
        <div style={props.readOnly && isEmpty(showableValue) ? { display: 'none' } : {}}>
            <div
                className={cn(`common-editor-comp ${edit} fs-mask`, isMobileClass, props.type)}
                data-paste-image={props.uiId}
                onClick={squashImage}
            >
                <QuillEditor
                    ref={localQuillRef}
                    defaultValue={defaultValue}
                    readOnly={props.readOnly}
                    placeholder={edit ? props.placeholder : ''}
                    onTextChange={handleTextChange}
                    onKeyDown={handleKeyDown}
                    style={props.style}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    onEditorLoad={props.onEditorLoad}
                />
            </div>
        </div>
    )
})

const getTextAndEmbeds = quill => {
    if (!quill || !quill.getContents || !quill.getContents().ops) {
        return ''
    }

    return quill.getContents().ops.reduce((text, op) => {
        if (typeof op.insert === 'string') {
            // Preserve spaces exactly as they are
            return text + op.insert
        } else if (op.insert && typeof op.insert === 'object') {
            // Handle embeds and mentions
            if (op.insert.mention) {
                return text + '@'
            }
            return text + ' '
        }
        return text
    }, '')
}

// Alternative to matchAll for older ECMAScript versions
function findAllMatches(text, regex) {
    if (!regex.global) {
        regex = new RegExp(regex.source, regex.flags + 'g')
    }

    const matches = []
    let match
    while ((match = regex.exec(text)) !== null) {
        // Add index property to make it consistent with matchAll results
        matches.push(match)
    }
    return matches
}

// Updated function for meme matches handling
function processMemeMatches(quill) {
    const memeMatches = findAllMatches(getTextAndEmbeds(quill), /\[\[\s*([\w\s]+)\s*\]\]/g)
    // console.log('memeMatches', memeMatches);

    memeMatches.forEach((val, index) => {
        const match = val[0]
        // console.log('match', match);
        const matchWithSpaces = match.substring(2, match.length - 2)
        const memeContent = val[1]
        // console.log('memeContent', memeContent);

        if (memeContent) {
            const format = quill.getFormat(val.index + 2, 1)
            const formatSecond = quill.getFormat(val.index + 3, 1) //to allow editing links from the front
            const formatLink = format?.link || formatSecond?.link

            if (
                formatLink &&
                typeof formatLink === 'string' &&
                formatLink.indexOf('_') > -1 &&
                formatLink.split('_').splice(2).join('_') !== memeContent
            ) {
                // console.log('formatLink', val.index, formatLink);
                const parts = formatLink.split('_')
                quill.formatText(val.index + 2, memeContent.length, {
                    link: `${parts[0]}_${parts[1]}_${memeContent}`,
                })
                if (parts.splice(2).join('_').length < memeContent.length)
                    quill.setSelection(quill.getSelection().index + 1, 0)
            } else if (!formatLink) {
                // console.log('new meme format editor BEFORE', val.index, quill.getContents(), val, memeContent);
                quill.formatText(val.index + 2, matchWithSpaces.length, {
                    // bold: true,
                    link: `@v2quest_self_${memeContent}`,
                })
                // console.log('meme format editor AFTER', quill.getContents(), val, memeContent);
            }
        }
    })
}

// Use Quill type for the export to maintain type compatibility
export default React.memo(
    React.forwardRef<Quill, any>((props, ref) => (
        <ErrorBoundary label="CommonEditor">
            <CommonEditor ref={ref} {...props} />
        </ErrorBoundary>
    )),
)
