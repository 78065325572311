import React, { useRef, useState, useEffect, useCallback } from 'react'
import cn from 'classnames'
import styles from 'components/panels/mobile-pull-to-refresh.module.scss'
import { isAndroid } from 'react-device-detect'

// Constants for mobile pull-to-refresh behavior
export const MOBILE_REFRESH_THRESHOLD = 20 // Dramatically reduced threshold for shorter swipes
export const MOBILE_SENSITIVITY = 6.0 // Significantly increased sensitivity multiplier
export const TOP_TOLERANCE = 15 // Even more generous top area detection
export const POST_REFRESH_COOLDOWN = 300
export const VERTICAL_GESTURE_RATIO = 0.8 // Higher ratio for more strict vertical detection
export const MIN_SWIPE_DISTANCE = 5 // Minimum pixels to move before detecting direction
export const VERTICAL_ANGLE_THRESHOLD = 60 // Consider gesture vertical if angle is within ±60° of vertical

export interface MobilePullToRefreshProps {
    scrollContainerRef: React.RefObject<HTMLDivElement>
    onRefresh: () => void
    isRefreshing: boolean
    filter?: any // The filter object that determines if refresh is available
    showSpinner?: boolean
    disabled?: boolean // New prop to disable pull-to-refresh
}

// Animation helper for consistent animation behavior
const animatePullBackToStart = (
    startAmount: number,
    setPullProgress: (progress: number) => void,
    setIsPulling: (isPulling: boolean) => void,
    onComplete = () => {},
) => {
    // First, indicate we're no longer actively pulling
    setIsPulling(false)

    // Clear any existing animation timeouts
    const timeoutId = setTimeout(() => {
        // Set to 0 to let CSS handle the animation
        setPullProgress(0)

        const cleanupId = setTimeout(() => {
            onComplete()
        }, 400) // Longer timeout to ensure animation completes

        // Store the timeout ID to avoid leaks
        return () => clearTimeout(cleanupId)
    }, 20) // Slight delay to ensure state updates

    // Return cleanup function
    return () => clearTimeout(timeoutId)
}

// Inner component for visual representation of pull state
const PullIndicator = ({ isPulling, pullProgress, isRefreshing, showSpinner = true }) => {
    // Define a constant for when the component is considered "fully pulled"
    const FULL_PULL_HEIGHT = 80 // Reduced height for faster animation
    const height = Math.min(pullProgress, FULL_PULL_HEIGHT)

    // Whether the indicator is retracting (not pulled by user but not fully collapsed yet)
    const isRetracting = !isPulling && pullProgress > 0 && !isRefreshing

    // Calculate style properties
    const containerStyle = {
        height: `${height}px`,
        // Add transition when refreshing OR retracting
        transition:
            isRefreshing || isRetracting ? 'height 0.25s cubic-bezier(0.25, 0.1, 0.25, 1)' : 'none',
    }

    const iconStyle = {
        opacity: isRefreshing ? 1 : Math.min(1, pullProgress / 40), // Fade in icon smoothly
        // Add transition for opacity change
        transition: 'opacity 0.25s ease-out',
    }

    return (
        <div
            className={cn(
                styles.pullToRefresh,
                isPulling && styles.pulling,
                isRefreshing && styles.refreshing,
                isRetracting && styles.retracting,
            )}
            style={containerStyle}
            data-testid="pull-to-refresh-indicator"
        >
            <div className={styles.loadingIcon} style={iconStyle}>
                {showSpinner ? <i className="fa fa-circle-o-notch fa-lg" /> : null}
            </div>
        </div>
    )
}

const MobilePullToRefresh: React.FC<MobilePullToRefreshProps> = ({
    scrollContainerRef,
    onRefresh,
    isRefreshing,
    filter,
    showSpinner = true,
    disabled = false,
}) => {
    // Pull-to-refresh state
    const [isPulling, setIsPulling] = useState(false)
    const [pullProgress, setPullProgress] = useState(0)
    const touchStartY = useRef<number | null>(null)
    const touchStartX = useRef<number | null>(null)
    const pullStartedAtTopRef = useRef<boolean>(false)
    const lastRefreshTimeRef = useRef<number>(0)
    const gestureDirectionRef = useRef<'none' | 'vertical' | 'horizontal'>('none')
    const touchPointsHistory = useRef<{ x: number; y: number }[]>([])

    // Animation frame reference for smooth animations
    const animationFrameRef = useRef<number | null>(null)

    // Helper functions to determine gesture direction
    const isGestureVertical = useCallback(
        (startX: number, startY: number, endX: number, endY: number) => {
            const deltaX = Math.abs(endX - startX)
            const deltaY = Math.abs(endY - startY)

            // Total movement must exceed minimum threshold
            const totalMovement = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
            if (totalMovement < MIN_SWIPE_DISTANCE) {
                return false
            }

            // Calculate angle from horizontal (in degrees)
            let angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI)

            // Determine if within vertical threshold
            return angle >= 90 - VERTICAL_ANGLE_THRESHOLD && angle <= 90 + VERTICAL_ANGLE_THRESHOLD
        },
        [],
    )

    // Helper to get the dominant direction from touch history
    const getDominantDirection = useCallback(() => {
        if (touchPointsHistory.current.length < 3 || !touchStartX.current || !touchStartY.current) {
            return 'none'
        }

        // Use the most recent point for more current direction
        const currentPoint = touchPointsHistory.current[touchPointsHistory.current.length - 1]

        // Check if primary movement is vertical using angle-based detection
        if (
            isGestureVertical(
                touchStartX.current,
                touchStartY.current,
                currentPoint.x,
                currentPoint.y,
            )
        ) {
            return 'vertical'
        }

        return 'horizontal'
    }, [isGestureVertical])

    // Helper function to check if a filter is valid/active
    const isFilterValid = useCallback(() => {
        if (disabled) return false
        if (filter === undefined) return true
        if (!filter) return false
        return true
    }, [filter, disabled])

    // Helper to check if we're in refresh cooldown
    const isInRefreshCooldown = useCallback(() => {
        return Date.now() - lastRefreshTimeRef.current < POST_REFRESH_COOLDOWN
    }, [])

    // Animation helper function for smooth pull progress updates
    const updatePullProgressSmoothly = useCallback(
        (targetValue: number) => {
            setPullProgress(targetValue)

            // Cancel any existing animation
            if (animationFrameRef.current !== null) {
                cancelAnimationFrame(animationFrameRef.current)
                animationFrameRef.current = null
            }
        },
        [], // No dependencies needed as it's just setting a value
    )

    // Helper to reset pull state with appropriate timing
    const resetPullState = useCallback(() => {
        // Force reset animation state completely
        setIsPulling(false)

        // Use timeout to ensure state updates
        setTimeout(() => {
            setPullProgress(0)

            // Force final reset after animation would be complete
            setTimeout(() => {
                pullStartedAtTopRef.current = false
                // Final failsafe reset
                setPullProgress(0)
                setIsPulling(false)
            }, 500)
        }, 20)
    }, [])

    // Handle refresh trigger
    const handleRefresh = useCallback(() => {
        if (isRefreshing || !isFilterValid() || isInRefreshCooldown()) {
            return
        }

        // Mark refresh time and reset tracking
        lastRefreshTimeRef.current = Date.now()

        // Trigger refresh immediately
        onRefresh()

        // Reset pull state immediately to avoid double animation
        pullStartedAtTopRef.current = false
    }, [onRefresh, isRefreshing, isFilterValid, isInRefreshCooldown])

    // Touch handlers
    const onTouchStart = useCallback(
        (e: TouchEvent) => {
            const scrollContainer = scrollContainerRef.current
            if (!scrollContainer || isRefreshing || !isFilterValid() || isInRefreshCooldown()) {
                return
            }

            // Check if we're at the top with tolerance
            const isAtTop = scrollContainer.scrollTop <= TOP_TOLERANCE

            // Store touch position
            touchStartY.current = e.touches[0].clientY
            touchStartX.current = e.touches[0].clientX

            // Reset touch history and direction
            touchPointsHistory.current = [{ x: e.touches[0].clientX, y: e.touches[0].clientY }]
            gestureDirectionRef.current = 'none'

            // Mark if pull started at top
            pullStartedAtTopRef.current = isAtTop
        },
        [isFilterValid, isRefreshing, isInRefreshCooldown],
    )

    // Helper for processing pull down gesture
    const processPullDownGesture = useCallback(
        (e: TouchEvent, deltaY: number) => {
            e.preventDefault()

            // Only process as pull gesture if direction is vertical
            if (gestureDirectionRef.current === 'vertical') {
                // Start pulling state if not already pulling
                if (!isPulling) {
                    setIsPulling(true)
                    pullStartedAtTopRef.current = true
                }

                // Calculate pull progress with higher sensitivity
                const sensitivity = MOBILE_SENSITIVITY * 1.2
                const boostFactor = 10
                const newProgress = deltaY * sensitivity + boostFactor

                // Update UI directly
                setPullProgress(newProgress)

                // Trigger refresh if threshold exceeded
                const effectiveThreshold = MOBILE_REFRESH_THRESHOLD * 0.3
                if (newProgress >= effectiveThreshold) {
                    handleRefresh()
                }
            }
        },
        [isPulling, handleRefresh],
    )

    // Helper for processing pull back (user pulling up after pulling down)
    const processPullBackGesture = useCallback(
        (e: TouchEvent, deltaY: number) => {
            e.preventDefault()

            // Reduce pull amount proportionally to upward movement
            const sensitivity = 0.7
            const newProgress = Math.max(0, pullProgress + deltaY * sensitivity)

            // Update UI directly
            setPullProgress(newProgress)

            // Cancel pull if completely reversed
            if (newProgress <= 0) {
                setIsPulling(false)
                pullStartedAtTopRef.current = false
            }
        },
        [pullProgress],
    )

    // Helper to handle touch events when not at top
    const handleNotAtTop = useCallback(() => {
        // No longer at top, cancel pull
        if (isPulling) {
            animatePullBackToStart(pullProgress, updatePullProgressSmoothly, setIsPulling)
        }
        pullStartedAtTopRef.current = false
    }, [isPulling, pullProgress, updatePullProgressSmoothly])

    // Helper to update touch history and detect movement direction
    const updateTouchHistoryAndDirection = useCallback(
        (touchX: number, touchY: number) => {
            // Add current point to history (limit to 5 points)
            touchPointsHistory.current.push({ x: touchX, y: touchY })
            if (touchPointsHistory.current.length > 5) {
                touchPointsHistory.current.shift()
            }

            // Calculate deltas for detection
            const deltaY = touchY - (touchStartY.current || 0)
            const deltaX = touchX - (touchStartX.current || 0)

            // Determine if this is the first significant movement
            if (gestureDirectionRef.current === 'none') {
                // Wait until we have enough movement to determine direction
                const totalMovement = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
                if (totalMovement > MIN_SWIPE_DISTANCE) {
                    gestureDirectionRef.current = getDominantDirection()
                }
            }

            return { deltaX, deltaY }
        },
        [getDominantDirection],
    )

    const onTouchMove = useCallback(
        (e: TouchEvent) => {
            const scrollContainer = scrollContainerRef.current
            if (!scrollContainer || isRefreshing || !isFilterValid() || isInRefreshCooldown()) {
                return
            }

            // Get current touch position
            const touchY = e.touches[0].clientY
            const touchX = e.touches[0].clientX

            // If we don't have a starting position, set it now
            if (touchStartY.current === null || touchStartX.current === null) {
                touchStartY.current = touchY
                touchStartX.current = touchX
                return
            }

            // Update touch history and get deltas
            const { deltaY } = updateTouchHistoryAndDirection(touchX, touchY)

            // Check if content is scrollable
            const isScrollable = scrollContainer.scrollHeight > scrollContainer.clientHeight
            // Only handle pull-down gestures at the top
            const isAtTop = scrollContainer.scrollTop <= TOP_TOLERANCE
            const isPullingDown = deltaY > 0

            // Always prevent default at top to prevent browser overscroll
            // Also prevent for non-scrollable content when pulling down
            if ((isAtTop && isPullingDown) || (!isScrollable && isPullingDown && isAndroid)) {
                processPullDownGesture(e, deltaY)
            } else if (deltaY < 0 && isPulling) {
                processPullBackGesture(e, deltaY)
            } else if (!isAtTop) {
                handleNotAtTop()
            }
        },
        [
            isRefreshing,
            isPulling,
            isFilterValid,
            isInRefreshCooldown,
            updateTouchHistoryAndDirection,
            processPullDownGesture,
            processPullBackGesture,
            handleNotAtTop,
        ],
    )

    const onTouchEnd = useCallback(
        (e: TouchEvent) => {
            if (isRefreshing) return

            // Super forgiving threshold for triggering refresh
            const effectiveThreshold = MOBILE_REFRESH_THRESHOLD * 0.3
            const lastChanceThreshold = effectiveThreshold * 0.3

            // For quick, short swipes, trigger refresh
            if (isPulling && pullProgress >= lastChanceThreshold && !isRefreshing) {
                handleRefresh()
                return
            }

            // If still pulling (didn't trigger refresh), animate back to start
            if (isPulling) {
                resetPullState()
            }
        },
        [isPulling, pullProgress, isRefreshing, handleRefresh, resetPullState],
    )

    // Set up event listeners
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current
        if (!scrollContainer) return

        // Add touch event listeners - passive false only where needed
        scrollContainer.addEventListener('touchstart', onTouchStart, { passive: true })
        scrollContainer.addEventListener('touchmove', onTouchMove, { passive: false })
        scrollContainer.addEventListener('touchend', onTouchEnd, { passive: true })

        return () => {
            // Clean up event listeners
            scrollContainer.removeEventListener('touchstart', onTouchStart)
            scrollContainer.removeEventListener('touchmove', onTouchMove)
            scrollContainer.removeEventListener('touchend', onTouchEnd)

            // Cancel any ongoing animations
            if (animationFrameRef.current !== null) {
                cancelAnimationFrame(animationFrameRef.current)
            }
        }
    }, [onTouchStart, onTouchMove, onTouchEnd])

    // Prevent native browser pull-to-refresh on Android for questId panels or empty panels
    useEffect(() => {
        // Only apply this effect for Android and specific panel types
        const scrollContainer = scrollContainerRef.current
        const shouldPreventNativePull = isAndroid && (filter?.questId || !showSpinner)

        if (!scrollContainer || !shouldPreventNativePull) return

        // Use WeakMap to store touch positions without modifying the DOM element
        const touchStartMap = new WeakMap<HTMLElement, number>()

        // Store initial touch position
        const handleTouchStart = (e: TouchEvent) => {
            const touch = e.touches[0]
            if (touch && scrollContainer) {
                touchStartMap.set(scrollContainer, touch.clientY)
            }
        }

        // Prevent browser default for pull-down gestures
        const preventDefaultPullToRefresh = (e: TouchEvent) => {
            // Get touch position
            const touch = e.touches[0]
            if (!touch || !scrollContainer) return

            // Get initial touch position from WeakMap
            const startY = touchStartMap.get(scrollContainer)
            if (startY === undefined) return

            // Calculate direction (positive = pulling down)
            const deltaY = touch.clientY - startY
            const isPullingDown = deltaY > 0
            const isAtTopOrNotScrollable =
                scrollContainer.scrollTop <= 10 ||
                scrollContainer.scrollHeight <= scrollContainer.clientHeight

            // Only prevent default when pulling DOWN at the top or on non-scrollable content
            if (isPullingDown && isAtTopOrNotScrollable) {
                e.preventDefault()
            }
        }

        // Reset touch position tracking
        const resetTouchStart = () => {
            if (scrollContainer) {
                touchStartMap.delete(scrollContainer)
            }
        }

        // Add touch event listeners
        scrollContainer.addEventListener('touchstart', handleTouchStart, { passive: true })
        scrollContainer.addEventListener('touchmove', preventDefaultPullToRefresh, {
            passive: false,
        })
        scrollContainer.addEventListener('touchend', resetTouchStart, { passive: true })
        scrollContainer.addEventListener('touchcancel', resetTouchStart, { passive: true })

        return () => {
            // Clean up event listeners
            scrollContainer.removeEventListener('touchstart', handleTouchStart)
            scrollContainer.removeEventListener('touchmove', preventDefaultPullToRefresh)
            scrollContainer.removeEventListener('touchend', resetTouchStart)
            scrollContainer.removeEventListener('touchcancel', resetTouchStart)
            if (scrollContainer) {
                touchStartMap.delete(scrollContainer)
            }
        }
    }, [filter?.questId, showSpinner])

    // Reset state when refresh completes
    useEffect(() => {
        // Handle completion of refresh
        if (!isRefreshing && pullProgress > 0) {
            // First set not pulling to engage CSS transition
            setIsPulling(false)

            // Create a sequence of steps with increasing delays to ensure complete retraction
            const resetSequence = () => {
                // Step 1: Start retracting by setting pull progress to 0
                setTimeout(() => {
                    setPullProgress(0)

                    // Step 2: Final cleanup after animation should be complete
                    setTimeout(() => {
                        pullStartedAtTopRef.current = false
                        // Double-check - force reset again
                        setPullProgress(0)
                    }, 400) // Allow enough time for animation to complete
                }, 50) // Initial delay to ensure state update
            }

            // Start the reset sequence
            resetSequence()
        }

        // Force full reset after 2 seconds as a failsafe
        const failsafeTimer = setTimeout(() => {
            if (pullProgress > 0) {
                setPullProgress(0)
                setIsPulling(false)
                pullStartedAtTopRef.current = false
            }
        }, 2000)

        return () => clearTimeout(failsafeTimer)
    }, [isRefreshing, pullProgress])

    return isPulling || isRefreshing ? (
        <PullIndicator
            isPulling={isPulling}
            pullProgress={pullProgress}
            isRefreshing={isRefreshing}
            showSpinner={showSpinner}
        />
    ) : null
}

export default MobilePullToRefresh
