import { Quill } from 'react-quill-new'
import useStore from 'state/knovStore'
import { isMobile } from 'react-device-detect'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'

const Link = Quill.import('formats/link') as any

// Custom Link Implementation
class MyLink extends Link {
    static create(value) {
        let node = Link.create(value)
        value = Link.sanitize(value)
        node.setAttribute('href', value)

        if (value.startsWith('@v2quest')) {
            // NOTE: @v2quest indicates a meme link
            node.removeAttribute('target')
            node.className = 'mention-link'
            node.onclick = e => {
                e.preventDefault()
                e.stopPropagation()

                const { panelId, editMode } = node.closest('.answer-comp')?.dataset
                if (editMode === 'true') return

                const memeText = node?.innerText

                const panels = useStore.getState().panels
                // Inserting vs Appending?
                const indexBeforeChange = panels.getIndexById(panelId)
                const clickedRightPanel = indexBeforeChange > getCenterIndex()
                // Optimization: start the slide animation before inserting the panel.
                panels.insertPanelRight(
                    panelId,
                    { filter: { all: true, meme: memeText } },
                    { animate: !isMobile && !clickedRightPanel },
                )
            }
        } else {
            node.setAttribute('target', '_blank')
            node.onclick = e => {
                e.stopPropagation()
                return true
            }
        }
        return node
    }
}

export default MyLink
