import { useEffect, useState } from 'react'

// Global flag to ensure version check only runs once
let versionCheckInitialized = false
let lastUpdateCheckTime = 0

/**
 * Hook that periodically checks for application updates by comparing the
 * current version with the latest version from the server.
 *
 * @param {number} intervalMinutes - How often to check for updates (in minutes)
 * @returns {{ isUpdateAvailable: boolean }} Whether an update is available
 */
export function useVersionCheck(intervalMinutes = 30) {
    const [isUpdateAvailable, setIsUpdateAvailable] = useState(false)

    useEffect(() => {
        // If window.Knov.isUpdateAvailable is already set, use that value immediately
        // This handles the case where the version check completed before this component mounted
        if (window.Knov && typeof window.Knov.isUpdateAvailable === 'boolean') {
            setIsUpdateAvailable(window.Knov.isUpdateAvailable)
        }

        // Only run the version check initialization once per app session
        if (versionCheckInitialized) {
            return
        }

        // Mark as initialized to prevent duplicate initialization
        versionCheckInitialized = true

        // Skip version check if window.Knov.version is not available
        if (!window.Knov?.version) {
            console.warn('Version check skipped: window.Knov.version is not available')
            return
        }

        const currentVersion = {
            buildTimeUnixTimestamp: window.Knov.version.buildTimeUnixTimestamp,
            gitCommitHash: window.Knov.version.gitCommitHash,
        }

        // Helper function to update state and dispatch event
        const updateVersionStatus = isOutdated => {
            // Update the global state
            if (window.Knov) {
                window.Knov.isUpdateAvailable = isOutdated
            }

            // Update the local state
            setIsUpdateAvailable(isOutdated)

            // Record the time this check was performed
            lastUpdateCheckTime = Date.now()

            // Dispatch event to notify components
            window.dispatchEvent(
                new CustomEvent('versionCheckUpdate', {
                    detail: { isUpdateAvailable: isOutdated, timestamp: lastUpdateCheckTime },
                }),
            )
        }

        // Function to check for updates
        const checkForUpdates = async () => {
            try {
                // console.log('>>> fetching version')
                // Add a cache-busting parameter to ensure we're getting the latest version
                const cacheBuster = `?t=${Date.now()}`

                // Array of directories to search for version.json
                const dirs = ['/', '/public/']

                let remoteVersion
                for (const dir of dirs) {
                    try {
                        const response = await fetch(
                            `${dir.replace(/\/$/, '')}${'/version.json'}${cacheBuster}`,
                        )
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`)
                        }
                        remoteVersion = await response.json()
                        // console.log('>>> fetched version', remoteVersion)
                        break
                    } catch (error) {
                        console.log(
                            `>>> Couldn't find or parse version.json in ${dir}, trying next location`,
                        )
                    }
                }

                if (!remoteVersion) {
                    throw new Error('Failed to fetch version from all locations')
                }

                // Compare versions - consider app outdated if either timestamp or git hash has changed
                const isOutdated =
                    remoteVersion.buildTimeUnixTimestamp > currentVersion.buildTimeUnixTimestamp ||
                    remoteVersion.gitCommitHash !== currentVersion.gitCommitHash

                // Update all states and emit event
                updateVersionStatus(isOutdated)

                if (isOutdated) {
                    console.log(
                        `Update available:\n`,
                        `Current version: ${currentVersion.gitCommitHash} (${new Date(
                            currentVersion.buildTimeUnixTimestamp,
                        ).toISOString()})\n`,
                        `Latest version: ${remoteVersion.gitCommitHash} (${new Date(
                            remoteVersion.buildTimeUnixTimestamp,
                        ).toISOString()})`,
                    )
                } else {
                    console.log('Treechat is up-to-date')
                }

                return { isUpdateAvailable: isOutdated, currentVersion, remoteVersion }
            } catch (error) {
                console.error('Error checking for updates:', error)
                return { error }
            }
        }

        // Set up an event listener for version updates
        const handleVersionUpdate = event => {
            if (event.detail && typeof event.detail.isUpdateAvailable === 'boolean') {
                setIsUpdateAvailable(event.detail.isUpdateAvailable)
            }
        }
        window.addEventListener('versionCheckUpdate', handleVersionUpdate)

        // Expose the function on window.Knov
        if (window.Knov) {
            window.Knov.checkVersionUpdate = checkForUpdates
            // Initialize the isUpdateAvailable property if it doesn't exist yet
            if (typeof window.Knov.isUpdateAvailable === 'undefined') {
                window.Knov.isUpdateAvailable = false
            }
        }

        // Initial check after a brief delay (2 seconds)
        const initialCheckTimeout = setTimeout(() => {
            checkForUpdates()

            // After a slight additional delay, resend the update status event
            // This helps catch any components that may have missed the first event due to race conditions
            setTimeout(() => {
                if (window.Knov && typeof window.Knov.isUpdateAvailable === 'boolean') {
                    window.dispatchEvent(
                        new CustomEvent('versionCheckUpdate', {
                            detail: {
                                isUpdateAvailable: window.Knov.isUpdateAvailable,
                                timestamp: lastUpdateCheckTime,
                                isRebroadcast: true,
                            },
                        }),
                    )
                }
            }, 500)
        }, 2000)

        // Set up periodic checks
        const intervalId = setInterval(checkForUpdates, intervalMinutes * 10 * 1000)

        // Clean up on component unmount
        return () => {
            clearTimeout(initialCheckTimeout)
            clearInterval(intervalId)
            window.removeEventListener('versionCheckUpdate', handleVersionUpdate)
            // Do NOT reset versionCheckInitialized here, as we want it to stay initialized
            // for the lifetime of the application
        }
    }, []) // only run once when component mounts

    // Set up an event listener for version updates outside of the initialization flow
    useEffect(() => {
        const handleVersionUpdate = event => {
            if (event.detail && typeof event.detail.isUpdateAvailable === 'boolean') {
                setIsUpdateAvailable(event.detail.isUpdateAvailable)
            }
        }

        window.addEventListener('versionCheckUpdate', handleVersionUpdate)

        return () => {
            window.removeEventListener('versionCheckUpdate', handleVersionUpdate)
        }
    }, [])

    return { isUpdateAvailable }
}

// Add TypeScript interface for window.Knov
declare global {
    interface Window {
        Knov: {
            version?: {
                buildTimeUnixTimestamp: number
                gitCommitHash: string
            }
            isUpdateAvailable?: boolean
            checkVersionUpdate?: () => Promise<any>
            [key: string]: any
        }
    }
}
