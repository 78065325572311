import React from 'react'
import styles from './lazy-youtube.module.scss'

interface LazyYoutubeProps {
    videoId: string
    imageUrl?: string
    onPlay: () => void
}

const LazyYoutube: React.FC<LazyYoutubeProps> = ({ videoId, imageUrl, onPlay }) => {
    return (
        <div className={styles.lazyYtContainer} onClick={onPlay}>
            <div className={styles.ytPlayButtonContainer}>
                <div className={styles.ytPlayButton} />
            </div>
            {imageUrl ? (
                <img src={imageUrl} />
            ) : (
                <img
                    className={styles.original}
                    src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
                />
            )}
        </div>
    )
}

export default LazyYoutube
