import React from 'react'
import cn from 'classnames'
import styles from 'components/panels/stream-order.module.scss'
import WithTooltip from 'components/shared/WithTooltip'
import useStreamFilter from 'components/filters/useStreamFilters'

interface StreamOrderProps {
    filter: any
    panelId: string
    panel: any
    query?: string
}

export default function StreamOrder({ panelId, filter, panel, query }: StreamOrderProps) {
    const { setStreamOrder, setStreamFilter } = useStreamFilter(panelId)

    const upvote = {
        vote: {
            icon: 'fa fa-caret-up fa-large',
            label: 'Upvotes',
            tip: 'Messages sorted by upvotes',
        },
    }
    const lockvalue = {
        lockvalue: {
            icon: 'fa fa-bitcoin',
            label: 'Top',
            tip: 'Messages sorted by lock value',
        },
    }

    const lockvalueTrending = {
        locktrending: {
            icon: 'fa fa-fire',
            label: 'Hot',
            tip: 'Messages sorted by lock value',
        },
    }
    const elasticRelevance = {
        relev: {
            icon: 'fa fa-search',
            label: 'Relev',
            tip: 'Messages sorted by relevance',
        },
    }
    const semantic = {
        semantic: {
            icon: 'fa fa-magic',
            label: 'Semantic',
            tip: 'Messages sorted by semantic relevance',
        },
    }

    const semanticAndTime = {
        semanticrecency: {
            icon: 'fa fa-clock-o',
            label: 'Recent Sem',
            tip: 'Messages sorted by semantic relevance and recency',
        },
    }

    const onlyLocked = {
        locked: { icon: 'fa fa-lock', label: 'Locked', tip: 'Recently locked messages.' },
    }
    const onlyUnlocked = {
        unlocked: {
            icon: 'fa fa-clock-o',
            label: 'Unlocked',
            tip: 'Recent nocoiner messages.',
        },
    }
    const time = {
        time: { icon: 'fa fa-clock-o', label: 'Recent', tip: 'Recently created messages.' },
    }

    const lockedTime = {
        time: {
            icon: 'fa fa-clock-o',
            label: 'Recent',
            tip: 'Recently created and locked messages.',
        },
    }

    const random = {
        random: { icon: 'fa fa-random', label: 'Random', tip: 'Messages sorted randomly' },
    }

    const yourPosts = {
        yours: { icon: 'fa fa-user', label: 'Your Posts', tip: 'Messages written by you' },
    }

    const filterMapping = (() => {
        const hasSearchQuery = !!filter?.query
        const isPublic = filter?.public || filter?.treechat || filter?.hodlocker
        const isMeme = filter?.meme

        const defaultSearchOrder = { ...time, ...upvote, ...random }
        if (hasSearchQuery) {
            return { ...elasticRelevance, ...semantic, ...semanticAndTime }
        } else if (isPublic) {
            return { ...lockedTime, ...lockvalueTrending, ...lockvalue }
        } else if (isMeme) {
            const [firstItem, ...restItems] = Object.entries(defaultSearchOrder)
            return { [firstItem[0]]: firstItem[1], ...yourPosts, ...Object.fromEntries(restItems) }
        } else {
            return defaultSearchOrder
        }
    })()

    return (
        <div className={styles.streamOrderComp}>
            <div className={styles.orderBtnContainer}>
                {Object.entries(filterMapping).map(([filterKey, filterValue]) => {
                    const isSelected =
                        filter?.order === filterKey ||
                        (filterKey === 'yours' && filter?.user === gon.currentUser.id) ||
                        (!filter?.order && filterKey === Object.keys(filterMapping)[0])
                    const orderBtnStyles = cn(styles.orderBtn, isSelected && styles.selected)
                    const { icon, label, tip } = filterValue
                    return (
                        <div key={label} style={{ flex: 1 }}>
                            <WithTooltip key={filterKey} tip={tip}>
                                <div
                                    className={orderBtnStyles}
                                    onClick={ev => {
                                        ev.stopPropagation()
                                        ev.preventDefault()

                                        if (filterKey === 'yours') {
                                            const newFilter = {
                                                ...filter,
                                                order: filterKey,
                                                from_user: gon.currentUser.id,
                                            }
                                            setStreamFilter(newFilter)
                                        } else {
                                            const newFilter = { ...filter, order: filterKey }
                                            delete newFilter.from_user
                                            setStreamFilter(newFilter)
                                        }
                                    }}
                                >
                                    <i className={icon} />
                                    <span className={styles.orderLabel}>{label}</span>
                                </div>
                            </WithTooltip>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
