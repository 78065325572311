import React from 'react'
import cn from 'classnames'
import { isMobile } from 'react-device-detect'
import styles from './panel-container.module.scss'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'

interface ScrollButtonProps {
    index: number
    onClick: () => void
    containerRef: React.RefObject<HTMLDivElement>
}

export default function ScrollButton({ index, onClick, containerRef }: ScrollButtonProps) {
    return (
        <div ref={containerRef} className={cn(styles.scrollButtonContainer)}>
            <div
                className={cn(
                    styles.scrollButton,
                    isMobile && styles.mobileScrollButton,
                    !isMobile && getCenterIndex() === index && styles.centerScrollButton,
                )}
                onClick={onClick}
            >
                <div className={styles.scrollButtonFace}>
                    <i className="fa fa-chevron-up" />
                </div>
            </div>
        </div>
    )
}
