import React, { useCallback, useMemo } from 'react'
import cn from 'classnames'
import styles from './file-embed.module.scss'
import WithTooltip from 'components/shared/WithTooltip'
import { getCachedAnswer } from 'state/cache'
import BabylonViewer from './BabylonViewer'

export default React.memo(function FileEmbed(props) {
    const { answerId, canDelete, file, showDelete, deleteFile: propsDeleteFile, removeFile } = props

    const answer = getCachedAnswer(answerId)

    // Find matching BSV attachment
    const matchingBsvAttachment = useMemo(
        () => answer?.bsv_attachments?.find(att => att.attachment_id === file.id),
        [answer?.bsv_attachments, file.id],
    )

    // Render BSV attachment status
    const renderBsvAttachmentStatus = useCallback(matchingBsvAttachment => {
        const bsvTx = matchingBsvAttachment?.bsv_tx
        const txId = bsvTx?.tx_id

        // Check if we have a pending transaction that's more than 5 seconds old
        const isAwaitingResubmission =
            bsvTx && !txId && (new Date() - new Date(bsvTx.created_at)) / 1000 > 5

        // Show different status for old pending transactions
        if (isAwaitingResubmission) {
            const retryInfo = bsvTx.retries > 0 ? ` (${bsvTx.retries} retries)` : ''
            return (
                <WithTooltip
                    tip={`Treechat wasn't able to post this onchain immediately but will try again on the next block ${retryInfo}`}
                >
                    <i className="fa fa-clock-o" />
                </WithTooltip>
            )
        }

        // Show spinner for new pending transactions
        if (!txId) {
            return (
                <WithTooltip tip="Transaction pending">
                    <i className="fa fa-spinner fa-spin" />
                </WithTooltip>
            )
        }

        const isUnconfirmed = !bsvTx?.confirmed

        return (
            <WithTooltip tip={`${isUnconfirmed ? 'Unconfirmed' : 'Confirmed'} tx: ${txId}`}>
                <a
                    href={`https://whatsonchain.com/tx/${txId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={ev => ev.stopPropagation()}
                >
                    <i className={`fa fa-cube`} />
                </a>
            </WithTooltip>
        )
    }, [])

    // Memoize the delete handler
    const deleteFile = useCallback(
        attachmentId => {
            if (attachmentId && answerId && canDelete) {
                propsDeleteFile(attachmentId, answerId)
            } else {
                removeFile()
            }
        },
        [answerId, canDelete, propsDeleteFile, removeFile],
    )

    // Memoize event handler
    const preventPropagation = useCallback(ev => {
        ev.stopPropagation()
        ev.preventDefault()
        return false
    }, [])

    // Memoize file type checks
    const fileTypes = useMemo(
        () => ({
            is3DModel: file.name?.match(/\.(obj|glb|gltf|stl)$/i),
            isAudio: file.name?.match(/\.(mp3|wav|ogg|m4a)$/i),
        }),
        [file.name],
    )

    return (
        <div
            className={cn(styles.fileEmbed)}
            onClick={preventPropagation}
            onMouseDown={preventPropagation}
            onMouseUp={preventPropagation}
            onTouchStart={preventPropagation}
            onTouchEnd={preventPropagation}
            onDrag={preventPropagation}
            onDragStart={preventPropagation}
        >
            {fileTypes.is3DModel ? (
                <div className={styles.modelViewer}>
                    <BabylonViewer file={file} />

                    {showDelete && (
                        <div className={styles.delete}>
                            <i className="fa fa-close" onClick={() => deleteFile(file.id)} />
                        </div>
                    )}
                </div>
            ) : fileTypes.isAudio ? (
                <div
                    className={cn(styles.audioPlayer, 'pointer-events-none')}
                    onDrag={preventPropagation}
                    onDragStart={preventPropagation}
                    onDragOver={preventPropagation}
                    onDragEnter={preventPropagation}
                    onDragLeave={preventPropagation}
                    onDrop={preventPropagation}
                >
                    <audio
                        controls
                        onClick={preventPropagation}
                        onMouseDown={preventPropagation}
                        onMouseUp={preventPropagation}
                        onTouchStart={preventPropagation}
                        onTouchEnd={preventPropagation}
                        onDrag={preventPropagation}
                        onDragStart={preventPropagation}
                        onDragOver={preventPropagation}
                        onDragEnter={preventPropagation}
                        onDragLeave={preventPropagation}
                        onDrop={preventPropagation}
                        draggable="false"
                        style={{ pointerEvents: 'auto' }}
                    >
                        <source src={file.url} type={`audio/${file.name.split('.').pop()}`} />
                        Your browser does not support the audio element.
                    </audio>

                    {matchingBsvAttachment && (
                        <div className={styles.bsvStatusOverlay}>
                            {renderBsvAttachmentStatus(matchingBsvAttachment)}
                        </div>
                    )}

                    {showDelete && (
                        <div className={styles.delete}>
                            <i className="fa fa-close" onClick={() => deleteFile(file.id)} />
                        </div>
                    )}
                </div>
            ) : (
                <div className={styles.downloadBtn}>
                    <div className={styles.icon}>
                        <i className="fa fa-paperclip" />
                    </div>

                    <a
                        className={styles.name}
                        href={file.url}
                        download={file.name}
                        onClick={e => {
                            preventPropagation(e)
                            fetch(file.url)
                                .then(response => response.blob())
                                .then(blob => {
                                    const url = window.URL.createObjectURL(blob)
                                    const link = document.createElement('a')
                                    link.href = url
                                    link.download = file.name
                                    link.click()
                                    window.URL.revokeObjectURL(url)
                                })
                        }}
                        onMouseDown={preventPropagation}
                        onMouseUp={preventPropagation}
                        onTouchStart={preventPropagation}
                        onTouchEnd={preventPropagation}
                        onDrag={preventPropagation}
                        onDragStart={preventPropagation}
                    >
                        {file.name}
                    </a>

                    {matchingBsvAttachment && (
                        <div className={styles.bsvStatusOverlay}>
                            {renderBsvAttachmentStatus(matchingBsvAttachment)}
                        </div>
                    )}

                    {showDelete && (
                        <div className={styles.delete}>
                            <i className="fa fa-close" onClick={() => deleteFile(file.id)} />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
})
