import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import { current, produce, enablePatches, produceWithPatches, applyPatches } from 'immer'

import api from 'api/api'
import { Quest } from 'types/quests'
import { Answer } from 'types/answers'

import { BsvWalletType } from 'wallets/wallets'
import * as cache from 'state/cache'
import { Space } from 'types/spaces'

import { emptyPanel, PanelState } from 'state/PanelState'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import { SATOSHI_UNIT, BITCOIN_UNIT } from 'lib/bsv-util'
import { SignalType } from 'types/signals'
import { isMobile } from 'react-device-detect'

type JobStatus = {
    status?: string
    substatus?: string
    data?: { [key: string]: any }
    messages?: string[]
    started_at?: number
    finished_at?: number
}

export interface IKnovStore {
    DEBUG: boolean
    panels: {
        state: PanelState[]
        lastAction: any
        animatedPanelInsert: any
        animatedPanelRemove: any
        getPanels: () => PanelState[]
        getPanel: (panelId: string) => PanelState | undefined
        getIndexById: (panelId: string) => number
        getPanelToRight: (panelId: string) => PanelState | undefined
        getPanelToLeft: (panelId: string) => PanelState | undefined
        editPanel: (panelId: string, newPanelValues: Partial<PanelState>) => void
        insertPanelAtIndex: (
            index: number,
            newPanel: Partial<PanelState>,
            actionParams?: object,
        ) => void
        removePanelAtIndex: (removeIndex: number) => void
        startAnimatedInsert: (
            index: number,
            newPanel: Partial<PanelState>,
            options: { direction: 'left' | 'right'; actionParams?: object },
        ) => void
        completeAnimatedInsert: () => void
        startAnimatedRemove: (index: number, direction: 'left' | 'right') => void
        completeAnimatedRemove: () => void
        insertPanelRight: (
            panelId: string,
            newPanel: Partial<PanelState>,
            options?: { animate: boolean; actionParams?: object },
        ) => void
        insertPanelLeft: (
            panelId: string,
            newPanel: Partial<PanelState>,
            options?: { animate: boolean },
        ) => void
        clearPanel: (panelId: string) => void
        appendEmptyPanel: () => void
        prependEmptyPanel: () => void
        appendSession: (newFilter: any, panelProps?: object) => void
    }
    /** Active space ID */
    activeSpaceId: string | null
    /** User features */
    userFeatures: any | null
    /** UTC time in ms since cache was hydrated from localstorage */
    lastLocalCacheRead: number
    /** Cached answers live here */
    answers: { [answerId: string]: Answer }
    /** Cached quests live here */
    quests: { [questId: string]: Quest }
    /** These are cached new answers */
    newAnswerIds: { [questId: string]: string[] }
    draftAnswerIds: { [answerId: string]: string[] }
    users: any[]
    userSpaces: Space[]
    userStreams: any[]
    lockedQuests: string[]
    lockedAnswers: string[]
    lockQuest: (questId: number) => void
    unlockQuest: (questId: number) => void
    lockAnswer: (answerId: number) => void
    unlockAnswer: (answerId: number) => void
    sliding: boolean
    slidingBack: boolean
    middlePanelExpandedQuests: { [questId: string]: boolean }
    rightPanelExpandedQuests: { [questId: string]: boolean }
    selectedQuote: any
    selectedQuoteParentAnswer: any
    searchHeaderAnimationStates: { [panelId: string]: { state?: 'fadeIn' | 'fadeOut' | string } }
    hideMiddlePanelExcept: any
    hideRightPanelExcept: any
    middleNewQuestInput: string
    newAnswerQueryMiddle: any
    newAnswerQueryRight: any
    unactedAnswers: any[]
    unactedParents: any[]
    starredQuests: Quest[]
    draftQuestIds: { [panel: string]: string | null }
    currentUserBsvWalletType: BsvWalletType | null

    upvalueSats: number | null
    boostSats: number | null
    lockSats: number | null
    lockBlocks: number | null

    answerJobs: { [answerId: string]: JobStatus[] }
    bsvUnit: typeof BITCOIN_UNIT | typeof SATOSHI_UNIT

    showSidebar: boolean
    setShowSidebar: (show: boolean) => void
    showRightSidebar: boolean
    setShowRightSidebar: (show: boolean) => void

    getSpace: (spaceId: string) => Space | undefined
    getActiveSpace: () => Space | undefined
    setActiveSpace: (spaceId: string, space: Space) => void
    setActiveHistoryTab: (tab: 'history' | 'starred') => void
    setUserSpaces: (spaces: Space[]) => void
    setUserStreams: (streams: any[]) => void
    set: (funcOrObj: any, callback?: () => void) => void
    get: () => IKnovStore
    getSpaceId: () => string | undefined
    deleteEmbed: (quest: Quest, answer: Answer, embedId: string) => Promise<void>
    updateQuest: (quest: Quest) => void
    getQuestState: (questId: string) => Quest | undefined
    updateQuestAnswer: (quest: Quest, answer: Answer) => Quest | undefined
    mergeAnswers: (containerQuestId: string, embeddedQuestId: string) => Promise<void>
    setVideoDownloadInProgress: (inProgress: boolean) => Promise<void>
    pinQuestToTeam: (teamId: string, quest: Quest) => Promise<void>
    unpinQuestFromTeam: (teamId: string, quest: Quest) => Promise<void>
    actions: {
        modalActions: {
            openAuthModal: () => void
            openWalletModal: () => void
            openOrdinalsModal: (ordinalId?: string) => void
            openMetricsModal: () => void
            openEditProfileModal: () => void
            openImportTextModal: () => Promise<void>
            openExportQuestModal: (quest: Quest) => Promise<void>
            openNewStreamModal: () => void
            openNewSpaceModal: () => void
            openVideoRecorderModal: (quest: Quest, videoHandler: any, answer: Answer) => void
            openScreenRecorderModal: (quest: Quest, videoHandler: any, answer: Answer) => void
            openHandCashErrorModal: (msg: string) => void
            openMessageModal: (msg: string) => void
            openConfirmModal: (
                message: string,
                confirmLabel: string,
                confirm: () => void,
                options?: { [key: string]: any },
            ) => void
            openStreamImageUpdateModal: (team: any, callback: () => void) => void
            openSpaceImageUpdateModal: (space: Space, callback: () => void) => void
            openUserImageUpdateModal: (user: any, callback: () => void) => void
            closeModal: () => void
            closeCryptoModal: () => void
            openCryptoModal: (left: number, top: number, callback?: () => void) => void
            openStoryModal: (quest: Quest) => void
            closeStoryModal: () => void
        }
    }
    isModalOpen: boolean
    modalParams: {
        type: string
        title?: string
        icon?: string
        noHeader?: boolean
        customStyles?: React.CSSProperties
    }
    pushUnactedIds: (ids: string[]) => void
    pushUnactedParentIds: (ids: string[]) => void
    signalType: SignalType
    publicTeams: ITeam[]
}

export interface ITeam {
    int_id: number
    name: string
    int_admin_id: number
    avatar: string | null
    created_at: string
    updated_at: string
    team_image: {
        url: string
    } | null
    int_quest_id: string | null
    notify_period: string
    last_notify: string | null
    next_notify: string
    public: boolean
    int_space_id: number
    id: string
    space_id: string
    admin_id: string
    quest_id: string | null
    short_name: string
    path: string
    url: string
}

const usePatches = false

const leftEmptyPanelBuffer = 1
const rightEmptyPanelBuffer = 1
export const startingIndex = leftEmptyPanelBuffer

const knovStore = (set, get): IKnovStore => {
    const origSet = set

    function lockQuest(questId: number) {
        // console.log('>>> Locked quest ', questId)
        set(state => {
            state.lockedQuests.push(Number(questId))
        })
    }

    function unlockQuest(questId: number) {
        // console.log('>>> UnLocked quest ', questId)
        set(state => {
            state.lockedQuests = state.lockedQuests.filter(qid => Number(qid) !== Number(questId))
        })
    }

    function lockAnswer(answerId: number) {
        // console.log('>>> Locked answer ', answerId)
        set(state => {
            state.lockedAnswers.push(Number(answerId))
        })
    }

    function unlockAnswer(answerId: number) {
        // console.log('>>> UnLocked answer ', answerId)
        set(state => {
            state.lockedAnswers = state.lockedAnswers.filter(
                aid => Number(aid) !== Number(answerId),
            )
        })
    }

    set = (funcOrObj, callback) => {
        let out
        if (usePatches) {
            if (typeof funcOrObj === 'function') {
                out = produceWithPatches(draftState => funcOrObj(draftState, current))
            } else if (typeof funcOrObj === 'object') {
                out = produceWithPatches(draftState => ({ ...draftState, ...funcOrObj }))
            } else {
                console.warn('The parameter passed to store set function is incompatible type')
            }

            // const [nextState, patches] = out

            origSet(draftState => {
                let [nextState, patches] = out(draftState)
                // NOTE: all the Number(id) stuff below is necessary for these to
                // evaluate to true for js-footgun reasons
                if (
                    patches.find(
                        ({ path: [type, id] }) =>
                            (type === 'quests' && draftState.lockedQuests.includes(id)) ||
                            (type === 'answers' && draftState.lockedAnswers.includes(id)),
                    )
                ) {
                    patches = patches.filter(
                        ({ path: [type, id] }) =>
                            !(type === 'quests' && draftState.lockedQuests.includes(id)) &&
                            !(type === 'answers' && !draftState.lockedAnswers.includes(id)),
                    )
                    nextState = applyPatches(draftState, patches)
                    // console.log('🚻filtered patches: ', patches)
                }
                return origSet(nextState)
            })
        } else {
            let nextState
            if (typeof funcOrObj === 'function') {
                nextState = produce(funcOrObj)
            } else if (typeof funcOrObj === 'object') {
                nextState = produce(draftState => ({ ...draftState, ...funcOrObj }))
            } else {
                console.warn('The parameter passed to store set function is incompatible type')
            }

            origSet(nextState)
        }
        if (callback) {
            callback()
        }
    }

    const getSpaceId = () =>
        get().activeSpaceId || gon.currentUser?.space_id || gon.KNOVIGATOR_SPACE_ID

    const getSpace = spaceId => get().userSpaces?.find(space => space.id === spaceId)
    const getActiveSpace = () => getSpace(get().activeSpaceId)

    const setActiveSpace = (spaceId: string, space: Space) => {
        if (gon.currentUser) {
            // TODO make this a partial State type.
            let newSpaceState: {
                activeSpaceId: string
                middleFilter: {}
                loadingSpaceId: string | null
                userSpaces?: Space[]
                questHistory: Quest[]
                starredQuests: Quest[]
                middleQuestId: string
                middleQuest: Quest
            } = {
                activeSpaceId: spaceId,
                middleFilter: { public: true },
                loadingSpaceId: spaceId,
                questHistory: [],
                starredQuests: [],
                middleQuestId: null,
                middleQuest: null,
            }
            if (space) newSpaceState.userSpaces = [...get().userSpaces, space]

            set({
                loadingSpaceId: spaceId,
            })

            // Keep space in sync with server user as we switch.
            // TODO remove space_id from server user and rely on client param.
            api.updateUser(gon.currentUser.id, { space_id: spaceId }).then(() => {
                set(newSpaceState)
            })

            /*
            const newSubdomain = space.subdomain_safe_name
            let newUrl = new URL(location.href)
            let splitHostname = newUrl.hostname.split('.')
            if (splitHostname.length > 1) splitHostname.shift()
            splitHostname.unshift(newSubdomain)
            newUrl.hostname = splitHostname.join('.')
            api.updateUser(gon.currentUser.id, { space_id: spaceId }).then(() => {
                location.href = newUrl.toString()
            })
            */
        }
    }

    const setActiveHistoryTab = (tab: 'history' | 'starred') => {
        set({ activeHistoryTab: tab })
        api.updateUserOptions({ activeHistoryTab: tab })
    }

    const setUserSpaces = spaces => set({ userSpaces: spaces })
    const setUserStreams = streams => set({ userStreams: streams })

    const updateQuest = (quest: Quest) => {
        cache.cacheQuest(quest)
    }

    const getQuestState = questId => {
        const state = get()
        if (state.middleQuest && state.middleQuest.id === questId) return state.middleQuest
        // TODO separate middleQuests display, and the quests data it shows similar to rightQuests vs childQuests.
        if (state.middleQuests && state.middleQuests.some(q => q.id === questId))
            return state.middleQuests.find(q => q.id === questId)
        if (state.childQuests && state.childQuests.some(q => q.id === questId))
            return state.childQuests.find(q => q.id === questId)
        if (state.rightQuests && state.rightQuests.some(q => q.id === questId))
            return state.rightQuests.find(q => q.id === questId)

        if (state.rightQuest && state.rightQuest.id === questId) return state.rightQuest
        if (state.pinnedQuest && state.pinnedQuest.id === questId) return state.pinnedQuest
    }

    const updateQuestAnswer = (quest, answer) => {
        let newQuest
        if (quest && answer) {
            const parent = quest.parent
            if (parent?.id === answer.id) {
                newQuest = { ...quest, parent: answer }
            } else {
                const ix = quest.sorted_answers.findIndex(ans => ans.id === answer.id)
                let newAnswers
                if (ix > -1) {
                    newAnswers = [...quest.sorted_answers]
                    newAnswers[ix] = answer
                } else {
                    newAnswers = [...quest.sorted_answers, answer]
                }
                newQuest = { ...quest, sorted_answers: newAnswers }
            }
        }
        return newQuest
    }

    const pushUnactedIds = ids => {
        const state = get()
        set({
            unactedAnswers: Array.from(new Set([...state.unactedAnswers, ...ids])),
        })
    }

    const pushUnactedParentIds = ids => {
        const state = get()
        set({
            unactedParents: Array.from(new Set([...state.unactedParents, ...ids])),
        })
    }

    const deleteEmbed = async (quest, answer, embedId) => {
        const newEmbeds = answer.embeds.filter(embed => embed.id !== embedId)
        const newAnswer = Object.assign({}, answer, { embeds: newEmbeds })
        const newQuest = updateQuestAnswer(quest, newAnswer)
        if (newQuest) {
            updateQuest(newQuest)
        }
        api.deleteEmbed(answer.id, embedId)
    }

    async function mergeAnswers(containerQuestId, embeddedQuestId) {
        const mergedQuest = await api.mergeAnswers(containerQuestId, embeddedQuestId)
        if (mergedQuest)
            // #TODO Remove from history.
            updateQuest(mergedQuest)
        actions.modalActions.closeModal()
    }

    async function setVideoDownloadInProgress(inProgress) {
        set({
            videoDownloadInProgress: inProgress,
        })
    }

    async function pinQuestToTeam(teamId, quest) {
        api.pinQuest(quest.id, teamId)
        const newTeam = Object.assign({}, quest.team, { quest_id: quest.id })
        const newQuest = Object.assign({}, quest, { team: newTeam })
        updateQuest(newQuest)
    }

    async function unpinQuestFromTeam(teamId, quest) {
        api.unpinQuest(quest.id, teamId)
        const newTeam = Object.assign({}, quest.team, { quest_id: null })
        const newQuest = Object.assign({}, quest, { team: newTeam })
        updateQuest(newQuest)
    }

    const actions: IKnovStore['actions'] = {
        modalActions: {
            openAuthModal: () => {
                $('#auth-modal').modal('show')
            },

            openWalletModal: () => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'Wallet',
                        icon: 'WalletIcon',
                        customStyles: {
                            width: '35dvw',
                            maxWidth: '35dvw',
                        },
                        mobileCustomStyles: {
                            width: '95dvw',
                            maxWidth: '95dvw',
                        },
                    },
                })
            },

            openOrdinalsModal: (ordinalId = null) => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'OrdinalsViewer',
                        icon: 'OrdinalsIcon',
                        noHeader: 'true',
                        customStyles: {
                            top: '10vh',
                            minWidth: '70vw',
                        },
                        ordinalId,
                    },
                })

                const url = ordinalId ? `/modal/ordinals/${ordinalId}` : '/modal/ordinals'
                window.history.pushState({}, '', url)
            },

            openMetricsModal: () => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'MetricsViewer',
                        title: 'Usage Metrics',
                        customStyles: {
                            top: '2.5vh',
                            width: '95vw',
                            minWidth: '95vw',
                            height: '95vh',
                            minHeight: '95vh',
                            margin: 'auto',
                        },
                    },
                })

                window.history.pushState({}, '', '/modal/metrics')
            },

            openEditProfileModal: () => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'EditProfile',
                        noHeader: true,
                        icon: null,
                        customStyles: {
                            top: '10vh',
                            maxWidth: isMobile ? '95vw' : '50vw',
                        },
                    },
                })
            },

            openImportTextModal: async () => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'ImportText',
                        title: 'Import Text',
                        icon: 'fa fa-download',
                    },
                })
            },

            openExportQuestModal: async quest => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'ExportQuest',
                        title: 'Export Thread',
                        icon: 'fa fa-upload',
                        quest,
                    },
                })
            },

            openNewStreamModal: () => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'NewStream',
                    },
                })
            },

            openNewSpaceModal: () => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'NewSpace',
                    },
                })
            },

            openVideoRecorderModal: (quest, videoHandler, answer) => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'VideoRecorder',
                        quest,
                        videoHandler,
                        answer,
                    },
                })
            },

            openScreenRecorderModal: (quest, videoHandler, answer) => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'ScreenRecorder',
                        quest,
                        videoHandler,
                        answer,
                        hidden: true,
                    },
                })
            },

            openHandCashErrorModal: msg => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'HandCashError',
                        message: msg,
                    },
                })
            },

            openMessageModal: msg => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'Message',
                        message: msg,
                    },
                })
            },

            openConfirmModal: (message, confirmLabel, onConfirm, options = {}) => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'Confirm',
                        message,
                        onConfirm,
                        confirmLabel,
                        ...options,
                    },
                })
            },

            openStreamImageUpdateModal: (team, callback) => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'StreamImageUpdate',
                        team,
                        callback,
                    },
                })
            },

            openSpaceImageUpdateModal: (space, callback) => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'SpaceImageUpdate',
                        space,
                        callback,
                    },
                })
            },

            openUserImageUpdateModal: (user, callback) => {
                set({
                    isModalOpen: true,
                    modalParams: {
                        type: 'UserImageUpdate',
                        user,
                        callback,
                    },
                })
            },

            closeModal: () => {
                const state = get()
                // Check if we're closing an OrdinalsViewer or MetricsViewer modal
                if (
                    state.modalParams?.type === 'OrdinalsViewer' ||
                    state.modalParams?.type === 'MetricsViewer'
                ) {
                    // If current URL contains modal path, reset to home
                    const currentPath = window.location.pathname
                    if (currentPath.includes('/modal/')) {
                        window.history.pushState({}, '', '/')
                    }
                }
                set({ isModalOpen: false })
            },

            closeCryptoModal: () => {
                set({
                    isCryptoPayModalOpen: false,
                    cryptoVoteValue: 0,
                    cryptoPayCallback: null,
                })
            },

            openCryptoModal(left, top, callback = null) {
                set({
                    isCryptoPayModalOpen: true,
                    cryptoPayModalLeft: left,
                    cryptoPayModalTop: top,
                    cryptoPayCallback: callback,
                })
            },

            openStoryModal(quest) {
                set({
                    isStoryOpen: true,
                    isModalOpen: false, // so that modal doesn't show up in Story
                    modalParams: {
                        type: 'PlayAll',
                        quest,
                    },
                })
            },

            closeStoryModal() {
                if (get().middleQuest && location.hash === '#play') {
                    history.pushState(
                        '',
                        document.title,
                        window.location.pathname + window.location.search,
                    )
                }
                set({ isStoryOpen: false })
            },
        },
    }

    const panels = {
        state: [
            ...Array(leftEmptyPanelBuffer).fill(null).map(emptyPanel),
            new PanelState(),
            ...Array(rightEmptyPanelBuffer).fill(null).map(emptyPanel),
        ],
        lastAction: null,
        animatedPanelInsert: null,
        animatedPanelRemove: null,

        getPanels: () => get().panels.state,

        getPanel: panelId => get().panels.state.find(panel => panel.panelId === panelId),

        getIndexById: panelId => {
            return get().panels.state.findIndex(panel => panel.panelId === panelId)
        },

        getPanelToRight: panelId => {
            const { getIndexById, state } = get().panels
            const rightPanelIndex = getIndexById(panelId) + 1
            return state[rightPanelIndex]
        },

        getPanelToLeft: panelId => {
            const { getIndexById, state } = get().panels
            const rightPanelIndex = getIndexById(panelId) - 1
            return state[rightPanelIndex]
        },

        editPanel: (panelId, newPanelValues) => {
            const { getIndexById } = get().panels
            let index
            if (panelId !== undefined) {
                index = getIndexById(panelId)
            }
            set(draft => {
                const oldPanel = draft.panels.state[index]
                const updatedPanel = { ...oldPanel, ...newPanelValues }
                draft.panels.state = [
                    ...draft.panels.state.slice(0, index),
                    updatedPanel,
                    ...draft.panels.state.slice(index + 1),
                ]
            })
        },

        insertPanelAtIndex: (index, newPanel, actionParams = {}) => {
            const panelToInsert = new PanelState(newPanel)
            //console.log('insertPanelAtIndex', index, panelToInsert.panelId)
            set(draft => {
                draft.panels.state.splice(index, 0, panelToInsert)
                draft.panels.animatedPanelInsert = null
                draft.panels.lastAction = { ...actionParams, insert: panelToInsert }
            })
        },

        removePanelAtIndex: removeIndex => {
            const panels = get().panels
            const panelToRemove = panels.state[removeIndex]
            const animated = panels.animatedPanelRemove
                ? panels.animatedPanelRemove.direction
                : false
            set(draft => {
                draft.panels.state = [
                    ...draft.panels.state.slice(0, removeIndex),
                    ...draft.panels.state.slice(removeIndex + 1),
                ]
                draft.panels.lastAction = {
                    remove: panelToRemove,
                    removeIndex,
                    animated,
                    centerIndex: getCenterIndex(),
                    panelIds: panels.state.map(panel => panel.panelId),
                }
                draft.panels.animatedPanelRemove = null
            })
        },

        startAnimatedInsert: (
            index,
            newPanel,
            options: { direction: 'left' | 'right'; actionParams?: object },
        ) => {
            //console.log('startAnimatedInsert', index, options.direction)
            set(state => {
                state.panels.animatedPanelInsert = {
                    index,
                    panel: newPanel,
                    direction: options.direction,
                    actionParams: options.actionParams,
                }
            })
        },

        completeAnimatedInsert: () => {
            const {
                panels: { insertPanelAtIndex, animatedPanelInsert },
            } = get()
            insertPanelAtIndex(
                animatedPanelInsert.index,
                animatedPanelInsert.panel,
                animatedPanelInsert.actionParams,
            )
        },

        startAnimatedRemove: (index, direction) => {
            set(draft => {
                draft.panels.animatedPanelRemove = { index, direction }
            })
        },

        completeAnimatedRemove: () => {
            const {
                panels: { animatedPanelRemove },
            } = get()
            panels.removePanelAtIndex(animatedPanelRemove.index)
        },

        insertPanelRight: (
            panelId,
            newPanel,
            options: { animate: boolean; actionParams?: object } = { animate: false },
        ) => {
            const {
                panels: {
                    getIndexById,
                    insertPanelAtIndex,
                    state: panelState,
                    startAnimatedInsert,
                },
            } = get()
            const currentIndex = getIndexById(panelId)
            const nextPanel = panelState[currentIndex + 1]
            if (
                nextPanel?.empty ||
                !newPanel.questId ||
                newPanel.filter?.questId !== nextPanel.filter?.questId
            ) {
                const appendToEnd = currentIndex === panelState.length - rightEmptyPanelBuffer
                const requiresAnimatedInsert = options.animate && !appendToEnd
                requiresAnimatedInsert
                    ? startAnimatedInsert(currentIndex + 1, newPanel, {
                          direction: 'right',
                          actionParams: options?.actionParams,
                      })
                    : insertPanelAtIndex(currentIndex + 1, newPanel, options?.actionParams)
            }
        },

        insertPanelLeft: (panelId, newPanel, options = { animate: false }) => {
            const {
                panels: { getIndexById, insertPanelAtIndex, startAnimatedInsert },
            } = get()
            const currentIndex = getIndexById(panelId)
            const prependToStart = currentIndex === 0
            const requiresAnimatedInsert = options.animate && !prependToStart
            requiresAnimatedInsert
                ? startAnimatedInsert(currentIndex, newPanel, { direction: 'left' })
                : insertPanelAtIndex(currentIndex, newPanel)
        },

        clearPanel: panelId => {
            panels.editPanel(panelId, { empty: true })
        },

        appendEmptyPanel: () => {
            const newEmptyPanel = new PanelState({ empty: true })
            set(draft => {
                // This will activate the correct slide effect in useSlideEffects.
                draft.panels.lastAction = {
                    insert: draft.panels.state[draft.panels.state.length - 1],
                }
                draft.panels.state.push(newEmptyPanel)
            })
        },

        prependEmptyPanel: () => {
            const newEmptyPanel = new PanelState({ empty: true })
            set(draft => {
                // This will activate the correct slide effect in useSlideEffects.
                draft.panels.lastAction = { insert: draft.panels.state[0] }
                draft.panels.state.unshift(newEmptyPanel)
            })
        },

        appendSession: (newFilter, panelProps = {}) => {
            const panels = get().panels
            const numPanels = panels.getPanels().length
            const newPanels = [
                new PanelState({
                    filter: newFilter,
                    empty: false,
                    ...panelProps,
                }),
                new PanelState({ empty: true }),
            ]
            set(draft => {
                draft.panels.state.splice(numPanels, 0, ...newPanels)
                // We're really inserting 2 panels, but the last one is empty, so we only want to show the first one.
                draft.panels.lastAction = { insert: newPanels[0], appendSession: true }
            })
        },
    }

    return {
        DEBUG: false,
        panels: panels,
        isModalOpen: false,
        modalParams: { type: '' },
        publicTeams: [],

        activeSpaceId: null,
        userFeatures: null,
        /** utc time in ms since cache was hydrated from localstorage **/
        lastLocalCacheRead: 0,
        /** cached answers live here **/
        answers: {} as { [answerId: string]: Answer },
        /** cached quests live here **/
        quests: {} as { [questId: string]: Quest },
        // These are cached new answers
        newAnswerIds: {} as { [questId: string]: [answerId: string] },
        draftAnswerIds: {} as { [answerId: string]: [answerId: string] },
        users: [],
        userSpaces: [],
        userStreams: [],
        lockedQuests: [] as string[],
        lockedAnswers: [] as string[],
        lockQuest,
        unlockQuest,
        lockAnswer,
        unlockAnswer,
        sliding: false,
        slidingBack: false,
        middlePanelExpandedQuests: {},
        rightPanelExpandedQuests: {},
        selectedQuote: null,
        selectedQuoteParentAnswer: null,
        hideMiddlePanelExcept: null,
        hideRightPanelExcept: null,
        middleNewQuestInput: '',
        newAnswerQueryMiddle: null,
        newAnswerQueryRight: null,
        unactedAnswers: [],
        unactedParents: [],
        starredQuests: [],
        draftQuestIds: { middle: null, right: null } as { [panel: string]: string },
        currentUserBsvWalletType: null as BsvWalletType,

        upvalueSats: null,
        boostSats: null,
        lockSats: null,
        lockBlocks: null,

        answerJobs: {} as { [answerId: string]: [JobStatus] },
        bsvUnit: BITCOIN_UNIT,
        signalType: null,

        // Getter Setters.
        showSidebar: false,
        setShowSidebar: (val: boolean) => set(state => ({ showSidebar: val })),
        showRightSidebar: false,
        setShowRightSidebar: show => set({ showRightSidebar: show }),
        getSpace,
        getActiveSpace,
        setActiveSpace,
        setActiveHistoryTab,
        setUserSpaces,
        setUserStreams,
        set: set,
        get: get,
        getSpaceId,
        deleteEmbed,
        updateQuest,
        getQuestState,
        updateQuestAnswer,
        mergeAnswers,
        setVideoDownloadInProgress,
        pinQuestToTeam,
        unpinQuestFromTeam,
        actions,
        pushUnactedIds,
        pushUnactedParentIds,
    }
}

if (usePatches) enablePatches()

const useStore = createWithEqualityFn(knovStore, shallow)

/** Global control for DEBUG mode (exported because this flag is also checked
 *  in Answer.jsx to enable debug display) */
export function DEBUG() {
    return (
        window.gon?.env === 'development' || document.location.host.match(/localhost(:[0-9=]+)?$/)
    )
}

window.setDebug = (debug = false, persist = false) => {
    const { set, userFeatures } = useStore.getState()
    set(state => {
        state.DEBUG = debug
    })
    if (persist) {
        localStorage.setItem(LOCAL_STORAGE_DEBUG_KEY, debug.toString())
    }
}

if (DEBUG()) {
    /* some occasionally useful debug functions for interacting with the
     * app/cache from the dev tools console*/

    window.knovStore = useStore

    window.setDebug(true)

    window.knovSet = window.knovStore.set
    window.knovGet = window.knovStore.get

    window.getCachedAnswer = id => cache.getCachedAnswer(id)
    window.getCachedQuest = id => cache.getCachedQuest(id)

    // usage: updateCachedQuest(248, {sorted_answers: []})
    window.updateCachedQuest = (...p: [number, any]) => cache.updateCachedQuest(...p)

    // usage: updateCachedAnswer(12, {position: 2})
    window.updateCachedAnswer = (...p: [number, any]) => cache.updateCachedAnswer(...p)

    // update the text content and the delta json in one go for easy visibility
    // usage: updateAnswerContent(22, "answer 22 now says this")
    window.updateAnswerContent = (ansId, newContent) =>
        window.updateAnswer(ansId, {
            content: newContent,
            delta_json: {
                ops: [
                    {
                        insert: newContent,
                    },
                ],
            },
        })
}

export const LOCAL_STORAGE_DEBUG_KEY = 'TREECHAT_LOCAL_DEBUG'
export const knovHostName = window.gon?.host || 'app.knovigator.com'

export default useStore
