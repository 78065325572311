import React from 'react'
import styles from './image-placeholder.module.scss'

const ImagePlaceholder = ({ failed = false }) => {
    return (
        <div className={`${styles.container} ${failed ? styles.failed : ''}`}>
            <div className={styles.orbContainer}>
                <div className={styles.strandRing}>
                    {Array.from({ length: 12 }).map((_, i) => (
                        <div key={i} className={styles.strand} style={{ '--i': i }} />
                    ))}
                </div>
                <div className={styles.glowLayers}>
                    {Array.from({ length: 6 }).map((_, i) => (
                        <div key={i} className={styles.glowWisp} style={{ '--i': i }} />
                    ))}
                </div>
                <div className={styles.orb}>
                    <div className={styles.innerOrb} />
                    <div className={styles.morphLayer1} />
                    <div className={styles.morphLayer2} />
                    <div className={styles.glow} />
                </div>
            </div>
        </div>
    )
}

export default ImagePlaceholder
