import { v4 as uuid } from 'uuid'
import { Filter } from 'components/filters/useStreamFilters'
import styles from 'components/panels/panel-container.module.scss'
import { isMobile } from 'react-device-detect'
import { getCachedQuest } from 'state/cache'
import questModel from 'components/quests/questModel'

export type PanelId = string
export type QuestId = string

export enum DefaultStreamFilters {
    public = 'public',
    private = 'private',
    notifications = 'notifications',
    link = 'link',
    all = 'all',
}

export const ANSWER_HEIGHT = isMobile ? 128 : 121
export const SCROLL_OFFSET = 1500

export class PanelState {
    panelId: PanelId = uuid()
    empty: boolean = false
    newThread: boolean = false
    stream?: `${DefaultStreamFilters}` | { teamId: string } = 'public'
    filter?: Filter
    questId?: QuestId
    query?: any

    private readonly MAX_SCROLL_ATTEMPTS = 5
    private readonly SCROLL_TIMEOUT = 3000

    constructor(panel?: Partial<PanelState>) {
        if (panel) {
            Object.assign(this, panel)
        }
    }

    private panelRef?: HTMLDivElement
    setPanelRef = (ref: HTMLDivElement) => {
        this.panelRef = ref
    }
    getPanelRef = () => this.panelRef

    shake = () => {
        const panelRef = this.getPanelRef()
        if (panelRef) {
            panelRef.classList.add(styles.shake)
            setTimeout(() => {
                panelRef.classList.remove(styles.shake)
            }, 400) // Duration of the animation
        }
    }

    getTransformContainer = () => this.panelRef?.querySelector('.transform-container')
    getScrollContainer = () => this.panelRef?.querySelector('.scroll-container') as HTMLElement

    private scrollWithRetry = (
        scrollContainer: HTMLElement,
        getTargetScroll: () => number,
        checkIfReachedTarget: () => boolean,
        callback?: () => void,
    ) => {
        let isUserScrolling = false
        let scrollAttempts = 0

        const attemptScroll = () => {
            let lastScrollTop = scrollContainer.scrollTop
            let lastCheckTime = Date.now()
            let scrollTimeout: number
            let isScrolling = false
            let hasCompleted = false
            let maxTimeoutId: number
            let callbackCalled = false

            const callbackOnce = () => {
                if (!callbackCalled && callback) {
                    callbackCalled = true
                    callback()
                }
            }

            const cleanup = () => {
                if (!hasCompleted) {
                    hasCompleted = true
                    scrollContainer.removeEventListener('scroll', scrollHandler)
                    window.clearTimeout(scrollTimeout)
                    window.clearTimeout(maxTimeoutId)
                }
            }

            if (isUserScrolling || scrollAttempts >= this.MAX_SCROLL_ATTEMPTS) {
                cleanup()
                return
            }

            scrollAttempts++

            const checkScrollEnd = () => {
                const now = Date.now()
                const scrollTop = scrollContainer.scrollTop

                // Only consider scroll ended if we've been at the same position for at least 100ms
                if (scrollTop === lastScrollTop && now - lastCheckTime >= 100) {
                    if (!checkIfReachedTarget()) {
                        // Try scrolling again if we haven't reached the target
                        isScrolling = true
                        scrollContainer.scrollTo({
                            top: getTargetScroll(),
                            behavior: 'smooth',
                        })
                        lastScrollTop = scrollContainer.scrollTop
                        lastCheckTime = now
                        scrollTimeout = window.setTimeout(checkScrollEnd, 100)
                    } else {
                        callbackOnce()
                        cleanup()
                    }
                } else if (scrollTop !== lastScrollTop) {
                    // Still scrolling, update last position and time
                    lastScrollTop = scrollTop
                    lastCheckTime = now
                    scrollTimeout = window.setTimeout(checkScrollEnd, 100)
                } else {
                    // Same position but not long enough, check again
                    scrollTimeout = window.setTimeout(checkScrollEnd, 50)
                }
            }

            const scrollHandler = () => {
                if (!isScrolling) {
                    isUserScrolling = true
                    cleanup()
                } else {
                    // Reset the check timer since we're still scrolling
                    lastCheckTime = Date.now()
                    window.clearTimeout(scrollTimeout)
                    scrollTimeout = window.setTimeout(checkScrollEnd, 100)
                }
            }

            scrollContainer.addEventListener('scroll', scrollHandler)

            // Set a timeout to prevent infinite scrolling
            maxTimeoutId = setTimeout(() => {
                cleanup()
            }, this.SCROLL_TIMEOUT)

            // Initial scroll attempt
            isScrolling = true
            lastCheckTime = Date.now()
            scrollContainer.scrollTo({
                top: getTargetScroll(),
                behavior: 'smooth',
            })
            scrollTimeout = window.setTimeout(checkScrollEnd, 100)
        }

        attemptScroll()
    }

    scrollToAnswer = (answerId: string, callback?: () => void) => {
        if (!this.filter?.questId || !answerId) return

        const scrollContainer = this.getScrollContainer()
        if (!scrollContainer) return

        const answerElement = answerId
            ? scrollContainer.querySelector(`.answer-in-view-${answerId}`)
            : null

        if (!answerElement) return

        const quest = getCachedQuest(this.filter.questId)
        if (!quest) return
        const answers = [quest.parent, ...questModel.showUnarchivedAnswers(quest)]

        const answerIndex = answers.filter(Boolean).findIndex(answer => answer.id === answerId)

        if (answerIndex === -1) return

        // Remove the initial scroll jump and let the centering logic handle it
        requestAnimationFrame(() => {
            const containerHeight = scrollContainer.clientHeight
            const elementRect = (answerElement as HTMLElement).getBoundingClientRect()
            const containerRect = scrollContainer.getBoundingClientRect()
            const BOTTOM_BUFFER = 30

            const calculateTargetScroll = () => {
                const elementRect = (answerElement as HTMLElement).getBoundingClientRect()
                const containerRect = scrollContainer.getBoundingClientRect()
                const elementHeight = elementRect.height
                const maxAllowableScroll = scrollContainer.scrollHeight - containerHeight

                // Calculate absolute positions instead of relative
                const elementAbsoluteTop =
                    elementRect.top + window.scrollY + scrollContainer.scrollTop
                const containerAbsoluteTop = containerRect.top + window.scrollY
                const elementRelativeToContainer = elementAbsoluteTop - containerAbsoluteTop

                let targetScroll: number

                // Try to center the element
                const centerPosition =
                    elementRelativeToContainer - (containerHeight - elementHeight) / 2

                if (elementHeight <= containerHeight) {
                    // For short answers: check if centering would maintain bottom buffer
                    const bottomAfterCenter =
                        centerPosition + elementHeight + (containerHeight - elementHeight) / 2
                    const hasEnoughBottomBuffer =
                        scrollContainer.scrollHeight - bottomAfterCenter >= BOTTOM_BUFFER

                    if (hasEnoughBottomBuffer) {
                        // Can safely center
                        targetScroll = centerPosition
                    } else {
                        // Position with bottom buffer
                        targetScroll =
                            elementRelativeToContainer +
                            elementHeight +
                            BOTTOM_BUFFER -
                            containerHeight
                    }
                } else {
                    // For tall answers: try to center the visible portion
                    const visibleHeight = Math.min(elementHeight, containerHeight)
                    targetScroll =
                        elementRelativeToContainer - (containerHeight - visibleHeight) / 2
                }

                // Ensure we don't scroll past bounds
                return Math.max(0, Math.min(targetScroll, maxAllowableScroll))
            }

            const checkIfReachedTarget = () => {
                const currentElementRect = (answerElement as HTMLElement).getBoundingClientRect()
                const currentContainerRect = scrollContainer.getBoundingClientRect()
                const elementHeight = currentElementRect.height
                const elementTop = currentElementRect.top - currentContainerRect.top
                const elementBottom = elementTop + elementHeight

                if (elementHeight <= containerHeight) {
                    // For short answers: check both centering and bottom buffer
                    const centerOffset = (containerHeight - elementHeight) / 2
                    const isCentered = Math.abs(elementTop - centerOffset) <= 5
                    const bottomSpace = containerHeight - elementBottom
                    const hasBuffer = bottomSpace >= BOTTOM_BUFFER - 5

                    // Accept either centered (if possible) or properly buffered position
                    const isOk = (isCentered && bottomSpace >= BOTTOM_BUFFER) || hasBuffer
                    return isOk
                } else {
                    // For tall answers: check if visible portion is roughly centered
                    const visibleHeight = Math.min(elementHeight, containerHeight)
                    const idealTop = (containerHeight - visibleHeight) / 2
                    const isOk = Math.abs(elementTop - idealTop) <= 5
                    return isOk
                }
            }

            this.scrollWithRetry(
                scrollContainer,
                calculateTargetScroll,
                checkIfReachedTarget,
                callback,
            )
        })
    }

    scrollToBottom = (callback?: () => void) => {
        const scrollContainer = this.getScrollContainer()
        if (!scrollContainer) return

        // Remove the initial scroll jump and let the smooth scroll handle it
        requestAnimationFrame(() => {
            const containerHeight = scrollContainer.clientHeight

            // Get number of answers if we have a quest
            let initialScrollPosition = 0
            if (this.filter?.questId) {
                const quest = getCachedQuest(this.filter.questId)
                if (quest) {
                    const answers = [quest.parent, ...questModel.showUnarchivedAnswers(quest)]
                    const numAnswers = answers.filter(Boolean).length
                    initialScrollPosition = Math.max(0, numAnswers * ANSWER_HEIGHT - SCROLL_OFFSET)
                }
            }

            // First jump to estimated position
            scrollContainer.scrollTop = initialScrollPosition

            const getTargetScroll = () => scrollContainer.scrollHeight - containerHeight

            const checkIfReachedBottom = () => {
                const currentScrollTop = scrollContainer.scrollTop
                const maxCurrentScroll = scrollContainer.scrollHeight - containerHeight
                // Allow small margin of error (5px) when checking if we reached bottom
                return Math.abs(currentScrollTop - maxCurrentScroll) <= 100
            }

            this.scrollWithRetry(scrollContainer, getTargetScroll, checkIfReachedBottom, callback)
        })
    }

    highlightAnswer = (answerId: string) => {
        if (!this.filter?.questId || !answerId) return

        const answerElement = this.panelRef?.querySelector(`.answer-highlight-${answerId}`)
        if (!answerElement) return

        // Add highlight class to trigger animation
        answerElement.classList.add('highlight')

        // Remove class after animation completes
        setTimeout(() => {
            answerElement.classList.remove('highlight')
        }, 1000) // Match the CSS animation duration
    }
}

export const emptyPanel = () => new PanelState({ empty: true })
