import React, { useCallback } from 'react'
import { PanelState } from 'state/PanelState'
import ThinAnswer from 'components/answers/ThinAnswer'
import ParentAnswerGate from 'components/answers/Answer/ParentAnswerGate'
import styles from './thin-parent.module.scss'
import { isMobile } from 'react-device-detect'
import { v4 as uuid } from 'uuid'
import useStore from 'state/knovStore'
import { getSwiperRef, slideTo, getCenterIndex } from 'state/imperativeApis/swiperApi'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useInsertLeft from 'refactor/hooks/useInsertLeft'

interface ThinParentProps {
    grandParent: any // Replace with proper type
    grandParentQuest: any // Replace with proper type
    parentQuestId: string
    parentId: string
    grandParentId: string
    titleSelected: boolean
    isSelected: boolean
    thinParentHover: boolean
}

const ThinParent: React.FC<ThinParentProps> = ({
    grandParent,
    grandParentQuest,
    parentQuestId,
    parentId,
    grandParentId,
    titleSelected,
    isSelected,
    thinParentHover,
}) => {
    const { panel } = usePanelContext()
    const insertLeft = useInsertLeft(panel.panelId)

    const scrollToAnswer = useCallback((panel: PanelState, answerId: string) => {
        panel.scrollToAnswer(answerId, () => {
            setTimeout(() => {
                panel.highlightAnswer(answerId)
                panel.shake()
            }, 0)
        })
    }, [])

    const handleAnswerClick = useCallback((panel: PanelState, answerId: string) => {
        if (panel?.filter?.answerId !== answerId) {
            useStore.getState().panels.editPanel(panel.panelId, {
                filter: { ...panel.filter, answerId: answerId },
            })
        }
        scrollToAnswer(panel, answerId)
    }, [])

    const onClickAncestorAnswer = async (ancestorAnswerId: string) => {
        const leftPanel = useStore.getState().panels.getPanelToLeft(panel.panelId)
        const leftPanelQuestId = leftPanel?.filter?.questId
        const leftFilterHasQuestId = leftPanelQuestId && leftPanelQuestId === parentQuestId

        const swiper = getSwiperRef()?.current
        const panelIndex = useStore
            .getState()
            .panels.state.findIndex(p => p.panelId === leftPanel.panelId)
        const isOffScreen = panelIndex < swiper.activeIndex

        if (leftFilterHasQuestId) {
            if (isMobile || isOffScreen) {
                swiper.on('slideChangeTransitionEnd', function afterSlide() {
                    handleAnswerClick(leftPanel, ancestorAnswerId)
                    swiper.off('slideChangeTransitionEnd', afterSlide)
                })
                slideTo(getCenterIndex() - 1)
            } else {
                handleAnswerClick(leftPanel, ancestorAnswerId)
            }
        } else {
            const newPanelId = uuid()
            let insertFilter: { questId: string; answerId?: string } = {
                questId: parentQuestId,
                answerId: ancestorAnswerId,
            }

            useStore.getState().set(state => {
                state.selectedAnswers[newPanelId] = { answerId: ancestorAnswerId }
            })

            insertLeft({
                panelId: newPanelId,
                filter: insertFilter,
            })
        }
    }

    return (
        <div className={styles.thinParentComp}>
            <div className={styles.branchIndicator}>
                <div className={styles.branchCircle}></div>
                <div className={styles.branchLine}></div>
            </div>

            <div className={styles.clipContainer}>
                <ThinAnswer
                    answer={grandParent}
                    quest={grandParentQuest}
                    onClick={() => onClickAncestorAnswer(grandParentId)}
                    contextStyles={styles.thinAnswer}
                />
            </div>

            <ParentAnswerGate
                childCount={grandParentQuest?.sorted_answers?.length || 0}
                hover={thinParentHover}
                selected={titleSelected || isSelected}
                onClick={() => onClickAncestorAnswer(parentId)}
            />
        </div>
    )
}

export default ThinParent
