import { useQuery } from '@tanstack/react-query'
import api from 'api/api'
import { SATOSHIS } from 'lib/bsv-util'

// returns current bsv/usd exchange rate
export function useBsvExchangeRate() {
    const result = useQuery({
        queryKey: ['bsv-exchange-rate'],
        queryFn: api.getBsvExchangeRate,
        staleTime: 7.5 * 60 * 1000, // about half a block's time to keep it fresh-ish
        gcTime: Infinity,
        retry: 0,
        refetchOnMount: true, // refetch on mount if data is stale
        refetchOnWindowFocus: true, // refetch on focus if data is stale
        refetchOnReconnect: true, // refetch i connection is re-established
    })

    return result.data?.exchangerate || 0
}

// converts sats to usd amount
export function useSatsToDollars(sats: number | null) {
    const exchangeRate = useBsvExchangeRate()

    if (!sats || !exchangeRate) return null

    return (sats / SATOSHIS) * exchangeRate
}

// converts bsv to usd amount
export function useBsvToDollars(bsv: number | null) {
    const exchangeRate = useBsvExchangeRate()

    if (!bsv || !exchangeRate) return null

    return bsv * exchangeRate
}

// converts usd to sats amount
export function useDollarsToSats(dollars: number | null) {
    const exchangeRate = useBsvExchangeRate()

    if (!dollars || !exchangeRate) return null

    return Math.round((dollars / exchangeRate) * SATOSHIS)
}

// converts usd to bsv amount
export function useDollarsToBsv(dollars: number | null) {
    const exchangeRate = useBsvExchangeRate()

    if (!dollars || !exchangeRate) return null

    return dollars / exchangeRate
}

// formats a usd amount into a display string
export function formatUsdValue(amount: number | null) {
    if (!amount) return null
    if (amount < 0.001) return '< $0.001'
    return `$${amount.toFixed(3)}`
}
