import React, { ReactNode, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

interface OrdinalInfoPopupPortalProps {
    /** The content to render in the portal */
    children: ReactNode
}

/**
 * A portal component for rendering content outside the normal component hierarchy.
 * This is used to prevent popup content from being clipped by overflow restrictions.
 */
export const OrdinalInfoPopupPortal: React.FC<OrdinalInfoPopupPortalProps> = ({ children }) => {
    const [mounted, setMounted] = useState<boolean>(false)
    const portalRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        portalRef.current = document.createElement('div')
        document.body.appendChild(portalRef.current)
        setMounted(true)

        return () => {
            if (portalRef.current) {
                document.body.removeChild(portalRef.current)
            }
        }
    }, [])

    return mounted && portalRef.current ? ReactDOM.createPortal(children, portalRef.current) : null
}
