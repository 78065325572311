import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import UserSelector from './UserSelector'
import { isMobile } from 'react-device-detect'
import { defer, debounce, partial } from 'lodash'
import { pipe, wrapIndex, discardReturn } from 'utils'
import cn from 'classnames'
import styles from './AutocompletePopup.module.scss'
import Fuse from 'fuse.js'

export const SHOW_HIDE_EV_NAME = 'showFuzzySearchPopup'

type listItem = { value: string; [key: string]: any }
type IPopupProperties = {
    popupTrigger: string
    showing: boolean
    x: number
    y: number
    inp: string
    values: listItem[]
    width?: number
    height?: number
    doSelect?: string // TODO: use type for event.code
    doContinueKey?: string // TODO: use type for event.code
    doNavKey?: string // TODO: use type for event.code
    doCancel?: string // TODO: use type for event.code
    extractItemVal?: (item: listItem) => string
    finalizeSelection?: (item: { [key: string]: any }, opts: { [key: string]: any }) => any
    // TODO: better types for below
    cancelKeys?: string[]
    confirmKeys?: string[]
    continueKeys?: string[]
    onChange?: Function
    filterItems?: Function
    renderItem?: Function
    renderHighlightedItem?: Function
    MenuComponent?: Function
    maxItemsInWindow?: number
}
// mutable!
let popupProps: IPopupProperties = {
    popupTrigger: '',
    showing: false,
    x: 0,
    y: 0,
    inp: '',
    values: [],
}

export function getPopupProps() {
    // console.log('getPopupProps() returning', { ...popupProps })
    return { ...popupProps }
}

export function updatePopupProps(newPopupProps: { [key: string]: any }) {
    //console.log('updatePopupProps', newPopupProps)
    replacePopupProps({ ...getPopupProps(), ...newPopupProps })
}

export function replacePopupProps(newPopupProps: IPopupProperties) {
    popupProps = { ...newPopupProps }
    window.dispatchEvent(
        new CustomEvent(SHOW_HIDE_EV_NAME, {
            detail: newPopupProps,
        }),
    )
}

export default function AutocompletePopupWrapper() {
    const [popupSettings, setPopupSettings] = useState(popupProps)

    useEffect(function listenForAutocompletePopupEvents() {
        function setListener(ev) {
            // make sure we always set the module-level popupProps() fn so that it
            // stays in sync react's popupSettings and we can retrieve it via
            // getPopupProps()
            popupProps = { ...ev?.detail }
            setPopupSettings(popupProps)
            return { ...popupProps }
        }
        window.addEventListener(SHOW_HIDE_EV_NAME, setListener)

        // remove the event listener after
        return () => window.removeEventListener(SHOW_HIDE_EV_NAME, setListener)
    }, [])

    return <AutocompletePopup {...popupSettings} />
}

function AutocompletePopup({
    popupTrigger = '',
    values = [],
    showing,
    x,
    y,
    width = 200,
    inp = '',
    extractItemVal = item => item?.value,
    // this renderItem fn works for knov user objects, will need to pass in
    // different ones for differnt types in the future
    renderItem = item => <UserSelector user={item} />,
    renderHighlightedItem = item => <UserSelector user={item} selected={true} />,
    MenuComponent = ({ children, ...props }) => <div {...props}>{children}</div>,
    /* function to find terms that match `searchStr` in `items` */
    doSelect = '',
    doContinueKey = '',
    doNavKey = '',
    doCancel = '',
    filterItems = (searchStr: string, items: any[]) => {
        const fuse = new Fuse(items, { keys: ['name'], threshold: 0.1 })
        return fuse.search(searchStr).map(r => r.item)
    },
    finalizeSelection = (...a) => {},
    onChange = (...args) => {},
    confirmKeys = ['Enter', 'Tab', 'ArrowRight'], // a confirm key means pick from the autocomplete menu
    continueKeys = [' '], // a continue key means insert what the user typed just as they typed it
    cancelKeys = ['Escape', 'ArrowLeft'],
    maxItemsInWindow = isMobile ? 100 : 100, // arbitrary, but too many and it might render offscreen
}: IPopupProperties) {
    const [highlightedItemIdx, setHighlightedItemIdx] = useState(0)
    const inpRef = useRef(null)
    const [filteredVals, setFilteredVals] = useState(values.slice(0, maxItemsInWindow))

    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    useEffect(function setupWindowHeightListener() {
        function listenWindow() {
            setWindowHeight(window.innerHeight)
        }
        window.addEventListener('resize', listenWindow)
        return () => window.removeEventListener('resize', listenWindow)
    }, [])

    const fullHeight = filteredVals?.length * 55 + 20 // TODO: calculate this dynamically somehow
    const marginBuffer = 30
    const upperHeight = y - marginBuffer
    const lowerHeight = windowHeight - y - 20 - marginBuffer
    let height = Math.min(fullHeight, lowerHeight)
    // Switch to upper, and cache, if we don't fit and there's more room there to set orientation.
    const [openUpwards, setOpenUpwards] = useState(null)
    if (openUpwards === null) setOpenUpwards(fullHeight > height && upperHeight > lowerHeight)
    if (openUpwards) {
        height = Math.min(fullHeight, upperHeight)
    }

    // small wrapper to hide the popup after finalizing (since
    // finalizeSelection can be passed in, need to wrap it).
    // also debounced to prevent onBlur and onClick from clobbering eachother
    const finalizeSelectionInternal = (
        ...args: [newVal: { [key: string]: any }, opts?: { [key: string]: any }]
    ) => {
        const ret = finalizeSelection.apply(this, args)
        reset()
        return ret
    }

    function reset() {
        replacePopupProps({
            popupTrigger: '',
            inp: '',
            doSelect: '',
            doContinueKey: '',
            showing: false,
            finalizeSelection: (...a) => console.log('BAD FINALIZE!', ...a),
            x: -100000,
            y: -100000,
            values: [],
        })
        resetLocalState()
    }

    function resetLocalState() {
        setOpenUpwards(null)
        setFilteredVals(values.slice(0, maxItemsInWindow))
        setHighlightedItemIdx(0)
    }

    useEffect(() => {
        if (doSelect) {
            if (highlightedItemIdx !== -1) {
                const selectedItem = filteredVals[highlightedItemIdx]
                setFilteredVals([selectedItem])
                finalizeSelectionInternal(selectedItem)
            } else if (filteredVals?.length) {
                // select the top item if there is an item to select
                finalizeSelectionInternal(filteredVals[0])
            }
            updatePopupProps({ doSelect: false })
        }
    }, [doSelect])

    useEffect(() => {
        if (doContinueKey) {
            //finalizeSelectionInternal
            doContinue()
        }
    }, [doContinueKey])

    useEffect(
        debounce(() => {
            if (doNavKey) {
                if (doNavKey === 'ArrowDown') {
                    const newIdx = wrapIndex(filteredVals.length, highlightedItemIdx + 1)
                    setHighlightedItemIdx(newIdx)
                    pipe(filteredVals[newIdx], onChange)
                } else if (doNavKey === 'ArrowUp') {
                    const newIdx = wrapIndex(filteredVals.length, highlightedItemIdx - 1)
                    setHighlightedItemIdx(newIdx)
                    pipe(filteredVals[newIdx], onChange)
                }
            }
            updatePopupProps({ doNavKey: '' })
        }, 10),
        [doNavKey],
    )

    useEffect(() => {
        if (doCancel) {
            onCancel()
        }
    }, [doCancel])

    useEffect(() => {
        resetLocalState()
        if (!showing) {
            // clean up if popup is being hidden
            reset()
        }
    }, [showing])

    const doContinue = () => {
        pipe(
            inp,
            v => ({ value: popupTrigger + v }),
            discardReturn(onChange),
            v => filteredVals.find(fv => extractItemVal(fv) === extractItemVal(v)) ?? v,
            v => finalizeSelectionInternal(v, { mention: !!v?.name }),
        )
    }

    useEffect(() => {
        const filtered = inp ? filterItems(inp, values) : values
        setFilteredVals(filtered.slice(0, maxItemsInWindow))
        // if (filtered?.length === 0) doContinue()
    }, [inp, popupTrigger])

    function onCancel() {
        // When canceling, don't pass anything to finalizeSelection
        // This will just close the popup without inserting a mention
        reset()
    }

    return ReactDOM.createPortal(
        <div
            style={{
                left: `${x}px`,
                top: `${y + 30}px`,
                width: `${width}px`,
                height,
                overflowY: 'scroll',
                position: 'fixed',
                ...(openUpwards ? { transform: `translateY(${(height + 40) * -1}px)` } : {}),
            }}
            className={cn(styles?.autocompletePopupComp, showing ? styles?.showing : {})}
        >
            <MenuComponent>
                {filteredVals.slice(0, maxItemsInWindow).map((v, i) => (
                    <div
                        key={i}
                        onMouseEnter={() =>
                            pipe(
                                i,
                                i => {
                                    setHighlightedItemIdx(i)
                                    return i
                                },
                                i => filteredVals[i],
                                onChange,
                            )
                        }
                        onMouseLeave={() => setHighlightedItemIdx(-1)}
                        onClick={ev => {
                            pipe(filteredVals[i], finalizeSelectionInternal)
                        }}
                    >
                        {i === highlightedItemIdx ? renderHighlightedItem(v) : renderItem(v)}
                    </div>
                ))}
            </MenuComponent>
        </div>,
        document.body,
    )
}
