import React from 'react'
import cn from 'classnames'
import styles from './user-name.module.scss'
import moment from 'moment'
import WithTooltip from 'components/shared/WithTooltip'
import useStore from 'state/knovStore'

export default function UserName(props) {
    const spaceName = useStore(state => state.getActiveSpace)()?.name
    const { user, virtualUserName, transcriptSegmentInfo } = props

    let flexDirection, margin
    if (props.column) {
        flexDirection = styles.flexColumn
    } else {
        flexDirection = styles.flexRow
        margin = styles.horizMargin
    }

    const name = props.showKnovigator ? spaceName : virtualUserName || user?.name

    return (
        <div className={cn(styles.userNameComp, props.contextStyles)}>
            {!props.hideName && (
                <div className={cn(styles.usernameWrapper)}>
                    <span className={cn(styles.username)}>
                        {name}
                        {props.agentModel && (
                            <span className={cn(styles.agentModel)}>{props.agentModel}</span>
                        )}
                    </span>
                </div>
            )}

            <div className={cn(styles.timeContainer, props.isTitle && styles.titleTimeContainer)}>
                {props.showAgg && (
                    <Time
                        time={props.lastAnswerCreatedAt}
                        tip={'Time of latest message in thread.'}
                        thread
                    />
                )}

                {props.showAgg && <>|{/*•*/}</>}

                {props.answerCreatedAt && (
                    <Time
                        answer={props?.answer}
                        time={props.answerCreatedAt}
                        transcriptSegmentInfo={transcriptSegmentInfo}
                        tip={new Date(props.answerCreatedAt).toLocaleString()}
                    />
                )}
            </div>
        </div>
    )
}

function Time(props) {
    const { thread, tip, time, transcriptSegmentInfo } = props
    const formattedTime = createdAt => {
        if (createdAt === 'now') return 'now'

        const momentCreatedAt = moment(createdAt)
        const now = moment()
        const diffSeconds = now.diff(momentCreatedAt, 'seconds')
        const diffMinutes = now.diff(momentCreatedAt, 'minutes')
        const diffHours = now.diff(momentCreatedAt, 'hours')
        const diffDays = now.diff(momentCreatedAt, 'days')

        // Less than a minute ago: show seconds
        if (diffSeconds < 60) {
            return `${diffSeconds}s`
        }
        // Less than an hour ago: show minutes
        if (diffMinutes < 60) {
            return `${diffMinutes}m`
        }
        // Less than a day ago: show hours
        if (diffHours < 24) {
            return `${diffHours}h`
        }

        // If it's this year, show "MMM D" (e.g., "Feb 10")
        if (momentCreatedAt.year() === now.year()) {
            return momentCreatedAt.format('MMM D')
        }

        // If it's a different year, show "MMM D, YYYY" (e.g., "Feb 10, 2024")
        return momentCreatedAt.format('MMM D, YYYY')
    }

    const formatTranscriptSegmentInfo = segmentInfo => {
        const formatAsHHMMSS = seconds => {
            const date = new Date(null)
            const duration = moment.duration(seconds, 'seconds')
            const formattedDuration =
                duration.asHours() < 1
                    ? moment.utc(duration.asMilliseconds()).format('mm:ss')
                    : moment.utc(duration.asMilliseconds()).format('HH:mm:ss')

            return formattedDuration
        }
        return `${formatAsHHMMSS(segmentInfo.start)} - ${formatAsHHMMSS(segmentInfo.end)}`
    }

    const threadStyles = thread ? styles.thread : null

    return (
        <div className={styles.timeComp}>
            <WithTooltip tip={tip}>
                <div className={cn(threadStyles)}>
                    {transcriptSegmentInfo
                        ? formatTranscriptSegmentInfo(transcriptSegmentInfo)
                        : formattedTime(time)}
                </div>
            </WithTooltip>
        </div>
    )
}

export { Time }
