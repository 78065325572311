import React from 'react'
import styles from './perms.module.scss'
import cn from 'classnames'
import perms from 'lib/perms'
import WithTooltip from 'components/shared/WithTooltip'
import questModel from 'components/quests/questModel'
import useInsertRight from 'refactor/hooks/useInsertRight'
import { withDepth } from 'components/filters/useStreamFilters'
import StreamIcon from './StreamIcon'

export default function Perms(props) {
    const quest = props.quest
    const amAdmin = perms.amQuestOwner(quest)
    const isBsv = props.isBsv

    const filter = questModel.getFilter(quest)

    // TODO confusing... questModel.isPublic means public:true and not in a team. should be renamed isTreechat
    const isPublic = questModel.isPublic(quest)
    const isHodlocker = gon.HODLOCKER_TEAM_ID && gon.HODLOCKER_TEAM_ID === quest.team_id
    const isTwetch = gon.TWETCH_TEAM_ID && gon.TWETCH_TEAM_ID === quest.team_id

    const isRoot = props.isRoot
    const showTeam = questModel.hasTeam(quest) && !isPublic

    let permsName
    if (showTeam) permsName = quest.team.name
    else if (questModel.isPrivate(quest)) permsName = 'Home'
    else if (!isRoot && isHodlocker) permsName = 'Hodlocker'
    else if (!isRoot && isTwetch) permsName = 'Twetch'
    else if (!isRoot && isPublic) permsName = 'Public'
    else if (questModel.isLinkShare(quest)) permsName = 'Link Access'

    const { insertRight } = useInsertRight()

    const onClickStream = ev => {
        return // Let ThinParent handle this
        ev.preventDefault()
        ev.stopPropagation()

        insertRight({ filter: withDepth(filter) })
    }

    const onClickControl = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if ((!isBsv || quest.is_draft) && props.setShowPermsOptions)
            props.setShowPermsOptions(!props.showPermsOptions)
    }

    return (
        <div className={cn(styles.permsComp, props.contextStyles)}>
            <WithTooltip tip={`Open ${permsName} stream`}>
                <div className={styles.permsValue} onClick={onClickStream}>
                    <div className={styles.permsIconContainer}>
                        <StreamIcon
                            quest={quest}
                            isRoot={isRoot}
                            contextStyles={styles.permsIcon}
                        />
                    </div>
                </div>
            </WithTooltip>

            {amAdmin && (!isBsv || quest.is_draft) ? (
                <WithTooltip tip={'Choose stream to publish to'} contextStyles={styles.withTooltip}>
                    <div
                        className={cn(styles.permsControlContainer, styles.withHover)}
                        onClick={onClickControl}
                    >
                        <div className={styles.permsLabelContainer}>
                            <div className={styles.permsLabel}>{permsName}</div>
                        </div>

                        {(!isBsv || quest.is_draft) && (
                            <div className={styles.permsControl}>
                                <i className="fa fa-angle-down" />
                            </div>
                        )}
                    </div>
                </WithTooltip>
            ) : (
                <div className={styles.permsControlContainer}>
                    <div className={styles.permsLabelContainer}>
                        <div className={styles.permsLabel}>{permsName}</div>
                    </div>
                </div>
            )}
        </div>
    )
}
