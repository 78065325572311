import React, { useState, useRef, type ReactPortal } from 'react'
import ReactDOM from 'react-dom'
import styles from 'components/answers/thread-info.module.scss'
import {
    LockAmount,
    BsvValueSignals,
    calculateThreadValueSignals,
    filterAnswersWithValueSignals,
} from 'components/answers/LockControl'
import questModel from 'components/quests/questModel'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import AnswerFilterButton from './AnswerFilterButton'
import QuestOptions from 'components/quests/QuestOptions'
import useBlockheight from 'api/useBlockheight'

export default function ThreadInfo({
    quest,
    query,
    isActive,
    answerFilter,
    setAnswerFilter,
    answerFilterOptions,
}) {
    const [showDropdown, setShowDropdown] = useState(false)
    const [showLocksPopover, setShowLocksPopover] = useState(false)
    const lockAmountRef = useRef<HTMLDivElement>(null)
    const blockheight = useBlockheight()
    const matchingAnswers = questModel.matchingAnswers(quest)
    const tailAnswers = questModel.showUnarchivedAnswers(quest)
    const threadLockedSats = calculateThreadValueSignals(tailAnswers, blockheight)
    const existNewAnswers = tailAnswers.some(
        answer => answer && answer.created_at >= quest.last_action_created_at,
    )

    // get all answers that have locks or upvalues
    const answersWithBsvValueSignals = filterAnswersWithValueSignals(tailAnswers)

    const toggleDropdown = (e: React.MouseEvent) => {
        e.stopPropagation()
        setShowDropdown(!showDropdown)
    }

    return (
        <ErrorBoundary>
            <div className={styles.threadInfoComp}>
                <div className={styles.leftContainer}>
                    {threadLockedSats > 0 && (
                        <div className={styles.amountContainer} ref={lockAmountRef}>
                            <div
                                onClick={ev => {
                                    ev.stopPropagation()
                                    setShowLocksPopover(true)
                                }}
                            >
                                <LockAmount lockedSats={threadLockedSats} />
                            </div>

                            {showLocksPopover &&
                                (ReactDOM.createPortal(
                                    <BsvValueSignals
                                        answers={answersWithBsvValueSignals}
                                        postLockRef={lockAmountRef}
                                        setShowPopover={setShowLocksPopover}
                                    />,
                                    document.body,
                                ) as React.ReactNode)}
                        </div>
                    )}

                    {isActive ? (
                        <Replies answers={tailAnswers} />
                    ) : (
                        <div className={styles.counts}>
                            {query && matchingAnswers?.length > 0 && (
                                <MatchingAnswers quest={quest} />
                            )}
                            {existNewAnswers && <NewAnswers quest={quest} />}
                            {tailAnswers?.length > 0 && !existNewAnswers && (
                                <LastAnswers quest={quest} maxNumLast={3} />
                            )}
                            <Replies answers={tailAnswers} />
                        </div>
                    )}
                </div>

                {isActive && quest.sorted_answers?.length > 0 && (
                    <div className={styles.rightContainer}>
                        <AnswerFilterButton
                            toggleDropdown={toggleDropdown}
                            isActive={showDropdown}
                            selectedFilter={answerFilter}
                        />
                    </div>
                )}

                {showDropdown && (
                    <div className={styles.dropdownContainer}>
                        <QuestOptions
                            quest={quest}
                            answerFilter={answerFilter}
                            answerFilterOptions={answerFilterOptions}
                            setAnswerFilter={setAnswerFilter}
                            showFilterDropdown={true}
                            closeQuestOptions={() => setShowDropdown(false)}
                        />
                    </div>
                )}
            </div>
        </ErrorBoundary>
    )
}

function MatchingAnswers({ quest }) {
    const matchingAnswers = questModel.matchingAnswers(quest)
    return (
        <div className={styles.matchingAnswers}>
            <span className={styles.count}>{matchingAnswers?.length}</span>
            <span className={styles.label}>
                match{matchingAnswers?.length === 1 ? '' : 'es'} of
            </span>
        </div>
    )
}

function NewAnswers({ quest }) {
    const newAnswers = questModel
        .showUnarchivedAnswers(quest)
        .filter(a => a.created_at >= quest.last_action_created_at)
    return (
        <div className={styles.newAnswers}>
            <span className={styles.count}>{newAnswers?.length}</span>
            <span className={styles.label}>new of</span>
        </div>
    )
}

function LastAnswers({ quest, maxNumLast }) {
    const latestAnswers = questModel.showUnarchivedAnswers(quest)
    return (
        <div className={styles.newAnswers}>
            <span className={styles.count}>{Math.min(maxNumLast, latestAnswers?.length)}</span>
            <span className={styles.label}>latest of</span>
        </div>
    )
}

function Replies({ answers }) {
    return (
        <div className={styles.replies}>
            <span className={styles.count}>{answers?.length}</span>
            <span className={styles.label}>{answers?.length === 1 ? 'reply' : 'replies'}</span>
        </div>
    )
}
