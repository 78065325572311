import React from 'react'
import useStore from 'state/knovStore'
import useStreamFilters from 'components/filters/useStreamFilters'
import usePanelContext from 'refactor/hooks/usePanelContext'

interface UserHandleFromIdProps {
    /** The ID of the user to display */
    userId: string
    /** Function to call after the user handle is clicked */
    afterClick?: () => void
}

/**
 * A component that renders a clickable user handle from a user ID.
 * When clicked, it selects the user in the stream filters.
 */
const UserHandleFromId: React.FC<UserHandleFromIdProps> = ({ userId, afterClick }) => {
    const users = useStore(state => state.users)
    const { panel } = usePanelContext()
    const { selectUser } = useStreamFilters(panel?.id, { append: true })

    const onSelectUser = (ev: React.MouseEvent) => {
        ev.preventDefault()
        ev.stopPropagation()
        selectUser(userId)
        if (afterClick) {
            afterClick()
        }
    }

    const user = users?.find(user => user.id === userId)

    return <a onClick={onSelectUser}>@{user?.name}</a>
}

export default UserHandleFromId
