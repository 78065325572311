import React from 'react'
import useStore from 'state/knovStore'
import styles from 'components/filters/filter-icon.module.scss'
import cn from 'classnames'
import streamStyles from '../PanelController/SimpleHeader/simple-header.module.scss'
import WithTooltip from 'components/shared/WithTooltip'

import UserIcon from 'components/users/UserIcon'
import LinkIcon from 'components/shared/icons/LinkIcon'
import ClipIcon from 'components/shared/icons/ClipIcon'
import TeamIcon from 'components/users/TeamIcon'
import PublicIcon from 'components/shared/icons/PublicIcon'
import TreechatIconPng from 'components/shared/icons/TreechatIconPng'
import HodlockerIcon from 'components/shared/icons/HodlockerIcon'
import OrdinalsIcon from 'components/shared/OrdinalsIcon'
import TwetchIcon from 'components/shared/icons/TwetchIcon'
import PrivateIcon from 'components/shared/icons/PrivateIcon'
import AllIcon from 'components/shared/icons/AllIcon'
import NotiIcon from 'components/shared/icons/NotiIcon'
import StarredIcon from 'components/shared/icons/StarredIcon'
import HistoryIcon from 'components/shared/icons/HistoryIcon'
import PeopleIcon from 'components/shared/icons/PeopleIcon'

import useStreamFilters, { withDepth } from 'components/filters/useStreamFilters'
import useInsertRight from 'refactor/hooks/useInsertRight'
import usePanelContext from 'refactor/hooks/usePanelContext'

import { focusMiddleSearchBar, focusRightSearchBar } from 'state/imperativeApis/editorApi'
import ErrorBoundary from 'components/shared/ErrorBoundary'

function FilterIcon(props) {
    let { panelId, filter } = usePanelContext()
    const panel = props.panel
    const filters = useStreamFilters(panelId)
    //console.log('FILTER ICON', filters)

    const onSelectLinks = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        filters.selectLinks()
    }

    const onSelectClips = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        filters.selectClips()
    }

    const onSelectPublicClips = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        filters.selectPublicClips()
    }

    const onSelectPublic = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        filters.selectPublic(filter.order)
    }

    const onSelectTreechat = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        filters.selectTreechat()
    }

    const onSelectHodlocker = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        filters.selectHodlocker()
    }

    const onSelectOrdinals = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        filters.selectOrdinals()
    }

    const onSelectTwetch = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        filters.selectTwetch()
    }

    const onSelectPrivate = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        filters.selectPrivate()
    }

    const onSelectTeam = (teamId, ev) => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()

        if (teamId) filters.selectTeam(teamId)
    }

    return (
        <div className={styles.filterIconComp}>
            {gon?.currentUser && !props.filter?.questId && props.filter?.notifications && (
                <div className={styles.iconContainer}>
                    <NotificationStream
                        disable={props.disable}
                        filter={props.filter}
                        panel={props.panel}
                        tip="Show Notification Stream"
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.notiIcon)}
                        showIcon
                        showNumNoti={props.showNumNoti}
                    />
                </div>
            )}

            {filters.isStarredSelected() && (
                <div className={cn(styles.iconContainer)}>
                    <StarredStream
                        disable={props.disable}
                        tip="Show Starred Threads."
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.fontIcon, props.starredIcon)}
                    />
                </div>
            )}

            {filters.isHistorySelected() && (
                <div className={cn(styles.iconContainer)}>
                    <HistoryStream
                        disable={props.disable}
                        tip="Show past actions."
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.fontIcon, props.historyIcon)}
                    />
                </div>
            )}

            {filters.isPeopleSelected() && (
                <div className={cn(styles.iconContainer)}>
                    <PeopleStream
                        disable={props.disable}
                        tip="Show people you follow."
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.fontIcon, props.peopleIcon)}
                    />
                </div>
            )}

            {filters.isParticipantsSelected(filter?.participants) && (
                <div className={cn(styles.iconContainer)}>
                    <PeopleStream
                        disable={props.disable}
                        tip="Show people you follow."
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.fontIcon, props.peopleIcon)}
                    />
                </div>
            )}

            {filters.isUserSelected() && (
                <div className={cn(styles.iconContainer)}>
                    <UserStream
                        disable={props.disable}
                        filter={props.filter}
                        user={props.user}
                        tip="Show threads and messages you've authored."
                        type={props.type}
                        contextStyles={props.contextStyles}
                        size={'small'}
                    />
                </div>
            )}

            {filters.isLinkSelected() && (
                <div className={cn(styles.iconContainer)}>
                    <LinksStream
                        disable={props.disable}
                        onClick={onSelectLinks}
                        tip="Show Shared Url Threads"
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.fontIcon, props.linksIcon)}
                    />
                </div>
            )}

            {filters.isClipSelected() && (
                <div className={cn(styles.iconContainer)}>
                    <ClipStream
                        disable={props.disable}
                        onClick={onSelectClips}
                        tip="Show Clip Threads"
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.fontIcon, props.linksIcon)}
                    />
                </div>
            )}

            {filters.isPublicClipSelected() && (
                <div className={cn(styles.iconContainer)}>
                    <ClipStream
                        disable={props.disable}
                        onClick={onSelectPublicClips}
                        tip="Show Public Clip Threads"
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.fontIcon, props.linksIcon)}
                    />
                </div>
            )}

            {filters.isPublicSelected() && (
                <div className={cn('public-icon-container flex-item', styles.iconContainer)}>
                    <PublicStream
                        disable={props.disable}
                        onClick={onSelectPublic}
                        tip="Show Public Threads"
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.publicIcon)}
                    />
                </div>
            )}

            {filters.isTreechatSelected() && (
                <div className={cn('public-icon-container flex-item', styles.iconContainer)}>
                    <TreechatStream
                        disable={props.disable}
                        onClick={onSelectTreechat}
                        tip="Show Public Treechat Threads"
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.treechatIcon)}
                    />
                </div>
            )}

            {filters.isHodlockerSelected() && (
                <div className={cn('public-icon-container flex-item', styles.iconContainer)}>
                    <HodlockerStream
                        disable={props.disable}
                        onClick={onSelectHodlocker}
                        tip="Show Hodlocker Threads"
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.hodlockerIcon)}
                    />
                </div>
            )}

            {filters.isOrdinalsSelected() && (
                <div className={cn(styles.iconContainer)}>
                    <OrdinalsStream
                        disable={props.disable}
                        onClick={onSelectOrdinals}
                        tip="Show Ordinals Threads"
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.ordinalsIcon)}
                    />
                </div>
            )}

            {filters.isTwetchSelected() && (
                <div className={cn(styles.iconContainer)}>
                    <TwetchStream
                        disable={props.disable}
                        onClick={onSelectTwetch}
                        tip="Show Twetch Threads"
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.twetchIcon)}
                    />
                </div>
            )}

            {gon.currentUser && filters.isPrivateSelected() && (
                <div className={cn(styles.iconContainer)}>
                    <PrivateStream
                        disable={props.disable}
                        onClick={onSelectPrivate}
                        tip="Show Private Threads"
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.fontIcon, props.privateIcon)}
                        isControl={props.isControl}
                    />
                </div>
            )}

            {gon.currentUser && filters.isAllSelected() && (
                <div className={cn(styles.iconContainer)}>
                    <AllStream
                        disable={props.disable}
                        filter={filter}
                        panel={panel}
                        tip="Show All Stream"
                        type={props.type}
                        contextStyles={cn(props.contextStyles, props.fontIcon, props.allIcon)}
                    />
                </div>
            )}

            {(() => {
                if (!filters.filterTeamId || !filters.teams) return null
                const team = filters.teams.find(team => filters.filterTeamId === team.id)
                if (!team) return null

                return (
                    <div
                        key={filters.filterTeamId}
                        className={cn(
                            'team-icon-container flex-item',
                            styles.iconContainer,
                            streamStyles.teamStreamComp,
                            props.type === 'control' ? streamStyles.controlStyles : null,
                        )}
                    >
                        <TeamIcon
                            disable={props.disable}
                            team={team}
                            onClick={onSelectTeam}
                            selectedClass={
                                filters.isTeamSelected(filters.filterTeamId) ? 'selected' : ''
                            }
                            tip={`Show ${team.name} Group`}
                            type={props.type}
                            borderStyles={props.borderStyles}
                            contextStyles={props.contextStyles}
                            initialStyles={props.initialStyles}
                        />
                    </div>
                )
            })()}
        </div>
    )
}

export default React.forwardRef((props, ref) => (
    <ErrorBoundary label={'FilterIcon'}>
        <FilterIcon ref={ref} {...props} />
    </ErrorBoundary>
))

const UserStream = React.memo(function UserStream(props) {
    const { user, virtualUserIcon, disable } = props
    const { panel } = usePanelContext()
    const panelId = panel?.panelId
    const filters = useStreamFilters(panelId, { append: true })
    const { insertRight } = useInsertRight()

    const onSelectUser = React.useCallback(
        ev => {
            if (disable || user?.id === gon?.TWETCH_USER_ID) return

            ev.preventDefault()
            ev.stopPropagation()

            if (panelId) insertRight({ filter: withDepth({ user: user.id }) })
            else filters.selectUser(user.id)
        },
        [disable, user?.id, panelId, insertRight, filters],
    )

    return (
        <WithTooltip disable={disable} tip={props.tip} place={props.place}>
            <div onClick={onSelectUser}>
                <UserIcon
                    user={user}
                    contextStyles={props.contextStyles}
                    agentIcon={props.agentIcon}
                    virtualUserIcon={virtualUserIcon}
                    showKnovigator={props.showKnovigator}
                    size={props.size}
                />
            </div>
        </WithTooltip>
    )
})

function LinksStream(props) {
    return (
        <WithTooltip disable={props.disable} tip={props.tip}>
            <div onClick={props.onClick}>
                <LinkIcon
                    onClick={props.onClick}
                    user={gon?.currentUser}
                    contextStyles={props.contextStyles}
                />
            </div>
        </WithTooltip>
    )
}

function ClipStream(props) {
    return (
        <WithTooltip disable={props.disable} tip={props.tip}>
            <div onClick={props.onClick}>
                <ClipIcon
                    onClick={props.onClick}
                    user={gon?.currentUser}
                    contextStyles={props.contextStyles}
                />
            </div>
        </WithTooltip>
    )
}

function PublicStream(props) {
    const { panelId } = usePanelContext()
    const { selectPublic } = useStreamFilters(panelId, { append: !!props.append })

    const onSelectPublic = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        selectPublic()
    }

    return (
        <WithTooltip disable={props.disable} tip={props.tip}>
            <div onClick={onSelectPublic}>
                <PublicIcon
                    contextStyles={props.contextStyles}
                    initialStyles={props.initialStyles}
                />
            </div>
        </WithTooltip>
    )
}

function TreechatStream(props) {
    return (
        <WithTooltip disable={props.disable} tip={props.tip}>
            <div onClick={props.onClick}>
                <TreechatIconPng
                    contextStyles={props.contextStyles}
                    initialStyles={props.initialStyles}
                />
            </div>
        </WithTooltip>
    )
}

function HodlockerStream(props) {
    return (
        <WithTooltip disable={props.disable} tip={props.tip}>
            <div onClick={props.onClick}>
                <HodlockerIcon
                    contextStyles={props.contextStyles}
                    initialStyles={props.initialStyles}
                />
            </div>
        </WithTooltip>
    )
}

function OrdinalsStream(props) {
    return (
        <WithTooltip disable={props.disable} tip={props.tip}>
            <div onClick={props.onClick}>
                <OrdinalsIcon
                    contextStyles={props.contextStyles}
                    initialStyles={props.initialStyles}
                    size={40}
                />
            </div>
        </WithTooltip>
    )
}

function TwetchStream(props) {
    return (
        <WithTooltip disable={props.disable} tip={props.tip}>
            <div onClick={props.onClick}>
                <TwetchIcon
                    contextStyles={props.contextStyles}
                    initialStyles={props.initialStyles}
                />
            </div>
        </WithTooltip>
    )
}

function PrivateStream(props) {
    const { panelId } = usePanelContext()
    const { selectPrivate } = useStreamFilters(panelId, { append: !!props.append })

    const onSelectPrivate = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        selectPrivate()
    }

    return (
        <WithTooltip disable={props.disable} tip={props.tip}>
            <div onClick={onSelectPrivate}>
                <PrivateIcon contextStyles={props.contextStyles} isControl={props.isControl} />
            </div>
        </WithTooltip>
    )
}

function AllStream(props) {
    const { panelId } = usePanelContext()
    const { selectAll } = useStreamFilters(panelId, { append: !!props.append })

    const onSelectAll = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        selectAll()
    }

    return (
        <WithTooltip disable={props.disable} tip={props.tip}>
            <div onClick={onSelectAll}>
                <AllIcon contextStyles={props.contextStyles} />
            </div>
        </WithTooltip>
    )
}

function PeopleStream({ disable, tip, contextStyles }) {
    const { panelId } = usePanelContext()
    const { selectPeople } = useStreamFilters(panelId)

    const onSelectPeople = ev => {
        if (disable) return

        ev.preventDefault()
        ev.stopPropagation()
        selectPeople()
    }

    return (
        <WithTooltip disable={disable} tip={tip}>
            <div onClick={onSelectPeople}>
                <PeopleIcon contextStyles={contextStyles} />
            </div>
        </WithTooltip>
    )
}

function StarredStream({ disable, tip, contextStyles }) {
    const { panelId } = usePanelContext()
    const { selectStarred } = useStreamFilters(panelId)

    const onSelectStarred = ev => {
        if (disable) return

        ev.preventDefault()
        ev.stopPropagation()
        selectStarred()
    }

    return (
        <WithTooltip disable={disable} tip={tip}>
            <div onClick={onSelectStarred}>
                <StarredIcon contextStyles={contextStyles} />
            </div>
        </WithTooltip>
    )
}

function HistoryStream({ disable, tip, contextStyles }) {
    const { panelId } = usePanelContext()
    const { selectHistory } = useStreamFilters(panelId)

    const onSelectHistory = ev => {
        if (disable) return

        ev.preventDefault()
        ev.stopPropagation()
        selectHistory()
    }

    return (
        <WithTooltip disable={disable} tip={tip}>
            <div onClick={onSelectHistory}>
                <HistoryIcon contextStyles={contextStyles} />
            </div>
        </WithTooltip>
    )
}

function NotificationStream(props) {
    const { panelId } = usePanelContext()
    const { selectNotifications } = useStreamFilters(panelId, { append: !!props.append })
    const numNoti = useStore(state => state.numNoti)

    const onSelectNotification = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        selectNotifications()
    }

    return (
        <WithTooltip disable={props.disable} tip={props.tip} place={props.place}>
            <div onClick={onSelectNotification}>
                <NotiIcon
                    showNumNoti={props.showNumNoti}
                    numNoti={numNoti}
                    canActive={props.canActive}
                    contextStyles={props.contextStyles}
                    showIcon={props.showIcon}
                    absolute={props.absolute}
                    activeStyles={props.activeStyles}
                />
            </div>
        </WithTooltip>
    )
}

function SearchStream(props) {
    const panel = props.panel

    const { panelId } = usePanelContext()
    const { selectAll } = useStreamFilters(panelId, { append: props.append })

    const onSelectSearch = ev => {
        if (props.disable) return

        ev.preventDefault()
        ev.stopPropagation()
        // Setting stream order without an order just puts you in the current stream with current order.
        selectAll(null, {
            callback: () => {
                if (panel === 'middle') setTimeout(() => focusMiddleSearchBar(), 100)
                else setTimeout(() => focusRightSearchBar(), 100)
            },
        })
    }

    return (
        <WithTooltip disable={props.disable} tip={props.tip} place={props.place}>
            <div onClick={onSelectSearch}>
                <div className={props.contextStyles}>
                    <i className="fa fa-search" />
                </div>
            </div>
        </WithTooltip>
    )
}

export {
    NotificationStream,
    UserStream,
    PublicStream,
    PrivateStream,
    AllStream,
    SearchStream,
    PeopleStream,
    StarredStream,
    HistoryStream,
}
