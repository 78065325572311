import React, { useState, useRef, useEffect } from 'react'
import styles from 'components/panels/stream-options.module.scss'
import WithTooltip from 'components/shared/WithTooltip'
import useStreamFilter from 'components/filters/useStreamFilters'
import { getDepth } from 'components/filters/useStreamFilters'

interface StreamOptionsProps {
    panelId: string
    filter: any
}

export default function StreamOptions({ panelId, filter }: StreamOptionsProps) {
    const [showStreamOptionsPopup, setShowStreamOptionsPopup] = useState(false)
    const popupRef = useRef<HTMLDivElement>(null)
    const controlRef = useRef<HTMLDivElement>(null)

    const { setStreamDepth } = useStreamFilter(panelId)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target as Node) &&
                controlRef.current &&
                !controlRef.current.contains(event.target as Node)
            ) {
                setShowStreamOptionsPopup(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [popupRef, controlRef])

    const togglePopup = (ev: React.MouseEvent) => {
        ev.stopPropagation()
        ev.preventDefault()
        setShowStreamOptionsPopup(!showStreamOptionsPopup)
    }

    const handleOption = (ev: React.MouseEvent, depth: string) => {
        ev.stopPropagation()
        ev.preventDefault()

        setStreamDepth(depth)
        setShowStreamOptionsPopup(false)
    }

    const depth = getDepth(filter)
    const label = depth?.charAt(0).toUpperCase() + depth?.slice(1) || 'Roots'
    let tip = 'Show root threads'
    if (depth === 'branches') {
        tip = 'Show roots & branches'
    }

    return (
        <div className={styles.streamOptionsComp}>
            <WithTooltip tip={tip}>
                <div ref={controlRef} className={styles.streamOptionsControl} onClick={togglePopup}>
                    <div className={styles.streamOptionLabel}>{label}</div>
                    <div className={styles.streamOptionsControl}>
                        <i className="fa fa-angle-down" />
                    </div>
                </div>
            </WithTooltip>

            <div className={styles.streamOptionsPopupContainer}>
                {showStreamOptionsPopup && (
                    <div ref={popupRef} className={styles.streamOptionsPopup}>
                        <div
                            className={styles.streamOption}
                            onClick={ev => handleOption(ev, 'roots')}
                        >
                            Roots
                        </div>

                        <div
                            className={styles.streamOption}
                            onClick={ev => handleOption(ev, 'branches')}
                        >
                            Branches
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
