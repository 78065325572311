// HACK TO CLEAN UP insert null bug for entity quests.
const cleanDeltaJson = deltaJson => {
    if (deltaJson && deltaJson.ops) {
        const newDeltaJson = { ...deltaJson, ops: [...deltaJson.ops] }

        for (var op of newDeltaJson.ops) {
            if (typeof op?.insert !== undefined && op?.insert === null) {
                op = { insert: '' }
            }
        }
        return newDeltaJson
    }
    return deltaJson
}

const removeTrailingNewlines = deltaJson => {
    if (!deltaJson) return null

    let newDeltaJson = { ...deltaJson, ops: [...deltaJson.ops] }
    let ops = newDeltaJson?.ops
    if (ops) {
        // Cleanup trailing blank inserts.
        let lastInsertString = ops[ops.length - 1]?.insert
        while (typeof lastInsertString === 'string' && lastInsertString?.trim() === '') {
            ops.pop()
            lastInsertString = ops[ops.length - 1]?.insert
        }

        // Cleanup and trailing newlines in non-blank insert string.
        if (typeof lastInsertString === 'string') {
            ops.pop()
            ops.push({ insert: lastInsertString.trimEnd() })
        }
    }
    return newDeltaJson
}

const getValue = answer => {
    let value = null
    if (answer?.delta_json) {
        value = cleanDeltaJson({ ...answer.delta_json })
    } else {
        value = answer?.content
    }
    return value
}

/**
 * Extracts text from a Delta JSON object, optionally wrapping usernames.
 * @param {Object} deltaJson - The Delta JSON object containing the text.
 * @param {Object} [options] - Optional configuration.
 * @param {boolean} [options.wrapUsernames=false] - Whether to wrap usernames in square brackets.
 * @returns {string|null} The extracted text, or null if deltaJson is falsy.
 */
const getText = (deltaJson, options = {}) => {
    if (deltaJson) {
        const { wrapUsernames = false } = options
        const ops = deltaJson['ops'] || []
        return ops
            .filter(
                op =>
                    typeof op.insert === 'string' ||
                    typeof op.insert === 'number' ||
                    op?.insert?.mention,
            )
            .map(op => {
                if (op?.insert?.mention) {
                    if (op.insert.mention.type === 'model') {
                        return `@@${op.insert.mention.content}`
                    } else {
                        // BACKWARD COMPATIBILITY : old mentions had denotationChar as empty string and content with leading '@'
                        let username = op.insert.mention.content
                        username = username || op.insert.mention.value || ''
                        // Remove @ if it exists at the start
                        if (username.startsWith('@')) {
                            username = username.substring(1)
                        }

                        return username ? (wrapUsernames ? `@[${username}]` : `@${username}`) : ''
                    }
                }
                // Explicitly convert to string to handle numbers
                return String(op?.insert || '')
            })
            .join('')
            .trim()
    } else {
        return null
    }
}

const getImages = deltaJson => {
    if (deltaJson) {
        const ops = deltaJson['ops'] || []
        const images = ops.filter(op => op?.insert?.image).map(op => op.image)
        return images?.length > 0 ? images : null
    } else {
        return null
    }
}

const isEmpty = deltaJson => {
    try {
        if (deltaJson) {
            const ops = deltaJson['ops'] || []

            // If no ops, it's empty
            if (ops.length === 0) return true

            // First check for model mentions - if we find one, the content is not empty
            const hasModelMention = ops.some(op => op?.insert?.mention?.denotationChar === '@@')
            if (hasModelMention) return false

            // If only one op, check if it's truly empty
            if (ops.length === 1) {
                const insert = ops[0].insert

                // Handle string inserts
                if (typeof insert === 'string') {
                    return insert.trim() === ''
                }

                // Handle object inserts (like mentions)
                if (typeof insert === 'object' && insert !== null) {
                    return false
                }
            }

            // More than one op means it's not empty
            return false
        } else return true
    } catch (e) {
        console.error('Error in isEmpty check:', e)
        return true
    }
}

/**
 * Checks if a delta contains a model mention
 * @param {Object} delta - The Quill delta object to check
 * @returns {boolean} - True if the delta contains a model mention
 */
const containsModelMention = delta => {
    if (!delta || !delta.ops) return false

    // Debug: log the delta content to see what we're working with
    // console.log('Checking delta for model mentions:', JSON.stringify(delta.ops));

    // Check each operation for a model mention
    return delta.ops.some(op => {
        if (op.insert && typeof op.insert === 'object' && op.insert.mention) {
            const mention = op.insert.mention
            const isModel = mention.type === 'model'
            //console.log('Found mention:', mention, 'isModel:', isModel);
            return isModel
        }
        return false
    })
}

const createModelMention = model => {
    return {
        id: model,
        value: model,
        content: model,
        denotationChar: '@@',
        type: 'model',
        link: `/models/${model}`,
        isModel: true,
    }
}

export {
    getText,
    getImages,
    getValue,
    isEmpty,
    removeTrailingNewlines,
    containsModelMention,
    createModelMention,
}
