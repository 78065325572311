import React, { useEffect, useRef, useLayoutEffect } from 'react'
import { isMobile } from 'react-device-detect'
import cn from 'classnames'
import useQuestChannel from 'state/channels/useQuestChannel'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useGetQuest from 'refactor/hooks/api/useGetQuest'
import { isPanelLeft, isPanelCenter, isPanelRight } from 'state/imperativeApis/swiperApi'
import Quest from 'components/quests/Quest'
import styles from 'components/panels/panel.module.scss'
import api from 'api/api'
import queryClient from 'api/queryClient'
import PrivateQuest from 'components/quests/PrivateQuest'
import { logEv } from 'lib/log'
import { getCachedAnswer } from 'state/cache'

interface QuestContainerProps {
    filter: {
        questId: string
        answerId?: string
        query?: any
    }
}

export default function QuestContainer({ filter }: QuestContainerProps) {
    const { panel } = usePanelContext()
    const panelId = panel?.panelId
    const questId = filter?.questId
    const { quest, isFetching } = useGetQuest(questId)

    useQuestChannel(questId)

    const newAnswerRef = useRef(null)

    useEffect(function onMount() {
        ;(async () => {
            const isOnScreen = isMobile
                ? isPanelCenter(panelId)
                : isPanelLeft(panelId) || isPanelCenter(panelId) || isPanelRight(panelId)
            if (
                isOnScreen &&
                !quest?.is_draft &&
                !quest?.localChildQuest &&
                !quest?.isLocalRootQuest
            ) {
                await api.userViewsQuest(filter.questId)
                const actionsQueryKey = ['actions']
                queryClient.invalidateQueries({ queryKey: actionsQueryKey })
                queryClient.refetchQueries({ queryKey: actionsQueryKey })

                // Break React Query cache for this quest ID
                queryClient.refetchQueries({ queryKey: ['quest', questId] })
            }
        })()
        logEv('QUEST VIEW', { questId })
    }, [])

    useEffect(
        function initScroll() {
            const answerId = panel?.filter?.answerId
            if (!isFetching && answerId && quest?.sorted_answers) {
                const delayHighlight = 300

                if (isMobile) {
                    // There is a hidden useLayoutEffect slide in useSlideEffects that triggers a slideNext
                    // This breaks the 'slideChangeTransitionEnd' approach.
                    // Use a timeout instead until we can refactor useSlideEffects TODO.
                    setTimeout(function highlightAnswer() {
                        if (panel) {
                            panel.scrollToAnswer(answerId, () => {
                                setTimeout(() => {
                                    panel.highlightAnswer(answerId)
                                }, delayHighlight)
                            })
                        }
                    }, 300)
                } else {
                    panel?.scrollToAnswer(answerId, () => {
                        setTimeout(() => {
                            panel.highlightAnswer(answerId)
                        }, delayHighlight)
                    })
                }
            }
        },
        [quest?.id, panel?.filter?.answerId],
    )

    if (quest?.id === '__PRIVATE__') {
        return <PrivateQuest />
    }

    return !quest && isFetching ? (
        <Loading />
    ) : (
        <Quest
            panelId={panelId}
            panel={panel}
            isActive={true}
            showAll={true}
            quest={quest}
            shouldPlay={window.location.href.endsWith('#play')}
            filter={filter}
            query={filter?.query}
            ix={0}
            newAnswerRef={newAnswerRef}
        />
    )
}

function Loading() {
    return (
        <div className={styles.loader}>
            <div className={cn(styles.loadingIcon)}>
                <i className="fa fa-circle-o-notch fa-spin fa-lg" />
            </div>
        </div>
    )
}
