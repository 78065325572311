import React, { useState, useEffect, Suspense, lazy } from 'react'
import Modal from 'react-modal'
import ExportQuest from '../modals/ExportQuest'
import ImportText from '../modals/ImportText'
import VideoRecorder from '../answers/VideoRecorder'
import ScreenRecorder from '../answers/ScreenRecorder'
import Reload from '../modals/Reload'
import NewStream from '../modals/NewStream'
import NewSpace from 'components/modals/NewSpace'
import UserImageUpdate from 'components/modals/UserImageUpdate'
import SpaceImageUpdate from 'components/modals/SpaceImageUpdate'
import StreamImageUpdate from 'components/modals/StreamImageUpdate'
import useStore from 'state/knovStore'
import HandCashError from '../modals/HandCashError'
import styles from 'components/shared/modal.module.scss'
import cn from 'classnames'
import { isMobile } from 'react-device-detect'
import Wallet from 'components/modals/Wallet'
import { Wallet as WalletIcon } from 'lucide-react'
import OrdinalsIcon from './OrdinalsIcon'
import Confirm from 'components/quests/Confirm'
import EditProfile from 'components/users/EditProfile'
import OrdinalsViewer from 'components/modals/OrdinalsViewer'

// Lazy loaded components
const MetricsViewer = lazy(() => import('components/modals/MetricsViewer'))

const CUSTOM_MODAL_ICONS = {
    WalletIcon: <WalletIcon size={40} />,
    OrdinalsIcon: <OrdinalsIcon size={40} />,
}

Modal.setAppElement('#knovigator-body')

// Setting top does not work bc react-modal uses inset property to control top margin.
const customStyles = {
    content: {
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, 0%)',
        width: '50dvw',
        maxWidth: '50dvw',
        height: '80dvh',
        padding: 0,
        overflow: 'visible',
        borderRadius: '8px',
    },
}

const mobileCustomStyles = {
    content: {
        width: '95dvw',
        maxWidth: '95dvw',
        height: '80dvh',
        padding: 0,
        overflow: 'visible',
    },
}

export default function KnovModal(props) {
    const isModalOpen = useStore(state => state.isModalOpen)
    const closeModal = useStore(state => state.actions.modalActions.closeModal)
    const modalParams = useStore(state => state.modalParams) || props.modalParams

    const logoSize = '36px'
    const hiddenModal = modalParams && modalParams.hidden

    const mobileStyles = isMobile ? styles.mobileKnovModal : null
    const mobileRemoveBtnStyles = isMobile ? styles.mobileRemoveBtn : null

    const mergedStyles = {
        content: {
            ...customStyles.content,
            ...(isMobile && mobileCustomStyles.content),
            ...(modalParams?.customStyles ? modalParams.customStyles : {}),
            ...(isMobile && modalParams?.mobileCustomStyles ? modalParams.mobileCustomStyles : {}),
        },
    }

    return hiddenModal ? (
        <div style={{ height: 0, width: 0 }}>{getModalContent(modalParams, closeModal)}</div>
    ) : (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            style={mergedStyles}
            contentLabel="Knov Modal"
        >
            <div id="knov-modal" className={cn('knov-modal-comp', styles.knovModal, mobileStyles)}>
                {!modalParams?.noHeader && (
                    <div
                        className={cn(
                            styles.knovModalHeader,
                            modalParams?.type === 'Wallet' && styles.walletHeader,
                        )}
                    >
                        <div className={styles.knovModalBrand}>
                            {modalParams?.icon && (
                                <div className={styles.modalIcon}>
                                    {
                                        // use custom modal icon if specified, otherwise fallback to fa icon
                                        CUSTOM_MODAL_ICONS[modalParams?.icon] || (
                                            <div className={styles.fontIcon}>
                                                <i className={modalParams.icon} />
                                            </div>
                                        )
                                    }
                                </div>
                            )}
                            {modalParams?.title && (
                                <span className={styles.modalTitle}>{modalParams.title}</span>
                            )}
                        </div>
                    </div>
                )}
                <div className={cn(styles.removeBtn, mobileRemoveBtnStyles)} onClick={closeModal}>
                    <i className="fa fa-close fa-lg"></i>
                </div>
                <div className={cn('knov-modal-content', styles.knovModalContent)}>
                    {getModalContent(modalParams, closeModal)}
                </div>
            </div>
        </Modal>
    )
}
function getModalContent(modalParams, closeModal) {
    if (!modalParams) return null

    switch (modalParams.type) {
        case 'ExportQuest':
            return <ExportQuest modalParams={modalParams} closeModal={closeModal} />
        case 'ImportText':
            return <ImportText modalParams={modalParams} closeModal={closeModal} />
        case 'VideoRecorder':
            return <VideoRecorder modalParams={modalParams} closeModal={closeModal} />
        case 'ScreenRecorder':
            return <ScreenRecorder modalParams={modalParams} closeModal={closeModal} />
        case 'Reload':
            return <Reload modalParams={modalParams} closeModal={closeModal} />
        case 'NewStream':
            return <NewStream modalParams={modalParams} closeModal={closeModal} />
        case 'NewSpace':
            return <NewSpace modalParams={modalParams} closeModal={closeModal} />
        case 'SpaceImageUpdate':
            return <SpaceImageUpdate modalParams={modalParams} closeModal={closeModal} />
        case 'UserImageUpdate':
            return <UserImageUpdate modalParams={modalParams} closeModal={closeModal} />
        case 'StreamImageUpdate':
            return <StreamImageUpdate modalParams={modalParams} closeModal={closeModal} />
        case 'HandCashError':
            return <HandCashError modalParams={modalParams} closeModal={closeModal} />
        case 'Message':
            return <div>{modalParams.message}</div>
        case 'Wallet':
            return <Wallet modalParams={modalParams} closeModal={closeModal} />
        case 'OrdinalsViewer':
            return <OrdinalsViewer modalParams={modalParams} closeModal={closeModal} />
        case 'MetricsViewer':
            return (
                <Suspense
                    fallback={
                        <div
                            className="loading-fallback"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50vh',
                                flexDirection: 'column',
                            }}
                        >
                            <div style={{ marginBottom: '20px' }}>
                                <i className="fa fa-circle-o-notch fa-spin fa-2x" />
                            </div>
                            <div>Loading metrics...</div>
                        </div>
                    }
                >
                    <MetricsViewer modalParams={modalParams} closeModal={closeModal} />
                </Suspense>
            )
        case 'Confirm':
            return <Confirm modalParams={modalParams} closeModal={closeModal} />
        case 'EditProfile':
            return <EditProfile modalParams={modalParams} closeModal={closeModal} />
    }
}
